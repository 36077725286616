import { Component, Input, OnChanges, OnInit, ChangeDetectorRef, ElementRef, ViewChild, HostListener } from '@angular/core';
import { TabItem, TabReceiver } from '../models/tab-item';
import { LocalizeService } from '../services/localize.service';
import { Util } from '../utils/utils.module';
import { PopupComponent } from './popup.component';

/**
 * Created by kevin on 2016-10-20.
 */

@Component({
  selector: 'edx-tab-selector',
  styleUrls: ['tab-selector.component.scss'],
  template: `
    <div class="container" role="tablist">
      <div class="leftspinner" *ngIf="overflowItems > 0" [ngClass]="{ disabled: startIdx <= 0 }" [attr.aria-disabled]="startIdx <= 0"[tabindex]="tabIndex" (click)="shiftTabs(true)" (keyup.enter)="shiftTabs(true)" (keyup.space)="shiftTabs(true)" [attr.aria-label]="getAriaLabel('left')" role="button"><div class="icon"></div></div>
      <div #tabcontainer class="tabcontainer" [ngClass]="{disabled:disable}">
        <ng-template ngFor let-tabdef [ngForOf]="tabdefs" let-idx="index">
          <div #tabitem *ngIf="!layoutComplete || (idx >= startIdx)" role="tab" [aria-selected]="tabdef.selected" class="tabitem" [ngClass]="{selected:tabdef.selected,enabled:tabdef.enabled,measuring:!layoutComplete,iconic:!!tabdef.icon,disableHover:(!tabdef.selected && disable)}" [title]="tabdef.title" [id]="tabdef.title" [tabindex]="(!tabdef.selected && disable) ? -1 : tabIndex" (click)="selectTab(idx)" (keydown.tab)="tabdef.title === 'Security'?shiftFocusToFilterIcon($event):(tabdef.title === 'Saved'?shiftFocusToWhatToSearch($event):(tabdef.title === 'Recent' && isOfficeAddIn ? shiftTabs(false) : null))" (keyup.enter)="selectTab(idx,$event)" (keyup.space)="selectTab(idx,$event)">
            <span *ngIf="!tabdef.icon">{{tabdef.title}}</span>
            <div *ngIf="!!tabdef.icon" class="tabicon" [style.backgroundImage]="getBGImage(tabdef)" role="img" [alt]="tabdef.title"></div>
          </div>
        </ng-template>
      </div>
      <div class="rightspinner" *ngIf="overflowItems > 0" [ngClass]="{ disabled: startIdx >= (overflowItems + hiddenItems) }" [tabindex]="tabIndex" (click)="shiftTabs(false)" (keyup.enter)="shiftTabs(false,$event)" role="button" [attr.aria-disabled]="startIdx >= (overflowItems + hiddenItems)" [attr.aria-label]="getAriaLabel('right')" (keyup.space)="shiftTabs(false,$event)"><div class="icon"></div></div>
    </div>
`
})
export class TabSelectorComponent implements OnChanges, OnInit {
  @Input() tabdefs: TabItem[];
  @Input() receiver: TabReceiver;
  @Input() allowOverflow?: boolean = true;
  @Input() tabID?: string;
  @Input() disable?: boolean = false;
  @Input() tabIndex?: number = 0;
  @ViewChild('tabcontainer') containerEl: ElementRef;
  public overflowItems = 0;
  private layoutComplete = false;
  private hiddenItems = 0;
  private startIdx = 0;
  private isOfficeAddIn = false;

  constructor(private localizer: LocalizeService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    for (const tab of this.tabdefs) {
      tab.title = this.localizer.getTranslation(tab.title);
    }
    this.isOfficeAddIn = Util.Device.bIsOfficeAddin;
  }

  ngOnChanges(): void {
    this.updateTabs();
  }

  @HostListener('window:resize')
  private checkLayout(): void {
    this.overflowItems = 0;
    if (this.allowOverflow) {
      this.layoutComplete = false;
      setTimeout(() => {
        this.setVisibleTabRange();
      }, 1);
    } else {
      this.layoutComplete = true;
    }
  }

  private setVisibleTabRange(): void {
    if (!this.layoutComplete) {
      let remainingWidth = this.containerEl.nativeElement.offsetWidth;
      const spinnerOverhead = 114;
      const tabEls: any[] = this.containerEl.nativeElement.childNodes;
      let nOverflow = 0;
      let nMeasured = 0;
      let nHidden = 0;
      for (const currentElement of tabEls) {
        if (currentElement.tagName && currentElement.classList.contains('tabitem')) {
          if (currentElement.classList.contains('enabled')) {
            remainingWidth -= (currentElement.offsetWidth + Util.remsToPx(2.25));
          } else {
            nHidden++;
          }
          nMeasured++;
          if (remainingWidth < 0) {
            nOverflow++;
          } else if (remainingWidth < spinnerOverhead && nMeasured < (this.tabdefs.length)) {
            nOverflow++;
            remainingWidth -= spinnerOverhead;
          }
        }
      }
      this.overflowItems = nOverflow;
      this.hiddenItems = nHidden;
      if (this.overflowItems === 0) {
        this.startIdx = 0;
      }
      if (this.startIdx > (this.overflowItems + this.hiddenItems)) {
        this.startIdx = this.overflowItems + this.hiddenItems;
      }
      this.cdr.markForCheck();
      //to set the scrollbar to the selected filelistitem.
      const selectedElement = document.getElementsByClassName('selected fileitem') as any;
      if (selectedElement && selectedElement[0]) {
        selectedElement[0].scrollIntoViewIfNeeded();
      }
      this.layoutComplete = true;
    }
  }

  private shiftTabs(toRight: boolean, event?: KeyboardEvent): void {
    const maxStartIdx = this.overflowItems + this.hiddenItems;
    const key = event?.key;
    if ((key === ' ' || key === 'Enter') && !toRight) {
      this.startIdx = this.tabdefs.length - 1;
    }
    if (toRight) {
      if (this.startIdx > 0) {
        this.startIdx--;
        while (this.startIdx > 0 && !this.tabdefs[this.startIdx].enabled) {
          this.startIdx--;
        }
      }
    } else {
      if (this.startIdx < maxStartIdx ) {
        this.startIdx++;
        while (this.startIdx <= maxStartIdx && !this.tabdefs[this.startIdx].enabled) {
          this.startIdx++;
        }
      }
    }
  }

  public updateTabs(): void {
    for (const tab of this.tabdefs) {
      if (this.receiver.tabEnabled) {
        tab.enabled = this.receiver.tabEnabled(tab.id);
      } else {
        tab.enabled = true;
      }
    }
    if (this.tabdefs.length) {
      const selectedTab: TabItem = this.tabdefs.find(t => !!t.selected);
      if (!selectedTab) {
        const enabledTab = this.tabdefs.filter(tab => tab.enabled)?.[0];
        if (!!enabledTab) {
          enabledTab.selected = true;
        } else {
          this.tabdefs[0].selected = true;
        }
      }
    }
    this.cdr.markForCheck();
    this.layoutComplete = false;
    this.checkLayout();
  }

  public selectTabById(id: string) {
    let index = 0;
    for (const tab of this.tabdefs) {
      if (tab.id === id) {
        this.selectTab(index);
        break;
      }
      index++;
    }
  }

  private selectTab(index: number, event?: KeyboardEvent): void {
    if (!this.disable) {
      const tabdef: TabItem = this.tabdefs[index];
      if (!this.receiver || !this.receiver.tabEnabled || this.receiver.tabEnabled(tabdef.id)) {
        this.tabdefs.forEach(t => t.selected = false);
        tabdef.selected = true;
        if (this.receiver) {
          this.receiver.tabSelected(tabdef.id);
          if (!this.receiver.allowEventPropagation?.()) {
            event?.preventDefault?.();
            event?.stopPropagation?.();
          }
        }
      }
    }
  }

  private getBGImage(tabdef: TabItem): string {
    if (!!tabdef.icon) {
      return 'url(assets/images/'+tabdef.icon+')';
    }
    return null;
  }

  private shiftFocusToFilterIcon(event: Event) {
    if (PopupComponent.nPopupsShown === 0) {
      const filterIcon = document.getElementById('edx_action_showfilter');
      if (!!filterIcon) {
        filterIcon.focus();
        event.preventDefault();
      }
    }
  }

  private shiftFocusToWhatToSearch(event: Event) {
    const searchTitle = document.getElementsByClassName('search-title')[0] as HTMLElement;
    if (!!searchTitle && !this.isOfficeAddIn) {
      searchTitle.focus();
      event.preventDefault();
    }
  }

  public getAriaLabel(key: string): string {
    return key === 'right' ? this.localizer.getTranslation('METADATA.TABS.RIGHT') : this.localizer.getTranslation('METADATA.TABS.LEFT');
  }

  @HostListener('document:keydown', ['$event'])
  public toggleFocus(event: KeyboardEvent): void {
    if (event.ctrlKey && !!this.receiver) {
      switch (event.key) {
        case 'ArrowDown':
          this.receiver.focusOnContent();
          break;
        case 'ArrowUp':
          this.receiver.focusOnSelectedTab();
          break;
      }
    }
  }
}
