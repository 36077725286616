import { Component, Input, Output, OnChanges, EventEmitter, SimpleChange, HostListener, ViewChild, ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';

import { SelectItem } from '../models/form-field';
import { Util } from '../utils/utils.module';
import { LocalizeService } from '../services/localize.service';

export class DropDown {
  public _items: SelectItem[] = [];
  public _isMultiSelect = false;
  public ulWidth : string = null;
  public ulLeft: string = null;
  public ulRight = false;
  public ulTop: string = null;
  public selectedList: SelectItem[] = [];
  public keyHoverItem: SelectItem = null;
  public currentItem: SelectItem = null;
  public isOpen = false;
  public isPhone: boolean = Util.Device.isPhoneLook();
  public iOS: boolean = Util.Device.bIsIOSDevice;
  public inNofityDialog = false;
  private disabledScrollableItems: HTMLElement[] = [];
  public elementId?: string;
  public isOpening?: boolean = false;

  // overrides
  constructor(protected cdr: ChangeDetectorRef) { }
  protected getNativeUL(): HTMLUListElement {
    return null;
  }
  protected itemSelected(item: SelectItem, event: Event): boolean {
    return false;
  }

  protected setId(id: string): void {
    this.elementId = id;
  }

  protected toggleMenuOpen(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.isOpening = this.isOpen ? false : true;
    const elementId = this.elementId;
    setTimeout(() => {
      Util.Transforms.focusOnElementByAnyQueryFromArray(['[id^="'+ elementId +'"][class*="selected"]','[id^="'+ elementId +'"][class*="keyhover"]']);
    }, 100);
  }

  protected checkLayout(selNativeEl: HTMLElement, ulNativeEl: HTMLUListElement): void {
    if (!!selNativeEl) {
      let topParentWidth: number = window.innerWidth;
      let topParentHeight: number = window.innerHeight;
      const pageOffset = (element) => {
        let top = 0; 
        let left = 0;
        do {
          top -= element.scrollTop || 0;
          if (element.tagName !== 'TBODY') {
            top += element.offsetTop  || 0;
          }
          left += element.offsetLeft || 0;
          if (element.tagName === 'TD') {
            element = element.parentElement.parentElement;  // go up past the tr to the tbody
          } else {
            if (!!element.parentElement && element.parentElement !== element.offsetParent && element.parentElement.scrollTop) {
              top -= element.parentElement.scrollTop;
            }
            element = element.offsetParent;
          }
          if (!!element) {
            if (element.classList.contains('popup')) {
              if (Util.Device.bIsIE || (Util.Device.isMobile() && !Util.Device.bIsCordova)) {
                const rect = element.getBoundingClientRect();
                top += rect.top;
                left += rect.left;
              } else {
                topParentHeight = element.clientHeight;
                topParentWidth = element.clientWidth;
              }
              element = null;
            } else {
              this.disabledScrollableItems.push(element);
              element.classList.add('edx_noscroll');
            }
          }
        } while (element);
        return {
          top,
          left
        };
      };
      const offset = pageOffset(selNativeEl);
      this.ulLeft = offset.left.toString() + 'px';
      this.ulRight = false;
      this.ulTop = (this.inNofityDialog ? '64' : (offset.top + 34).toString()) + 'px';
      this.ulWidth = (!!selNativeEl ? selNativeEl.offsetWidth : 120).toString()+ 'px';
      setTimeout(() => {
        const ulHeight: number = !!ulNativeEl ? (ulNativeEl.clientHeight + 8) : 0;
        const ulWidth: number = !!ulNativeEl ? ulNativeEl.clientWidth : 0;
        if ((offset.top + ulHeight) > topParentHeight) {
          offset.top = topParentHeight - ulHeight;
          this.ulTop = (this.inNofityDialog ? '64' : offset.top.toString()) + 'px';
          this.cdr.markForCheck();
        }
        if ((offset.left + ulWidth) > topParentWidth) {
          this.ulLeft = null;
          this.ulRight = true;
          this.cdr.markForCheck();
        }
      }, 1);
    }
  }

  protected menuClosing(): void {
    this.disabledScrollableItems.forEach(element => {
      element.classList.remove('edx_noscroll');
    });
    this.disabledScrollableItems = [];
  }

  private scrollIntoView(index: number): void {
    const ulNativeEl: HTMLUListElement = this.getNativeUL();
    if (!!ulNativeEl) {
      const currentScrollTop: number = ulNativeEl.scrollTop;
      const ulHeight: number = ulNativeEl.offsetHeight;
      const curLI: HTMLLIElement = Array.from(ulNativeEl.children)[index] as HTMLLIElement;
      const liTop = curLI.offsetTop;
      if ((liTop < currentScrollTop) || (liTop > (currentScrollTop+ulHeight))) {
        ulNativeEl.scrollTop = liTop;
      }
    }
  }

  private nav(inc: number): void {
    let index: number = !!document.activeElement ? (this._items.findIndex( i=>i.display === document.activeElement.textContent) + (document.activeElement.textContent === '' ? (2*inc) : inc)) : 0;
    if (index<0) {
      index = 0;
    } else if (index>=this._items.length) {
      index = this._items.length-1;
    }
    setTimeout(() => {
      const ele = Util.Transforms.getHTMLElementById((!!this.elementId) ? this.elementId + '_' + index : undefined) as HTMLElement;
      if (ele.tabIndex === -1) {
        Util.Transforms.focusOnElementById((!!this.elementId) ? this.elementId + '_' + (index + inc) : undefined);
      } else {
        Util.Transforms.focusOnHTMLElement(ele);
      }
    }, 1);
    this.scrollIntoView(index);
  }

  protected onKeyup(event: KeyboardEvent, item?: SelectItem): boolean {
    let rc = true;
    if (this.isOpen) {
      switch (event.key) {
        case 'Enter': this.itemSelected(null, event); rc = false; break;
        case 'Escape': this.toggleMenuOpen(event); rc = false; break;
        case 'ArrowUp': this.nav(-1); rc = false; break;
        case 'ArrowDown': this.nav(1); rc = false; break;
        case ' ':
          if (this._isMultiSelect) {
            if (!this.keyHoverItem && this._items.length > 0) {
              this.keyHoverItem = this._items[0];
            }
            this.keyHoverItem = (!!item) ? item : this.keyHoverItem;
            if (!!this.keyHoverItem && !this.isOpening) {
              this.itemSelected(this.keyHoverItem, event);
            }
            rc = false;
          }
          break;
      }
    } else {
      switch (event.key) {
       case ' ': this.toggleMenuOpen(event); rc = false; break;
      }
    }
    if (!rc) {
      event.stopPropagation();
      event.preventDefault();
    }
    return rc;
  }
}

@Component({
  selector: 'edx-select',
  styleUrls: ['select.component.scss'],
  template: `
    <ng-template [ngIf]="useNativeSelect && !disabled">
      <div #nativeDiv *ngIf="!justButton" class="edx_select" [ngClass]="{disabled:disabled, savesearchselect:id==='$edx_savesearch_combo', justbutton:justButton, header:inHeader, formselect:id==='formselect', indialog:inDialog, phone:isPhone, accessrights: inAccessRights}" [attr.aria-label]="ariaLabel+' '+(isOpen?this.localizer.getTranslation('ALT_TEXT.COMBOBOX_EXPANDED'):this.localizer.getTranslation('ALT_TEXT.COMBOBOX_COLLAPSED'))">
        <div class="text">{{selectedList.length>0 ? selectedList[0].display : value}}</div>
      </div>
      <select #nativeSel [id]="id" (change)="selChange($event)" [required]="required || null" [ngClass]="{justbutton:justButton,formselect:id==='formselect',phone:isPhone,ios:iOS,indialog:inDialog}" [tabindex]="tabIndex">
        <option *ngIf="initialPlaceHolder" selected hidden value=" ">{{initialPlaceHolder}}</option>
        <option *ngFor="let item of _items" value="{{item.value}}" [disabled]="item.disabled" [selected]="isItemSelected(item) || null">{{item.display}}</option>
      </select>
      <div *ngIf="justButton" class="edx_select" [ngClass]="{disabled:disabled, open:isOpen, savesearchselect:id==='$edx_savesearch_combo', justbutton:justButton, accessrights: inAccessRights}" [tabindex]="tabIndex"></div>
    </ng-template>
    <ng-template [ngIf]="!useNativeSelect || disabled">
      <div class="select-wrapper" [ngClass]="{admin: id === 'edx_admin_libsel_item'}">
        <div #sel class="edx_select" id="selectopener" role="combobox" [title]="ariaLabel"
            [ngClass]="{singleitem:_items?.length===0,
                        disabled:disabled, 
                        open:isOpen,
                        savesearchselect:id==='$edx_savesearch_combo',
                        justbutton:justButton, 
                        header:inHeader,
                        formselect:id==='formselect',
                        indialog:inDialog, 
                        phone:isPhone,
                        accessrights: inAccessRights,
                        admin: id === 'edx_admin_libsel_item'}" 
            [tabindex]="disabled?-1:tabIndex" 
            (click)="toggleMenuOpen($event)" 
            (keydown.space)="toggleMenuOpen($event)" 
            (keydown.shift.tab)="closeOnFocusOut($event, 0, true)" 
            [attr.aria-label]="getLabel()">
          <div *ngIf="!justButton" class="text">{{dislpay || (selectedList.length>0 ? selectedList[0].display : value)}}</div>
        </div>
        <div *ngIf="isOpen" class="overlay" (click)="toggleMenuOpen($event)"></div>
        <ul *ngIf="_items?.length!==0" #ul 
            [ngClass]="{edx_hidden:!isOpen,
                        right:ulRight, innotify:inNotify,
                        multi:_isMultiSelect, indialog:inDialog,
                        indentation: edxContacts}"
            [style.top]="ulTop" [style.left]="ulLeft" [style.width]="ulWidth">
          <li *ngIf="initialPlaceHolder" selected value=" " class="hidden">
            <div class="item-container">
              <div class="text" role="option">{{initialPlaceHolder}}</div>
            </div>
          </li>
          <li *ngFor="let item of _items;let i=index" id="{{!!id?id+'_'+i:null}}" [attr.aria-label]="getSelectedLabel(item)" [ngClass]="{selected:isItemSelected(item), keyhover:(isItemSelected(item) || (!isSingleItemSelected() && (i==0))), disabled:item.disabled, heading:item.heading}" [tabindex]="item.disabled?-1:tabIndex" (click)="itemSelected(item, $event)" (keyup.space)="_isMultiSelect?onKeyup($event,item):itemSelected(item, $event)" (keyup.enter)="!_isMultiSelect?itemSelected(item, $event):null" (keydown.tab)="closeOnFocusOut($event, i)">
              <div *ngIf="_isMultiSelect" class="selector" [ngClass]="{disabled:disabled}"></div>
              <img *ngIf="showIcon" class="icon" src="{{item['src']}}"/>
              <div class="text" [title]="item.display" role="option">{{item.display}}</div>
          </li>
        </ul>
      </div>
    </ng-template>
  `
})
export class SelectComponent extends DropDown implements OnChanges, OnInit {
  @Input('items') set setter(items: SelectItem[]) {
    if (!this.isEqual(this._items, items)) {
      this._items = items;
      if (!!items && items.length > 0) {
        this.dislpay = this.selectedList.map(i => i.display).join(', ');
      }
    }
  }
  @Input('isMultiSelect') set msSetter(isMultiSelect: boolean) {
    this._isMultiSelect = isMultiSelect;
  }
  @Input() value?: any = null;
  @Input() disabled?: boolean = false;
  @Input() required?: boolean = false;
  @Input() justButton?: boolean = false;
  @Input() id?: string;
  @Input() initialPlaceHolder?: string;
  @Input() inDialog?: boolean = false;
  @Input() inNotify?: boolean = false;
  @Input() inHeader?: boolean = false;
  @Input() inAccessRights?: boolean = false;
  @Input() multiSelectSep?: string = ',';
  @Input() tabIndex = 0;
  @Input() data?: string;
  @Input() ariaLabel?: string = '';
  @Input() showIcon = false;
  @Output() change: EventEmitter<SelectComponent> = new EventEmitter<SelectComponent>();
  @ViewChild('ul') private ul: ElementRef;
  @ViewChild('sel') private sel: ElementRef;
  @ViewChild('nativeSel') private nativeSel: ElementRef;
  @ViewChild('nativeDiv') private nativeDiv: ElementRef;
  public useNativeSelect = false;
  private dislpay: string = null;
  @Input() edxContacts?:boolean = false;
  static isActive = false;
  public altLabels: any;

  constructor(protected cdr: ChangeDetectorRef,public localizer: LocalizeService) {
    super(cdr);
    this.altLabels={
      selected: this.localizer.getTranslation('ALT_TEXT.SELECTED'),
      notSelected: this.localizer.getTranslation('ALT_TEXT.NOT_SELECTED')
    };
  }

  ngOnInit() {
    super.setId(this.id);
  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    const valueChng: any = changes['value'];
    const itemsChng: any = changes['items'];
    this.useNativeSelect = !this._isMultiSelect && !this.inAccessRights && !Util.Device.bIsOfficeAddin && Util.Device.bIsTouchDevice && !this.edxContacts;
    if (valueChng || itemsChng) {
      if (this.value !== null && this._items && this._items.length > 0) {
        const values = this._isMultiSelect ? (this.value as string).split(this.multiSelectSep) : [this.value];
        this.selectedList = [];
        for (let dataVal of values) {
          const currentItem = this._items.find(i => {
            const selVal: any = i.value;
            const selType: string = typeof selVal;
            const dataType: string = typeof dataVal;
            if (dataType !== selType) {
              switch (selType ) {
                case 'string':
                  dataVal = dataVal?.toString();
                  break;
                case 'number':
                  if (dataType === 'boolean' && dataVal === false) {
                    dataVal = 0;
                  } else if (dataType === 'boolean' && dataVal === true) {
                    dataVal = 1;
                  } else if (dataType === 'string') {
                    dataVal = parseInt(dataVal);
                  }
                  break;
                case 'booelan':
                  if (dataType === 'number' && dataVal===0) {
                    dataVal = false;
                  } else if (dataType === 'number' && dataVal!==0) {
                    dataVal = true;
                  }
                  break;
              }
            }
            return selVal === dataVal;
          });
          if (!!currentItem) {
            this.selectedList.push(currentItem);
          }
        }
        this.dislpay = this.selectedList.map(i => i.display).join(', ');
      } else {
        this.selectedList = [];
        this.dislpay = null;
      }
    }
    this.inNofityDialog = this.inNotify;
    setTimeout(() => {
      this.onResize();
    }, 1);
  }

  isEqual(previousItems, newItems): boolean {
    if (!!previousItems && !!newItems) {
      if (Object.keys(previousItems).length !== Object.keys(newItems).length) {
        return false;
      } else {
        Object.keys(previousItems).forEach(prop => {
          if ((previousItems[prop] instanceof Object) && (newItems[prop] instanceof Object)) {
            return this.isEqual(previousItems[prop], newItems[prop]);
          } else {
            if (previousItems[prop] !== newItems[prop]) {
              return false;
            }
          }
        });
        return true;
      }
    }
    return false;
  }

  public getLabel(): string {
    return this.ariaLabel + ' ' + (this._isMultiSelect ? this.localizer.getTranslation('ALT_TEXT.MULTI_SELECT') : this.localizer.getTranslation('ALT_TEXT.SINGLE_SELECT')) + ' ' + (this.isOpen ? this.localizer.getTranslation('ALT_TEXT.COMBOBOX_EXPANDED') : this.localizer.getTranslation('ALT_TEXT.COMBOBOX_COLLAPSED'));
  }

  public getSelectedLabel(item: SelectItem): string {
    return (item.display + ' ' + (this.isItemSelected(item) ? this.altLabels['selected'] : this.altLabels['notSelected']));
  }

  public isItemSelected(item: SelectItem): boolean {
    return !!item && this.selectedList.find(i=>i.display === item.display) !== undefined;
  }

  public isSingleItemSelected(): boolean {
    return this.selectedList?.length > 0;
  }

  public getItems(): SelectItem[] {
    return this._items;
  }

  private closeOnFocusOut(event: KeyboardEvent, index: number, force?: boolean): void {
    if (this.isOpen && (!!force || (!event.shiftKey && index === this._items.length - 1))) {
      this.toggleMenuOpen(event);
    }
  }

  protected getNativeUL(): HTMLUListElement {
    return !!this.ul ? this.ul.nativeElement : null;
  }

  protected toggleMenuOpen(event: Event): void {
    if (!!event && event.type === 'keydown') {
      event.preventDefault();
    }
    if (!this.disabled) {
      const select: HTMLSelectElement = this.nativeSel ? (this.nativeSel.nativeElement as HTMLSelectElement) : null;
      if (!!select) {
        select.click();
      } else {
        this.isOpen = !this.isOpen;
        SelectComponent.isActive = this.isOpen;
        this.isOpening = (this._isMultiSelect && this.isOpen) ? true : false;
        if (this.isOpen) {
          this.checkLayout(!!this.sel ? this.sel.nativeElement : null , !!this.ul ? this.ul.nativeElement : null);
        } else {
          this.menuClosing();
          if (this._isMultiSelect) {
            this.change.emit(this);
          }
        }
      }
    }
    if (this._isMultiSelect) {
      setTimeout(() => {
        super.toggleMenuOpen(event);
      }, 150);
    } else {
      super.toggleMenuOpen(event);
    }
  }

  protected itemSelected(item: SelectItem, event: Event): boolean {
    if (this.isOpen) {
      if (this._isMultiSelect) {
        if (!!item) {
          const indx = this.selectedList.indexOf(item);
          if (indx === -1) {
            this.selectedList.push(item);
          } else {
            this.selectedList.splice(indx, 1);
          }
        }
        if (this.selectedList.length > 0) {
          if (this.selectedList.length > 1) {
            this.value = this.selectedList.map(i => i.value).join(this.multiSelectSep);
            this.dislpay = this.selectedList.map(i => i.display).join(', ');
          } else {
            this.value = this.selectedList[0].value;
            this.dislpay = this.selectedList[0].display;
          }
        } else {
          this.value = null;
          this.dislpay = null;
        }
        this.change.emit(this);
        if (!item) {
          this.toggleMenuOpen(event);
        }
      } else {
        if (!!item) {
          this.selectedList = [item];
        }
        const selectedItem = this.selectedList.length > 0 ? this.selectedList[0] : null;
        this.dislpay = selectedItem?.display;
        this.value = selectedItem?.value;
        this.change.emit(this);
        this.toggleMenuOpen(event);
      }
    } else if (this.useNativeSelect) {
      if (!!item) {
        this.selectedList = [item];
      }
      this.change.emit(this);
    }
    return false;
  }

  @HostListener('keyup', ['$event'])
  protected onKeyup(event: KeyboardEvent, item?: SelectItem): boolean {
    return super.onKeyup(event,item);
  }

  @HostListener('window:resize')
  private onResize(): void {
    if (this.nativeDiv && this.nativeSel) {
      this.nativeSel.nativeElement.style.top = this.nativeDiv.nativeElement.offsetTop + 'px';
      this.nativeSel.nativeElement.style.left = this.nativeDiv.nativeElement.offsetLeft + 'px';
    }
  }

  private selChange(event: Event): void {
    this.value = (event.currentTarget as HTMLInputElement).value;
    const currentItem = this._items.find(i => i.value===this.value);
    this.itemSelected(currentItem, event);
    event.stopPropagation();
    event.preventDefault();
  }
}
