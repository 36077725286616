import { of as observableOf, from,  Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { Component, Input, ViewChild, OnInit, ElementRef, NgZone } from '@angular/core';

import { LocalizeService } from './services/localize.service';
import { FormService } from './services/form.service';
import { ListService } from './services/list.service';
import { SettingsService } from './services/settings.service';
import { TileService } from './services/tile.service';
import { CommandHandler, FormResult } from './models/command-handler';
import { ListData } from './models/base';
import { ListItem, ListInterface } from './models/list-item';
import { ColFormat } from './models/column';
import { AccessLevel } from './models/security-control';
import { Tile } from './models/tile';
import { FileFormInfo, DataFile, tFilePickCB, tListPickCB } from './models/file-form-info';
import { SelectItem } from './models/form-field';
import { Util, UserInterface } from './utils/utils.module';
import { WindowModalComponent } from './windows/window-modal.component';
import { ListBaseComponent } from './lists/list-base.component';
import { FormWrapperComponent } from './forms/form-wrapper.component';
import { PopupCallback } from './widgets/popup.component';
import { SearchFilterComponent } from './widgets/search-filter.component';
import { AppComponent } from './app.component';
import { SelectComponent } from './widgets/select.component';
import { FavoriteService } from './services/favorite.service';
import { UploadService } from './services/upload.service';
import { RecentLocationService } from './services/recent-location.service';

declare let Office;
declare let Word;
declare let Excel;
declare global {
  interface Window {
      showDirectoryPicker: any;
  }
}

class _FileUploadData {
  public files: File[];
  public filePaths: string[];
  public dataFiles: DataFile[];
  public form: string;
  public desc: any;
  public formData: any[];
  public trustees: any[];
  public fileFormInfo: FileFormInfo[];
  constructor(files: File[], filePaths: string[], dataFiles: DataFile[], form: string, desc: any, formData: any[], trustees: any[], info: FileFormInfo[])  {
    this.files = files;
    this.filePaths = filePaths;
    this.dataFiles = dataFiles;
    this.form = form;
    this.desc = desc;
    this.formData = formData || [];
    this.trustees = trustees || [];
    this.fileFormInfo = info || [];
  }
}


/*

  Be careful with ordering in this file and reset

  Case 1: A folder picker can be brought up in FRONT of a form dialog
  So the popup that conains the folder picker must be on top or in front of the form dialog
  When we dismiss the popup folder picker do not dismiss the form dialog if it is shown

  Case 2: A Profile dialog for creating containers is brought up in FRONT of a folder picker
  So the popup that conains the folder picker must be below or behind of the profile form dialog
  When we dismiss the popup profile form dialog do not dismiss the folder picker
*/


@Component({
  selector: 'edx-app-commands',
  styleUrls: [ 'app-commands.component.scss' ],
  templateUrl: 'app-commands.component.html'
})
export class AppCommandsComponent implements OnInit, CommandHandler, PopupCallback {
  public pickerShown = false;
  public dialogShown = false;
  public notifyShown = false;
  public pickerCreateContainerShown = false;
  public pickerGroupsCreateContainerShown = false;
  private filePickerCB: tFilePickCB = null;
  private pickerTitle = '';
  private pickerGroupsTitle = '';
  private pickerOK = '';
  private pickerDesc: any = {};
  private pickerGroupsDesc: any = {};
  private pickerKind = '';
  private pickerCB: tListPickCB = null;
  private pickerGroupsCB: tListPickCB = null;
  private pickerHasFilter = false;
  private pickerForm: string = null;
  private pickerParams: string = null;
  private pickerLeadingColums: number[] = null;
  private pickerDisableList: ListItem[] = null;
  private pickerForceOK = false;
  private pickerDestListComponent: ListBaseComponent;
  private pickerRights = 0;
  private pickerFlexfoldersLevels: SelectItem[] = [];
  private pickerCurFlexfoldersLevel: SelectItem = null;
  private pickerKey: string = null;
  private isCustomPicker = false;
  // main dialog
  public dialogKind = '';
  private dialogTitle = '';
  private dialogOK = '';
  private dialogExtras: string = null;
  private dialogExtrasAlt: string = null;
  private dialogThirdBtn: string = null;
  private dialogCreateType: string = null;
  private dialogFormData: any = {};
  private dialogEmailHasAttachments = false;
  private dialogTrustees: any[] = [];
  private dialogApplyAllChecked: boolean = null;
  private dialogExtrasShown = false;
  private dialogFileFormInfo: FileFormInfo;
  private dialogSuccess = false;
  // picker create container dialog
  private pccDialogTitle = '';
  private pccDialogOK = '';
  private pccDialogExtras: string = null;
  private pccDialogExtrasAlt: string = null;
  private pccDialogThirdBtn: string = null;
  private pccDialogCreateType: string = null;
  private pccDialogFormData: any = {};
  private pccDialogTrustees: any[] = [];
  private pccDialogApplyAllChecked: boolean = null;
  private pccDialogExtrasShown = false;
  private pccDialogFileFormInfo: FileFormInfo;
  private pccDialogSuccess = false;
  private pccDesc: any = null;

  private list: ListItem[] = null;
  private pendingUploads: _FileUploadData[] = [];
  private curPendingUpload: _FileUploadData = null;
  private desc: any = null;
  private desc2: any = null;
  private kind2: string = null;
  private fileList: File[] = null;
  private filePaths: string[] = null;
  private dataFiles: DataFile[] = null;
  private currentCommand = '';
  private dialogWidth = 800;
  private pickerWidth = 800;
  private ui: UserInterface;
  private nFiles = 0;
  private contentFileList: File[] = [];
  private contentFilePath: string[] = [];
  public refList: ListItem[] = [];
  public fileEntries = [];
  public folderEntries = [];
  @Input() app: AppComponent;
  @ViewChild('filter') private filter: SearchFilterComponent;
  @ViewChild('picker') private picker: ListInterface;
  @ViewChild('pickerGroups') private pickerGroups: ListInterface;
  @ViewChild('formWrapper') private formWrapper: FormWrapperComponent;
  @ViewChild('pccFormWrapper') private pccFormWrapper: FormWrapperComponent;
  @ViewChild('rightWrapper') private rightWrapper: FormWrapperComponent;
  @ViewChild('headerWrapper') private headerWrapper: FormWrapperComponent;
  @ViewChild('uploadFilePicker') private uploadFilePicker: ElementRef;

  constructor(private localizer: LocalizeService, private formService: FormService, private tileService: TileService, private listService: ListService, private settingsService: SettingsService, private zone: NgZone,
              private favoriteService: FavoriteService, private uploadService: UploadService, private recentLocationService: RecentLocationService) {
    this.ui = Util.Device.ui;
  }

  ngOnInit(): void {
    if (this.app) {
      this.app.registerCommands(this);
    }
  }

  private resetPicker(): void {
    this.pickerShown = false;
    this.pickerTitle = '';
    this.pickerOK = '';
    this.pickerHasFilter = false;
    this.pickerLeadingColums = null;
    this.pickerDisableList = null;
    this.pickerDestListComponent = null;
    this.pickerRights = 0;
    this.pickerKey = null;
  }

  public resetDialog(): void {
    this.dialogShown = false;
    this.dialogKind = '';
    this.dialogTitle = '';
    this.dialogOK = '';
    this.dialogExtras = null;
    this.dialogExtrasAlt = null;
    this.dialogThirdBtn = null;
    this.dialogCreateType = null;
    this.dialogFormData = {};
    this.dialogTrustees = [];
    this.dialogExtrasShown = false;
    this.dialogFileFormInfo = null;
    this.dialogSuccess = false;
  }

  private resetPickerCreateContainer(): void {
    this.pickerCreateContainerShown = false;
    this.pccDialogTitle = '';
    this.pccDialogOK = '';
    this.pccDialogExtras = null;
    this.pccDialogExtrasAlt = null;
    this.pccDialogThirdBtn = null;
    this.pccDialogCreateType = null;
    this.pccDialogFormData = {};
    this.pccDialogTrustees = [];
    this.pccDialogExtrasShown = false;
    this.pccDialogFileFormInfo = null;
    this.pccDialogSuccess = false;
  }

  private resetGroupPickerCreateContainer(): void {
    this.pickerGroupsCreateContainerShown = false;
    this.filePickerCB = null;
    this.pickerGroupsTitle = '';
    this.pickerGroupsDesc = {};
    this.pickerGroupsCB = null;
  }

  private reset(): void {
    this.resetPicker();
    this.resetDialog();
    this.resetPickerCreateContainer();
    this.desc = null;
    this.desc2 = null;
    this.kind2 = null;
    this.dialogWidth = 800;
    // do not reset pending uploads
  }

  private isPhoneLook(): boolean {
    return Util.Device.isPhoneLook();
  }

  private hasLevelDropdown(): boolean {
    return (this.currentCommand === 'addflexfolder');
  }

  private hasRightHeader(): boolean {
    return (!this.pickerShown && this.dialogShown && ((this.dialogKind==='__local_editsecurity') || (((['profile', 'profile_uploadfolders', 'profile_folderfiles', 'profile_savetoedocs', 'profile_checkin'].indexOf(this.dialogKind) !== -1 || this.dialogKind.startsWith('profile_editdefs_')) && (this.dialogKind === 'profile_savetoedocs' || (this.desc && !Util.isExternalLib(this.desc.lib))))) || (this.kind2 && this.kind2.startsWith('profile') && this.desc2 && !Util.isExternalLib(this.desc2.lib)))) || (this.pickerShown && this.ui>=2 && this.pickerDesc.type==='folders' && this.pickerDesc.id==='downloads');
  }

  private pickerOKDisabled(): boolean {
    return (!this.pickerForceOK && this.picker) ? this.picker.okDisabled : false;
  }

  private pickerGroupsOKDisabled(): boolean {
    return !!this.pickerGroups? this.pickerGroups.okDisabled : false;
  }

  public getField(name: string): any {
    return name + '_ignore';
  }

  public fileInputChanged(): boolean {
    const fileList: File[] = this.uploadFilePicker.nativeElement.files;
    if (fileList.length > 0) {
      const contentFileList: File[] = [];
      let emptyFileNames = '';
      let countEmptyFiles = 0;
      const filePickCB = this.filePickerCB;
      for (const file of fileList) {
        if (file.size === 0) {
          countEmptyFiles = countEmptyFiles + 1;
          if (countEmptyFiles === 1) {
            emptyFileNames = file.name;
          } else {
            emptyFileNames = emptyFileNames + ',' + file.name;
          }
        } else {
          contentFileList.push(file);
        }
      }
      this.filePickerCB = null;
      if (this.pickerShown) {
        this.pickerForceOK = true;
        if (this.isPhoneLook()) {
          this.app.cordovaRight();
        } else {
          this.dismissPopup(true);
        }
      }
      // delay starting file uploads, we do not want a profile form to come up on top of the file picker so wait to make sure the file picker is gone bye-bye
      setTimeout(() => {
        if (filePickCB) {
          filePickCB(contentFileList, null, true);
        } else {
          if (countEmptyFiles !== 0) {
            Util.Notify.info(this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.UPLOADING'), this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.EMPTY_FILE', [emptyFileNames]));
          }
          if (countEmptyFiles < fileList.length) {
            this.initUpload(contentFileList, null, null);
          }
        }
      }, 1000);
    }
    return false;
  }

  private initUpload(fileList: File[], filePaths: string[], dataFiles: DataFile[]): void {
    const count = fileList ? fileList.length : filePaths ? filePaths.length : 0;
    if (count) {
      const listComponent: ListBaseComponent = this.pickerDestListComponent ? this.pickerDestListComponent : Util.RestAPI.getCurListComponent();
      // If a file is uploaded from hero tile, do not send any container info.
      const desc: any = listComponent ? listComponent.desc : null;
      if (this.pickerDestListComponent) {
        Util.RestAPI.setCurListComponent(this.pickerDestListComponent);
      }
      this.pickerDestListComponent = null;
      setTimeout(() => {  // time for any picker dialogs to dismiss
        Util.RestAPI.uploadFilesWithUI(fileList, filePaths, dataFiles, desc);
      }, 500);
    }
  }

  public fileInputClick(): void {
    this.uploadFilePicker.nativeElement.value = null;
  }

  private getMainWrapper(): FormWrapperComponent {
    return this.rightWrapper ? this.rightWrapper : this.formWrapper;
  }

  public getCurrentCommand(): string {
    return this.currentCommand;
  }

  public isFormReadonly(): boolean {
    return this.currentCommand === 'viewprofile' || this.currentCommand === 'shortcuts' || (!!this.desc && this.desc['STATUS'] === '16');
  }

  public copyDescChanged(desc: any): void {
    this.desc2 = desc;
  }

  public clickPickFiles(extensions?: string): void {
    this.uploadFilePicker.nativeElement.accept = extensions;
    this.uploadFilePicker.nativeElement.removeAttribute('disabled');
    this.uploadFilePicker.nativeElement.click();
  }

  public async parseFolderStructure(folderHandle, path = '') {
    const entries = folderHandle.values();
    for await (const entry of entries) {
      const entryPath = `${path}/${entry.name}`;
      if (entry.kind === 'file') {
        const file = await entry.getFile();
        this.fileEntries.push({ file: file, path: entryPath });
      } else if (entry.kind === 'directory') {
        this.folderEntries.push({ name: entry.name, path: entryPath });
        await this.parseFolderStructure(entry, entryPath);
      }
    }
  }

  public async pickAndUploadFolderStructure() {
    try {
      const folderHandle = await window.showDirectoryPicker();
      this.folderEntries.push({ name: folderHandle.name, path: '/' + folderHandle.name });
      await this.parseFolderStructure(folderHandle, '/' + folderHandle.name);
      Util.RestAPI.setFolderUploadFoldersAndFiles(this.folderEntries, this.fileEntries);
      const listComponent = Util.RestAPI.getCurListComponent();
      const desc: any = listComponent ? listComponent.desc : { type: 'folders', id: 'recentedits', lib: Util.RestAPI.getPrimaryLibrary() };
      Util.RestAPI.setLastCmdName("uploadfolders");
      this.createFromCmd('newfolder', desc, null, true).then(() => { }, err => { });
    } catch (err) {
      console.error(err);
      Util.RestAPI.showFolderUploadSpinner(false);
    }
  }
  
  public pickFiles(extensions: string, callback: tFilePickCB): void {
    if (Util.Device.bIsCordova) {
      const doList = (list: any, success: boolean) => {
        if (success && list && list.length) {
          const filePaths: string[] = [];
          for (const item of list) {
            const fileName: string = Util.Transforms.lastPathComponent(item.fullPath);
            if (fileName) {
              filePaths.push(fileName);
            }
          }
          if (!!callback) {
            callback(null, filePaths, true);
          } else if (filePaths.length) {
            this.initUpload(null, filePaths, null);
          }
        } else if (!!callback) {
          callback(null, null, false);
        }
      };
      const doFilesOrPaths = (files: File[], paths: string[], success: boolean) => {
        if (!!callback) {
          callback(files, paths, success);
        } else if (!!paths || !!files) {
          this.initUpload(files, paths, null);
        }
      };
      this.pickFromDownloads(doList, doFilesOrPaths);
    } else {
      this.filePickerCB = callback;
      this.clickPickFiles(extensions);
    }
  }

  public pickFilepart(callback: tListPickCB): void {
    this.resetPicker();
    this.pickerTitle = this.localizer.getTranslation('FOLDER_ACTIONS.SELECT_FILEPART');
    this.pickerOK = this.localizer.getTranslation('FORMS.BUTTONS.ADD');
    this.pickerCB = callback;
    this.pickerDesc = { id:'', type:'fileplans', lib:'', DOCNAME:this.localizer.getTranslation('FOLDER_ACTIONS.FILEPLANS_CONTAINER') };
    this.pickerHasFilter = false;
    this.pickerKind = 'list_2_single_filepart';
    this.pickerDisableList = this.list;
    this.pickerShown = true;
  }

  public pickDocument(callback: tListPickCB): void {
    this.resetPicker();
    this.pickerTitle = this.localizer.getTranslation('FOLDER_ACTIONS.SELECT_DOCUMENT');
    this.pickerOK = this.localizer.getTranslation('FORMS.BUTTONS.ADD');
    this.pickerCB = callback;
    this.pickerDesc = { id:'', type:'folders', lib:'', DOCNAME:this.localizer.getTranslation('FOLDER_ACTIONS.CONTAINERS') };
    this.pickerHasFilter = true;
    this.pickerKind = 'list_2_single_onlydocs';
    this.pickerDisableList = this.list;
    this.pickerShown = true;
  }

  public pickFolders(desc: any, multiple: boolean, allowLibrary: boolean, locations: boolean, callback: tListPickCB): void {
    this.resetPicker();
    this.pickerDesc = desc ? desc : {id:'',type:'folders',lib:Util.RestAPI.getPrimaryLibrary(), DOCNAME:this.localizer.getTranslation('FOLDER_ACTIONS.CONTAINERS')};
    this.pickerCB = callback;
    this.pickerTitle = locations ? this.localizer.getTranslation('FORMS.PLACEHOLDERS.CONTAINER') : this.localizer.getTranslation('FORMS.LOCAL.COPY.SELECT_FOLDER');
    this.pickerKind = (multiple ? (allowLibrary ? 'list_2_library' : 'list_2') : (allowLibrary ? 'list_2_single_library' : 'list_2_single')) + (locations ? '_locations' : '');
    this.pickerHasFilter = true;
    this.pickerShown = true;
  }

  public pickGroupsUsers(callback: tListPickCB, disableList?: ListItem[], title?: string): void {
    let library: string = this.desc ? this.desc.lib : Util.RestAPI.getPrimaryLibrary();
    if (Util.isExternalLib(library)) {
      library = Util.RestAPI.getPrimaryLibrary();
    }
    if (this.pickerCreateContainerShown) {
      this.pickerGroupsDesc = {id:'_GROUPS_ENABLED',type:'lookups',lib:library};
      this.pickerGroupsCreateContainerShown = true;
      this.pickerGroupsCB = callback;
      this.pickerTitle = title ? title : this.localizer.getTranslation('METADATA.SECURITY_ACTIONS.ADD');
    } else {
      this.resetPicker();
      this.pickerDesc = {id:'_GROUPS_ENABLED',type:'lookups',lib:library};
      this.pickerCB = callback;
      this.pickerTitle = title ? title : this.localizer.getTranslation('METADATA.SECURITY_ACTIONS.ADD');
      this.pickerKind = 'list_2';
      this.pickerHasFilter = true;
      this.pickerDisableList = disableList;
      this.pickerWidth = Util.kMaxPopupDialogWidth;
      this.pickerShown = true;
    }
  }

  public pickFromLookups(id: string, key: string, title: string, callback: tListPickCB): void {
    this.resetPicker();
    this.pickerDesc = {id,type:'lookups',lib:Util.RestAPI.getPrimaryLibrary()};
    this.pickerKey = key;
    this.pickerCB = callback;
    this.pickerTitle = title || this.localizer.getTranslation('FOLDER_ACTIONS.ADD');
    this.pickerKind = 'list_1';
    this.pickerHasFilter = true;
    this.pickerLeadingColums = [ColFormat.SELECTOR];
    this.pickerShown = true;
  }

  public pickFromDownloads(callbackList: tListPickCB, callbackFiles: tFilePickCB, title?: string): void {
    this.resetPicker();
    this.pickerDesc = {id:'downloads',type:'folders',lib:''};
    this.pickerCB = callbackList;
    this.filePickerCB = callbackFiles;
    this.pickerTitle = title ? title : this.localizer.getTranslation('FORMS.PLACEHOLDERS.FILE');
    this.pickerKind = 'list_1';
    this.pickerLeadingColums = [ColFormat.SELECTOR];
    this.pickerDestListComponent = Util.RestAPI.getCurListComponent();
    this.pickerShown = true;
  }

  public createContainer(type: string, desc: any): Promise<FormResult> {
    const cmd: string = type === 'workspaces' ? 'newworkspace' : 'newfolder';
    return this.createFromCmd(cmd, desc, true);
  }

  public runLocalForm(formData: any, formName: string, title: string, okTitle: string, showExtras: boolean): Promise<FormResult> {
    return this.runForm(formData, Util.kLocalFormPrefix + formName, title || formName, okTitle, showExtras);
  }

  public runCustomForm(formData: any, vendor, formName: string, title: string, okTitle: string, showExtras: boolean): Promise<FormResult> {
    return this.runForm(formData, Util.kCustomFormPrefix + vendor + '_' + formName, title || formName, okTitle, showExtras);
  }

  private runForm(formData: any, kind: string, title: string, okTitle: string, showExtras: boolean): Promise<FormResult> {
    return new Promise<any>((resolve, reject) => {
      this.dialogKind = kind;
      this.dialogTitle = title;
      this.dialogFormData = formData;
      this.dialogOK = okTitle || this.localizer.getTranslation('FORMS.BUTTONS.OK');
      if (showExtras) {
        this.dialogExtras = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_ALL');
        this.dialogExtrasAlt = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_LESS');
      }
      this.dialogShown = true;
      const checkDone = () => {
        if (!this.dialogShown) {
          resolve({success:this.dialogSuccess, data:this.dialogFormData});
        } else {
          setTimeout(checkDone, 1000);
        }
      };
      checkDone();
    });
  }

  public dialogFormDataChanged(formData: any): void {
    this.dialogFormData = formData;
  }


  public uploadFilesWithUI(files: File[], filePaths?: string[], dataFiles?: DataFile[], intoThisFolder?: any, formData?: any, serverData?: any, doNotShowProfileForm?: boolean, isInitial = true): void {
    if (isInitial) {
      this.uploadService.setData(files, this.desc, null, true);
    } else {
      if (!formData) {
        formData = {};
      }
      formData['SECURITY'] = this.uploadService.getSecurityData() ? 1 : 0;
    }
    const contentFileList = this.uploadService.getFilesToUpload(false);
    if (!(this.dialogApplyAllChecked && contentFileList?.length === 1)) {
      this.dialogApplyAllChecked = this.getApplyToAllChecked(contentFileList?.length);
    }
    if (this.dialogApplyAllChecked) {
      files = this.uploadService.getFilesToUpload(true);
    }
    const outlookMailbox = Util.Device.bIsOfficeAddin && Office && Office.context ? Office.context.mailbox : null;
    const externalApp = intoThisFolder ? Util.isExternalLib(intoThisFolder.lib) : false;
    if (!((files && files.length) || (filePaths && filePaths.length) || (dataFiles && dataFiles.length)) && !!outlookMailbox) {
      this.doCommand('uploadofficeitem');
      return;
    }
    let listComponent: ListBaseComponent = Util.RestAPI.getCurListComponent();
    if (!!listComponent && !Util.isUploadTarget(listComponent.desc)) {
      listComponent = null;
    }
    intoThisFolder = intoThisFolder || (!!listComponent ? listComponent.desc : {id:'recentedits',type:'folders',lib:Util.RestAPI.getPrimaryLibrary()});
    if (!!files && files.length && (files[0].name.toUpperCase().endsWith('.MSG') || files[0].name.toUpperCase().endsWith('.EML'))) {
      if (outlookMailbox && outlookMailbox.item) {
        if (!formData) {
          this.uploadOutlookMSGItem(outlookMailbox, null, files, null, intoThisFolder, false);
          return;
        }
      } else {
        if (!formData) {
          formData = {};
        }
        formData['APP_ID'] = 'MS OUTLOOK';
        formData['ITEM_TYPE'] = 'E';
      }
    }
    if (externalApp && files && files.length) {
      this.uploadToExternalApp(files, intoThisFolder, serverData);
      return;
    }
    let nMaxForms = 1;
    let iForm: number;
    const nFiles: number = files ? files.length : filePaths ? filePaths.length : dataFiles.length;
    const fileAdded: boolean[] = [];
    const fileTemplateTrustees: any[] = [];
    const fileTemplateNames: string[] = [];
    const fileTemplateNamesCount: number[] = [];
    const fileFormInfo: FileFormInfo[] = [];
    const ffisForExtions = {};
    let fileIndex: number;

    const getFInfo = () => {
      const handleFFIForExt = (ffi: FileFormInfo, ext?: string): void => {
        fileFormInfo.push(ffi);
        fileAdded.push(false);
        if (!!ext && !ffisForExtions[ext]) {
          ffisForExtions[ext] = ffi;
        }
        if (++fileIndex < nFiles) {
          getFInfo();
        } else {
          // If File-Plans RM item, put the FilePart id into the formData
          if (intoThisFolder.type === 'fileplans') {
            const filePartNo: string = intoThisFolder.id.split('FP-FilePart-')[1];
            if (!formData) {
              formData = {};
            }
            formData['PD_FILEPT_NO'] = filePartNo;
          }
          this.pendingUploads = [];
          // loop through all of the file form infos and find the files with the most forms
          for (const info of fileFormInfo) {
            if (info) {
              for (let i=0; i<info.forms.length; i++) {
                const form = info.forms[i];
                const trustees: any[] = !!info.trustees.length ? info.trustees[i] : [];
                const index: number = fileTemplateNames.indexOf(form);
                if (index >=0) {
                  const count: number = fileTemplateNamesCount[index] + 1;
                  fileTemplateNamesCount[index] = count;
                  if (count > nMaxForms) {
                    nMaxForms = count;
                  }
                } else {
                  fileTemplateTrustees.push(trustees);
                  fileTemplateNames.push(form);
                  fileTemplateNamesCount.push(1);
                }
              }
            }
          }
          iForm = nMaxForms;
          while (iForm > 0) {
            for (let i=0; i<fileTemplateNamesCount.length; i++) {
              if (fileTemplateNamesCount[i] === iForm) {
                let formName = fileTemplateNames[i];
                const newFormData: any[] = [];
                let trustees: any[] = fileTemplateTrustees[i];
                const newFiles: File[] = files ? [] : null;
                const newFilePaths: string[] = filePaths ? [] : null;
                const newDataFiles: DataFile[] = dataFiles ? [] : null;
                const formInfo: FileFormInfo[] = [];
                for (let j=0; j<nFiles; j++) {
                  if (!fileAdded[j]) {
                    const info = fileFormInfo[j];
                    if (!!info) {
                      if (!Util.RestAPI.isFolderFilesProfile()) {
                        formName = formName || info.defForm;
                      } else {
                        formName = info.defForm || formName;
                      }
                      formName = info.defForm || formName;
                      const formIndex: number = info.forms.indexOf(formName);
                      if (formIndex!==-1) {
                        const defaults: any = info.profile_defaults.length && formIndex < info.profile_defaults.length ? info.profile_defaults[formIndex] : null;
                        trustees = info.trustees.length && formIndex < info.trustees.length ? info.trustees[formIndex] : null;
                        formInfo.push(info);
                        if (newFiles) {
                          newFiles.push(files[j]);
                        } else if (newFilePaths) {
                          newFilePaths.push(filePaths[j]);
                        } else if (newDataFiles) {
                          newDataFiles.push(dataFiles[j]);
                        }
                        fileAdded[j] = true;
                        if (!!formData) {
                          if (!!defaults) {
                            newFormData.push(Object.assign({}, defaults, formData));
                          } else {
                            newFormData.push(Util.deepCopy(formData));
                          }
                        } else if (!!defaults) {
                          newFormData.push(Util.deepCopy(defaults));
                        } else {
                          newFormData.push({});
                        }
                      }
                    }
                  }
                }
                if ((newFiles && newFiles.length) || (newFilePaths && newFilePaths.length) || (newDataFiles && newDataFiles.length)) {
                  this.pendingUploads.push(new _FileUploadData(newFiles, newFilePaths, newDataFiles, formName, intoThisFolder, newFormData, trustees, formInfo));
                }
              }
            }
            --iForm;
          }
          if (doNotShowProfileForm) {
            this.updateCurrPendingUpload(this.pendingUploads.pop());
          } else {
            this.uploadNextFiles(this.pendingUploads.pop());
          }
        }
      };
      if (!!dataFiles && !!dataFiles[fileIndex].templateDesc && !!dataFiles[fileIndex].templateDesc['APP_ID']) {
        Util.RestAPI.getFormInfoForAppID(dataFiles[fileIndex].templateDesc['APP_ID'], intoThisFolder).then(handleFFIForExt);
      } else {
        const fileName = !!files ? files[fileIndex].name : !!filePaths ? filePaths[fileIndex] : dataFiles[fileIndex].name;
        const nameExt: string[] = fileName.split('.');
        let ext: string;
        if (nameExt.length>2) {
          ext = nameExt[nameExt.length-1];
          nameExt.length = nameExt.length - 1;
        } else if (nameExt.length<2) {
          ext = '';
        } else {
          ext = nameExt[1];
        }
        if (!!ffisForExtions[ext]) {
          handleFFIForExt(ffisForExtions[ext], ext);
        } else {
          Util.RestAPI.getFormInfoForFile(fileName, intoThisFolder).then((ffi: FileFormInfo) => {
            handleFFIForExt(ffi, ext);
          });
        }
      }
    };
    fileIndex = 0;
    getFInfo();
  }

  public getApplyToAllChecked(nFiles: number): boolean {
    return nFiles > 1 ? this.dialogApplyAllChecked===null ? true : this.dialogApplyAllChecked : false;
  }

  public uploadDone(error?: string): void {
    const nCurFiles: number = this.curPendingUpload ? this.curPendingUpload.files ? this.curPendingUpload.files.length : this.curPendingUpload.filePaths ? this.curPendingUpload.filePaths.length : this.curPendingUpload.dataFiles ? this.curPendingUpload.dataFiles.length : 0 : 0;
    if (this.uploadService.getContentFileList()?.length > 0) {
      const contentFileList = this.uploadService.getFilesToUpload(false);
      if (contentFileList?.length > 0) {
        if (!(this.dialogApplyAllChecked && contentFileList?.length === 1)) {
          this.dialogApplyAllChecked = this.getApplyToAllChecked(contentFileList?.length);
        }
        const listComponent: ListBaseComponent = this.pickerDestListComponent ?? Util.RestAPI.getCurListComponent();
        const desc: any = listComponent ? listComponent.desc : this.desc ?? null;
        const filesToUpload = this.uploadService.getFilesToUpload(this.dialogApplyAllChecked);
        if (filesToUpload?.length > 0) {
          this.uploadFilesWithUI(filesToUpload, null, null, desc, null, null, null, false);
        }
      } else {
        this.uploadService.resetData();
        Util.RestAPI.resetUploadFolderFilesCount();
        Util.RestAPI.resetFolderUploadFiles();
      }
    } else if (!error && (this.pendingUploads.length || (this.dialogApplyAllChecked === false && nCurFiles > 1))) {
      if (this.dialogApplyAllChecked === false && nCurFiles > 1) {
        if (this.curPendingUpload.files) {
          this.curPendingUpload.files.shift();
        } else if (this.curPendingUpload.filePaths) {
          this.curPendingUpload.filePaths.shift();
        } else if (this.curPendingUpload.dataFiles) {
          this.curPendingUpload.dataFiles.shift();
        }
        this.curPendingUpload.formData.shift();
        this.curPendingUpload.fileFormInfo.shift();
        this.uploadNextFiles(this.curPendingUpload);
      } else {
        this.uploadNextFiles(this.pendingUploads.pop());
      }
    } else {
      this.pendingUploads = [];
      this.curPendingUpload = null;
      this.dialogApplyAllChecked = null;
      this.fileList = null;
      this.filePaths = null;
      this.dataFiles = null;
      Util.RestAPI.showFolderUploadSpinner(false);
      this.uploadService.resetData();
    }
  }

  public importListItems(items: ListItem[]): void {
    const paths: string[] = [];
    for (const item of items) {
      const fileName: string = Util.Transforms.lastPathComponent((item as any).fullPath);
      paths.push(fileName ? fileName : item.DOCNAME);
    }
    this.initUpload(null, paths, null);
  }

  public uploadToExternalApp(files: File[], intoThisFolder: any, serverData?: any): void {
    if (!serverData) {
      serverData = {};
    }
    serverData['AUTHOR_ID'] = Util.RestAPI.getUserID();
    let delimiter = '';
    if (!serverData['_restapi']) {
      serverData['_restapi'] = {};
    }
    serverData['_restapi']['ref'] = intoThisFolder;
    serverData['_restapi']['filelist'] = [];
    const partialUrl = 'documents?library=' + intoThisFolder.lib;
    let fileList = '';
    for (const file of files) {
      fileList += delimiter + encodeURIComponent(file.name);
      delimiter = '%3B';
    }
    const url: string = 'documents/' + intoThisFolder.id + '/uploadfilenamecheck';
    const queryArgs: string = '?library=' + intoThisFolder.lib + '&files=' + fileList;
    Util.RestAPI.get(url, null, queryArgs).subscribe((response: any) => {
      const nFiles: number = !!response['list'] ? response['list'].length : 0;
      let checkedFiles = 0;
      const source = new Observable(subscriber => {
        for (const item of response['list']) {
          item.file = decodeURIComponent(item.file);
          subscriber.next(item);
        }
      });
      const observer = source.pipe(concatMap(f => this.checkFileConflict(f))).subscribe(result => {
        if (result && result['data']['radio'] !== 'ignore') {
          let fileInfo: any = {};
          if (result['confirm'] && result['data']['radio'] !== 'ignore') {
            fileInfo = { file: result['data']['file'], conflictbehavior: result['data']['radio'] };
            serverData['_restapi']['filelist'].push(fileInfo);
          } else {
            // Remove ignored/un-comfirmed file
            for (let i = 0; i < nFiles; i++) {
              if (files[i].name === result['data']['file']) {
                files.splice(i, 1);
                break;
              }
            }
          }
          checkedFiles++;
          if (checkedFiles === nFiles && files.length > 0) {
            Util.RestAPI.uploadFilesWithBrowser(partialUrl, serverData, files);
          }
        }
      }, error => {
        Util.Notify.info(this.localizer.getTranslation('GENERIC_ERRORS.ERROR'), error);
      });
    }, error => {
      Util.Notify.info(this.localizer.getTranslation('GENERIC_ERRORS.ERROR'), error);
    });
  }

  private checkFileConflict(file: any): Observable <any> {
    const title: string = this.localizer.getTranslation('FORMS.LOCAL.CONFLICT_BEHAVIOR.TITLE');
    let question: string;
    let formData: any;
    let fileStatus: any = {};
    let innerSource: Observable <any> = null;
    if (file['exists'] === 'yes') {
      question = this.localizer.getTranslation('FORMS.LOCAL.CONFLICT_BEHAVIOR.ASK_SELECT',[file['file']]);
      formData = { question, docName: file['file']};
      innerSource = from(Util.Notify.warning(title,'__local_conflictbehavior',title,formData,true,true,true));
    } else {
      fileStatus = {confirm: true,data:{file: file['file'],radio:'replace'}};
      return observableOf(fileStatus);
    }
    return innerSource;
  }

  public doCommand(cmd: string, data?: any): boolean {
    const outlookMailbox = Util.Device.bIsOfficeAddin && Office && Office.context ? Office.context.mailbox : null;
    const appDesc = this.app.desc;
    const loginReply: any = Util.RestAPI.getLoginReply();
    const listComponent: ListBaseComponent = Util.RestAPI.getCurListComponent();
    const curWindow: WindowModalComponent = Util.RestAPI.getCurWindow();
    let cmdParts: string[] = cmd.split('doc_');
    const isDocCmd: boolean = cmdParts.length > 1;
    const fullList: ListItem[] = listComponent ? listComponent.getList() : [];

    const prepareEmailForm = (verb: string) => {
      this.dialogKind = Util.kLocalFormPrefix + (verb);
      if (Util.Device.bIsOfficeAddin && Office && Office.context && Office.context.mailbox) {
        if (!!Office.context.mailbox.item.addFileAttachmentAsync) {
          this.dialogFormData = { $edx_share_attach: true, $edx_share_nomail: true };
          this.dialogTitle = this.localizer.getTranslation('FORMS.LOCAL.SHARE.ATTACH');
          this.dialogOK = this.localizer.getTranslation('FORMS.BUTTONS.OK');
        } else {
          this.dialogFormData = { $edx_share_nomail: true };
          this.dialogOK = this.localizer.getTranslation('FORMS.BUTTONS.OK');
          this.dialogTitle = this.localizer.getTranslation('FOLDER_ACTIONS.EMAIL');
          this.dialogFormData['$edx_email_outlook_from'] = Util.RestAPI.getEmail();
          this.dialogExtras = null;
          this.dialogExtrasAlt = null;
        }
      } else {
        this.dialogOK = this.localizer.getTranslation('FORMS.LOCAL.SHARE.SEND');
        this.dialogTitle = this.localizer.getTranslation('FOLDER_ACTIONS.EMAIL');
        this.dialogFormData['$edx_email_outlook_from'] = Util.RestAPI.getEmail();
        this.dialogExtras = null;
        this.dialogExtrasAlt = null;
      }
      if (!!data) {
        this.dialogFormData['isVersionEmail'] = data.isVersionEmail;
        this.dialogFormData['attachments'] = data.attachments;
      }
    };

    if (isDocCmd) {
      const item: ListItem = Util.RestAPI.getCurItem();
      if (item) {
        cmd = cmdParts[1];
        this.list = [item];
      } else {
        cmd = '';
      }
    } else if ((cmd === 'newversion' || cmd === 'versions_new' || cmd === 'attachments_new') && (!listComponent || !listComponent.desc || (!Util.isSharedDownloads(listComponent.desc) && !Util.isSharedImports(listComponent.desc)))) {
       this.list = Util.RestAPI.getCurList();
    } else if (cmd === 'insertlinkintodocument' && !listComponent) {
        const item: ListItem = Util.RestAPI.getCurItem();
        if (item) {
         this.list = [item];
      }
    } else {
      this.list = listComponent ? listComponent.getSelections() : Util.RestAPI.getCurList();
    }
    let nItems: number = this.list ? this.list.length : 0;
    this.reset();
    if (cmd==='email_mini' || cmd === 'email') {
      cmd = 'email';
    } else if (cmd.startsWith('editprofdefs_')) {
      cmdParts = cmd.split('editprofdefs_');
      cmdParts = cmdParts[1].split('___');
      cmd = 'editprofdefs';
      this.list = null;
    }
    if (cmd==='share_mini') {
      cmd = 'shareonedrive';
    }
    this.currentCommand = cmd;

    const searchForms: any[] = Util.RestAPI.getSearchForms();
    let name: string;
    let notifyTitle: string = null;
    let title: string = this.localizer.getTranslation('FORMS.LOCAL.REMOVE.REMOVE_CHECKOUT_TITLE');
    let body: string = this.localizer.getTranslation('FORMS.LOCAL.REMOVE.REMOVE_CHECKOUT_BODY');
    const okBtn: string = this.localizer.getTranslation('FORMS.BUTTONS.OK');
    switch (cmd) {
      case 'addto':
      case 'whereused_add':
      case 'subscribe':
      case 'adddocuments':
      case 'additems':
      case 'related_add':
        if (nItems || cmd==='adddocuments' || cmd === 'additems' || cmd==='whereused_add'|| cmd==='related_add') {
          switch (cmd) {
            case 'whereused_add':
              nItems = 1;
              const item: ListItem = Util.RestAPI.getCurItem();
              if (item) {
                this.list = [item];
              }
              // fall thru
            case 'addto':
              const anyFilePartSelected: boolean = this.list.some((doc) => doc.type === 'fileplans');
              const anyQuickSearchesSelected: boolean = this.list.some((doc) => doc.type === 'searches' && doc['UNSAVED'] !== 'Y');
              let nResponses = 0;
              this.pickerTitle = nItems===1 ? this.localizer.getTranslation('FOLDER_ACTIONS.ADD_TO_SINGLE') : this.localizer.getTranslation('FOLDER_ACTIONS.ADD_TO_MANY',[nItems.toString()]);
              this.pickerOK = this.localizer.getTranslation('FORMS.BUTTONS.ADD');
              let docName;
              let pickerType;
              if (Util.RestAPI.IsFavoritesEnabled()) {
                pickerType = 'filepartworkspaces';
                docName = this.localizer.getTranslation('FOLDER_ACTIONS.CONTAINERS');
              } else {
                pickerType = 'workspaces';
                docName = this.localizer.getTranslation('FOLDER_ACTIONS.WORKSPACES');
              }
              this.pickerDesc = { id:'', type: anyFilePartSelected || anyQuickSearchesSelected ? pickerType : 'folders', lib:'', DOCNAME:docName };
              this.pickerHasFilter = true;
              this.pickerKind = anyQuickSearchesSelected ? 'list_2_primarylibrary' : 'list_2';
              this.pickerRights = AccessLevel.ACCESS_LEVEL_COLLABORATE;
              this.pickerDisableList = [...this.list];
              for (let i=0; i<nItems; i++) {
                Util.RestAPI.get(this.list[i], 'references').subscribe((listData: ListData) => {
                  this.pickerDisableList = Util.mergeArrays(this.pickerDisableList, listData.list);
                  if (++nResponses === nItems) {
                    this.pickerShown = true;
                  }
                }, err2 => {
                  const errMsg = Util.Transforms.formatError(err2);
                  Util.Notify.error(title, errMsg);
                  nResponses = nItems;
                });
              }
              break;
            case 'adddocuments':
            case 'additems':
            case 'related_add':
              this.pickerTitle = cmd==='related_add' ? this.localizer.getTranslation('FOLDER_ACTIONS.ADD_RELATED') : this.localizer.getTranslation('FOLDER_ACTIONS.ADD_DOCUMENTS');
              this.pickerOK = this.localizer.getTranslation('FORMS.BUTTONS.ADD');
              this.pickerDesc = { id:'', type:'folders', lib:'', DOCNAME:this.localizer.getTranslation('FOLDER_ACTIONS.CONTAINERS') };
              this.pickerHasFilter = true;
              this.pickerKind = 'list_2_docs';
              this.pickerDisableList = listComponent ? [new ListItem(listComponent.desc)] : data && data.desc ? [new ListItem(data.desc)] : null;
              this.pickerShown = true;
              break;
            case 'subscribe':
              if (listComponent && listComponent.desc && listComponent.desc.type==='flexfolders' && !this.list.every(i => i.type === 'folders')) {
                notifyTitle = nItems===1 ? this.localizer.getTranslation('FOLDER_ACTIONS.SUBSCRIBED_TO_SINGLE',[this.list[0].DOCNAME]) : this.localizer.getTranslation('FOLDER_ACTIONS.SUBSCRIBED_TO_MANY',[nItems.toString()]);
                this.postDataToServer(cmd, null, notifyTitle);
              } else {
                this.pickerTitle = nItems===1 ? this.localizer.getTranslation('FOLDER_ACTIONS.SUBSCRIBE_TO_SINGLE') : this.localizer.getTranslation('FOLDER_ACTIONS.SUBSCRIBE_TO_MANY',[nItems.toString()]);
                this.pickerOK = this.localizer.getTranslation('FOLDER_ACTIONS.SUBSCRIBE');
                this.pickerDesc = { id:'', type:'flexfolders', lib:this.list[0].lib, DOCNAME:this.localizer.getTranslation('TILE_NAMES.FLEXFOLDERS') };
                this.pickerKind = 'list_1';
                this.pickerShown = true;
              }
              break;
          }
        }
        break;
      case 'addflexfolder':
        const flexInfo: any = listComponent && listComponent.getSet() ? listComponent.getSet().flexinfo : null;
        if (!!flexInfo && !!flexInfo.configuration && !!flexInfo.filters && !!flexInfo.filters.LEVEL_NAME) {
          this.pickerFlexfoldersLevels = flexInfo.configuration.filter(s => s.TYPE === 'L')
            .map(item => new Object({
              value: item.ID_FIELD_NAME,
              display: item.LEVEL_NAME,
              LEVEL_NO: item.LEVEL_NO
            }));
          this.pickerCurFlexfoldersLevel = this.pickerFlexfoldersLevels.find(item => item.value === flexInfo.filters.ID_FIELD_NAME);
          this.pickerForm = !!flexInfo.form ? flexInfo.form : Util.RestAPI.getDefaultProfileForm().id;
          this.getLookupInfo(this.localizer.getTranslation('FOLDER_ACTIONS.SUBSCRIBE_TO'));
        }
        break;
      case 'import':
        this.importListItems(this.list);
        break;
      case 'uploadfile':
      case 'newdocument':
        Util.RestAPI.setLastCmdName(cmd);
        this.pickFiles(null,null);
        break;
      case 'uploadfolders':
        this.pickAndUploadFolderStructure();
        break;
      case 'createobject':
        this.dialogCreateType = cmd;
        this.dialogKind = Util.kLocalFormPrefix + cmd;
        this.desc = listComponent.desc;
        this.dialogFormData = {};
        this.dialogTrustees = [];
        this.dialogFormData['FORMNAME'] = cmd;
        this.dialogTitle = this.localizer.getTranslation('FOLDER_ACTIONS.NEW_OBJECT');
        this.dialogOK = this.localizer.getTranslation('FORMS.BUTTONS.CREATE');
        this.dialogShown = true;
        break;
      case 'createfolder':
        this.dialogCreateType = cmd;
        this.dialogKind = Util.kLocalFormPrefix + cmd;
        this.desc = listComponent.desc;
        this.dialogFormData = {};
        this.dialogTrustees = [];
        this.dialogFormData['FORMNAME'] = cmd;
        this.dialogTitle = this.localizer.getTranslation('FOLDER_ACTIONS.NEW_FOLDER');
        this.dialogOK = this.localizer.getTranslation('FORMS.BUTTONS.CREATE');
        this.dialogShown = true;
        break;
      case 'newfolder':
      case 'newworkspace':
      case 'newpaperdocument':
        if (listComponent && listComponent.desc) {
          this.createFromCmd(cmd, listComponent.desc).then(() => { }, err => { });
        }
        break;
      case 'editprofile':
      case 'viewprofile':
      case 'edit_mini':
      case 'versions_editprofile':
        this.dialogKind = cmd === 'viewprofile' ? 'profile_readonly' : cmd === 'versions_editprofile' ? (Util.kLocalFormPrefix + cmd) : 'profile';
        this.desc = isDocCmd || cmd === 'versions_editprofile' ? Util.RestAPI.getCurItem() : this.list[0];
        this.dialogTitle =  cmd === 'viewprofile' ? this.localizer.getTranslation('FOLDER_ACTIONS.EDIT_PROFILE') : cmd === 'versions_editprofile' ?  this.list[0]['VERSION'] === '0' ? this.localizer.getTranslation('COLUMN_HEADINGS.ATTACHMENTS.ATTACHMENT_NO', [this.list[0]['VERSION_LABEL']]) : this.localizer.getTranslation('COLUMN_HEADINGS.VERSIONS.VERSION_NO', [this.list[0]['VERSION_LABEL']]) : this.localizer.getTranslation('FOLDER_ACTIONS.EDIT_PROFILE');
        this.dialogExtras = cmd === 'versions_editprofile' ? null : this.localizer.getTranslation('FORMS.BUTTONS.SHOW_ALL');
        this.dialogExtrasAlt = cmd === 'versions_editprofile' ? null : this.localizer.getTranslation('FORMS.BUTTONS.SHOW_REQUIRED_ONLY');
        this.dialogShown = true;
        break;
      case 'editprofdefs':
        this.dialogKind = 'profile_editdefs_' + cmdParts.join('___');
        this.desc = {id:'preferences',type:'preferences',lib:cmdParts[2],FORM_NAME:cmdParts[0]};
        this.dialogTitle = cmdParts[0] + ' : ' + cmdParts[3];
        this.dialogOK = this.localizer.getTranslation('FORMS.BUTTONS.SAVE');
        this.dialogShown = true;
        break;
      case 'security_save':
        this.putDataToServer(cmd, data, notifyTitle, null);
        break;
      case 'publish':
        data = data || {'%VERSION_DIRECTIVE': '%PUBLISH_VERSION'};
        notifyTitle = this.localizer.getTranslation('DOC_STATUS.PUBLISHED');
        const curPublishedItem: ListItem = fullList.find(i => i['STATUS']==='20');
        const itemToPublish: ListItem = this.list[0];
        if (!!curPublishedItem && !Util.RestAPI.canPublishMultiple()) {
          const versionNew: string = itemToPublish['VERSION_LABEL'];
          const versionOld: string = curPublishedItem['VERSION_LABEL'];
          const docName: string = itemToPublish.DOCNAME;
          const question: string = this.localizer.getTranslation('FORMS.LOCAL.PUBLISH.ASK_CHANGE_PUBLISHED',[versionNew,docName,versionOld]);
          const formData: any = { question, selections:[itemToPublish] };
          title = this.localizer.getTranslation('FOLDER_ACTIONS.PUBLISH');
          Util.Notify.warning(title,'__local_publish',title,formData,true,true,true).then(confirmed => {
            if (confirmed && confirmed.confirm) {
              this.putDataToServer(cmd, data, notifyTitle, null);
            }
          });
        } else {
          this.putDataToServer(cmd, data, notifyTitle, null);
        }
        break;
      case 'unpublish':
        data = data || {'%VERSION_DIRECTIVE': '%UNPUBLISH_VERSION'};
        notifyTitle = this.localizer.getTranslation('DOC_STATUS.UNPUBLISHED');
        this.putDataToServer(cmd, data, notifyTitle, null);
        break;
      case 'readonly':
        data = data || {'%STATUS': '%MAKE_READ_ONLY'};
        title = this.localizer.getTranslation('FOLDER_ACTIONS.READ_ONLY');
        body = this.localizer.getTranslation('FORMS.LOCAL.REMOVE.MAKE__READONLY_BODY');
        Util.Notify.warning(title,body,okBtn,null,true,true,true).then(confirmed => {
          if (confirmed && confirmed.confirm) {
             notifyTitle  = this.localizer.getTranslation('DOC_STATUS.MADE_READONLY');
            this.putDataToServer(cmd, data,  notifyTitle );
          }
        });
        break;
      case 'unlock':
        data = data || {'%STATUS': '%UNLOCK'};
        Util.Notify.warning(title,body,okBtn,null,true,true,true).then(confirmed => {
          if (confirmed && confirmed.confirm) {
            this.putDataToServer(cmd, data);
          }
        });
        break;
      case 'removereadonly':
        data = data || {'%STATUS': '%REMOVE_READ_ONLY'};
        title = this.localizer.getTranslation('FOLDER_ACTIONS.REMOVE_READ_ONLY');
        body = this.localizer.getTranslation('FORMS.LOCAL.REMOVE.REMOVE_READONLY_BODY');
        Util.Notify.warning(title,body,okBtn,null,true,true,true).then(confirmed => {
          if (confirmed && confirmed.confirm) {
            notifyTitle  = this.localizer.getTranslation('DOC_STATUS.REMOVED_READONLY');
            this.putDataToServer(cmd, data);
          }
        });
        break;
      case 'unsubscribe':
        this.postDataToServer(cmd, null);
        break;
      case 'newversion':
      case 'versions_new':
      case 'checkout':
        if (this.list[0].type === 'folders' || (this.list.length > 0 && this.list.every((item) => item['ITEM_TYPE'] === 'M'))) {
          data = {'%STATUS': '%LOCK'};
          this.putDataToServer(cmd, data);
          break;
        } // fall thru
      case 'checkin':
        if (this.list[0].type === 'folders') {
          data = {'%STATUS': '%UNLOCK'};
          this.putDataToServer(cmd, data);
          break;
        } // fall thru
      case 'shareonedrive':
      case 'email':
      case 'outlook_email':
      case 'link':
      case 'export':
      case 'exportresults':
        this.dialogKind = Util.kLocalFormPrefix + (cmd);
        this.dialogExtras = this.localizer.getTranslation('FORMS.BUTTONS.MORE');
        this.dialogExtrasAlt = this.localizer.getTranslation('FORMS.BUTTONS.LESS');
        if (cmd==='checkin') {
          this.dialogTitle = this.localizer.getTranslation('FOLDER_ACTIONS.CHECK_IN');
          this.dialogOK = this.localizer.getTranslation('FOLDER_ACTIONS.CHECK_IN');
          this.dialogExtras = null;
          this.dialogExtrasAlt = null;
        } else if (cmd==='checkout') {
          this.dialogTitle = this.localizer.getTranslation('FOLDER_ACTIONS.CHECK_OUT');
          this.dialogOK = this.localizer.getTranslation('FOLDER_ACTIONS.CHECK_OUT');
          this.dialogExtras = null;
          this.dialogExtrasAlt = null;
        } else if (cmd==='export') {
          this.dialogTitle = this.localizer.getTranslation('FOLDER_ACTIONS.EXPORT');
          this.dialogOK = this.localizer.getTranslation('FOLDER_ACTIONS.EXPORT');
          this.dialogExtras = null;
          this.dialogExtrasAlt = null;
        } else if (cmd==='exportresults') {
          this.dialogFormData = { $edx_export_form: true, columns: data.columns, defaultCols: data.defaultCols, sortKey: data.sortKey };
          this.dialogTitle = this.localizer.getTranslation('FOLDER_ACTIONS.EXPORT_RESULTS');
          this.dialogOK = this.localizer.getTranslation('FOLDER_ACTIONS.EXPORT_RESULTS');
          this.dialogExtras = null;
          this.dialogExtrasAlt = null;
        } else if (cmd==='newversion') {
          if (!this.dialogFormData) {
            this.dialogFormData = {};
          }
          this.dialogFormData['TYPE_ID'] = !!curWindow['currentFile'] ? curWindow['currentFile']['TYPE_ID'] : null;
          this.dialogTitle = this.localizer.getTranslation('METADATA.VERSIONS_ACTIONS_ADD.NEW_VERSION');
          this.dialogOK = this.localizer.getTranslation('FORMS.BUTTONS.ADD');
          this.dialogExtras = null;
          this.dialogExtrasAlt = null;
        } else if (cmd==='link') {
          this.dialogTitle = this.localizer.getTranslation('FOLDER_ACTIONS.LINK');
          this.dialogOK = this.localizer.getTranslation('FORMS.LOCAL.LINK.COPY');
          this.dialogExtras = null;
          this.dialogExtrasAlt = null;
        } else if (cmd==='email' || cmd === 'outlook_email') {
            if (nItems === 0 && !!data) {
              this.list = [data];
            }
            prepareEmailForm(cmd);
            if (cmd === 'outlook_email') {
              this.dialogFormData['$edx_email_outlook_from'] = Util.RestAPI.getOutlookPreferences('whoamI');
            } else {
              this.dialogFormData['$edx_email_from'] = Util.RestAPI.getEmail();
            }
        } else if (cmd==='shareonedrive') {
          this.dialogTitle = this.localizer.getTranslation('FOLDER_ACTIONS.SHARE');
          this.dialogOK = this.localizer.getTranslation('FORMS.BUTTONS.SHARE');
          this.dialogExtras = null;
          this.dialogExtrasAlt = null;
        }
        this.dialogShown = true;
        break;
      case 'confirm_delete':
        this.dialogTitle = this.localizer.getTranslation('FORMS.LOCAL.DELETE.CONFIRM_DELETE');
        this.dialogOK = this.localizer.getTranslation('FORMS.BUTTONS.OK');
        this.dialogKind = Util.kLocalFormPrefix + (cmd);
        this.dialogExtras = null;
        this.dialogExtrasAlt = null;
        this.list = this.refList;
        this.dialogShown = true;
        this.dialogWidth = Util.kMaxPopupDialogWidth;
        this.kind2 = Util.kLocalFormPrefix + (cmd);
        break;
      case 'stopshareonedrive':
        data = data || {};
        title = this.localizer.getTranslation('FORMS.LOCAL.SHARE.STOP_ONEDRIVE');
        body = this.localizer.getTranslation('FORMS.LOCAL.REMOVE.SHARE_ONEDRIVE_BODY');
        Util.Notify.warning(title,body,okBtn,null,true,true,true).then(confirmed => {
          if (confirmed && confirmed.confirm) {
              notifyTitle  = this.localizer.getTranslation('DOC_STATUS.UNSHARED');
            this.putDataToServer(cmd, data, notifyTitle);
          }
        });
        break;
      case 'insertintodocument':
      case 'insertlinkintodocument':
        if (Util.Device.bIsOfficeAddinWord || Util.Device.bIsOfficeAddinExcel || Util.Device.bIsOfficeAddinPowerPoint) {
          let versionID = 'C';
          const imgFmts: string[] = ['PNG','JPG','JPEG','BMP','TIFF'];
          const insertDone = (err: any): void => {
            if (listComponent) {
              listComponent.spinnerOff();
            }
            if (err) {
              Util.Notify.error(this.localizer.getTranslation('GENERIC_ERRORS.ERROR'), err);
            }
          };
          if (listComponent) {
            listComponent.spinnerOn();
          }
          if (!!data && !!data['%VERSION_ID']) {
            versionID = data['%VERSION_ID'];
          }
          Util.RestAPI.getFileDownloadInfo(this.list[0], versionID).then(infoData => {
            const fileName: string = infoData.name || '';
            const nameParts: string[] = fileName.split('.');
            const ext: string = nameParts.length ? nameParts[nameParts.length-1].toUpperCase() : '';
            const messageUnsupported = this.localizer.getTranslation('GENERIC_ERRORS.UNSUPPORTED_FORMAT');
            const versionForUrl = versionID==='C' || !this.list[0]['VERSION_LABEL'] ? 'r' : this.list[0]['VERSION_LABEL'];
            const pcdocsUrl = 'pcdocs://' + this.list[0].lib + '/' + (this.list[0].DOCNUM || this.list[0].id) + '/'+versionForUrl;
            if (cmd !== 'insertintodocument' || (ext === 'DOCX' || imgFmts.indexOf(ext) !== -1)) {
              const afterDL = (base64File) => {
                if (Util.Device.bIsOfficeAddinWord) {
                  Word.run(context => {
                    const range = context.document.getSelection();
                    range.load('html');
                    return context.sync().then(() => {
                      if (cmd === 'insertintodocument') {
                        if (imgFmts.indexOf(ext) !== -1) {
                          range.insertInlinePictureFromBase64(base64File, Word.InsertLocation.after);
                        } else {
                          range.insertFileFromBase64(base64File, Word.InsertLocation.after);
                        }
                      } else {
                        range.insertHtml('<a href="' + pcdocsUrl + '">' + fileName + '</a>', Word.InsertLocation.after);
                      }
                      return context.sync().then(() => {
                        insertDone(null);
                      });
                    });
                  }).catch(insertDone);
                } else if (Util.Device.bIsOfficeAddinExcel || Util.Device.bIsOfficeAddinPowerPoint) {
                  if (cmd === 'insertintodocument') {
                    if (imgFmts.indexOf(ext) !== -1) {
                      Office.context.document.setSelectedDataAsync(base64File, { coercionType: Office.CoercionType.Image }, result => {
                        if (result.status === Office.AsyncResultStatus.Failed) {
                          insertDone(messageUnsupported);
                        }
                      });
                    } else {
                      insertDone(messageUnsupported);
                    }
                    insertDone(null);
                  } else if (Util.Device.bIsOfficeAddinExcel) {
                    Excel.run(context => {
                      const cell = context.workbook.getActiveCell();
                      cell.select();
                      cell.hyperlink = { address: pcdocsUrl, documentReference: null, screenTip: null, textToDisplay: fileName };
                      return context.sync().then(() => {
                        insertDone(null);
                      });
                    }).catch(insertDone);
                  }
                }
              };
              if (cmd === 'insertintodocument') {
                Util.RestAPI.downloadAsBase64(this.list[0], 'versions/'+versionID).subscribe(base64File => {
                  afterDL(base64File);
                }, restErr1 => {
                  insertDone(restErr1);
                });
              } else {
                afterDL(null);
              }
            } else {
              insertDone(this.localizer.getTranslation('GENERIC_ERRORS.UNSUPPORTED_FORMAT'));
            }
          }, restErr2 => {
            insertDone(restErr2);
          });
        }
        break;
      case 'attachments_delete':
      case 'delete':
        if (nItems) {
          let docName: string = this.list[0].DOCNAME;
          const firstItem: ListItem = this.list[0];
          if (firstItem['VERSION_LABEL']) {
            docName = this.localizer.getTranslation(cmd === 'attachments_delete' ? 'COLUMN_HEADINGS.ATTACHMENTS.ATTACHMENT_NO' : 'COLUMN_HEADINGS.VERSIONS.VERSION_NO',[this.list[0]['VERSION_LABEL']]);
          }
          title = this.localizer.getTranslation('FOLDER_ACTIONS.DELETE');
          const question: string = nItems===1 ? this.localizer.getTranslation('FORMS.LOCAL.DELETE.ASK_DELETE_SINGLE',[docName]) : this.localizer.getTranslation('FORMS.LOCAL.DELETE.ASK_DELETE_MANY',[nItems.toString()]);
          const formData: any = { question, selections: this.list, docName};

          Util.Notify.warning(title, '__local_delete', title, formData, true, true, true).then(async confirmed => {
            if (confirmed && confirmed.confirm) {
              const radioVal: string = confirmed.data.radio;
              this.refList = [];
              if (radioVal === 'DP') {
                this.refList = await this.getMultiReferenceDocs(this.list);
                this.list = this.list.filter(item => !this.refList.includes(item));
              }
              if (this.list.length > 0) {
                this.doDelete(cmd, radioVal, this.list, listComponent, title);
              }
              if (this.refList.length > 0) {
                this.doCommand('confirm_delete');
              }
            }
          });
        }
        break;
      case 'delete_multiref':
        const deleteList = this.formWrapper.dynamicForm.form.value['$edx_form_list'];
        title = this.localizer.getTranslation('FOLDER_ACTIONS.DELETE');
        this.doDelete(cmd,'DP',deleteList,listComponent,title);
        break;
      case 'footer_options':
        this.dialogKind = Util.kLocalFormPrefix + cmd;
        this.dialogTitle = this.localizer.getTranslation('FORMS.LOCAL.FOOTER_OPTIONS.FOOTER_OPTIONS');
        this.dialogOK = this.localizer.getTranslation('FORMS.BUTTONS.SAVE');
        this.dialogShown = true;
        break;
      case 'copy':
        const originalDesc = Util.deepCopy(this.list[0]);
        this.desc = originalDesc;
        this.dialogKind = Util.kLocalFormPrefix + cmd;
        this.dialogTitle = this.localizer.getTranslation('FORMS.LOCAL.COPY.COPY');
        this.dialogOK = this.localizer.getTranslation('FORMS.LOCAL.COPY.SAVE_COPY');
        this.dialogExtras = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_ALL');
        this.dialogExtrasAlt = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_REQUIRED_ONLY');
        this.desc2 = originalDesc;
        this.kind2 = 'profile_copy';
        this.dialogWidth = Util.kMaxPopupDialogWidth;
        this.dialogShown = true;
        break;
      case 'copytree':
        this.desc = this.list[0];
        this.dialogKind = Util.kLocalFormPrefix + cmd;
        this.dialogTitle = this.localizer.getTranslation('FORMS.LOCAL.COPY_TREE.COPY_TREE');
        this.dialogOK = this.localizer.getTranslation('FORMS.LOCAL.COPY_TREE.SAVE_COPY_TREE');
        this.dialogExtras = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_ALL');
        this.dialogExtrasAlt = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_REQUIRED_ONLY');
        this.desc2 = this.list[0];
        this.kind2 = 'profile_copy_tree';
        this.dialogShown = true;
        break;
      case 'savetoedocs':
        this.desc = this.list[0];
        this.dialogKind = 'profile_savetoedocs';
        this.dialogShown = true;
        break;
      case 'profilesearch':
        this.dialogKind = 'profile_query';
        this.dialogExtras = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_ALL');
        this.dialogExtrasAlt = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_LESS');
       // fall thru
      case 'activitysearch':
        if (!searchForms || !searchForms.length) {
          break;
        }
        // When default search form is not set under preferences, pick first search form.
        this.desc = { id: searchForms[0]['%FORM_NAME'], type: 'forms', lib: searchForms[0].lib, FORM_NAME: searchForms[0]['%FORM_NAME'] };
          // fall thru
      case 'advancedsearch':
        this.dialogTitle = this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.' + (cmd === 'advancedsearch' ? 'ADVANCED_SEARCH' : cmd === 'activitysearch' ? 'ACTIVITY_SEARCH' : 'PROFILE_SEARCH'));
        this.dialogOK = this.localizer.getTranslation('FOLDER_ACTIONS.SEARCH');
        if (cmd === 'activitysearch' || cmd === 'advancedsearch') {
          this.dialogKind = Util.kLocalFormPrefix + cmd;
        }
        if (!!this.desc && !!this.desc.lib) {
          this.settingsService.get('preferences/formdefaults').then(defsData => {
            if (!!defsData && !!defsData[this.desc.lib.toUpperCase()] && !!defsData[this.desc.lib.toUpperCase()]['SEARCH']) {
              const index = searchForms.findIndex(f => f['%FORM_NAME'] === defsData[this.desc.lib.toUpperCase()]['SEARCH']);
              if (index >= 0) {
                this.desc['FORM_NAME'] = searchForms[index]['%FORM_NAME'];
              }
            }
            this.dialogShown = true;
          }, err => {
            this.dialogShown = true;
          });
        } else {
          this.dialogShown = true;
        }
        break;
      case 'shortcuts':
        this.dialogKind = cmd;
        this.dialogTitle = this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.SHORTCUTS');
        this.dialogOK = '';
        this.dialogExtras = null;
        this.dialogExtrasAlt = null;
        this.dialogShown = true;
        break;
      case 'preferences':
        const locSansHash = location.hash.substr(1);
        if (locSansHash !== Util.RestAPI.getPrefsURL()) {
          if (locSansHash !== Util.RestAPI.getHomeURL()) {
            Util.RestAPI.navHome().then(() => {
              Util.RestAPI.navPreferences();
            });
          } else {
            Util.RestAPI.navPreferences();
          }
        }
        break;
      case 'adminmaintenance':
        const locHash = location.hash.substr(1);
        if (locHash !== Util.RestAPI.getAdminMaintenanceURL()) {
          if (locHash !== Util.RestAPI.getHomeURL()) {
            Util.RestAPI.navHome().then(() => {
              Util.RestAPI.navAdmin();
            });
          } else {
            Util.RestAPI.navAdmin();
          }
        }
        break;
      case 'profile_lookup_add':
        this.dialogKind = cmd;
        this.desc = !!data['desc'] ? data['desc'] : {};
        this.dialogTitle = this.localizer.getTranslation('ADMIN.LOOKUPS.ADD', [(this.desc['DOCNAME'] || '')]);
        this.dialogOK = '';
        this.dialogExtras = null;
        this.dialogExtrasAlt = null;
        this.dialogShown = true;
        break;
      case 'profile_lookup_copy':
      case 'profile_lookup_edit':
        this.dialogKind = cmd;
        this.desc = data['desc'];
        this.dialogTitle = this.localizer.getTranslation(cmd === 'profile_lookup_copy' ? 'ADMIN.LOOKUPS.COPY' : 'ADMIN.LOOKUPS.EDIT', [(this.desc['DOCNAME'] || '')]);
        this.dialogOK = '';
        this.dialogExtras = null;
        this.dialogExtrasAlt = null;
        this.dialogShown = true;
        break;
      case 'profile_outlook_item':
        if (data && data.list) {
          const listItem: ListItem = data.list as ListItem;
          this.listService.openMetadata([listItem], null, null, listItem, data.tab);
        }
        break;
      case 'uploadofficeitem':
        if (!!outlookMailbox) {
          this.desc = Util.RestAPI.getAppDesc();
          if (this.desc) {
            Util.RestAPI.navToURL(Util.RestAPI.getHomeURL() + '?name=' + this.desc['DOCNAME']);
          }
        }
        break;
      case 'about':
        let versionLink = '';
        let restAPIVersInfo = '';
        const pftaVersion: string = Util.RestAPI.pftaAboutVersion();
        if (loginReply && loginReply.SERVER_INFO) {
          const rapiVers: string = !!loginReply.SERVER_INFO.ABOUT_VERSION ? loginReply.SERVER_INFO.ABOUT_VERSION : loginReply.SERVER_INFO.VERSION;
          restAPIVersInfo = loginReply.SERVER_INFO.NAME + ': ' + rapiVers + '.' + loginReply.SERVER_INFO.BUILD;
          if (loginReply['SYSTEM_DEFAULTS'] && loginReply['SYSTEM_DEFAULTS']['VERSION_INFO_LINK']) {
            versionLink = '\n$edx_link:'+loginReply['SYSTEM_DEFAULTS']['VERSION_INFO_LINK']+'$edx_link_title:'+this.localizer.getTranslation('FORMS.LOCAL.ABOUT.FIXES_IN_THIS_VERSION');
          }
        }
        if (!!pftaVersion) {
          restAPIVersInfo = this.localizer.getTranslation('FORMS.LOCAL.PFTA.PFTA') + ': ' + pftaVersion + ' \n ' + restAPIVersInfo;
        }
        Util.Notify.info(this.localizer.getTranslation('FORMS.LOCAL.ABOUT.ABOUT'), this.localizer.getTranslation('FORMS.LOCAL.ABOUT.GUI_VERSION', [Util.Device.version, restAPIVersInfo]) + versionLink);
        break;
      case 'removefromfolder':
      case 'removeitem':
      case 'related_remove':
        if (nItems) {
          const isSharedDownloads: boolean = (listComponent && listComponent.desc && listComponent.desc.type==='folders' && listComponent.desc.id==='downloads');
          const isSharedImports: boolean = (listComponent && listComponent.desc && listComponent.desc.type==='folders' && listComponent.desc.id==='imports');
          let nResponses = 0;
          let nErrors = 0;
          const desc: any = cmd==='removeitem' || cmd==='related_remove' ? (data.desc || {}) : this.list[0];
          const docName: string = desc.DOCNAME || '';
          const refDocName: string = cmd==='removeitem' || cmd==='related_remove' ? this.list[0].DOCNAME : curWindow ? curWindow.getName() : '';
          const okButton: string = this.localizer.getTranslation('METADATA.WHERE_USED_ACTIONS.REMOVE');
          let question: string;

          if (cmd==='removefromfolder') {
            title = this.localizer.getTranslation('FOLDER_ACTIONS.REMOVE');
            question = nItems < 2 ? this.localizer.getTranslation('FORMS.LOCAL.REMOVE.ASK_REMOVE_SINGLE', [docName, refDocName]) : this.localizer.getTranslation('FORMS.LOCAL.REMOVE.ASK_REMOVE_MANY', [nItems.toString(), refDocName]);
          } else if (cmd==='related_remove') {
            title = this.localizer.getTranslation('FOLDER_ACTIONS.RELATIONS');
            question = nItems < 2 ? this.localizer.getTranslation('FORMS.LOCAL.RELATIONS.ASK_REMOVE_SINGLE', [docName, refDocName]) : this.localizer.getTranslation('FORMS.LOCAL.RELATIONS.ASK_REMOVE_MANY', [nItems.toString(), docName]);
          } else if (cmd==='removeitem') {
            title = this.localizer.getTranslation('FOLDER_ACTIONS.WHERE_USED');
            question = nItems < 2 ? this.localizer.getTranslation('FORMS.LOCAL.WHERE_USED.ASK_REMOVE_SINGLE', [docName, refDocName]) : this.localizer.getTranslation('FORMS.LOCAL.WHERE_USED.ASK_REMOVE_MANY', [docName, nItems.toString()]);
          }
          Util.Notify.warning(title, '__local_remove', okButton, {question}, true, true, true).then(confirmed => {
            if (confirmed && confirmed.confirm) {
              let query: string;
              let errMsg = '';
              let notifyBody: string;
              let descItem: any;
              const removeDone = () => {
                if (listComponent) {
                  listComponent.reloadList();
                }
                if (isSharedDownloads || isSharedImports) {
                  Util.RestAPI.refreshDownloads(isSharedImports);
                }
                if (curWindow) {
                  curWindow.refresh(cmd);
                }
                if (cmd === 'removefromfolder' && Util.Transforms.isFavoriteFolder(this.app.desc['id'])) {
                  Util.RestAPI.refreshTile(this.favoriteService.getDescForFavorite());
                }
                if (nErrors === 0) {
                  if (cmd==='removefromfolder') {
                    notifyBody = nItems < 2 ? this.localizer.getTranslation('FORMS.LOCAL.REMOVE.SUCCESS_SINGLE', [docName, refDocName]) : this.localizer.getTranslation('FORMS.LOCAL.REMOVE.SUCCESS_MANY', [nItems.toString(), refDocName]);
                  } else if (cmd==='related_remove') {
                    notifyBody = nItems < 2 ? this.localizer.getTranslation('FORMS.LOCAL.RELATIONS.SUCCESS_SINGLE', [docName, refDocName]) : this.localizer.getTranslation('FORMS.LOCAL.RELATIONS.SUCCESS_MANY', [nItems.toString(), refDocName]);
                  } else if (cmd==='removeitem') {
                    notifyBody = nItems < 2 ? this.localizer.getTranslation('FORMS.LOCAL.WHERE_USED.SUCCESS_SINGLE', [docName, refDocName]) : this.localizer.getTranslation('FORMS.LOCAL.WHERE_USED.SUCCESS_MANY', [docName, nItems.toString()]);
                  }
                  Util.Notify.success(title, notifyBody);
                } else {
                  if (cmd==='removefromfolder') {
                    notifyBody = nItems < 2 ? this.localizer.getTranslation('FORMS.LOCAL.REMOVE.FAILURE_SINGLE', [docName, refDocName, errMsg]) : this.localizer.getTranslation('FORMS.LOCAL.REMOVE.FAILURE_MANY', [nItems.toString(), refDocName, errMsg]);
                  } else if (cmd==='related_remove') {
                    notifyBody = nItems < 2 ? this.localizer.getTranslation('FORMS.LOCAL.RELATIONS.FAILURE_SINGLE', [docName, refDocName, errMsg]) : this.localizer.getTranslation('FORMS.LOCAL.RELATIONS.FAILURE_MANY', [nItems.toString(), refDocName, errMsg]);
                  } else if (cmd==='removeitem') {
                    notifyBody = nItems < 2 ? this.localizer.getTranslation('FORMS.LOCAL.WHERE_USED.FAILURE_SINGLE', [docName, refDocName, errMsg]) : this.localizer.getTranslation('FORMS.LOCAL.WHERE_USED.FAILURE_MANY', [docName, nItems.toString(), errMsg]);
                  }
                  Util.Notify.warning(title, notifyBody);
                }
              };
              for (let i = 0; i < nItems; i++) {
                const item = this.list[i];
                if (isSharedDownloads || isSharedImports) {
                  Util.RestAPI.deleteFromDownloads(item).then(success => {
                    if (!success) {
                      ++nErrors;
                      errMsg = this.localizer.getTranslation('GENERIC_ERRORS.ERROR');
                    }
                    if (++nResponses === nItems) {
                      removeDone();
                    }
                  });
                } else {
                  const param: string = cmd==='related_remove' ? 'associations' : 'references';
                  if (cmd==='removefromfolder') {
                    query = 'reftype=' + this.app.desc.type + '&refid=' + this.app.desc.id + '&reflib=' + this.app.desc.lib + '&refsysid=' + (item['FI.SYSTEM_ID'] || '""');
                    descItem = item;
                  } else if (cmd==='related_remove') {
                    query = 'relid=' + item.id + '&rellib=' + item.lib;
                    descItem = desc;
                  } else  {
                    query = 'reftype=' + item['type'] + '&refid=' + item['id'] + '&reflib=' + item['lib'] + '&refsysid=' + (item['REMOTE_SYSTEM_ID'] || item['SYSTEM_ID'] || '""');
                    descItem = desc;
                  }
                  Util.RestAPI.delete(descItem, param, query).subscribe((response) => {
                    if (Util.Transforms.isFavoriteFolder(this.app.desc['id'])) {
                      this.favoriteService.addOrRemoveFavoriteIdInLocalStorage(item, false);
                    }
                    if (++nResponses === nItems) {
                      removeDone();
                    }
                  }, error => {
                    ++nErrors;
                    if (++nResponses === nItems) {
                      errMsg = Util.Transforms.formatError(error);
                      removeDone();
                    }
                  });
                }
              }
            }
          });
        }
        break;
      case 'newurl':
        if (listComponent && listComponent.desc) {
          this.dialogTitle = this.localizer.getTranslation('FOLDER_ACTIONS.NEW_URL');
          this.dialogOK = this.localizer.getTranslation('FORMS.BUTTONS.CREATE');
          this.dialogKind = Util.kLocalFormPrefix + cmd.substr(3);
          this.dialogCreateType = cmd.substr(3)+'s';
          this.desc = listComponent.desc;
          this.dialogShown = true;
        }
        break;
      case 'addquicksearch':
        this.pickerTitle = this.localizer.getTranslation('FOLDER_ACTIONS.ADD_SEARCHES');
        this.pickerOK = this.localizer.getTranslation('FORMS.BUTTONS.OK');
        this.pickerDesc = { id: '', type: 'searches', lib: '', DOCNAME: this.localizer.getTranslation('FOLDER_ACTIONS.SEARCH') };
        this.pickerHasFilter = true;
        this.pickerKind = 'list_2';
        this.pickerShown = true;
        break;
      case 'create_tile':
        name = curWindow ? curWindow.getName() : null;
        let tooltip = name;
        let isShared = appDesc['IS_SHARED'];
        const filePartNum = appDesc['filePartNo'];
        const isFilePart: boolean = appDesc.type === 'fileplans' && filePartNum;
        if (isFilePart) {
          tooltip = curWindow ? curWindow.getTooltip() : null;
        }
        if (!isShared && this.list) {
          const theListItem = this.list.find(i => i.type===appDesc.type && i.lib===appDesc.lib && i.id===appDesc.id);
          if (!!theListItem) {
            isShared = theListItem['IS_SHARED'];
          }
        }
        if (name) {
          const newTile = new Tile({
            type: appDesc.type,
            id: isFilePart ? 'FP-FilePart-'+ decodeURIComponent(filePartNum) : appDesc.id,
            lib: appDesc.lib,
            index: 0,
            size: 1,
            name,
            tooltip,
            imgPath: appDesc['imgPath'] || '',
            docNumber: appDesc['docNumber'] || '',
            IS_SHARED: isShared || '',
            PD_ACTIVE_STATUS: appDesc['PD_ACTIVE_STATUS'] || ''
          });
          const tiles: Tile[] = this.tileService.appendTile(newTile);
          if (tiles.indexOf(newTile)===-1) {
            Util.Notify.warning(this.localizer.getTranslation('TILE_MENU.TILE_NOT_ADDED',[name,'']));
          } else {
            Util.Notify.success(this.localizer.getTranslation('TILE_MENU.TILE_ADDED',[name]));
            if (!!curWindow) {
              curWindow.refresh();
            }
          }
        }
        break;
      case 'rename':
        if (this.list && this.list.length) {
          title = this.localizer.getTranslation('FOLDER_ACTIONS.RENAME');
          const item: ListItem = this.list[0];
          let formData: any = { DOCNAME: item.DOCNAME };
          Util.Notify.info(title,'__local_rename',title,formData,true,true,true).then(confirmed => {
            if (confirmed && confirmed.confirm) {
              name = confirmed.data.DOCNAME;
              if (name && name.length) {
                formData = item.type==='searches' ? {DESCRIPTION: name} : {DOCNAME: name};
                Util.RestAPI.put(this.list[0], formData, 'profile').subscribe(response => {
                  if (listComponent) {
                    listComponent.reloadList();
                  }
                  if (curWindow) {
                    curWindow.refresh(cmd);
                  }
                  Util.Notify.success(this.localizer.getTranslation('FOLDER_ACTIONS.RENAMED_SINGLE',[item.DOCNAME, name]));
                }, error => {
                  Util.Notify.warning(title, error);
                });
              }
            }
          });
        }
        break;
      case 'editsearch':
        if (this.list && this.list.length === 1) {
          this.desc = this.list[0];
          const loadForm = (theCriteria: any) => {
            if (!!theCriteria && theCriteria['SEARCH_IN']==='3') {
              theCriteria['SEARCH_IN'] = '0';
            }
            this.dialogKind = 'profile_query_edit';
            this.dialogShown = true;
            this.dialogOK = this.localizer.getTranslation('FOLDER_ACTIONS.SAVE');
            this.dialogExtras = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_ALL');
            this.dialogExtrasAlt = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_LESS');
            this.dialogFormData = theCriteria;
          };
          let criteria: any = {};
          if (typeof this.desc['CRITERIA'] === 'string') {
            const criteriaParts: string[] = this.desc['CRITERIA'].split(',');
            for (const criteriaPair of criteriaParts) {
              const criteriaPairParts: string[] = criteriaPair.split('=');
              if (criteriaPairParts.length===2) {
                let value: string = criteriaPairParts[1];
                if (value.charAt(0)==='\'' && value.charAt(value.length-1)==='\'') {
                  value = value.substr(1,value.length-2);
                }
                criteria[criteriaPairParts[0]] = value;
              }
            }
          } else if (typeof this.desc['CRITERIA'] === 'object') {
            criteria = this.desc['CRITERIA'];
          }
          const keys: string[] = Object.keys(criteria);
          const nKeys: number = keys ? keys.length : 0;
          if (nKeys) {
            for (const key of keys) {
              if (key.toUpperCase().indexOf('DATE')>=0) {
                const value = criteria[key];
                if (typeof value === 'string' && value.toUpperCase().indexOf(' TO ')>=0) {
                  criteria[key] = Util.Transforms.utcTimeRangeToLocalDate(value);
                }
              }
            }
            this.dialogTitle = this.desc.DOCNAME || this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.PROFILE_SEARCH');
            loadForm(criteria);
          } else {
            const url: string = 'searches/' + this.desc.id;
            Util.RestAPI.get(url, null, 'max=1').subscribe((listData: ListData) => {
              this.dialogTitle = listData.set.search['DESCRIPTION'];
              loadForm(listData.set.search.criteria);
            }, error => {
              Util.Notify.info(this.localizer.getTranslation('GENERIC_ERRORS.ERROR'), error);
            });
          }
        }
        break;
      case 'share':
        const shareItem: ListItem = Util.RestAPI.getCurItem();
        const containerRights = !!listComponent ? listComponent.containerRights() : null;
        this.listService.openMetadata([shareItem], null, containerRights, shareItem, 'security', '&share=true');
        break;
      case 'open':
      case 'view':
        if (this.list && this.list.length && (Util.Device.bIsElectron || Util.RestAPI.hasPFTA() || (Util.Device.canDownloadOfficeDoc(this.list[0]) && cmd==='open') || Util.Device.bIsCordova)) {
          Util.RestAPI.viewOrOpenFilesWithStatusCheck(this.list, undefined, cmd==='view', null);
        }
        break;
      case 'exportfolder':
        if (this.list && this.list.length) {
          Util.RestAPI.exportContents(this.list[0]);
        }
        break;
      case 'home':
        Util.RestAPI.navHome();
        break;
      case 'help':
        Util.Help.show();
        break;
      case 'logoff':
        Util.RestAPI.logOff(true, true);
        break;
      case 'savedsearches':
      case 'checkedout':
      case 'downloads':
      case 'imports':
        let tileDesc: any;
        if (cmd==='savedsearches') {
          tileDesc = {id:'', type:'searches'};
        } else if (cmd==='checkedout') {
          tileDesc = {id:'checkedout', type:'folders'};
        } else if (cmd==='downloads') {
          tileDesc = {id:'downloads', type:'folders'};
        } else if (cmd==='imports') {
          tileDesc = {id:'imports', type:'folders'};
        }
        const tile: Tile = this.tileService.getTile(tileDesc);
        if (tile) {
          Util.RestAPI.navHome().then(success => {
            this.tileService.openTile(tile);
          });
        }
        break;
      case 'declarerecord':
        data = {'%STATUS': '%DECLARE_RECORD'};
        title = this.localizer.getTranslation('FOLDER_ACTIONS.DECLARE_RECORD');
        body = this.localizer.getTranslation('FORMS.LOCAL.REMOVE.DECLARE_RECORD_BODY');
        Util.Notify.warning(title,body,okBtn,null,true,true,true).then(confirmed => {
          if (confirmed && confirmed.confirm) {
            notifyTitle  = this.localizer.getTranslation('DOC_STATUS.DECLARED_RECORD');
            this.putDataToServer(cmd, data,  notifyTitle );
          }
        });
        break;
      case 'undeclarerecord':
        data = {'%STATUS': '%UNDECLARE_RECORD'};
        title = this.localizer.getTranslation('FOLDER_ACTIONS.UNDECLARE_RECORD');
        body = this.localizer.getTranslation('FORMS.LOCAL.REMOVE.UNDECLARE_RECORD_BODY');
        Util.Notify.warning(title,body,okBtn,null,true,true,true).then(confirmed => {
          if (confirmed && confirmed.confirm) {
            this.putDataToServer(cmd, data);
          }
        });
        break;
      case 'fp_box_rm_request':
      case 'rm_request':
        this.dialogKind = Util.kLocalFormPrefix + ('rm_request');
        this.dialogExtras = this.localizer.getTranslation('FORMS.BUTTONS.MORE');
        this.dialogExtrasAlt = this.localizer.getTranslation('FORMS.BUTTONS.LESS');
        this.dialogTitle = this.localizer.getTranslation('FORMS.LOCAL.RM_REQUEST.REQUEST');
        this.dialogOK = this.localizer.getTranslation('FOLDER_ACTIONS.REQUEST_OK');
        this.dialogShown = true;
        break;
      case 'rm_changevitalstatus':
        data = {'%CHANGE_VITAL_STATUS': '1'};
        this.putDataToServer(cmd, data);
        break;
      case 'rm_vitalreviewdate':
        this.dialogKind = Util.kLocalFormPrefix + (cmd);
        this.dialogExtras = this.localizer.getTranslation('FORMS.BUTTONS.MORE');
        this.dialogExtrasAlt = this.localizer.getTranslation('FORMS.BUTTONS.LESS');
        this.dialogTitle = this.localizer.getTranslation('FORMS.LOCAL.RM_REQUEST.CHANGE_REVIEW_DATE');
        this.dialogOK = this.localizer.getTranslation('FOLDER_ACTIONS.SAVE');
        this.dialogShown = true;
        break;
      case 'rm_supersede':
        this.pickerTitle = this.localizer.getTranslation('FOLDER_ACTIONS.SELECT_FILE');
        this.pickerOK = this.localizer.getTranslation('FORMS.BUTTONS.OK');
        this.pickerDesc = { id:'', type:'folders', lib:'', DOCNAME:this.localizer.getTranslation('FOLDER_ACTIONS.CONTAINERS') };
        this.pickerHasFilter = true;
        this.pickerKind = 'list_2_single_onlydocs';
        const disabledList: ListItem[] = new Array<ListItem>();
        disabledList[0] = this.list[0];
        // Pass in a new variable since picker will append RED and FF entries to the diabled list
        this.pickerDisableList = disabledList;
        this.pickerShown = true;
        break;
      case 'rm_putinbox':
        this.dialogKind = Util.kLocalFormPrefix + (cmd);
        this.dialogTitle = this.localizer.getTranslation('FORMS.LOCAL.RM_REQUEST.PUT_IN_BOX');
        this.dialogOK = this.localizer.getTranslation('FOLDER_ACTIONS.PUT_IN_BOX_OK');
        this.dialogShown = true;
        break;
      case 'editsecurity':
          this.dialogKind = Util.kLocalFormPrefix + (cmd);
          if (isDocCmd) {
            this.desc = this.app.desc;
          } else {
            this.desc = this.list[0];
          }
          this.dialogTitle = this.localizer.getTranslation('FOLDER_ACTIONS.EDIT_SECURITY');
          this.dialogOK = this.localizer.getTranslation('FOLDER_ACTIONS.PUT_IN_BOX_OK');
          this.dialogShown = true;
          break;
      case 'setsecurity':
        // Reuse the Edit security form - just change the title
        this.dialogKind = Util.kLocalFormPrefix + ('editsecurity');
        if (isDocCmd) {
          this.desc = this.app.desc;
        } else {
          this.desc = this.list[0];
        }
        this.dialogTitle = this.localizer.getTranslation('FOLDER_ACTIONS.SET_SECURITY');
        this.dialogOK = this.localizer.getTranslation('FOLDER_ACTIONS.PUT_IN_BOX_OK');
        this.dialogShown = true;
        break;
      case 'rm_removefrombox':
        this.dialogKind = Util.kLocalFormPrefix + (cmd);
        this.dialogTitle = this.localizer.getTranslation('FORMS.LOCAL.RM_REQUEST.REMOVE_FROM_BOX');
        this.dialogOK = this.localizer.getTranslation('FORMS.BUTTONS.OK');
        this.dialogShown = true;
        break;
      case 'fp_rm_suspend':
      case 'rm_suspend':
        data = {'%SUSPEND': '1'};
        this.putDataToServer(cmd, data);
        break;
      case 'fp_rm_release':
      case 'rm_release':
        data = {'%RELEASE': '1'};
        this.putDataToServer(cmd, data);
        break;
      case 'rm_disposalinstructions':
        break;
      case 'rm_printlabel':
        break;
      case 'addfilepart':
      case 'rm_changefilepart':
      case 'rm_assignfilepart':
        this.pickerTitle = this.localizer.getTranslation('FOLDER_ACTIONS.SELECT_FILEPART');
        this.pickerOK = this.localizer.getTranslation('FORMS.BUTTONS.ADD');
        this.pickerDesc = { id:'', type:'fileplans', lib:'', DOCNAME:this.localizer.getTranslation('FOLDER_ACTIONS.FILEPLANS_CONTAINER') };
        this.pickerHasFilter = false;
        this.pickerKind = 'list_2_single_filepart';
        this.pickerDisableList = this.list;
        this.pickerShown = true;
        break;
      case 'box_cancelrequest':
      case 'rm_cancelrequest':
        if (nItems) {
          let nResponses = 0;
          let nErrors = 0;
          let errMsg = '';
          const firstItem: ListItem = this.list[0];
          const docName: string = this.list[0].DOCNAME;
          let notifyBody = '';
          const cancelDone = () => {
            if (listComponent) {
              listComponent.reloadList();
            }
            Util.RestAPI.refreshLists(firstItem);
            title = this.localizer.getTranslation('FORMS.LOCAL.CANCEL.TITLE');
            if (nErrors===0) {
              notifyBody = nItems===1 ? this.localizer.getTranslation('FORMS.LOCAL.CANCEL.SUCCESS_SINGLE',[docName]) : this.localizer.getTranslation('FORMS.LOCAL.CANCEL.SUCCESS_MANY',[nItems.toString()]);
              Util.Notify.success(title, notifyBody);
            } else {
              notifyBody = nItems===1 ? this.localizer.getTranslation('FORMS.LOCAL.CANCEL.FAILURE_SINGLE',[docName,errMsg]) : this.localizer.getTranslation('FORMS.LOCAL.CANCEL.FAILURE_MANY',[nItems.toString(),errMsg]);
              Util.Notify.info(title, notifyBody);
            }
            if (curWindow) {
              curWindow.refresh('delete');
            }
          };
          for (let i=0; i<nItems; i++) {
            const item: ListItem = this.list[i];
            Util.RestAPI.delete(item,null,null).subscribe((response) => {
              if (++nResponses===nItems) {
                cancelDone();
              }
            }, error => {
              ++nErrors;
              if (++nResponses===nItems) {
                errMsg = Util.Transforms.formatError(error);
                cancelDone();
              }
            });
          }
        }
        break;
      case 'rm_showfiles':
        data = {'%SHOW_CLOSED_FILES': 'Y'};
        this.putDataToServer(cmd, data);
        break;
      case 'rm_hidefiles':
        data = {'%SHOW_CLOSED_FILES': 'N'};
        this.putDataToServer(cmd, data);
        break;
      case 'fp_box_returnrequest':
      case 'rm_returnrequest':
        data = {'%RETURN_ITEM': '1'};
        this.putDataToServer(cmd, data);
        break;
      case 'fp_box_rm_loanrequest':
      case 'rm_loanrequest':
        const doLoan = () => {
          this.dialogKind = Util.kLocalFormPrefix + ('rm_loanrequest');
          this.dialogExtras = this.localizer.getTranslation('FORMS.BUTTONS.MORE');
          this.dialogExtrasAlt = this.localizer.getTranslation('FORMS.BUTTONS.LESS');
          this.dialogTitle = this.localizer.getTranslation('FORMS.LOCAL.RM_REQUEST.LOAN_ITEM');
          this.dialogOK = this.localizer.getTranslation('FOLDER_ACTIONS.LOAN_OK');
          this.dialogShown = true;
        };
        const anyFilePartAlreadyBorrowed: boolean = this.list.some((item) => (item['PD_STATUSES'] === '1' || item['PD_PART_STATUS'] === 'BORROWED'));
        if (loginReply && !!loginReply.RM_SETTINGS && loginReply.RM_SETTINGS['ReturnOnBorrow'] === 'A' && anyFilePartAlreadyBorrowed) {
          title = this.localizer.getTranslation('FORMS.LOCAL.RM_REQUEST.CONFIRM_LOAN');
          body = this.list.length > 1 ? this.localizer.getTranslation('FORMS.LOCAL.RM_REQUEST.CONFIRM_LOAN_TEXT_MULTIPLE') : this.localizer.getTranslation('FORMS.LOCAL.RM_REQUEST.CONFIRM_LOAN_TEXT');
          const yesBtn: string = this.localizer.getTranslation('FORMS.BUTTONS.YES');
          Util.Notify.confirm(title,body,yesBtn,null,true,true,true).then(confirmed => {
            if (confirmed && confirmed.confirm) {
              doLoan();
            }
          });
        } else {
          doLoan();
        }
        break;
      case 'rm_close':
        data = {'%FILEPART_CLOSE': '1'};
        title = this.localizer.getTranslation('FILEPART_ACTIONS_INLINE.CLOSE_FILEPART');
        body = this.localizer.getTranslation('FILEPART_ACTIONS_INLINE.CLOSED_FILEPART_WARNING');
        Util.Notify.warning(title,body,okBtn,null,true,true,true).then(confirmed => {
          if (confirmed && confirmed.confirm) {
            this.postDataToServer(cmd, data,  notifyTitle);
          }
        });
        break;
      case 'rm_reopen':
        data = {'%FILEPART_REOPEN': '1'};
        title = this.localizer.getTranslation('FILEPART_ACTIONS_INLINE.REOPEN_FILEPART');
        body = this.localizer.getTranslation('FILEPART_ACTIONS_INLINE.REOPEN_FILEPART_WARNING');
        Util.Notify.warning(title,body,okBtn,null,true,true,true).then(confirmed => {
          if (confirmed && confirmed.confirm) {
            this.postDataToServer(cmd, data,  notifyTitle);
          }
        });
        break;
    }
    return true;
  }

  private uploadOutlookMSGItem(outlookMailbox: any, headers: any, files: File[], dataFiles: DataFile[], intoThisFolder: any, addOutlookItemMetadata: boolean): void {
    Util.RestAPI.getOutlookItemProfileData(outlookMailbox, headers, addOutlookItemMetadata, intoThisFolder).then(serverFormData => {
      this.uploadFilesWithUI(files, null, dataFiles, intoThisFolder, serverFormData);
    }, error => {
      this.uploadFilesWithUI(files, null, dataFiles, intoThisFolder, {});
    });
  }

  private flexfolderLevelChanged(sel: SelectComponent): void {
    this.pickerCurFlexfoldersLevel = sel._items.find(item => item.value === sel.value);
    this.filter.setSearchKeyItem(this.pickerCurFlexfoldersLevel);
    this.filter.updateCols();
    this.getLookupInfo();
  }

  private getLookupInfo(title?: string): void {
    const listComponent: ListBaseComponent = Util.RestAPI.getCurListComponent();
    Util.RestAPI.getFormTemplate(this.pickerForm, Util.RestAPI.getPrimaryLibrary()).subscribe((template: any) => {
      const level = this.pickerCurFlexfoldersLevel;
      let lookupKey = level.value;
      let lookupID: string = Util.FieldMappings.lookNameForField(template, lookupKey);
      if (!lookupID) {
        lookupKey = level.value;
        lookupID = Util.FieldMappings.lookNameForField(template, lookupKey);
      }
      if (!!lookupID) {
        this.pickFromLookups(lookupID, lookupKey, null, (list: any[], success: boolean) => {
          if (success && list && list.length) {
            let name = '';
            const ffIDs: any = {};
            ffIDs['nodes'] = [];
            const nItems = list.length;
            for (let i = 0; i < nItems; i++) {
              ffIDs['nodes'].push(list[i]['%PRIMARY_KEY']);
              if (i === 0) {
                const keys: string[] = Object.keys(list[i]);
                for (const key of keys) {
                  if (key.indexOf('_NAME') !== -1) {
                    if (name.length) {
                      name += ' - ' + list[i][key];
                    } else {
                      name = list[i][key];
                    }
                  }
                }
              }
            }
            ffIDs['level'] = level['LEVEL_NO'];
            const notifyTitle: string = nItems === 1 ? this.localizer.getTranslation('FOLDER_ACTIONS.SUBSCRIBED_TO_SINGLE', [name]) : this.localizer.getTranslation('FOLDER_ACTIONS.SUBSCRIBED_TO_MANY', [nItems.toString()]);
            this.postDataToServer('addflexfolder', ffIDs, notifyTitle, null, listComponent, [listComponent.desc]);
          }
        });
      }
    }, err => { });
  }

  private putDataToServer(cmd: string, body: any, notifyTitle?: string, notifyBody?: string, listComponent?: ListBaseComponent, list?: any[]): void {
    this.postOrPutDataToServer(true, cmd, body, notifyTitle, notifyBody, listComponent, list);
  }

  private postDataToServer(cmd: string, body: any, notifyTitle?: string, notifyBody?: string, listComponent?: ListBaseComponent, list?: any[]): void {
    this.postOrPutDataToServer(false, cmd, body, notifyTitle, notifyBody, listComponent, list);
  }

  private postOrPutDataToServer(doPut: boolean, cmd: string, body: any, notifyTitle?: string, notifyBody?: string, listComponent?: ListBaseComponent, list?: any[]): void {
    listComponent = listComponent || Util.RestAPI.getCurListComponent();
    list = list || (this.list ? this.list : (listComponent ? listComponent.getSelections() : Util.RestAPI.getCurList()));
    const loginReply: any = Util.RestAPI.getLoginReply();
    let apiVerb = '';
    const newBody: any = {};
    let sendRaw = false;
    const nBodyItems: number = body ? body.length : 0;
    let nItems: number = list ? list.length : 0;
    let nSubscribedItems = 0;
    switch (cmd) {
      case 'security_save':
        list = [];
        list[0] = new ListItem(this.app.desc);
        nItems = 1;
        apiVerb = 'security';
        break;
      case 'addto':
      case 'whereused_add':
        notifyTitle = nItems === 1 ? (nBodyItems > 1 ? this.localizer.getTranslation('FOLDER_ACTIONS.ADDED_TO_MANY', [nBodyItems.toString()]) : this.localizer.getTranslation('FOLDER_ACTIONS.ADD_TO_SINGLE')) : (nBodyItems === 1 ? this.localizer.getTranslation('FOLDER_ACTIONS.ADDED_TO_SINGLE', [body[0]['docname']]) : this.localizer.getTranslation('FOLDER_ACTIONS.ADD_TO_MANY', [nItems.toString()]));
        if (nBodyItems < 2 && nItems < 2) {
          notifyBody = this.localizer.getTranslation('FOLDER_ACTIONS.ADDTOSUCCESS', [list[0]['DOCNAME'], body[0]['docname']]);
        } else {
          notifyBody = this.localizer.getTranslation('FOLDER_ACTIONS.ADDTOSUCCESSMANY');
        }
        // fall thru intentional
      case 'addquicksearch':
      case 'adddocuments':
      case 'additems':
        apiVerb = 'references';
        break;
      case 'related_add':
        apiVerb = 'associations';
        list = Util.RestAPI.getCurItem() ? [Util.RestAPI.getCurItem()] : [];
        nItems = list.length;
        notifyTitle = this.localizer.getTranslation('FOLDER_ACTIONS.RELATIONS');
        notifyBody = this.localizer.getTranslation('HISTORY_ACTIONS.37');
        break;
      case 'addfilepart':
        apiVerb = 'references';
        break;
      case 'newversionfromfile':
        apiVerb = '/';
        cmd = 'newversion';
        sendRaw = true;
        break;
      case 'unlock':
        notifyTitle = this.localizer.getTranslation('FORMS.LOCAL.REMOVE.REMOVE_CHECKOUT_SUCCESS');
        apiVerb = 'profile';
        break;
      case 'checkin':
        notifyTitle  = nItems===1 ? this.localizer.getTranslation('FORMS.LOCAL.CHECK_IN.SUCCESS_SINGLE',[this.list[0].DOCNAME]) : this.localizer.getTranslation('FORMS.LOCAL.CHECK_IN.SUCCESS_MANY',[nItems.toString()]);
        apiVerb = 'profile';
        break;
      case 'checkout':
        notifyTitle  = nItems===1 ? this.localizer.getTranslation('FORMS.LOCAL.CHECK_OUT.SUCCESS_SINGLE',[this.list[0].DOCNAME]) : this.localizer.getTranslation('FORMS.LOCAL.CHECK_OUT.SUCCESS_MANY',[nItems.toString()]);
        apiVerb = 'profile';
        break;
      case 'publish':
      case 'unpublish':
      case 'readonly':
      case 'removereadonly':
      case 'declarerecord':
      case 'undeclarerecord':
        apiVerb = 'profile';
        break;
      case 'rm_supersede':
        apiVerb = 'records';
        doPut = true;
        newBody['%SUPERSEDE'] = '1';
        newBody['%SUPERSEDE_NEW_ITEM'] = body[0]['id'];
        body = Util.deepCopy(newBody);
        break;
      case 'rm_putinbox':
        apiVerb = 'records';
        doPut = true;
        body = Util.deepCopy(newBody);
        break;
      case 'rm_removefrombox':
        apiVerb = 'records';
        doPut = true;
        body = Util.deepCopy(newBody);
        break;
      case 'rm_changevitalstatus':
      case 'rm_suspend':
      case 'fp_rm_suspend':
      case 'rm_release':
      case 'fp_rm_release':
      case 'rm_disposalinstructions':
      case 'rm_printlabel':
        apiVerb = 'records';
        break;
      case 'rm_close':
      case 'rm_reopen':
        apiVerb = 'closeorreopen';
        break;
      case 'rm_changefilepart':
      case 'rm_assignfilepart':
        apiVerb = 'records';
        doPut = true;
        newBody['%CHANGE_FILE_PART'] = '1';
        newBody['PD_FILEPT_NO'] = body[0]['filepartno'];
        body = JSON.parse(JSON.stringify(newBody));
        break;
      case 'fp_box_returnrequest':
      case 'rm_returnrequest':
        apiVerb = 'requests';
        doPut = true;
        break;
      case 'subscribe':
        notifyTitle  = nItems===1 ? this.localizer.getTranslation('FOLDER_ACTIONS.SUBSCRIBED_TO_SINGLE',[this.list[0].DOCNAME]) : this.localizer.getTranslation('FOLDER_ACTIONS.SUBSCRIBED_TO_MANY',[nItems.toString()]);
        apiVerb = 'subscriptions';
        break;
      case 'addflexfolder':
        apiVerb = 'subscriptions';
        break;
      case 'unsubscribe':
        notifyTitle = nItems===1 ? this.localizer.getTranslation('FOLDER_ACTIONS.UNSUBSCRIBED_FROM_SINGLE',[this.list[0].DOCNAME]) : this.localizer.getTranslation('FOLDER_ACTIONS.USUBSCRIBED_FROM_MANY',[nItems.toString()]);
        apiVerb = 'unsubscribe';
        if (listComponent) {
          const fullList: ListItem[] = listComponent.getList();
          const nFullList: number = fullList ? fullList.length : 0;
          for (let i=0; i<nFullList; i++) {
            if (fullList[i]['SUBSCRIBED_INDICATOR']==='S') {
              ++nSubscribedItems;
            }
          }
        }
        break;
      case 'rm_showfiles':
      case 'rm_hidefiles':
        list = [];
        list[0] = new ListItem(this.app.desc);
        nItems = 1;
        apiVerb = 'root/showclosedfiles';
        break;
      case 'newversion':
        apiVerb = 'versions/';
        break;
      case 'newsub':
        if (body._restapi['src']) {
          apiVerb = 'versions/' + body._restapi['src']['ver'];
        }
        break;
      case 'stopshareonedrive':
        apiVerb = 'stopsharing';
        break;
    }
    if (apiVerb) {
      let observable: Observable<HttpResponse<any>>;
      if (list && nItems) {
        let nResponses = 0;
        for (let i = 0; i < nItems; i++) {
          const item = list[i];
          if (cmd.indexOf('addto') < 0 || !this.itemInList(item, body)) {
            if (cmd === 'addto' && item.id.startsWith('FP-FilePart')) {
              item.id = item['DOCNUM'];
            }
            if (cmd === 'unlock' && item.checkout && item.checkout['LOCATION'] && item.checkout['LOCATION'].toUpperCase().startsWith('HTTP')) {
              body['LOCATION'] = item.checkout['LOCATION'];
            }
            if (doPut) {
              observable = Util.RestAPI.put(item, body, apiVerb, null, { observe: 'response' });
            } else {
              if (body?.[0]?.type === 'fileplans') {
                body = body[0];
              }
              observable = Util.RestAPI.post(item, body, apiVerb, null, { observe: 'response', sendRaw });
            }
            observable.subscribe((response) => {
              // try and pseudo update the items before the refresh to make the UI more responsive
              switch (cmd) {
                case 'unpublish':
                case 'checkin':
                case 'unlock': item['STATUS'] = '0'; break;
                case 'checkout': item['STATUS'] = '3'; break;
                case 'publish': item['STATUS'] = '20'; break;
                case 'readonly': item['READONLY'] = 'Y'; break;
                case 'removereadonly': item['READONLY'] = 'N'; break;
                case 'declarerecord': item['RECORD'] = 'Y'; break;
                case 'undeclarerecord': item['RECORD'] = 'N'; break;
                case 'rm_returnrequest':
                case 'fp_box_returnrequest':
                  item['PD_STATUSES'] = '0';
                  if (notifyTitle === undefined) {
                    notifyTitle = this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.RETURNED_TITLE');
                    notifyBody = '';
                  }
                  notifyBody += this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.RETURNED',[item.DOCNUM]) + '\n';
                  break;
                case 'fp_rm_suspend':
                case 'rm_suspend':
                  item['PD_SUSPEND'] = 'Y';
                  if (notifyTitle === undefined) {
                    notifyTitle = this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.SUSPENDED_TITLE');
                    notifyBody = '';
                  }
                  notifyBody += this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.SUSPENDED',[item.DOCNUM,item.PD_FILEPT_NO]) + '\n';
                  break;
                case 'rm_close':
                  item['PD_ACTIVE_STATUS'] = 'C';
                    if (notifyTitle === null) {
                      notifyTitle = this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.CLOSED_TITLE');
                      notifyBody = '';
                    }
                    notifyBody += this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.CLOSED',[item.DOCNUM]) + '\n';
                  break;
                case 'rm_reopen':
                  item['PD_ACTIVE_STATUS'] = 'R';
                  if (notifyTitle === null) {
                    notifyTitle = this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.REOPENED_TITLE');
                    notifyBody = '';
                  }
                  notifyBody += this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.REOPENED',[item.DOCNUM]) + '\n';
                  break;
                case 'fp_rm_release':
                case 'rm_release':
                  item['PD_SUSPEND'] = 'N';
                  if (notifyTitle === undefined) {
                    notifyTitle = this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.RELEASED_TITLE');
                    notifyBody = '';
                  }
                  notifyBody += this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.RELEASED',[item.DOCNUM,item.PD_FILEPT_NO]) + '\n';
                  break;
                case 'rm_changevitalstatus':
                  let vstatus = 0;
                  let data: any = null;
                  if (!!response.body.data) {
                    data = response.body.data;
                  }
                  if (!!data) {
                    vstatus = data['%CHANGE_VITAL_STATUS_NEWSTATUS'];
                  }
                  if (notifyTitle === undefined) {
                    notifyTitle = '';
                    notifyBody = '';
                  }
                  if (vstatus && vstatus === 1) {
                    item['PD_VITAL'] = 'Y';
                    notifyBody += this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.STATUS_VITAL',[item.DOCNUM]) + '\n';
                  } else {
                    item['PD_VITAL'] = 'N';
                    notifyBody += this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.STATUS_NOT_VITAL',[item.DOCNUM]) + '\n';
                  }
                  break;
                case 'rm_showfiles':
                  if (loginReply && !!loginReply.RM_SETTINGS) {
                    loginReply.RM_SETTINGS['ShowClosedFiles'] = 'Y';
                  }
                  break;
                case 'rm_hidefiles':
                  if (loginReply && !!loginReply.RM_SETTINGS) {
                    loginReply.RM_SETTINGS['ShowClosedFiles'] = 'N';
                  }
                  break;
                case 'additems':
                case 'addfilepart':
                case 'addto':
                case 'whereused_add':
                  if ((cmd === 'addfilepart' || cmd === 'addto' || cmd === 'whereused_add') && !!body && body.length>0 && !Util.Transforms.isFavoriteFolder(body[0]['id'])) {
                    break;
                  }
                  if (!!response.body.data && !!response.body.data.list && response.body.data.list.length > 0) {
                    const addedItem = response.body.data['list'][0];
                    const currSelectedItem = list?.find(x => x['id'] === addedItem['DOCNUMBER']);
                    this.favoriteService.handleAddToFavoriteResponse(addedItem, currSelectedItem);
                  }
                  break;
              }
              if (++nResponses === nItems) {
                if (cmd === 'additems' || ((cmd === 'addfilepart' || cmd === 'addto') && !!body && body.length>0 && Util.Transforms.isFavoriteFolder(body[0]['id']))) {
                  Util.RestAPI.refreshTile(this.favoriteService.getDescForFavorite());
                }
                Util.RestAPI.refreshLists(list[nItems-1]);
                if (listComponent) {
                  if (apiVerb === 'unsubscribe' && listComponent.desc['SUBSCRIBED_INDICATOR'] === 'P' && nSubscribedItems === nItems) {
                    Util.RestAPI.post(listComponent.desc, null, 'unsubscribe').subscribe(response2 => {}, error2 => {});
                  }
                  if (!Util.RestAPI.isItemInListsToRefresh(listComponent)) {
                    listComponent.reloadList();
                  }
                }
                if (notifyTitle || notifyBody) {
                  Util.Notify.success(notifyTitle, notifyBody);
                }
                if (apiVerb === 'profile' || apiVerb === 'records' || apiVerb === 'requests' || apiVerb === 'associations') {
                  const curWindow: WindowModalComponent = Util.RestAPI.getCurWindow();
                  if (curWindow && !Util.RestAPI.isItemInListsToRefresh(curWindow)) {
                    curWindow.refresh(this.currentCommand);
                  }
                }
              }
              if (response.status===206) {
                Util.Notify.error(notifyTitle, response);
              }
            }, error => {
              if (++nResponses === nItems && listComponent) {
                if (cmd==='security_save' && listComponent.desc && listComponent.desc['edx_selected_security_choice']) {
                  // This will force-reload of security trustees
                  listComponent.desc['edx_selected_security_choice']='';
                }
                listComponent.reloadList();
              }
              if (!!error && !!error.error && !!error.error.ERROR && !!error.error.ERROR.rapi_code && error.error.ERROR.rapi_code === 17) {
                Util.Notify.warning(this.localizer.getTranslation('GENERIC_ERRORS.SUBSCRIBE_WARNING'), this.localizer.getTranslation('GENERIC_ERRORS.FLEXFOLDER_INFO_NOT_FOUND', [item['DOCNAME']]));
              } else if (!!error && !!error.error && !!error.error.ERROR && !!error.error.ERROR.rapi_code && error.error.ERROR.rapi_code === 24) {
                const rapi_code = error.error.ERROR.rapi_code;
                error = this.localizer.getTranslation('RAPI_ERRORS.' + rapi_code) + ' ' + this.localizer.getTranslation('FOLDER_ACTIONS.ADDTO_FAILED',[list[0]['DOCNAME'], body['name']]);
                Util.Notify.error(notifyTitle, error);
              } else {
                Util.Notify.error(notifyTitle, error);
              }
            });
          } else {
            ++nResponses;
          }
        }
      } else if (cmd === 'addquicksearch' && listComponent) {
        nItems = body ? body.length : 0;
        //For searches post, the data need to be an array, hence the workspace is added to array
        const desc: any = [listComponent.desc];
        let nResponses = 0;
        for (let i = 0; i < nItems; i++) {
          const item = body[i];
          if (doPut) {
            observable = Util.RestAPI.put(item, desc, apiVerb, null, { observe: 'response', sendRaw });
          } else {
            observable = Util.RestAPI.post(item, desc, apiVerb, null, { observe: 'response', sendRaw });
          }
          observable.subscribe(response => {
            if (!!response.body.data && !!response.body.data.list && response.body.data.list.length > 0 && Util.Transforms.isFavoriteFolder(listComponent.desc['id'])) {
              this.favoriteService.handleAddToFavoriteResponse(response.body.data['list'][0], null);
            }
            if (++nResponses === nItems && listComponent) {
              if (Util.Transforms.isFavoriteFolder(listComponent.desc['id'])) {
                Util.RestAPI.refreshTile(this.favoriteService.getDescForFavorite());
              }
              listComponent.reloadList();
              if (notifyTitle) {
                Util.Notify.success(notifyTitle, notifyBody);
              }
            }
          }, error => {
            const title = this.localizer.getTranslation(item['docname']);
            Util.Notify.error(title, error);
            if (++nResponses === nItems && listComponent) {
              listComponent.reloadList();
            }
          });
        }
      } else if (cmd === 'whereused_add') {
        Util.RestAPI.post(this.app.desc, body, apiVerb, null, { sendRaw }).subscribe(response => {
         if (notifyTitle) {
            Util.Notify.success(notifyTitle, notifyBody);
          }
           listComponent.reloadList();
        }, error => {
           listComponent.reloadList();
        });
      }
    }
  }

  private itemInList(item1: any, list: any[]): boolean {
    if (list && item1) {
      for (const item2 of list) {
        if (this.isSameListItem(item1, item2)) {
          return true;
        }
      }
    }
    return false;
  }

  private isSameListItem(item1: any, item2: any): boolean {
    return (item1 && item2 && item1.type && item1.type === item2.type && item1.id && item1.id === item2.id && item1.lib === item2.lib);
  }

  private updateCurrPendingUpload(pendingUpload) {
    this.curPendingUpload = pendingUpload;
  }

  private uploadNextFiles(pendingUpload: _FileUploadData): void {
    const title: string = this.localizer.getTranslation('FOLDER_ACTIONS.CREATE_PROFILE');
    const nFiles: number = pendingUpload.files ? pendingUpload.files.length : pendingUpload.filePaths ? pendingUpload.filePaths.length : pendingUpload.dataFiles ? pendingUpload.dataFiles.length : 0;
    const previousKind = this.dialogKind;
    this.reset();
    this.updateCurrPendingUpload(pendingUpload);
    this.fileList = pendingUpload.files;
    this.filePaths = pendingUpload.filePaths;
    this.dataFiles = pendingUpload.dataFiles;
    this.desc = pendingUpload.desc;
    this.dialogCreateType = 'documents';
    this.dialogFormData = pendingUpload.formData[0];
    if (!Util.RestAPI.isFolderFilesProfile()) {
      this.dialogFormData['FORMNAME'] = pendingUpload.form ?? pendingUpload.fileFormInfo[0].defForm;
    } else {
      this.dialogFormData['FORMNAME'] = pendingUpload.fileFormInfo[0].defForm ?? pendingUpload.form;
    }
    this.dialogTrustees = pendingUpload.trustees;
    this.dialogFileFormInfo = pendingUpload.fileFormInfo[0];
    const contentFileList = this.uploadService.getFilesToUpload(false);
    if (contentFileList && !Util.RestAPI.isFolderFilesProfile()) {
      if (!(this.dialogApplyAllChecked && contentFileList?.length === 1)) {
        this.dialogApplyAllChecked = this.getApplyToAllChecked(contentFileList?.length);
      }
    } else {
      this.dialogApplyAllChecked = this.getApplyToAllChecked(nFiles);
    }
    this.dialogExtras = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_ALL');
    this.dialogExtrasAlt = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_REQUIRED_ONLY');
    this.dialogThirdBtn = Util.RestAPI.canAutoProfile() ? this.localizer.getTranslation('FORMS.BUTTONS.AUTO_FILL') : null;
    if (!!this.dataFiles && !!this.dataFiles[0].externalName) {
      this.dialogKind = 'profile_savetoedocs';
      if (this.dataFiles[0].name === this.dataFiles[0].externalName) {
        // File names are same so this is a simple save-to-edocs command
        this.dialogTitle = this.localizer.getTranslation('FORMS.LOCAL.SAVETOEDOCS.TITLE');
        this.dialogOK = this.localizer.getTranslation('FORMS.LOCAL.SAVETOEDOCS.SAVE');
      } else {
        // File names are not same so this is a checkin command
        this.dialogTitle = title;
        this.dialogOK = this.localizer.getTranslation('FORMS.BUTTONS.CREATE');
      }
    } else {
      this.dialogKind = (previousKind === Util.kLocalFormPrefix + 'checkin' || previousKind === 'profile_checkin') ? 'profile_checkin' : 'profile';
      this.dialogTitle = title;
      this.dialogOK = this.localizer.getTranslation('FORMS.BUTTONS.CREATE');
    }
    if (previousKind === 'profile_uploadfolders' || previousKind === 'profile_folderfiles') {
      this.dialogKind = 'profile_folderfiles';
    }

    if (this.dialogApplyAllChecked && !this.uploadService.isInitialForm()) {
      this.uploadService.getNewRequiredFields(pendingUpload.form, pendingUpload.desc).then(fields => {
        //Checking if there are more required fields in the new form and if the new required fields data is already available with us will process the files without showing the profile form. If the profile form have more required fields will show the profile form.
        if (fields.length > 0 && this.uploadService.requiredFieldsSatisfied(fields)) {
          let serverData = this.uploadService.getServerData();
          if (!serverData) {
            serverData = {};
          }
          serverData['DOCNAME'] = this.uploadService.fileNameForUpload(this.dialogApplyAllChecked, 'profile', this.fileList, this.filePaths, null, null, false);
          serverData['APP_ID'] = pendingUpload.fileFormInfo.map(file => file.appID).join(',; ');
          if (!serverData._restapi) {
            serverData['_restapi'] = {};
          }
          serverData._restapi['form_name'] = pendingUpload.form;
          this.uploadService.setServerData(serverData);
          if (Util.Device.bIsOfficeAddinOutlook) {
            serverData = this.uploadService.updateAttachNum(serverData, this.fileList, true);
            Util.RestAPI.uploadDataFiles(null, serverData, this.fileList, false, null, this.desc.lib);
          } else {
            if (!Util.RestAPI.isFolderFilesProfile()) {
              Util.RestAPI.uploadFilesWithBrowser('documents', serverData, this.fileList);
            } else {
              Util.RestAPI.uploadFolderPendingFiles(serverData, this.fileList, this.dialogApplyAllChecked);
            }
          }
        } else {
          this.dialogShown = true;
        }
      });
    } else {
      this.dialogShown = true;
    }
  }

  public onApplyAllChanged(checked: boolean): void {
    this.dialogApplyAllChecked = checked;
      this.fileList = this.uploadService.getFilesToUpload(this.dialogApplyAllChecked);
  }

  public extrasShownByChild(show: boolean): void {
    this.dialogExtrasShown = show;
  }

  public pccExtrasShownByChild(show: boolean): void {
    this.pccDialogExtrasShown = show;
  }

  public createFromCmd(cmd: string, desc: any, onPicker?: boolean,folderUpload?: boolean): Promise<FormResult> {
    return new Promise<any>((resolve, reject) => {
      const appID = cmd==='newpaperdocument' ? 'Paper' : cmd==='newworkspace' ? 'WORKSPACE' : 'FOLDER';
      const createIt = (formName: string) => {
        const showDialog = (data: any, trustees: any[], ffi: FileFormInfo) => {
          let createType: string;
          onPicker = onPicker && this.pickerShown;
          if (onPicker) {
            this.resetPickerCreateContainer();
          } else {
            this.reset();
          }
          if (!data) {
            data = {};
          } else if (data['DOCNAME']) {
            delete data['DOCNAME'];
          }
          if (cmd==='newpaperdocument') {
            data['STORAGE'] = 'P';
            createType = 'documents';
          } else {
            data['APP_ID'] = appID;
            if (folderUpload && !!Util.RestAPI.getFolderUploadFolders()) {
              data['DOCNAME'] = Util.RestAPI.getDocNameForUploadFolder();
            }
            createType = cmd.substr(3) + 's';
          }
          if (onPicker) {
            this.pccDialogCreateType = createType;
            this.pccDesc = desc;
            this.pccDialogFormData = data;
            this.pccDialogTrustees = trustees;
            this.pccDialogFileFormInfo = ffi;
            this.pccDialogFormData['FORMNAME'] = formName;
            this.pccDialogTitle = this.localizer.getTranslation('FOLDER_ACTIONS.CREATE_PROFILE');
            this.pccDialogOK = this.localizer.getTranslation('FORMS.BUTTONS.CREATE');
            this.pccDialogExtras = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_ALL');
            this.pccDialogExtrasAlt = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_REQUIRED_ONLY');
            this.pickerCreateContainerShown = true;
          } else {
            this.dialogCreateType = createType;
            this.dialogKind = folderUpload ? 'profile_uploadfolders' : 'profile';
            this.desc = desc;
            this.dialogFormData = data;
            this.dialogTrustees = trustees;
            this.dialogFileFormInfo = ffi;
            this.dialogFormData['FORMNAME'] = formName;
            this.dialogTitle = this.localizer.getTranslation('FOLDER_ACTIONS.CREATE_PROFILE');
            this.dialogOK = this.localizer.getTranslation('FORMS.BUTTONS.CREATE');
            this.dialogExtras = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_ALL');
            this.dialogExtrasAlt = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_REQUIRED_ONLY');
            this.dialogShown = true;
          }
          const checkDone = () => {
            let isDone: boolean;
            let success: boolean;
            if (this.pickerShown) {
              isDone = !this.pickerCreateContainerShown;
              success = this.pccDialogSuccess;
              data = this.pccDialogFormData;
            } else {
              isDone = !this.dialogShown;
              success = this.dialogSuccess;
              data = this.dialogFormData;
            }
            if (isDone) {
              resolve({success, data});
            } else {
              setTimeout(checkDone, 1000);
            }
          };
          checkDone();
        };
        if (cmd==='newworkspace') {
          showDialog({AUTHOR_ID: Util.RestAPI.getLoginReply().USER_ID, AUTHOR_NAME: Util.RestAPI.getLoginReply().FULL_NAME}, null, null);
        } else {
          const profiledDesc = (cmd==='newfolder' && desc && desc.id==='public') ? null : (cmd==='newfolder' || cmd==='newpaperdocument') ? desc : desc.lib;
          Util.RestAPI.getFormInfoForAppID(appID, desc, formName).then((ffi: FileFormInfo) => {
            Util.RestAPI.getProfileDefaultsAndTrustees(formName, appID, profiledDesc).then((data: any) => {
              showDialog(data.formData, data.trustees, ffi);
            });
          });
        }
      };
      if (cmd==='newworkspace') {
        createIt(Util.RestAPI.getDefaultWorkspaceForm().id);
      } else {
        const forms = Util.RestAPI.getProfileFormsForApp(appID);
        let firstFormName: string = forms[0].id;
        this.settingsService.get('preferences/formdefaults').then(data => {
          const appIDtoCheck = appID==='Paper' ? '%FORM_PAPER_APPLICATION' : appID;
          if (!!data && !!data[desc.lib.toUpperCase()] && !!data[desc.lib.toUpperCase()][appIDtoCheck]) {
            firstFormName = data[desc.lib.toUpperCase()][appIDtoCheck];
          }
          createIt(firstFormName);
        }, err => {
          createIt(firstFormName);
        });
      }
    });
  }

  // **** PopupCallback implementation

  public dismissPopup(cancelled: boolean): void {
    if (this.pickerGroupsCreateContainerShown) {
      this.resetGroupPickerCreateContainer();
      this.pickerGroupsCreateContainerShown = false;
    } else if (this.pickerCreateContainerShown) {
      this.pickerCreateContainerShown = false;
      this.pccDialogExtras = null;
      this.pccDialogExtrasAlt = null;
      this.pccDialogSuccess = !cancelled;
    } else if (this.pickerShown) {
      if (Util.isSharedDownloads(this.pickerDesc) && this.pickerDestListComponent) {
        Util.RestAPI.setCurListComponent(this.pickerDestListComponent);
      }
      this.pickerDestListComponent = null;
      this.pickerShown = false;
      this.pickerForceOK = false;
      if (!this.dialogShown) {
        this.list = null;
        this.currentCommand = '';
      }
    } else if (this.dialogShown) {
      this.dialogShown = false;
      this.dialogExtras = null;
      this.dialogExtrasAlt = null;
      this.dialogThirdBtn = null;
      this.list = null;
      this.currentCommand = '';
      this.fileList = null;
      this.dialogSuccess = !cancelled;
    }
  }

  popupThirdBtn(): void {
    if (this.dialogShown && !!this.formWrapper && !!this.formWrapper.popupThirdBtn) {
      this.formWrapper.popupThirdBtn();
    }
  }

  popupCancel(): void {
    if (this.pickerGroupsCreateContainerShown) {
      if (!!this.pickerGroupsCB) {
        this.pickerGroupsCB(null, false);
        this.pickerGroupsCB = null;
      }
    } else if (this.pickerCreateContainerShown) {
      if (!!this.pccFormWrapper) {
        this.pccFormWrapper.popupCancel();
      }
    } else if (this.pickerShown) {
      if (this.pickerCB) {
        this.pickerCB(null, false);
        this.pickerCB = null;
      }
    } else if (this.dialogShown) {
      if (this.formWrapper) {
        this.formWrapper.popupCancel();
      }
    }
    if (this.dialogKind === 'profile_uploadfolders' || this.dialogKind === 'profile_folderfiles') {
      Util.RestAPI.showFolderUploadSpinner(false);
      Util.RestAPI.setFolderDropDone(false);
    }
    this.dismissPopup(true);
  }

  popupOK(): void {
    let pickerList: ListItem[];
    if (this.dialogKind === 'profile_uploadfolders') {
      Util.RestAPI.showFolderUploadSpinner(true);
    }
    if (this.pickerGroupsCreateContainerShown) {
      pickerList = this.pickerGroups.getSelections();
      if (this.pickerGroupsCB) {
        this.pickerGroupsCB(pickerList, true);
        this.pickerGroupsCB = null;
      }
    } else if (this.pickerCreateContainerShown) {
      if (!!this.pccFormWrapper) {
        this.pccFormWrapper.popupOK();
      }
    } else if (this.pickerShown) {
      pickerList = this.picker.getSelections();
      if (this.pickerCB) {
        this.pickerCB(pickerList, true);
        this.pickerCB = null;
      } else {
        if (pickerList.length) {
          const pickedFolders: any = [];
          for (const pickedItem of pickerList) {
            if (this.currentCommand === 'addfilepart') {
              pickedFolders.push({
                id: pickedItem['DOCNUM'],
                type: pickedItem.type,
                lib: pickedItem.lib,
                docname: pickedItem['DOCNAME'],
                SYSTEM_ID: pickedItem['PROFILE.SYSTEM_ID']
              });
            } else if (this.currentCommand === 'rm_assignfilepart' || this.currentCommand === 'rm_changefilepart') {
              pickedFolders.push({
                id: pickedItem.id,
                type: pickedItem.type,
                lib: pickedItem.lib,
                filepartno: pickedItem['PD_FILEPT_NO'],
                docname: pickedItem['DOCNAME']
              });
            } else {
              pickedFolders.push({
                id: pickedItem.id,
                type: pickedItem.type,
                lib: pickedItem.lib,
                docname: pickedItem['DOCNAME'],
                SYSTEM_ID: pickedItem['SYSTEM_ID']
              });
            }
          }
          if (this.currentCommand === 'adddocuments' || this.currentCommand === 'addfilepart' || this.currentCommand === 'additems') {
            const listComponent: ListBaseComponent = Util.RestAPI.getCurListComponent();
            if (listComponent) {
              const serverData: any = { _restapi: {} };
              let title = 'FOLDER_ACTIONS.';
              const name = listComponent.desc['DOCNAME'];
              if (this.currentCommand === 'adddocuments' || this.currentCommand === 'additems') {
                title += 'ADDED_TO_SINGLE';
                serverData._restapi['ref'] = listComponent.desc;
              } else if (this.currentCommand === 'addfilepart') {
                title += 'ADD_FILEPART';
                serverData._restapi['ref'] = listComponent.desc;
              }
              this.postDataToServer(this.currentCommand, serverData, this.localizer.getTranslation(title, [name]), null, listComponent, pickedFolders);
            }
          } else {
            this.postDataToServer(this.currentCommand, pickedFolders);
          }
        }
      }
    } else if (this.dialogShown) {
      if (this.formWrapper && this.currentCommand !== 'confirm_delete') {
        this.formWrapper.popupOK();
      } else {
        this.doCommand('delete_multiref');
      }
    }
    if (this.currentCommand !== 'footer_options' && this.dialogKind !== '__local_footer_options') {
      this.dismissPopup(false);
    }
  }

  async getMultiReferenceDocs(items: ListItem[]): Promise<ListItem[]> {
    const referenceList: ListItem[] = [];
    for (const item of items) {
      const extAppInfo: any = Util.RestAPI.findExternalApp(item.lib.split(',')[0]);
      const itemType = item.type;
      if ((['documents', 'folders'].indexOf(itemType) >= 0) && !extAppInfo) {
        await Util.RestAPI.get(item, 'references', undefined).toPromise().then((listData: ListData) => {
          if (!!listData && !!listData.list) {
            listData.list = listData.list.filter(x => !Util.Transforms.isFavoriteFolder(x['type']));
            if (listData.list.length > 1) {
              referenceList.push(item);
            }
          }
        });
      }
    }
    return referenceList;
  }

  public doDelete(cmd?: string,radioVal?: string,list?: ListItem[], listComponent?: ListBaseComponent, title?: string): void {
    const nItems = list.length;
    let nResponses = 0;
    let nErrors = 0;
    let docName: string = list[0].DOCNAME;
    const firstItem: ListItem = list[0];
    if (firstItem['VERSION_LABEL']) {
      docName = this.localizer.getTranslation(cmd === 'attachments_delete' ? 'COLUMN_HEADINGS.ATTACHMENTS.ATTACHMENT_NO' : 'COLUMN_HEADINGS.VERSIONS.VERSION_NO',[list[0]['VERSION_LABEL']]);
    }
    let errMsg = '';
    let notifyBody: string;
    let query = 'delrefs';
    let successDocName = '';
    let failDocName = '';
    const curWindow: WindowModalComponent = Util.RestAPI.getCurWindow();
    Util.RestAPI.setPreference('edx_delete_type', radioVal);
    if (radioVal === 'DQ') {
      query = 'queue';
      title = this.localizer.getTranslation('HISTORY_ACTIONS.35');
    } else if (radioVal === 'DO') {
      query += '&keepprof';
    }
    const deleteDone = () => {
      if (!!listComponent) {
        if (!!listComponent.desc && (listComponent.desc.type === 'searches' && firstItem.type === 'searches' && !isNaN(parseInt(listComponent.desc.id)) || (!firstItem['VERSION'] && !firstItem['VERSION_ID'] && listComponent.desc.type === firstItem.type && listComponent.desc.lib === firstItem.lib && listComponent.desc.id === firstItem.id))) {
          Util.RestAPI.navBack();
          setTimeout(() => {
            Util.RestAPI.refreshLists();
          });
        } else {
          Util.RestAPI.refreshLists(firstItem);
        }
      }
      if (nErrors === 0) {
        notifyBody = nItems === 1 ? this.localizer.getTranslation('FORMS.LOCAL.DELETE.SUCCESS_SINGLE', [docName]) : this.localizer.getTranslation('FORMS.LOCAL.DELETE.SUCCESS_MANY', [nItems.toString()]);
        Util.Notify.success(title, notifyBody);
      } else {
        if (nErrors === nItems) {
          notifyBody = nItems === 1 ? this.localizer.getTranslation('FORMS.LOCAL.DELETE.FAILURE_SINGLE', [docName, errMsg]) : this.localizer.getTranslation('FORMS.LOCAL.DELETE.FAILURE_MANY', [nItems.toString(), errMsg]);
        } else {
          const nSuccess = nItems - nErrors;
          notifyBody = nSuccess === 1 ? this.localizer.getTranslation('FORMS.LOCAL.DELETE.SUCCESS_SINGLE', [successDocName]) : this.localizer.getTranslation('FORMS.LOCAL.DELETE.SUCCESS_MANY', [nSuccess.toString()]);
          notifyBody += '.\n' + (nErrors === 1 ? this.localizer.getTranslation('FORMS.LOCAL.DELETE.FAILURE_SINGLE', [failDocName, errMsg]) : this.localizer.getTranslation('FORMS.LOCAL.DELETE.FAILURE_MANY', [nErrors.toString(), errMsg]));
        }
        Util.Notify.info(title, notifyBody);
      }
      if (curWindow) {
        curWindow.refresh('delete');
      }
    };
    let params: string = null;
    for (let i = 0; i < nItems; i++) {
      const item: ListItem = list[i];
      if (item['VERSION_ID']) {
        params = (cmd === 'attachments_delete' ? 'attachments/' : 'versions/') + item['VERSION_ID'];
      }
      if (item.type === 'workspaces' && radioVal !== 'DQ') {
        query = null;
      }
      Util.RestAPI.delete(item, params, query).subscribe((response) => {
        //Delete the container's tile when a container is added as a tile and the container is deleted.
        if (item && Util.isContainerWithId(item)) {
          const tileToDelete = this.tileService.getTile(item);
          if (tileToDelete) {
            this.tileService.hideTile(tileToDelete);
          }
          this.recentLocationService.updateRecentLocationInLocalStorage(item, true);
        }
        if (item['is_favorite'] && (cmd === 'delete' || cmd === 'delete_multiref') && radioVal !== 'DQ') {
          this.favoriteService.addOrRemoveFavoriteIdInLocalStorage(item, false);
        }
        successDocName = nResponses === nErrors ? item.DOCNAME : '';
        if (++nResponses === nItems) {
          deleteDone();
        }
      }, error => {
        ++nErrors;
        failDocName = nErrors === 1 ? item.DOCNAME : '';
        if (++nResponses === nItems) {
          errMsg = Util.Transforms.formatError(error);
          deleteDone();
        }
      });
    }
  }

  extrasToggled(shown: boolean): void {
    if (!this.pickerShown && this.formWrapper) {
      this.formWrapper.extrasToggled(shown);
    } else if (this.pickerCreateContainerShown && this.pccFormWrapper) {
      this.pccFormWrapper.extrasToggled(shown);
    }
  }
}
