import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { NavItem } from './models/nav-item';
import { MenuItem, MenuItemDesc } from './models/menu-item';
import { MenuService, MenuId, MenuDef } from './services/menu.service';
import { NavService } from './services/nav.service';
import { LocalizeService } from './services/localize.service';
import { Util, UserInterface } from './utils/utils.module';
import { ShortcutsService, KeyCommand } from './services/shortcuts.service';

@Component({
  selector: 'edx-app-nav-sidebar',
  styleUrls: ['app-nav-sidebar.component.scss'],
  template: `
  <div *ngIf="expanded || isShowing || isHiding" class="container-mask" (click)="dismiss($event)">
  </div>
  <div class="container" id="edx_hamburger_menu" [attr.aria-expanded]="expanded" [ngClass]="{mobile:ui>=2, oai:isOfficeAddin, edgeie:isEdgeIE, expanded:expanded, showing:isShowing, hiding:isHiding}" (animationend)="sidebarAnimationComplete()">
    <div *ngIf="ui>=2" class="welcome">
      <div class="name">{{welcome}}</div>
    </div>
    <ul role="menu">
      <ng-template [ngIf]="!!startItem">
        <li role="none">
          <div class="startitem" title="{{startItem.name | localize}}" [tabindex]="expanded?1:-1" role="menuitem" (click)="navItemClicked(startItem)" (keydown)="onKeyDown($event, startItem)" id="{{getNavItemDisplayId(startItem, 0)}}">
            <img class="icon" src="{{getNavIconSrc(startItem)}}" alt="{{getNavIconText(startItem)}}">
            <div class="name">{{startItem.name}}</div>
          </div>
          <div class="separator"></div>
        </li>
      </ng-template>
      <ng-template [ngIf]="menuItems && menuItems.length">
        <li *ngFor="let item of menuItems; let i=index" role="none">
          <div *ngIf="!item.separator" class="item" [tabindex]="expanded?1:-1" role="menuitem" title="{{item.name | localize}}" (click)=menuItemClicked(item) (keydown)="onKeyDown($event, item, i, true)" id="{{getMenuItemDisplayId(item, i)}}">
            <img *ngIf="item.iconic" class="icon" src="{{getMenuIconSrc(item)}}" alt="">
            <div class="name">{{item.name | localize}}</div>
          </div>
          <div *ngIf="item.separator" class="separator"></div>
        </li>
        <li *ngIf="navItems.length && !menuItems[menuItems.length-1].separator" tabindex="-1"><div class="separator"></div></li>
      </ng-template>
      <ng-template [ngIf]="navItems.length">
        <li *ngFor="let item of navItems; let i=index" [ngSwitch]="item.type" role="none">
          <div *ngSwitchDefault class="item" [tabindex]="expanded?1:-1" role="menuitem" title="{{item.name | localize}}" (click)="navItemClicked(item)" (keydown)="onKeyDown($event, item, i)" (keydown.shift.tab)="onKeyDown($event, item, i)" (focusout)="closeMenuOnFocusOut(i)" id="{{getNavItemDisplayId(item, i)}}">
            <img class="icon" src="{{getNavIconSrc(item)}}" alt="">
            <div class="name">{{item.name}}</div>
            <img *ngIf="checkIt(item)" class="icon" src="assets/images/checkmark.svg"/>
          </div>
          <div *ngSwitchCase="'separator'" class="separator"></div>
          <div *ngSwitchCase="'heading'" class="heading" (keydown.escape)="toggle()" [tabindex]="expanded?1:-1">
            <div class="name">{{item.name}}</div>
            <div class="libkind" title="{{getLibKindTitle(item)}}">{{getLibKind(item)}}</div>
          </div>
        </li>
      </ng-template>
      <ng-template [ngIf]="menuItemsPostNav && menuItemsPostNav.length">
        <li><div class="separator"></div></li>
        <li *ngFor="let item of menuItemsPostNav">
          <div *ngIf="!item.separator" class="item" role="menuitem" title="{{item.name | localize}}" (click)=menuItemClicked(item) id="{{'edx_navmenu_'+item.cmd}}">
            <img *ngIf="item.iconic" class="icon" src="{{getMenuIconSrc(item)}}" alt="">
            <div class="name">{{item.name | localize}}</div>
          </div>
          <div *ngIf="item.separator" class="separator"></div>
        </li>
      </ng-template>
    </ul>
  </div>
  `
})
export class AppNavSidebarComponent implements OnInit, OnDestroy {
  static isActive = false;
  public ui: UserInterface;
  public isOfficeAddin: boolean;
  public isEdgeIE: boolean;
  public expanded = false;
  public isShowing = false;
  public isHiding = false;
  public menuItems: MenuItemDesc[] = [];
  public navItems: NavItem[] = [];
  public menuItemsPostNav: MenuItemDesc[] = [];
  private navLoc = '';
  private routerSubscription: Subscription;
  private remote: string;
  private primary: string;
  private welcome: string;
  private startDesc: any = null;
  private lastNavItemIndex = 0;
  private stopClosing: boolean;
  public startItem: NavItem = null;
  private shortcutKeysSubscription: Subscription;

  constructor(private menuService: MenuService, private navService: NavService, private localizer: LocalizeService, private router: Router,
              private shortcutService: ShortcutsService) {
    this.ui = Util.Device.ui;
    this.isOfficeAddin = Util.Device.bIsOfficeAddin;
    this.isEdgeIE = Util.Device.bIsEdge  || Util.Device.bIsIE;
    this.remote = localizer.getTranslation('NAVBAR.REMOTE');
    this.primary = localizer.getTranslation('FORMS.LOCAL.PREFERENCES.PRIMARY');
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.shortcutKeysSubscription) {
      this.shortcutKeysSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    const menuID: number = this.ui >= 2 ? (Util.Device.bIsOfficeAddin ? MenuId.MENU_OAI_HAMBURGER : MenuId.MENU_MOBILE_HAMBURGER) : -1;
    const menuDef: MenuDef = menuID < 0 ? null : this.menuService.getMenu(menuID);
    this.menuItems = !!menuDef ? menuDef.items : [];
    if (menuID === MenuId.MENU_MOBILE_HAMBURGER) {
      if (!Util.Device.bIsCordova/* && (Util.Device.bIsOfficeAddin || !Util.Device.isMobile())*/) { // comment back in for sim testing
        this.menuItems = this.menuItems.filter(item => item.cmd !== 'downloads' && item.cmd !== 'imports');
      }
    }
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.navLoc = this.router.url;
        if (this.navLoc === Util.RestAPI.getHomeURL()) {
          const waitLogin = () => {
            if (Util.RestAPI.isLoggedIn()) {
              if (!this.navItems.length) {
                this.navService.getItems().then(() => {
                  this.setItems.bind(this);
                  Util.RestAPI.warmCache();
                });
              }
            } else {
              setTimeout(waitLogin, 100);
            }
          };
          waitLogin();
        } else if (this.navLoc === Util.RestAPI.getLoginURL()) {
          this.welcome = null;
          this.navItems = [];
          this.navService.reset();
          if (this.expanded) {
            this.toggle();
          }
        }
      }
    });
    this.shortcutKeysSubscription = this.shortcutService.commands.subscribe(c => this.handleCommand(c));
  }

  private handleCommand(command: KeyCommand): void {
    if (command && this.expanded) {
      let index = -1;
      switch (command.name) {
        case 'primary-lib-menu':
          index = this.navItems.findIndex(item => item.name === Util.RestAPI.getPrimaryLibrary());
          break;
        case 'remote-lib-menu':
          for (let i=1; i< this.navItems.length; i++) {
            if (this.navItems[i].type === 'heading' && !Util.isExternalLib(this.navItems[i].lib)) {
              index = i;
              break;
            }
          }
          break;
        case 'applications-menu':
          index = this.navItems.findIndex(item => Util.isExternalLib(item.lib) && item.name === this.localizer.getTranslation('NAVBAR.APPLICATIONS'));
          break;
      }
      if (index > -1) {
        index++;
        this.focusOnNavItem(index, false);
      }
    } else {
      setTimeout(() => {
        let elementId: string;
        switch (command.name) {
          case 'header-trigger-hamburger':
            this.focusOnNavItem(1, false);
          break;
          case 'header-logoff':
            elementId = 'userid';
            break;
        }
        if (elementId) {
          this.focusOnNavItem(1, false, elementId);
        }
      }, 300);
    }
  }

  private setItems(items: NavItem[]): void {
    if (!Util.RestAPI.canUserCreateFolders()) {
      this.menuItems = this.menuItems.filter(item => item.name != "FOLDER_ACTIONS.UPLOAD_FOLDERS");
    }
    if (Util.Device.bIsOfficeAddinWord) {
      const menuDef = this.menuService.getMenu(MenuId.MENU_OAI_HAMBURGER);
      const footerOptionsItem = menuDef.items.find(item => item.name === 'FORMS.LOCAL.FOOTER_OPTIONS.FOOTER_OPTIONS');
      const footerOptionsIndex = menuDef.items.findIndex(item => item === footerOptionsItem);
      if (!Util.RestAPI.canShowFooterOptions() && !!this.menuItems.find(item => item.cmd === footerOptionsItem.cmd)) {
        this.menuItems = this.menuItems.filter(item => item.cmd !== footerOptionsItem.cmd);
      } else if (Util.RestAPI.canShowFooterOptions() && !this.menuItems.find(item => item.cmd === footerOptionsItem.cmd)) {
          this.menuItems.splice(footerOptionsIndex,0,footerOptionsItem);
      }
    }
    const firstExtApp = !!items ? items.find(navItem => Util.isExternalLib(navItem.lib)) : null;
    const prefsMenuItemDesc = !!this.menuItems ? this.menuItems.find(item => item.cmd === 'preferences') : null;
    if (!!firstExtApp && !!prefsMenuItemDesc && Util.Device.isMobile() && !Util.Device.bIsOfficeAddin) {
      const prefsIndex: number = this.menuItems.indexOf(prefsMenuItemDesc);
      const postItems = this.menuItems.slice(prefsIndex);
      this.menuItemsPostNav = [].concat(postItems);
      this.menuItems.splice(prefsIndex, this.menuItems.length - prefsIndex);
    }
    if (!Util.Device.isMobile() || Util.Device.bIsOfficeAddin) {
      this.navItems = items;
    } else {
      this.navItems = items.filter(navItem => Util.isExternalLib(navItem.lib));
    }
    for (let i = items.length - 1; i >= 0; i--) {
      if (['separator', 'header'].indexOf(items[i].type) === -1) {
        this.lastNavItemIndex = i;
        break;
      }
    }
    if (Util.Device.bIsOfficeAddin) {
      const startUpLocStr: string = Util.RestAPI.getPreference('edx_start_location');
      if (startUpLocStr && startUpLocStr.length) {
        let startUpDesc: any;
        try {
          startUpDesc = JSON.parse(startUpLocStr);
        } catch (e) {
          startUpDesc = null;
        }
        this.startLocChanged(startUpDesc);
      }
    }
  }

  private getNavIconSrc(item: NavItem): string {
    return Util.Transforms.titleIconUrlFromDesc(item);
  }

  private getNavIconText(item: NavItem): string {
    return Util.Transforms.titleIconTextFromDesc(item);
  }

  private getMenuIconSrc(item: MenuItem): string {
    return 'assets/images/' + item.icon;
  }

  private getMenuIconText(item: MenuItem): string {
    return Util.Transforms.iconAltTextFromDesc(item);
  }

  private getLibKind(item: NavItem): string {
    return item.name===Util.RestAPI.getPrimaryLibrary() ? this.primary : Util.isExternalLib(item.lib) ? '' : this.remote;
  }

  private getLibKindTitle(item: NavItem): string {
    return item.name===Util.RestAPI.getPrimaryLibrary() ? this.localizer.getTranslation('NAVBAR.PRIMARY') : Util.isExternalLib(item.lib) ? '' : this.localizer.getTranslation('NAVBAR.REMOTE_LIB');
  }

  private checkIt(item: NavItem): boolean {
    return (item.id==='0' && item.type==='libraries');
  }

  private navItemClicked(item: NavItem): void {
    if (this.expanded) {
      this.toggle();
    }
    if (item.type==='libraries') {
      if (item.id!=='0') {
        this.navItems = [];
        this.navService.changingPrimaryLibraries();
        Util.RestAPI.setPrimaryLibrary(item.lib);
      }
    } else {
      this.navService.openItem(item);
    }
  }

  private menuItemClicked(item: MenuItemDesc): void {
    if (this.expanded) {
      this.toggle();
    }
    Util.RestAPI.getAppComponent().doCommand(item.cmd);
  }

  private closeMenuOnFocusOut(itemIndex: number): void {
    if (itemIndex === this.lastNavItemIndex && !this.stopClosing) {
      this.closeMenu();
    }
  }

  private onKeyDown(event: KeyboardEvent, item: NavItem, currentIndex: number = -1, isMenuItem: boolean = false) {
    if (!!event.key) {
      let forward: boolean;
      this.stopClosing = false;
      switch (event.key) {
        case 'ArrowDown':
          forward = true;
          break;
        case 'ArrowUp':
          forward = false;
          this.stopClosing = true;
          break;
        case 'Tab':
          if (event.shiftKey) {
            this.stopClosing = true;
          }
          break;
        case ' ':
        case 'Enter':
          if (event.key === ' ') {
            event.preventDefault();
          }
          if (isMenuItem) {
            this.menuItemClicked(this.menuItems[currentIndex]);
          } else {
            this.navItemClicked(item);
          }
          break;
        case 'Escape':
          this.toggle();
          this.focusOnMenuItem();
          break;
      }
      if (['ArrowUp', 'ArrowDown'].indexOf(event.key) !== -1 && !!event.target) {
        const currentEl = event.target as HTMLElement;
        if (!!currentEl) {
          const nextEl = this.getNextSibling(currentEl.parentNode, forward);
          if (!!nextEl) {
            nextEl.focus();
          }
        }
      }
    }
  }

  private getNextSibling(currentLi: Node, forward: boolean): HTMLElement {
    if (!!currentLi) {
      const nextLi: ChildNode = forward ? currentLi.nextSibling : currentLi.previousSibling;
      if (!!nextLi) {
        const nextEl = nextLi.firstChild as HTMLElement;
        return (!!nextEl && !!nextEl.className && nextEl.className.indexOf('item') !== -1) ? nextEl : this.getNextSibling(nextLi, forward);
      }
    }
  }

  private focusOnNavItem(index: number, isMenuItem: boolean, elementId?: string, isClassElement?: boolean): void {
    if (isClassElement) {
      document.querySelector<HTMLInputElement>(elementId)?.focus();
    } else if (index >= 0 && (index <= this.lastNavItemIndex || isMenuItem)) {
      const id = isMenuItem ? this.getMenuItemDisplayId(this.menuItems[index], index) : this.getNavItemDisplayId(this.navItems[index], index);
      const navElement: HTMLElement = document.getElementById(id);
      if (!!navElement) {
        navElement.focus();
      }
    } else if (elementId) {
      const navElement: HTMLElement = document.getElementById(elementId);
      if (!!navElement) {
        navElement.focus();
      }
    }
  }

  private focusOnMenuItem(): void {
    const navElement: HTMLElement = document.getElementById(this.getMenuItemDisplayId(null,0));
    if (!!navElement) {
      navElement.focus();
    }
  }

  private getNavItemDisplayId(item: NavItem, index?: number): string {
    const itemId: string = !!item ? (item['type'] + (item['lib'] ? '_' + item['lib'] : '') + (item['id'] ? '_' + item['id'] : '')) : '' + index;
    return 'edx_navitem_' + itemId;
  }

  private getMenuItemDisplayId(item: MenuItemDesc, index?: number): string {
    return this.ui === 0 ? 'edx_app_nav' : 'edx_navmenu_' + (!!item ? item.cmd : index);
  }

  private dismiss(event: Event): void {
    if (this.expanded) {
      this.isShowing = false;
      AppNavSidebarComponent.isActive = this.isShowing;
      this.isHiding = true;
      event.stopPropagation();
    }
  }

  public closeMenu(): void {
    if (this.expanded) {
      this.toggle();
    } else if (this.isShowing) {
      setTimeout(() => {
        this.toggle();
      }, 300);
    }
  }

  public toggle(event?: Event): void {
    this.stopClosing = false;
    if (this.expanded) {
      this.isShowing = false;
      this.isHiding = true;
    } else {
      if (!this.welcome) {
        this.welcome = this.localizer.getTranslation('NAVBAR.WELCOME', [Util.RestAPI.getUserFullName()]);
      }
      this.isShowing = true;
      this.isHiding = false;
      this.reset();
      this.focusOnNavItem(0, false);
    }
    AppNavSidebarComponent.isActive = this.isShowing;
  }

  public sidebarAnimationComplete(): void {
    if (this.isHiding) {
      this.expanded = false;
    } else if (this.isShowing) {
      this.expanded = true;
    }
    this.isShowing = false;
    this.isHiding = false;
  }

  public changePrimaryLibrary(lib?: string): void {
    this.navItems = [];
    if (lib) {
      this.navService.changingPrimaryLibraries();
      Util.RestAPI.setPrimaryLibrary(lib);
    } else {
      Util.RestAPI.delete('/settings/tiles').toPromise().then(response => {
        this.navService.changingPrimaryLibraries();
        Util.RestAPI.reloadTiles();
        this.reset();
      }).catch(error => {
        Util.Notify.warning(this.localizer.getTranslation('RAPI_ERRORS.0'),error);
      });
    }
  }

  public reconnected(): void {
    this.navItems = [];
    this.navService.getItems().then(this.setItems.bind(this));
  }

  public reset(): void {
    this.navService.reset();
    this.reconnected();
  }

  public startLocChanged(desc: any): void {
    if (this.startDesc) {
      this.startItem = null;
      this.startDesc = null;
      }
    if (!!desc) {
      this.startDesc = desc;
      this.startItem = new NavItem({
        id: desc.id,
        type: desc.type,
        lib: desc.lib,
        name: desc.DOCNAME || desc.name,
        imgPath: desc.imgPath
        });
      }
  }
}
