import { Injectable } from '@angular/core';
import { Util } from '../utils/utils.module';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const kFavoritesKey = 'edx_favorites';

@Injectable({
    providedIn: 'root'
})
export class FavoriteService {
    constructor() { }

    private getFavoritesForAllLibraries() {
        const favStr = this.getFavoriteData();
        return JSON.parse(favStr);
    }

    private getFavoriteData(): string {
        return localStorage.getItem(kFavoritesKey);
    }

    public setFavoriteDataInLocalStorage(favoriteData): void {
        localStorage.setItem(kFavoritesKey, JSON.stringify(favoriteData));
    }

    private getFavoritesForLibrary(lib): any {
        const favorites = this.getFavoritesForAllLibraries();
        if (!!favorites) {
            if (!favorites.hasOwnProperty(lib)) {
                favorites[lib] = {};
            }
            return favorites[lib];
        }
        return {};
    }

    private getFavoriteId(item): string {
        if (item['type'] === 'searches') {
            return item['id'];
        } else if (!!item['PROFILE.SYSTEM_ID']) {
            return item['PROFILE.SYSTEM_ID'];
        }
        return item['SYSTEM_ID'];
    }

    private setFavoriteDataForLib(lib, data): void {
        let favoriteData = this.getFavoritesForAllLibraries();
        if (!favoriteData) {
            favoriteData = {};
        }
        favoriteData[lib] = data;
        this.setFavoriteDataInLocalStorage(favoriteData);
    }

    public getDescForFavorite(l = '', docName = '') {
        return {
            lib: l,
            type:'workspaces',
            id:'favorites',
            DOCNAME: docName
        };
    }

    public getFavoriteIdsForAllLibraries(): void {
        if (Util.RestAPI.IsFavoritesEnabled()) {
            Util.RestAPI.get(this.getDescForFavorite(), 'all', 'ascending=DOCNAME').subscribe((data) => {
                if (!!data['list']) {
                    this.setFavoriteDataInLocalStorage(data['list']);
                }
            }, err => {});
        }
    }

    public addOrRemoveFavoriteIdInLocalStorage(item, isAdd) {
        const favoriteIdsDict = this.getFavoritesForLibrary(item['lib']);
        const id = this.getFavoriteId(item);
        const isKeyFound = !!favoriteIdsDict && favoriteIdsDict.hasOwnProperty(id);
        if (!isKeyFound && isAdd && !!id) {
            favoriteIdsDict[id] = item['FI.SYSTEM_ID'];
        } else if (isKeyFound && !isAdd) {
            delete favoriteIdsDict[id];
        }
        this.setFavoriteDataForLib(item['lib'], favoriteIdsDict);
    }

    public setIsFavoriteForListData(listData, descId) {
        if (!listData || listData.length === 0 || !Util.RestAPI.IsFavoritesEnabled()) {
            return;
        }
        this.handleSetIsFavoriteForListData(listData, descId);
    }

    private handleSetIsFavoriteForListData(listData, descId) {
        const favorites = this.getFavoritesForAllLibraries();
        if (!favorites || favorites.length === 0) {
            return;
        }
        for (const item of listData) {
            const id = this.getFavoriteId(item);
            const libFavorites = !!favorites.hasOwnProperty(item['lib']) ? favorites[item['lib']] : {};
            const favHasOwnProperty = libFavorites.hasOwnProperty(id);
            if (!favHasOwnProperty && Util.Transforms.isFavoriteFolder(descId)) {
                this.addOrRemoveFavoriteIdInLocalStorage(item, true);
                item['is_favorite'] = true;
            }
            else {
                item['is_favorite'] = favHasOwnProperty;
            }
        }
    }
    
    public addToFavorites(item): Observable<any> {
        const serverData = { _restapi: {} };
        serverData['_restapi'] = {ref: this.getDescForFavorite(Util.RestAPI.getPrimaryLibrary()) };
        return Util.RestAPI.post(item, serverData, 'references').pipe(map(response => {
            if (!!response && !!response.list && response.list.length > 0) {
                this.handleAddToFavoriteResponse(response.list[0], item);
            }
        }));
    }

    public handleAddToFavoriteResponse(addedItem, sourceItem): void {
        if (!!addedItem) {
            if (!addedItem['FI.SYSTEM_ID']) {
                addedItem['FI.SYSTEM_ID'] = addedItem['SYSTEM_ID'];
            }
            if (!!sourceItem) {
                addedItem['SYSTEM_ID'] = sourceItem['PROFILE.SYSTEM_ID'] || sourceItem['SYSTEM_ID'];
            }
            this.addOrRemoveFavoriteIdInLocalStorage(addedItem, true);
        }
    }

    public removeFromFavorites(item, desc): Observable<any> {
        const favoriteIdsDict = this.getFavoritesForLibrary(item['lib']);
        const id = this.getFavoriteId(item);
        const folderItemSystemId = !!favoriteIdsDict && favoriteIdsDict.hasOwnProperty(id) ? favoriteIdsDict[id]: '';
        const query = 'reftype=' + desc['type'] + '&refid=favorites&reflib=' + Util.RestAPI.getPrimaryLibrary() + '&refsysid=' + folderItemSystemId;
        return Util.RestAPI.delete(item, 'references', query).pipe(map((response) => {
            this.addOrRemoveFavoriteIdInLocalStorage(item, false);
        }));
    }

    public isFavoriteItem(item): boolean {
        const favoriteIdsDict = this.getFavoritesForLibrary(item['lib']);
        const id = this.getFavoriteId(item);
        return favoriteIdsDict?.hasOwnProperty(id);
    }
}
