/* eslint-disable quote-props */
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { WindowModalComponent } from './window-modal.component';
import { Util } from '../utils/utils.module';
import { AppComponent } from '../app.component';
import { TabItem, TabReceiver } from '../models/tab-item';
import { ListItem } from '../models/list-item';
import { CommandHandler } from '../models/command-handler';
import { SelectItem } from '../models/form-field';
import { LocalizeService } from '../services/localize.service';
import { SettingsService } from '../services/settings.service';
import { FormWrapperComponent } from '../forms/form-wrapper.component';
import { TabSelectorComponent } from '../widgets/tab-selector.component';
import { SelectComponent } from '../widgets/select.component';
import { FilelistReceiver } from '../widgets/filelist-sidebar.component';
import { ActionBarComponent } from '../widgets/action-bar.component';
import { ListTableParent, ListTableComponent } from '../lists/list-table.component';
import { KeyCommand, ShortcutsService } from '../services/shortcuts.service';
import { MenuId } from '../services/menu.service';
import { FilterSidebarComponent } from '../forms/filter-sidebar.component';
import { ListData } from '../models/base';
import { TileService } from '../services/tile.service';

export enum AdminTabs {
  GENERAL = 'GENERAL',
  LOOKUPS = 'LOOKUPS',
  TILES = 'TILES',
  FOOTER = 'FOOTER'
};
@Component({
  selector: 'edx-window-modal-admin',
  styleUrls: [ 'window-modal.component.scss', 'window-modal-metadata.component.scss', 'window-modal-admin.component.scss' ],
  templateUrl: 'window-modal-admin.component.html'
})
export class WindowModalAdminComponent extends WindowModalComponent implements OnInit, OnDestroy, TabReceiver, CommandHandler, FilelistReceiver, ListTableParent {
  @ViewChild('formWrapper') formWrapper: FormWrapperComponent;
  @ViewChild('tabSelector') tabSelector: TabSelectorComponent;
  @ViewChild('lookupTable') lookupTable: ListTableComponent;
  @ViewChild('actionbar') actionBar: ActionBarComponent;
  @ViewChild(FilterSidebarComponent) filters: FilterSidebarComponent;
  public tabId = '';
  public showSpinner = true;
  private appComponent: AppComponent = Util.RestAPI.getAppComponent();
  private primaryLib: string = Util.RestAPI.getPrimaryLibrary();
  private urlLoaded = false;
  private showSave = false;
  private saveStr;
  private leftNavSelectedIndex = 0;
  private leftNavList: ListItem[] = [];
  private lookupTableSelections: ListItem[] = [];
  private listItems: ListItem[] = [];
  private librarySelectItems: SelectItem[];
  private lookupSelItems: SelectItem[];
  private currentLookup: string;
  private forLibStr: string;
  private forPrimaryStr: string;
  private lookupDesc: any;
  private lookupForm: string;
  private lookupFormType: any;
  private lookupKey: any;
  private lookupLeadingColums = [0];
  private actionId = MenuId.MENU_LOOKUP_ACTIONS;
  private shortcutsSubscription: Subscription;
  private showFilter = false;
  private filter = '*';
  private tabList: TabItem[] = [
    { id: AdminTabs.GENERAL, title: 'FORMS.LOCAL.PREFERENCES.GENERAL' },
    { id: AdminTabs.LOOKUPS, title: 'ADMIN.VALIDATION_TABLES' },
    { id: AdminTabs.TILES, title: 'FORMS.LOCAL.PREFERENCES.HOME_TILES' },
    { id: AdminTabs.FOOTER, title: 'ADMIN.FOOTER.FOOTER' }
  ];
  private contentColumns: string[] = [];
  private contentFormKind: string = '';
  private contentAPIKey: string = '';
  private contentLoaded = false;
  private contentSaveCommand: string = '';
  private formData = {};
  public searchValue = '';
  public isFiltered = false;
  public showInfoMessage = false;
  public infoMessage = '';
  public isFiltering = false;
  

  constructor(protected location: Location, protected router: Router, protected route: ActivatedRoute, private localizer: LocalizeService, private settingsService: SettingsService, shortcutsService: ShortcutsService,
              private tileService: TileService) {
    super(location,router,route);
    this.forLibStr = this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.FOR_LIB_SHORT');
    this.saveStr = this.localizer.getTranslation('FOLDER_ACTIONS.SAVE');
    this.shortcutsSubscription = shortcutsService.commands.subscribe(c => this.handleCommand(c));
  }

  handleCommand(command: KeyCommand) {
    this.tabSelector.selectTabById(command.name.replace('-', '').toUpperCase());
    setTimeout(() => {
      Util.Transforms.focusOnElementByClassName('fileitem selected');
    }, 100);
  }

  ngOnInit() {
    const libs: any[] = Util.RestAPI.getLibraries();
    this.showSpinner = false;
    this.librarySelectItems = [];
    this.lookupSelItems = [];
    this.tabId = this.getFirstEnabledTab();
    if (!!libs && !!libs.length) {
      for (const lib of libs) {
        if (lib.DISABLED === 'N') {
          const libNameUC: string = lib.LIBRARY_NAME.toUpperCase();
          this.librarySelectItems.push({ value: libNameUC, display: lib.LIBRARY_NAME, disabled: !this.userHasAccessToRemoteLibraryAdminMaintenance(lib) });
        }
      }
    }
    if (this.urlLoaded) {
      this.libraryChanged(null);
    }
    this.forPrimaryStr = this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.FOR_LIB', [Util.RestAPI.getPrimaryLibrary()]);
    if (!Util.RestAPI.isGuestUser()) {
      super.ngOnInit();
    } else {
      Util.Notify.error(this.localizer.getTranslation('RAPI_ERRORS.13'));
      this.appComponent.navBack();
    }
  }

  ngOnDestroy() {
    if (this.shortcutsSubscription) {
      this.shortcutsSubscription.unsubscribe();
    }
    this.resetFormData();
  }

  private getFirstEnabledTab(): string {
    for (const tab of this.tabList) {
      if (this.tabEnabled(tab.id)) {
        return tab.id;
      }
    }

    return '';
  }

  private okEnabled(event: any): void {
    this.enableSave(event.dirty && event.enable);
  }

  private enableSave(enable: boolean): void {
    if (this.showSave !== enable) {
      this.showSave = enable;
      this.appComponent.blockForDirty(this, enable);
    }
  }

  private userHasAccessToRemoteLibraryAdminMaintenance(library: any): boolean {
    return library.LIBRARY_NAME === Util.RestAPI.getPrimaryLibrary() || (!!library.EFFECTIVE_RIGHTS && library.EFFECTIVE_RIGHTS['EDIT_VTS'] === 'Y');
  }

  private libraryChanged(selectComponent: SelectComponent): void {
    let queryArgs = '';
    let params = '';
    if (!!selectComponent) {
      this.desc.lib = selectComponent.value;
    }
    if (!!this.desc && this.desc.lib) {
      queryArgs = '?library=' + this.desc.lib;
    }
    if (this.tabId === AdminTabs.LOOKUPS) {
      params = 'modifiabletables';
    } else {
      params = '_GROUPS_ENABLED';
      queryArgs += '&profile=v_groups&key=GROUP_ID';
    }
    this.initContentSection();
    Util.RestAPI.get('lookups', params, queryArgs).toPromise().then(data => {
      if (!!data) {
        this.leftNavSelectedIndex = 0;
        if (this.tabId === AdminTabs.LOOKUPS) {
          this.contentLoaded = true;
          this.leftNavList = data.map((table) => new ListItem({ id: table.formName, type: 'lookups', lib: this.desc?.lib, DOCNAME: table.formTitle, DOCNUM: table.formId }));
          this.lookupSelItems = this.leftNavList.map(aii => ({ value: aii.id, display: aii.DOCNAME }) as SelectItem);
          this.loadLookupsList();
        } else {
          this.loadGroupData(params,queryArgs);
        }
      } else {
        this.leftNavList = [];
      }
    }, err => {
      this.leftNavList = [];
     });
  }

  private initContentSection(): void {
    this.contentColumns = []
    this.contentLoaded = false;
    this.contentSaveCommand = this.tabId === AdminTabs.LOOKUPS ? '' : 'save_' + this.tabId.toLowerCase();
    switch (this.tabId) {
      case AdminTabs.GENERAL:
        this.contentColumns.push(this.localizer.getTranslation('ADMIN.TILES.USER_OVERRIDE'));
        this.contentFormKind = '__local_preferences_general';
        this.contentAPIKey = 'GroupGeneralSettings';
        break;
      case AdminTabs.TILES:
        this.contentColumns.push(this.localizer.getTranslation('ADMIN.TILES.AVAILABLE'));
        this.contentColumns.push(this.localizer.getTranslation('ADMIN.TILES.USER_OVERRIDE'));
        this.contentFormKind = '__local_group_tile_settings';
        this.contentAPIKey = 'GroupTileSettings';
        break;
      case AdminTabs.FOOTER:
        this.contentColumns.push(this.localizer.getTranslation('ADMIN.FOOTER.ENABLE'));
        this.contentFormKind = '__local_group_footer_defaults';
        this.contentAPIKey = 'GroupFooterDefaults';
        break;
    }
  }

  private loadList(): void {
    switch (this.tabId) {
      case AdminTabs.LOOKUPS:
        this.loadLookupsList();
        break;
      case AdminTabs.GENERAL:
      case AdminTabs.TILES:
      case AdminTabs.FOOTER:
        this.loadGroupSettings();
        break;
    }
  }

  private loadLookupsList(): void {
    this.currentLookup = this.lookupSelItems.length > 0 ? this.lookupSelItems[this.leftNavSelectedIndex].value : undefined;
    this.lookupDesc = this.leftNavList[this.leftNavSelectedIndex];
    this.lookupForm = this.leftNavList[this.leftNavSelectedIndex]?.id;
    this.lookupKey = this.currentLookup + '_ID';
    this.lookupFormType = 'profile';
    const lib = this.desc.lib;
    this.desc = { id: '', type: '', lib: '' };
    Util.Transforms.scrollIntoViewIfNeededUsingClassName('selected fileitem');
    setTimeout(() => {
      this.setDesc(lib);
      if (!!this.lookupTable) {
        this.lookupTableSelections = [];
        this.handleFilterChange();
        this.contentLoaded = true;
        this.selectionsUpdated(this.lookupTable);
      }
    }, 10);
  }

  private loadGroupSettings() {
    const groupName = this.leftNavList?.[this.leftNavSelectedIndex]?.DOCNAME;
    if (!!groupName) {
      Util.RestAPI.getGroupSettings(groupName, this.desc.lib, this.contentAPIKey).then((data) => {
        const formData = { GROUP_NAME: groupName, SELECTED_LIB: this.desc?.lib };
        formData[this.tabId] = data;
        this.formData = formData;
        this.contentLoaded = true;
        Util.Transforms.scrollIntoViewIfNeededUsingClassName('selected fileitem');
      });
    }
  }

  private updateFormData(formData): void {
    this.formData = formData;
  }

  private resetFormData(): void {
    this.formWrapper = null;
    this.formData = null;
  }

  private handleFilterChange(filter = '*') {
    if (!!this.lookupTable) {
      this.filter = filter;
      this.lookupTable.handleFilterChange(filter, this.lookupKey, 'all');
      if (!!this.filters) {
        this.filters.enableOK(false, true);
        setTimeout(() => {
          if (!filter && !!this.filters) {
            this.filters.getAndSetFormData();
          }
        }, 500);
      }
      this.lookupTableSelections = [];
      if (!!this.actionBar) {
        this.actionBar.updateActions();
      }
    }
  }

  private setDesc(lib: string): void {
    this.desc = ({id:'admin',type:'admin',lib,imgPath:'assets/images/console_settings_grey.svg'} as any);
  }

  public getDesc(): any {
    return this.desc;
  }

  protected loadURL(url: string): void {
    // block the base class from loading the url
    this.appComponent.registerWindow(this, url);
    this.setDesc(Util.RestAPI.getPrimaryLibrary().toUpperCase());
    if (!this.showSpinner) {
      this.libraryChanged(null);
    }
    this.urlLoaded = true;
  }

  public initialList(table: ListTableComponent): ListItem[] {
    return this.listItems;
  }

  public initialSet(table: ListTableComponent): any {
    return { total: this.listItems.length };
  }

  public checkboxClick(table: ListTableComponent, item: ListItem, property: string): void {
  }

  public tabSelected(id: string): void {
    this.tabId = id;
    this.libraryChanged(null);
    if (this.tabId !== AdminTabs.LOOKUPS) {
      this.resetData();
    } 
  }

  public tabEnabled(id: string): boolean {
    if (Util.Device.isWebLook()) {
      switch (id) {
        case AdminTabs.GENERAL:
        case AdminTabs.TILES:
        case AdminTabs.FOOTER:
          return Util.RestAPI.canUserManageAdminFeature(id);
        case AdminTabs.LOOKUPS:
          return Util.RestAPI.canUserManageLookups();
      }
    }
    return false;
  }

  public fileSelected(index: number): void {
    // load a file from the list into the content panel
    if (index >= 0 && index < this.leftNavList.length) {
      this.leftNavSelectedIndex = index;
      this.loadList();
    }
  }

  public cancelHdrBtnClick(): void {
    this.showSpinner = true;
    setTimeout(() => {
      this.enableSave(false);
      this.showSpinner = false;
    }, Util.kPopupDismissMS);
  }

  public rightHdrBtnClick(): void {
    if (!!this.contentSaveCommand) {
      this.doCommand(this.contentSaveCommand);
    }
  }

  public doCommand(cmd: string): boolean {
    switch (cmd) {
      case 'lookup_copy':
      case 'lookup_edit':
      case 'lookup_add':
      case 'lookup_filter':
        this.doCommandForLookup(cmd);
        break;
      case 'save_tiles':
      case 'save_footer':
      case 'save_general':
        this.doCommandForGroup(cmd);
        break;
    }
    return false;
  }

  public doCommandForGroup(cmd: string) : boolean {
    switch (cmd) {
      case 'save_tiles':
      case 'save_footer':
      case 'save_general':
        this.showSpinner = true;
        if (this.formWrapper) {
          this.formWrapper.popupOK();
        }
        setTimeout(() => {
          this.enableSave(false);
          this.showSpinner = false;
        }, Util.kPopupDismissMS);
        setTimeout(() => {
          this.fileSelected(this.leftNavSelectedIndex);
        }, 1);
        break;
    }
    return false;
  }

  public doCommandForLookup(cmd: string): boolean {
    const selectedLookupItem = Util.RestAPI.deepCopy(this.lookupDesc);
    selectedLookupItem['FORM_NAME'] = selectedLookupItem.id;
    switch (cmd) {
      case 'lookup_copy':
      case 'lookup_edit':
        selectedLookupItem['DOCNUM'] = this.lookupTableSelections[0]['%PRIMARY_KEY'];
        this.appComponent.doCommand('profile_' + cmd, { desc: selectedLookupItem });
        break;
      case 'lookup_add':
        this.appComponent.doCommand('profile_' + cmd, { desc: selectedLookupItem });
        break;
      case 'lookup_filter':
        this.showFilter = !this.showFilter;
        if (!this.showFilter) {
          this.handleFilterChange();
        }
        if (!!this.filters) {
          this.filters.ngOnInit();
          this.filters.isOpen = this.showFilter;
        }
        break;
    }
    return false;
  }

  public selectionsUpdated(table: ListTableComponent): void {
    if (!!this.lookupTable) {
      this.lookupTableSelections = this.lookupTable.getSelections();
    }
    if (!!this.actionBar) {
      this.actionBar.updateActions();
    }
  }

  public refresh(cmd?: string, value?: any): void {
    this.handleFilterChange(this.showFilter ? this.filter : '*');
  }

  public commandEnabled(cmd: string): boolean {
    switch (cmd) {
      case 'lookup_add':
      case 'lookup_filter':
        return true;
      case 'lookup_separator':
      case 'lookup_copy':
      case 'lookup_edit':
        return !!this.lookupTableSelections && this.lookupTableSelections.length === 1;
    }
    return false;
  }

  public getListData(): ListData {
    const list = !!this.lookupTable && !!this.lookupTable.getList() ? this.lookupTable.getList() : [];
    const set = !!this.initialSet ? this.initialSet : {};
    if (!set['search']) {
      set['search'] = {};
    }
    set['search']['FORM_NAME'] = this.currentLookup;
    return { set, list };
  }

  public searchClicked(event: Event, clear?: boolean): void {
    if (event.type === 'keyup') {
      event.preventDefault();
      event.stopPropagation();
    }
    this.isFiltering = true;
    if (!this.searchValue) {
      clear = true;
    }
    if (clear) {
      this.searchValue = '';
      document.getElementById('search')?.focus();
    } else {
      if (event.type === 'keyup' && event.target['id'] && event.target['id'] === 'edx_search_group_btn') {
        setTimeout(() => {
          document.getElementById(event.target['id'])?.focus();
        }, 300);
      }
    }
    const searchFilter = `?library=${this.desc.lib}&profile=v_groups&key=GROUP_ID&filter=GROUP_ID=*${this.searchValue}* or GROUP_NAME=*${this.searchValue}*`
    this.loadGroupData('_GROUPS_ENABLED', searchFilter);
  }

  public loadGroupData(params: string, queryArgs: string): void {
    Util.RestAPI.get('lookups', params, queryArgs).toPromise().then(data => {
      this.showInfoMessage = data.set.total === data.set.max;
      this.infoMessage = this.localizer.getTranslation('FORMS.LOOKUPS.INFO_MORE', [data.set.max.toString()]);
      const groupData = data.list.map(x => x.item).map(x => x.filter(y => y.PROPNAME === 'GROUP_ID'));
      this.leftNavList = groupData?.map((group) => new ListItem({ id: group[0].PROPNAME, type: 'lookups', lib: this.desc?.lib, DOCNAME: group[0].DATA, DOCNUM: group[0].TITLE, APP_ID: '_GROUPS_ENABLED' }));
      this.isFiltering = false;
      this.loadGroupSettings();
    })
  }

  public resetData(): void {
    this.showFilter = false;
    this.searchValue = '';
    this.isFiltered = false;
    this.showInfoMessage = false;
    if (!!this.filters) {
      this.filters.close();
    }
  }

  public clearSearchWhenEmpty(event:KeyboardEvent): void {
    if (!this.searchValue) {
      this.searchClicked(event, true);
    } else {
      return;
    }
  }

}
