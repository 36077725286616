import { Component, ViewChildren, ViewChild, HostListener, OnInit, ElementRef, QueryList, NgZone } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Tile, TileSize, dragHoleKey } from '../models/tile';
import { TileComponent, TilesContainerInterface } from './tile.component';
import { TileService } from '../services/tile.service';
import { ListItem } from '../models/list-item';
import { LocalizeService } from '../services/localize.service';
import { Util } from '../utils/utils.module';
import { ListBaseComponent } from '../lists/list-base.component';
import { WidgetsModule } from '../widgets/widgets.module';
import { FavoriteService } from '../services/favorite.service';

const kRefreshInterval: number = (10 * 60 * 1000);

@Component({
  selector:'edx-tiles-container',
  styleUrls: [ 'tiles-container.component.scss' ],
  template: `
    <div #tc class="edx-tiles-container" [edx-file-drop-target]="this" [ngClass]="{edx_hidden:hidden&&!isOfficeAddin,dragover:dragover,oai:isOfficeAddin}" [style.padding]="getPadding()">
      <edx-tile *ngFor="let tile of getVisibleTiles()" [isFirstTile]="isFirstTile(tile)" class="edx-tile" [desc]="tile"  [tileId] = "getDisplayId(tile)" id="{{ getDisplayId(tile) }}" [parent]="this"></edx-tile>
    </div>
    <div *ngIf="dragover" class="dragmask"></div>
    <edx-spinner *ngIf="!tilesLoaded"></edx-spinner>
  `
})
export class TilesContainerComponent implements OnInit, TilesContainerInterface {
  @ViewChildren(TileComponent) tileComponents: QueryList<TileComponent>;
  @ViewChild('tc') tc: ElementRef;
  public isOfficeAddin: boolean;
  public dragover = false;
  public hidden = false;
  public tilesLoaded = false;
  private homeLibrary: string = null;
  private tiles: Tile[];
  private dragHole: Tile = null;
  private dragTileOriginalIndex = -1;
  private dragTile: TileComponent = null;
  private reconnecting = false;
  private lastRefreshTime: Date = null;
  private selectedTile: Tile = null;
  private nTilesPerRow = 1;
  private activeElement: HTMLElement = null;

  constructor(private tileService: TileService, private localizer: LocalizeService, private router: Router, private zone: NgZone,
             private favoriteService: FavoriteService) {
    this.isOfficeAddin = Util.Device.bIsOfficeAddin;
    Util.RestAPI.setTilesContainer(this);
    this.zone.runOutsideAngular(() => {
      // this empty mousemove listener gets around a bug in Electron that will not run animations when no draggable tiles are present. yes stupid
      addEventListener('mousemove', (event) => {});
    });
  }

  ngOnInit(): void {
    this.lastRefreshTime = new Date();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.handleNavEnd();
      }
    });
    setTimeout(() => {
      this.refresh();
    }, kRefreshInterval);
    this.handleNavEnd();
  }

  @HostListener('window:resize')
  public onResize(): void {
    setTimeout(() => {
      if (this.tilesLoaded) {
        const visTiles = this.getVisibleTiles();
        const nVisTiles = visTiles ? visTiles.length : 0;
        this.nTilesPerRow = 1;
        if (nVisTiles) {
          let curTile = visTiles[0];
          let tileComp: TileComponent = this.tileComponents.find(tc => tc.desc.id===curTile.id && tc.desc.type===curTile.type && tc.desc.lib===curTile.lib);
          let element: HTMLElement = !!tileComp ? tileComp.getElement() : null;
          if (!!element) {
            const lastY: number = element.offsetTop;
            for (let i=1; i<nVisTiles; i++) {
              curTile = visTiles[i];
              tileComp = this.tileComponents.find(tc => tc.desc.id===curTile.id && tc.desc.type===curTile.type && tc.desc.lib===curTile.lib);
              if (!!tileComp) {
                element = tileComp.getElement();
              }
              if (!!element && element.offsetTop !== lastY) {
                break;
              }
              ++this.nTilesPerRow;
            }
          }
        }
      }
    }, 100);
  }

  @HostListener('document:keydown', ['$event'])
  private onKeyDown(event: KeyboardEvent): boolean {
    if (!this.hidden && WidgetsModule.isOKtoHandleKeydown()) {
      if (event.altKey) {
        const tileStr: string = this.localizer.getTranslation('HOT_KEYS.TILES.' + event.key.toUpperCase());
        if (!!tileStr) {
          const visTiles: Tile[] = this.getVisibleTiles();
          const tileInfo: string[] = tileStr.split(';');
          this.selectedTile = visTiles.find(t => t.type === tileInfo[0] && t.id === tileInfo[1]);
          this.nav(event.key, true);
        }
      } else {
        // IE has key events of Down, Up, Left, Right instead of ArrowDown, ArrowUp... as in Chrome, Safari, etc.
        switch (event.key) {
          case 'ArrowDown':
          case 'Down':
          case 'ArrowUp':
          case 'Up':
          case 'ArrowLeft':
          case 'Left':
          case 'ArrowRight':
          case 'Right':
            if (this.getActiveElementParentId().startsWith('edx_tile_')) {
              this.nav(event.key);
              this.activeElement = document.activeElement as HTMLElement;
              return false;
            }
        }
      }
    }
    return true;
  }

  @HostListener('document:keyup', ['$event'])
  private onKeyUp(event: KeyboardEvent): boolean {
    if (!this.hidden && WidgetsModule.isOKtoHandleKeydown()) {
      if (event.key === 'Tab') {
        this.activeElement = document.activeElement as HTMLElement;
        const tileId: string = this.getActiveElementParentId();
        if (tileId.startsWith('edx_tile_')) {
          const visTiles: Tile[] = this.getVisibleTiles();
          this.selectedTile = visTiles.find(t => this.getDisplayId(t) === tileId);
        }
      }
    }
    return true;
  }

  private getActiveElementParentId(): string {
    const activeElement: Element = document.activeElement;
    return (!!activeElement && !!activeElement.parentElement && activeElement.parentElement) ? activeElement.parentElement.id : '';
  }

  private nav(direction: string, forceFocus?: boolean): void {
    const visTiles: Tile[] = this.getVisibleTiles();
    const selectedIndex: number = this.selectedTile ? visTiles.indexOf(this.selectedTile) : -1;
    let newIndex: number = selectedIndex;
    if (!forceFocus) {
      if (selectedIndex === -1) {
        if (['ArrowDown', 'Down', 'ArrowLeft', 'Left'].indexOf(direction) !== -1) {
          newIndex = 0;
        } else {
          newIndex = visTiles.length - 1;
        }
      } else {
        switch (direction) {
          // IE has key events of Down, Up, Left, Right instead of ArrowDown, ArrowUp...
          case 'Down':
          case 'ArrowDown': newIndex += this.nTilesPerRow; break;
          case 'Up':
          case 'ArrowUp': newIndex -= this.nTilesPerRow; break;
          case 'Right':
          case 'ArrowRight': newIndex++; break;
          case 'Left':
          case 'ArrowLeft': newIndex--; break;
        }
      }
      if (newIndex<0) {
        Util.RestAPI.getAppComponent().focusSearchBar();
      } else if (newIndex>=visTiles.length) {
        newIndex = visTiles.length - 1;
      }
    }
    if (newIndex !== selectedIndex || forceFocus) {
      if (newIndex >=0) {
        this.selectedTile = visTiles[newIndex];
        const tileComp: TileComponent = this.tileComponents.find(tc => tc.desc.id === this.selectedTile.id && tc.desc.type === this.selectedTile.type && tc.desc.lib === this.selectedTile.lib);
        const curEl: HTMLElement = !!tileComp ? tileComp.getElement() : null;
        if (!!curEl) {
          curEl.focus();
        }
      } else {
        this.selectedTile = null;
      }
    }
  }

  private handleNavEnd(): void {
    const isHome: boolean = this.router.url===Util.RestAPI.getHomeURL();
    if (isHome) {
      const lib: string = Util.RestAPI.getPrimaryLibrary();
      if (!!lib) {
        if (lib!==this.homeLibrary) {
          if (!this.reconnecting) {
            this.reset();
          }
        } else {
          this.refresh();
        }
      }
    }
    this.setHidden(!isHome);
    if (isHome) {
      setTimeout(() => {
        if (!!this.activeElement) {
          this.activeElement.focus();
        }
      }, 1);
    }
  }

  public getPadding(): string {
    if (Util.Device.bIsOfficeAddin) {
      return '0.75rem 0 0.25rem 0';
    } else if (Util.Device.isPhoneLook()) {
      return '0';
    } else if (Util.Device.isTabletLook()) {
      return '1rem 0 0 3.0rem';
    }
    return '3.0rem 0 0 3.0rem';
  }

  private refresh(): void {
    const now: Date = new Date();
    let refreshTime: number = (now.valueOf() - this.lastRefreshTime.valueOf());
    if (this.router.url===Util.RestAPI.getHomeURL()) {
      if (refreshTime > kRefreshInterval) {
        this.lastRefreshTime = now;
        refreshTime = kRefreshInterval;
        if (this.tiles) {
          for (const tile of this.tiles) {
            const tileComp: ListBaseComponent = this.getTileListComponent(tile);
            if (tileComp) {
              tileComp.reloadList();
            }
          }
        }
      }
    }
    setTimeout(() => {
      this.refresh();
    }, refreshTime);
  }

  private translateTileNames(): void {
    if (this.tiles) {
      for (const tile of this.tiles) {
        tile.name = this.localizer.getTranslation(tile.name);
        tile.tooltip = this.localizer.getTranslation(tile.tooltip);
      }
    }
  }

  private setHidden(hidden: boolean): void {
    if (this.hidden!==hidden) {
      this.zone.run(() => {
        this.hidden = hidden;
      });
    }
  }

  private tileAtLocation(locX: number, locY: number): {tile: TileComponent; x: number; w: number } {
    const kids: TileComponent[] = this.tileComponents.toArray();
    const nKids = kids.length;
    for (let i=0; i<nKids; i++) {
      const kid = kids[i];
      if (kid.elRef) {
        const kidEl = kid.elRef.nativeElement;
        const x = kidEl.offsetLeft;
        const y = kidEl.offsetTop;
        const w = kidEl.offsetWidth;
        const h = kidEl.offsetHeight;
        if (Util.pointInRect(locX, locY, x, y, w, h)) {
          return {tile: kid, x, w};
        }
      }
    }
    return null;
  }

  private getDisplayId(tile: Tile): string {
    const tileId: string = tile['name'] === 'hero' ? 'hero' : tile['type'] + (tile['lib'] ? '_' + tile['lib'] : '') + (tile['id'] ? '_' + tile['id'] : '');
    return 'edx_tile_' + tileId;
  }

  public tileDragStarted(tile: TileComponent, element: ElementRef): void {
    const kidIndex = tile.desc.index;
    this.dragTile = tile;
    this.dragTileOriginalIndex = kidIndex;
    this.dragHole = new Tile({index:kidIndex,size:tile.desc.size,name:dragHoleKey, type:'', id:'', lib:''});
    this.tiles.push(this.dragHole);
    tile.desc.index = this.tiles.length - 1;
    this.tiles = TileService.ReorderTiles(this.tiles);
  }

  public tileDragMoved(tile: TileComponent, x: number, y: number, element: ElementRef): void {
    const dragHoleIndex: number = this.tiles.indexOf(this.dragHole);
    const kidOver: { tile: TileComponent; x: number; w: number } = this.tileAtLocation(x, y);
    if (kidOver) {
      let kidOverTileIndex = -1;
      if (this.canMoveFavoritesTile(kidOver, tile) && kidOver.tile.desc.name !== dragHoleKey && kidOver.tile !== this.dragTile) {
        const dragHoleArrayIndex = this.tiles.indexOf(this.dragHole);
        const kidOverArrayIndex = this.tiles.indexOf(kidOver.tile.desc);
        const hitLeftSideOfTile: boolean = x < (kidOver.x + kidOver.w / 2);
        if (hitLeftSideOfTile) {
          kidOverTileIndex = kidOver.tile.desc.index;
          if (dragHoleArrayIndex > kidOverArrayIndex) {
            for (let i = kidOverArrayIndex; i < dragHoleArrayIndex; i++) {
              this.tiles[i].index = this.tiles[i].index + 1;
            }
          } else {
            for (let i = dragHoleArrayIndex; i <= kidOverArrayIndex; i++) {
              this.tiles[i].index = this.tiles[i].index - 1;
            }
          }
        }
      } else if (kidOver.tile === this.dragTile && this.dragHole.index !== this.tiles.length - 1) {
        const kidOverArrayIndex: number = this.tiles.length - 1;
        const dragHoleArrayIndex: number = this.tiles.indexOf(this.dragHole);
        for (let i = dragHoleArrayIndex; i <= kidOverArrayIndex; i++) {
          this.tiles[i].index = this.tiles[i].index - 1;
        }
        kidOverTileIndex = kidOverArrayIndex;
      }
      if (kidOverTileIndex !== -1) {
        this.dragHole.index = kidOverTileIndex;
        this.tiles = TileService.ReorderTiles(this.tiles);
      }
    }
  }

  public tileDragEnded(tile: TileComponent, event: any, element: ElementRef): void {
    if (this.dragHole) {
      const dragHoleIndex = this.tiles.indexOf(this.dragHole);
      this.tiles.splice(dragHoleIndex,1);
      if (event.type==='keydown' && event.which===27) {
        tile.desc.index = this.dragTileOriginalIndex;
        this.tiles = TileService.ReorderTiles(this.tiles);
      } else {
        tile.desc.index = this.dragHole.index;
        this.tiles = this.tileService.tilesChanged();
        this.translateTileNames();
        this.onResize();
      }
      this.dragHole = null;
      this.dragTileOriginalIndex = -1;
      this.dragTile = null;
    }
  }

  public listItemDragStarted(listItem: ListItem, element: ElementRef): void {
    this.dragHole = new Tile({index:this.tiles.length, size:TileSize.oneXone, name:dragHoleKey, type:'', id:'', lib:''});
    this.tiles.push(this.dragHole);
    this.tiles = TileService.ReorderTiles(this.tiles);
  }

  public listItemDragMoved(listItem: ListItem, x: number, y: number, element: ElementRef): void {
    this.tileDragMoved(null, x, y, null);
  }

  public listItemDragEnded(listItem: ListItem, event: any, element: ElementRef): void {
    const tileAtLocation = this.tileAtLocation(event.x,event.y);
    if (!Util.isExternalLib(listItem.lib) && Util.Transforms.isFavoriteFolder(tileAtLocation?.tile.desc.id)) {
      const name: string = tileAtLocation.tile.desc.name;
      const notifyTitle: string = this.localizer.getTranslation('FOLDER_ACTIONS.ADDED_TO_SINGLE', [name]);
      this.favoriteService.addToFavorites(listItem).subscribe((response) => {
        this.refreshTile(tileAtLocation.tile.desc);
        Util.Notify.success(notifyTitle);
      }, error => {
        Util.Notify.warning(listItem['DOCNAME'], error);
      });
      const dragHoleIndex = this.tiles.indexOf(this.dragHole);
      this.tiles.splice(dragHoleIndex,1);
      this.dragHole = null;
    } else if (this.dragHole) {
      const dragHoleIndex = this.tiles.indexOf(this.dragHole);
      this.tiles.splice(dragHoleIndex,1);
      if (event.type==='keydown' && event.which===27) {
        this.tiles = TileService.ReorderTiles(this.tiles);
      } else if (!Util.Transforms.isFavoriteFolder(tileAtLocation?.tile.desc.id)) {
        this.createTile(listItem, dragHoleIndex);
      }
      this.dragHole = null;
    }
  }

  public createTile(listItem: ListItem, atIndex: number): void {
    if (atIndex === -1) {
      atIndex = Util.RestAPI.getNewTileIndex(this.tiles.find((t) => t.name === 'hero'), this.tiles.find((t) => t.id === 'recentedits'));
      atIndex = atIndex!==-1 ? atIndex : this.tiles.length;
    }
    const isFilePlan: boolean = listItem.type === 'fileplans';
    const docNum: string = isFilePlan ? listItem['DOCNUM'] : listItem['docNumber'];
    let name: string;
    if (listItem.type === 'activities' && !isNaN(parseInt(listItem['ACTIVITY_TYPE']))) {
      name = this.localizer.getTranslation('HISTORY_ACTIONS.' + parseInt(listItem['ACTIVITY_TYPE']));
    } else {
      name = listItem['DOCNAME'];
    }
    const tile = new Tile({
      name,
      type: listItem.type,
      id: isFilePlan ? decodeURIComponent(listItem.id) : listItem.id,
      lib: listItem.lib,
      size: 1,
      index: atIndex,
      imgPath: listItem['imgPath'],
      tooltip: listItem['tooltip'] || name,
      docNumber: docNum,
      IS_SHARED: listItem['IS_SHARED'] || '',
      PD_ACTIVE_STATUS: listItem['PD_ACTIVE_STATUS'] || ''
    });
    this.tiles = this.tileService.insertTile(tile);
    this.translateTileNames();
    this.onResize();
  }

  public tileChangedSize(tile: TileComponent): void {
    this.tileService.tilesChanged();
  }

  public tileOpened(tileComp: TileComponent): void {
    this.selectedTile = this.getVisibleTiles().find(t => t.id===tileComp.desc.id && t.type===tileComp.desc.type && t.lib===tileComp.desc.lib);
  }

  public reset(): void {
    this.clearTiles();
    this.tileService.reset();
    this.tileService.getTiles().then((tiles) => {
      this.reconnecting = false;
      this.homeLibrary = Util.RestAPI.getPrimaryLibrary();
      this.tiles = tiles;
      this.translateTileNames();
      this.onResize();
      this.tilesLoaded = true;
    });
  }

  public reconnect(): void {
    this.reconnecting = true;
    this.clearTiles();
  }

  public clearTiles(): void {
    this.tiles = [];
    this.tilesLoaded = false;
  }

  public getVisibleTiles(): Tile[] {
    const list: Tile[] = [];
    if (this.tiles) {
      for (const tile of this.tiles) {
        if (tile.index !== -1 && (!this.isOfficeAddin || tile.type !== 'searches')) {
          list.push(tile);
        }
      }
    }
    return list;
  }

  public isFirstTile(tile: Tile) {
    if (!!tile && tile.index === 0) {
      return true;
    }
    return false;
  }

  public getTiles(): Tile[] {
    return this.tiles;
  }

  public getDefaultTiles(): Tile[] {
    return this.tileService.getDefaultTiles();
  }

  public setTiles(tiles: Tile[]): void {
    this.tiles = TileService.ReorderTiles(tiles);
    this.tiles = this.tileService.tilesChanged();
    this.onResize();
  }

  public shareChanged(item: any): void {
    const tile: Tile = this.tiles.find(t => t.id===item.id && t.lib===item.lib && t.type===item.type);
    if (!!tile && tile.IS_SHARED !== item.IS_SHARED) {
      tile.IS_SHARED = item.IS_SHARED;
      this.setTiles(this.tiles);
    }
  }

  public refreshHero(): void {
    const kids: TileComponent[] = this.tileComponents ? this.tileComponents.toArray() : null;
    const hero: TileComponent = kids ? kids.find(t => t.desc.name==='hero') : null;
    if (hero) {
      hero.refresh();
    }
  }

  public refreshDownloads(imports: boolean): void {
    const kids: TileComponent[] = this.tileComponents ? this.tileComponents.toArray() : null;
    const key = imports ? 'imports' : 'downloads';
    const tileComponent: TileComponent = kids ? kids.find(t => t.desc.id===key && t.desc.type==='folders') : null;
    if (!!tileComponent) {
      tileComponent.refresh();
    }
  }

  public refreshTile(tile: Tile): void {
    const kids: TileComponent[] = this.tileComponents ? this.tileComponents.toArray() : null;
    const tileComponent: TileComponent =  kids ? kids.find(t => t.desc.id===tile.id && t.desc.lib===tile.lib && t.desc.type===tile.type) : null;
    if (tileComponent) {
      tileComponent.refresh();
    }
  }

  public getTileListComponent(tile: Tile): ListBaseComponent {
    if (tile && tile.name!=='hero') {
      const kids: TileComponent[] = this.tileComponents ? this.tileComponents.toArray() : null;
      const tileComp: TileComponent = kids ? kids.find(t => t.desc.id===tile.id && t.desc.type===tile.type) : null;
      if (tileComp && tileComp.listTile) {
        return tileComp.listTile;
      }
    }
    return null;
  }

  public listItemChanged(data: any): void {
    const kids: TileComponent[] = this.tileComponents ? this.tileComponents.toArray() : null;
    if (!!kids) {
      const recentEdits = kids.find(t => t.desc.id === 'recentedits');
      const theRest = kids.filter(t => t.desc.id !== 'recentedits');
      if (!!recentEdits) {
        recentEdits.refresh();
      }
      if (!!theRest) {
        theRest.forEach(t => t.listItemChanged(data));
      }
    }
  }

  public isHidden(): boolean {
    return this.hidden;
  }

  // **** Drag Target
  public setDragOver(dragover: boolean): void {
    this.dragover = dragover;
  }

  public dropOK(): boolean {
    return this.dragover;
  }

  public dropHandled(): void {
    Util.RestAPI.showDropBanner(null);
    this.dragover = false;  // another tile handled the drop so we ignore the drop
  }

  private canMoveFavoritesTile(kidOver: any, tile: any): boolean {
    return !Util.Transforms.isFavoriteFolder(kidOver.tile?.desc.id) || this.tiles.indexOf(tile?.desc) !== -1;
  }
}
