import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuService, MenuId, MenuDef } from '../services/menu.service';
import { LocalizeService } from '../services/localize.service';
import { ListService } from '../services/list.service';
import { MenuItem } from '../models/menu-item';
import { CommandHandler } from '../models/command-handler';
import { ListData } from '../models/base';
import { TabItem, TabReceiver } from '../models/tab-item';
import { AppComponent } from '../app.component';
import { Util } from '../utils/utils.module';
import { KeyCommand, ShortcutsService } from '../services/shortcuts.service';
import { TabSelectorComponent } from '../widgets/tab-selector.component';

@Component({
  selector: 'edx-search-menu',
  styleUrls: ['menu.component.scss', 'tab-selector.component.scss', 'search-menu.component.scss'],
  templateUrl: 'search-menu-component.html'
})

export class SearchMenuComponent implements OnInit, TabReceiver, OnDestroy {
  @Input() callback: CommandHandler;
  @Input() app: AppComponent;
  @Input() adjust? = false;
  @ViewChild('tabSelector') tabSelector: TabSelectorComponent;
  public menuID: number = MenuId.MENU_SEARCH;
  public mobile: boolean = Util.Device.isMobile();
  public isChrome: boolean = Util.Device.bIsChrome;
  public isOpen = false;
  public tabId = 'recent';
  protected menuDef: MenuDef = null;
  protected isOpening = false;
  protected isClosing = false;
  protected allowChecks = false;
  protected drawToRight = true;
  protected drawUp = false;
  protected buttonIcon = '';
  protected buttonIconSize = 0;
  protected nEnabledItems = 0;
  protected items: MenuItem[] = [];
  protected moreSearches: any[];
  protected savedSearchesData: ListData = { set: {}, list: [] };
  protected libraries: any = [];
  protected savedSearchesParam = 'recent';
  protected loadingSearches = false;
  private tabList: TabItem[] = [
    { title: 'GLOBAL_SEARCHSCOPE.RECENT', id: 'recent' },
    { title: 'GLOBAL_SEARCHSCOPE.SAVED', id: 'saved' }
  ];
  private stopClosing = false;
  private shortcutsSubscription: Subscription;
  public altLabels: any;

  constructor(public localizer: LocalizeService, protected menuService: MenuService, protected cdr: ChangeDetectorRef, protected listService: ListService, protected shortcutsService: ShortcutsService) {
    this.shortcutsSubscription = shortcutsService.commands.subscribe(c => this.handleCommand(c));
    this.altLabels = {
      collapsed: this.localizer.getTranslation('ALT_TEXT.COLLAPSED'),
      expanded: this.localizer.getTranslation('ALT_TEXT.EXPANDED')
    };
  }

  handleCommand(command: KeyCommand) {
    let elementId: string;
    let isClassElement: boolean;
    switch (command.name) {
      case 'search-profile':
        this.doCommand('profilesearch');
        elementId = '.edx_select';
        isClassElement = true;
        break;
      case 'search-advanced':
        this.doCommand('advancedsearch');
        elementId = '$edx_advsearch_exact_phrase';
        break;
      case 'search-recent':
      case 'search-saved':
        if (!this.isOpen) {
          this.toggleMenuOpen(command.ev);
        }
        setTimeout(() => {
          if (!!this.tabSelector) {
            this.tabSelector.selectTabById(command.name.replace('search-', ''));
          }
        }, 0);
        break;
    }
    setTimeout(() => {
      if (elementId && isClassElement) {
        document.querySelector<HTMLInputElement>(elementId)?.focus();
      } else if (elementId) {
        (document.getElementById(elementId) as HTMLElement)?.focus();
      }
    }, 600);
  }

  ngOnInit(): void {
    this.menuDef = this.menuService.getMenu(this.menuID);
    this.buttonIcon = 'assets/images/caret_down24_li.svg';
    if (this.menuDef.buttonIconSize) {
      this.buttonIconSize = this.menuDef.buttonIconSize;
    }
    this.allowChecks = this.menuDef.allowChecks;
    this.drawToRight = this.menuDef.drawToRight;
    this.drawUp = this.menuDef.drawUp;
    if (this.mobile) {
      this.tabList.splice(0,0, { title: 'TOOLTIP.OPTIONS', id: 'options' });
      this.tabId = 'options';
    }
  }

  ngOnDestroy(): void {
    if (this.shortcutsSubscription) {
      this.shortcutsSubscription.unsubscribe();
    }
  }

  protected initMenuItems(): void {
    this.items = [];
    const searchScope: string = Util.RestAPI.getSearchScope();
    for (const itemDef of this.menuDef.items) {
      const item: MenuItem = new MenuItem(itemDef);
      item.name = this.localizer.getTranslation(item.name, item.nameargs);
      if (this.callback.checkCommand) {
        if (searchScope) {
          item.checked = item.cmd === searchScope;
        }
        if (item.checked) {
          this.callback.checkCommand(item.cmd, item.checked);
        }
      }
      this.items.push(item);
    }
    // change css .libraries-list height if adding more searches
    this.moreSearches = [{ id: 'profilesearch', isLink: true, resource: this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.PROFILE_SEARCH') + '...' },
                         { id: 'advancedsearch', isLink: true, resource: this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.ADVANCED_SEARCH') + '...' }/*,
                         { id: "activitysearch", isLink: true, resource: this.localizer.getTranslation("GLOBAL_SEARCHSCOPE.ACTIVITY_SEARCH") + "..." }*/
                        ];
  }

  public loadSavedSearches(): void {
    this.loadingSearches = true;
    this.savedSearchesData.list = [];
    this.savedSearchesData.set = {};
    const queryPageSize: string = this.savedSearchesParam === 'recent' ? '&max=25&start=0' : '';
    Util.RestAPI.get('/searches', this.savedSearchesParam, 'descending=SYSTEM_ID' + queryPageSize).subscribe((listData: ListData) => {
      this.savedSearchesData = listData;
      this.loadingSearches = false;
    }, err => {
      this.loadingSearches = false;
    });
  }

  protected execute(itemIndex: number, event: Event): void {
    if (this.callback.checkCommand) {
      for (const i of this.items) {
        const index = this.items.indexOf(i);
        if (index === itemIndex) {
          this.items[itemIndex].checked = true;
        } else {
          this.items[index].checked = false;
        }
      }
      this.callback.checkCommand(this.items[itemIndex].cmd, this.items[itemIndex].checked);
    }
  }

  protected executeSearch(item: any, event: Event): void {
    if (this.listService) {
      this.removeMenu();
      // give chance for mobile to close the form on mobile
      setTimeout(() => {
        this.listService.openItem(item);
      }, Util.kPopupDismissMS);
    }
  }

  public doCommand(cmd: string, item?: any): void {
    if (cmd === 'libraries' && !!item) {
      if (!item.checked || this.libraries.filter(libraryitem => libraryitem.checked).length > 1) {
        item.checked = item.checked ? false : true;
        Util.RestAPI.setSelectedLibraries(item);
      }
    } else {
      this.removeMenu();
      // give chance for mobile to close the form
      setTimeout(() => {
        this.app.doCommand(cmd);
      }, Util.kPopupDismissMS);
    }
  }

  public toggleMenuOpen(event: Event): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.libraries = Util.RestAPI.getLibraries();
      if (!!this.libraries) {
        const searchLibs: any[] = Util.RestAPI.getSelectedLibraries();
        for (const lib of this.libraries) {
          if (lib.DISABLED === 'N') {
            const searchIndex = searchLibs.findIndex(l => l.LIBRARY_NAME===lib.LIBRARY_NAME);
            lib.checked = searchIndex >= 0;
          }
        }
      }
      this.initMenuItems();
      this.loadSavedSearches();
      this.isOpening = true;
      this.isClosing = false;
    } else {
      this.isClosing = true;
    }
    setTimeout(() => {
      const recent = document.getElementById('edx_search_menu') as HTMLElement;
      if (event instanceof KeyboardEvent && !!recent) {
        recent.focus();
      }
    }, 100);
    event.stopPropagation();
  }


  protected openMenu(): void {
    this.enableMenuItems();
    if (this.nEnabledItems) {
      this.isOpen = true;
      this.isOpening = true;
    }
  }

  public closeMenu(immediate: boolean): void {
    if (immediate) {
      this.removeMenu();
    } else {
      this.isClosing = true;
    }
  }

  public enableMenuItems(): void {
    this.nEnabledItems = 0;
    for (const item of this.items) {
      if (this.callback.commandEnabled) {
        item.enabled = this.callback.commandEnabled(item.cmd);
        if (item.enabled) {
          ++this.nEnabledItems;
        }
      } else {
        ++this.nEnabledItems;
      }
    }
  }

  protected removeMenu(): void {
    this.isOpen = false;
    this.isOpening = false;
    this.isClosing = false;
  }

  private transitionComplete(): void {
    if (this.isClosing) {
      this.removeMenu();
    }
    this.isOpening = false;
    this.isClosing = false;
  }

  protected overlayClick(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.closeMenu(false);
  }

  protected clearRecent(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.loadingSearches = true;
    Util.RestAPI.delete('searches/recent').subscribe(response => {
      this.loadingSearches = false;
      this.savedSearchesData.list = [];
      this.savedSearchesData.set = {};
    }, e => {
      this.loadingSearches = false;
      Util.RestAPI.handleError(e);
    });
  }

  public tabSelected(id: string): void {
    this.tabId = id;
    if (this.tabId === 'recent' || this.tabId === 'saved') {
      if (this.tabId === 'recent') {
        this.savedSearchesParam = this.tabId;
      } else {
        this.savedSearchesParam = null;
      }
      this.loadSavedSearches();
    }
  }

  public tabEnabled(id: string): boolean {
    return true;
  }

  private onTab(event: KeyboardEvent, index: number) {
    this.stopClosing = event.shiftKey;
    if (index === this.libraries.length - 1 && !this.stopClosing) {
      this.closeMenu(false);
    }
  }

  private focusOnMenuItem(items: any[], index: number) {
    if (!!items[index]) {
      const id = this.getMenuId(items[index]);
      const menuElement = document.getElementById(id);
      if (!!menuElement) {
        menuElement.focus();
      }
    }
  }

  private getMenuId(item: any): string {
    return 'edx_menu_' + (!!item.cmd ? item.cmd : (!!item.id && item.type !== 'searches' ? item.id : (item.LIBRARY_NAME ? item.LIBRARY_NAME : item.DESCRIPTION)));
  }

  public getNextIndex(items: any[], currentIndex: number, shift: number): number {
    currentIndex += shift;
    while (currentIndex < items.length && currentIndex >= 0) {
      if (!!items[currentIndex]) {
        return currentIndex;
      }
      currentIndex += shift;
    }
    return -1;
  }

  private onKeyUp(items: any[], event: KeyboardEvent, index: number) {
    let nextIndex;
    switch (event.key) {
      case 'ArrowUp':
        nextIndex = this.getNextIndex(items, index, -1);
        break;
      case 'ArrowDown':
        nextIndex = this.getNextIndex(items, index, 1);
    }
    if (nextIndex !== -1) {
      this.focusOnMenuItem(items, nextIndex);
    }
  }

  public shiftFocusToSavedTab(event: Event) {
    const savedTab = document.getElementById('Saved');
    if (!!savedTab) {
      savedTab.focus();
      event.preventDefault();
    }
  }

  public focusOnContent(): void {
    const savedTab = document.getElementById('Saved');
    const recentTab = document.getElementById('Recent');
    if (this.tabId === 'recent') {
      const clearAllButton = document.getElementById('edx_clearall');
      if (!!clearAllButton) {
        clearAllButton.focus();
      }
    } else  {
      const firstSavedSearch = document.getElementById(this.getMenuId(this.savedSearchesData.list[0]));
      if (!!firstSavedSearch) {
        firstSavedSearch.focus();
      }
    }
  }

  public focusOnSelectedTab(): void {
    const element = document.getElementsByClassName('tabitem selected')[0] as HTMLElement;
    const content = document.getElementsByClassName('search-content')[0] as HTMLElement;
    if (!!element && document.activeElement === content) {
      element.focus();
    }
  }

}
