import { Component, Input } from '@angular/core';
import { LocalizeService } from '../services/localize.service';
import { Util, UserInterface } from '../utils/utils.module';
import { ListItem } from '../models/list-item';
import { ListData } from '../models/base';
import { ListService } from '../services/list.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'edx-hero-tile',
  styleUrls: ['hero-tile-component.scss'],
  template: `
  <div class="main">
    <div *ngIf="ui !== 0">
      <edx-refresher *ngIf="ui==3 || ui==5" id="edx-hero-refresh" (refresh)="refresh()" [name]="reloadStr"></edx-refresher>
      <div class="header welcome">{{welcomeHeader}}</div>
      <div class="header user-name" title="{{userName}}">{{userName}}</div>
    </div>
    <h1 *ngIf="ui == 0" class="header user-name" title="{{welcomeHeader}} {{userName}}">
      {{welcomeHeader}} {{userName}}
    </h1>
    <div>
      <div class="user-info" [ngClass]="{modifier: !showPftaNotification}">
        <div><span>{{userLibraryGuide}} {{userLibrary}}</span></div>
        <div><span>{{userGroupGuide}} {{userGroup}}</span></div>
      </div>
      <div class="recent-item" [ngClass]="{modifier: !showPftaNotification}">
        <h2 *ngIf="recentButton" class="heading" [ngClass]="{modifier: !showPftaNotification}">{{recentButton.header}}</h2>
        <div *ngIf="recentButton" class="iconicbutton" tabindex="0" role="link"
              (click)="doButton(recentButton.cmd, recentButton.desc)"
              (keyup.enter)="doButton(recentButton.cmd, recentButton.desc)"
              (keydown.space)="doButton(recentButton.cmd, recentButton.desc,$event)">
          <img src="{{recentButton.icon}}" alt="{{recentButton.header+' '+iconAltTextFromDesc(recentButton.desc)}}">
          <img *ngIf="displayMiniStatus(this.recentButton.desc)" class="overlaystatusicon"
                src="{{formatStatusIcon(this.recentButton.desc)}}"
                alt="{{formatStatusText(this.recentButton.desc)}}">
          <img *ngIf="displayTopMiniStatus(this.recentButton.desc)" class="overlaystatusicon top"
                src="{{formatTopStatusIcon(this.recentButton.desc)}}"
                alt="{{formatStatusText(this.recentButton.desc)}}">
          <span>{{recentButton.label}}</span>
        </div>
      </div>
      <div class="section-checkedOut-upload">
        <div *ngIf="checkedOutButton" class="section checked-out">
          <h2 class="heading">{{checkedOutButton.header}}</h2>
          <div class="iconicbutton" tabindex="0" (click)="doButton(checkedOutButton.cmd)"
              role="link"
              (keyup.enter)="doButton(checkedOutButton.cmd)"
              (keydown.space)="doButton(checkedOutButton.cmd,undefined,$event)">
            <img src="{{checkedOutButton.icon}}" alt="{{this.localizer.getTranslation('DOC_STATUS.CHECKEDOUT')}}">
            <span>{{checkedOutButton.label}}</span>
          </div>
        </div>
        <div *ngIf="uploadButton" class="section upload" tabindex="0" role="button"
             [aria-label]="uploadButton.header" [ngClass]="{dragover:showDragOver}"
             (click)="doButton(uploadButton.cmd)" (keyup.enter)="doButton(uploadButton.cmd)"
             (keydown.space)="doButton(uploadButton.cmd,undefined,$event)">
          <div class="upload-icon"></div>
          <div class="heading">{{uploadButton.header}}</div>
        </div>
      </div>
      <div *ngIf="showPftaNotification && pftaButton" class="pfta">
        <div class="section pfta">
          <h2 class="heading" title="{{pftaButton.header}}">{{pftaButton.header}}</h2>
          <div class="iconicbutton">
            <img src="{{pftaButton.icon}}" alt="{{pftaButton.label}}">
            <span>{{pftaButton.label}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  `
})
export class HeroTileComponent {
  private app: AppComponent;
  public ui: UserInterface = Util.Device.ui;
  public reloadStr: string;
  public welcomeHeader: string;
  public userName: string;
  public userID: string;
  public userLibrary: string;
  public userGroup: string;
  public userIDGuide: string;
  public userLibraryGuide: string;
  public userGroupGuide: string;
  public recentButton: HeroButton;
  public checkedOutButton: HeroButton;
  public uploadButton: HeroButton;
  public pftaButton: HeroButton;
  public showPftaNotification = false;
  @Input() showDragOver = false;

  constructor(protected listService: ListService, private localizer: LocalizeService) {
    this.app = Util.RestAPI.getAppComponent();
    this.userName = Util.RestAPI.getUserFullName();
    this.userID = Util.RestAPI.getUserID();
    this.userLibrary = Util.RestAPI.getPrimaryLibrary();
    this.userGroup = !!Util.RestAPI.getLoginReply()['GROUP'] ? Util.RestAPI.getLoginReply()['GROUP'] : Util.RestAPI.getLoginReply()['GROUPS'] && Util.RestAPI.getLoginReply()['GROUPS'].length ? Util.RestAPI.getLoginReply()['GROUPS'][0]['GROUP_ID'] : '';
    this.welcomeHeader = this.localizer.getTranslation('HERO.WELCOME');
    this.userIDGuide = this.localizer.getTranslation('FORMS.LOCAL.AUTHENTICATE.USERID');
    this.userLibraryGuide = this.localizer.getTranslation('NAVBAR.PRIMARY');
    this.userGroupGuide = this.localizer.getTranslation('NAVBAR.PRIMARY_GROUP');
    this.reloadStr = this.localizer.getTranslation('HERO.RELOAD_TILES');
    this.reloadList();
    if (Util.RestAPI.canUserCreateDocument()) {
      this.uploadButton = {
        label: '',
        cmd: 'upload',
        header: this.localizer.getTranslation('HERO.SECTIONS.UPLOAD_FILES')
      };
    }
    if (this.ui === 0 && Util.RestAPI.siteConfigurations.showPftaNotification) {
      this.showPftaNotification = true;
      this.updatePftaNotification();
      Util.RestAPI.pftaNotificationEmitter.on('update', () => {
        this.updatePftaNotification();
      });
    }
  }

  public reloadList(): void {
    //fetch the last edited DOCUMENT only
    Util.RestAPI.get('folders', 'recentedits', 'max=1&descending=%RECENTACTIVITYDATE&filter=ITEM_TYPE=D and STORAGE=A,T,K and STATUS=0%7C3').subscribe((listData: ListData) => {
      const desc: ListItem = listData.list && listData.list.length ? listData.list[0] : null;
      if (!!desc) {
        this.recentButton = {
          label: desc.DOCNAME,
          icon: Util.Transforms.iconUrlFromDesc(desc),
          cmd: 'lastopened',
          value: +desc.id,
          desc,
          header: this.localizer.getTranslation('HERO.SECTIONS.RECENT')
        };
      }
    }, err => { });
    Util.RestAPI.get('folders', 'checkedout', 'start=0&max=0&descending=LAST_EDIT_DATE').subscribe((listData: ListData) => {
      this.checkedOutButton = {
        label: listData.set.total > 0 ? this.localizer.getTranslation('HERO.SECTIONS.ITEMS_COUNT', [listData.set.total]) : this.localizer.getTranslation('HERO.SECTIONS.NO_ITEMS'),
        icon: 'assets/images/title_checked_out36.svg',
        cmd: 'checkedout',
        value: listData.set.total,
        header: this.localizer.getTranslation('HERO.SECTIONS.CHECKED_OUT')
      };
    }, err => { });
  }

  private updatePftaNotification(): void {
    const hasPFTA = Util.RestAPI.hasPFTA();
    this.pftaButton = {
      label: hasPFTA ? this.localizer.getTranslation('HERO.SECTIONS.PFTA_CONNECTED')
        : this.localizer.getTranslation('HERO.SECTIONS.PFTA_DISCONNECTED'),
      icon: hasPFTA ? 'assets/images/notification_success.svg'
        : 'assets/images/status_abort.svg',
      header: this.localizer.getTranslation('FORMS.LOCAL.PFTA.PFTA'),
    };
  }

  private refresh(): void {
    Util.RestAPI.reloadTiles();
  }

  private setupButtons(buttons: HeroButton[]) {
    for (const button of buttons) {
      if (button.icon) {
          button.icon = 'assets/images/' + button.icon;
        }
      if (button.label) {
        button.label = this.localizer.getTranslation(button.label);
      }
    }
  }

  private doButton(cmd: string, desc?: ListItem, event?: Event): void {
    if (!!event && event.type === 'keydown') {
      event.preventDefault();
    }
    switch (cmd) {
      case 'lastopened':
        this.openLastEditedItem(desc);
        break;
      case 'checkedout':
        this.app.doCommand('checkedout');
        break;
      case 'upload':
        Util.RestAPI.updateFilesDragDrop(true);
        Util.RestAPI.setLastCmdName(null);
        this.app.doCommand('newdocument');
        break;
    }
  }

  private openLastEditedItem(listItem: ListItem): void {
    if (listItem) {
      if (Util.isContainer(listItem.type)) {
        this.listService.openItem(listItem);
      } else {
        Util.RestAPI.viewOrOpenFilesWithStatusCheck([listItem], undefined, false, null);
      }
    }
  }

  private displayMiniStatus(item: ListItem): boolean {
    return Util.RestAPI.displayMiniStatus(item, null);
  }

  private displayTopMiniStatus(item: ListItem): boolean {
    return Util.RestAPI.displayTopMiniStatus(item, null);
  }

  private formatStatusIcon(item: ListItem): string {
    return Util.RestAPI.formatStatusIcon(item, null);
  }

  private formatTopStatusIcon(item: ListItem): string {
    return Util.RestAPI.formatTopStatusIcon(item, null);
  }

  private iconAltTextFromDesc(item: ListItem): string {
    return Util.Transforms.iconAltTextFromDesc(item);
  }

  private formatStatusText(item: ListItem): string {
    return Util.RestAPI.formatStatusText(item);
  }
}

class HeroButton {
  label: string;
  icon?: string;
  value?: number;
  cmd?: string;
  desc?: ListItem;
  header?: string;
}
