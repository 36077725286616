<ng-template [ngIf]="ui<2 || forceMenuButton">
  <div *ngIf="isMenuShown" aria-haspopup="true" aria-expanded="true" [ngClass]="{menubutton:buttonIconSize>=0,compare: isCompare, menuonly:buttonIconSize<0, open:isOpen, crumby:buttonIconSize==3, mobile:ui>=2, oai:officeAddin}" (click)="toggleMenuOpen($event)" (keyup.enter)="toggleMenuOpen($event)" (keyup.arrowdown)="!isOpen?toggleMenuOpen($event):null" (keyup.arrowup)="isOpen?toggleMenuOpen($event):null" (keydown.space)="toggleMenuOpen($event)" (keydown.shift.tab)="closeMenuOnFocusOut(-2)">
    <img *ngIf="!!buttonIcon" [id]="id" role="menu" [tabindex]="tabIndex" src="{{isOpen ? buttonIconOpen : buttonIcon}}" [ngClass]="{mini:buttonIconSize==1||buttonIconSize==-1, maxi:buttonIconSize==2||buttonIconSize==-2, crumby:buttonIconSize==3}" alt="{{toolTip}}" title="{{toolTip}}" (keyup)="onKeyUp($event)" [attr.aria-label]="(!!ariaLabel ? ariaLabel : toolTip)+' '+(isOpen?altLabels['expanded']:altLabels['collapsed'])">
    <div *ngIf="!buttonIcon" [tabindex]="tabIndex" class="noimg"></div>
  </div>
  <div *ngIf="isOpen" class="menu-overlay" (click)="overlayClick($event)"></div>
  <ul #ul *ngIf="isOpen" role="menu" [ngClass]="{opening:isOpening, closing:isClosing, drawright:drawToRight, allowchecks:allowChecks, drawup:drawUp, mini:buttonIconMini, wide:wide, noselect:noSelect, oai:officeAddin, crumby:buttonIconSize==3}" [style.maxHeight]="ulMaxHeight" (animationend)="transitionComplete()">
    <li *ngFor="let item of items; let i=index" role="none" [ngClass]="{separator:item.separator, edx_hidden:!item.enabled, button:item.button, heading:item.heading}" (keyup)="onKeyUp($event,i)" (keydown.shift.tab)="onKeyUp($event,i)">
      <div class="item-container">
        <div *ngIf="item.checkable" role="none" class="checkmark" [ngClass]="{checked:item.checked, safari:safari||officeAddinDesktopMac}" (click)="toggleCheck(i, $event)" (keyup.enter)="toggleCheck(i, $event)" (keydown.space)="toggleCheck(i, $event)"></div>
        <div *ngIf="!item.button && !item.heading" [attr.role]="item.checkable?'menuitemcheckbox':'menuitem'" [attr.aria-checked]="item.checkable?item.checked:none" class="menu-text" id="{{getMenuId(item)}}" [attr.aria-label]="isChrome?(item.checked?item.name:item.name+' '):item.name" [tabindex]="item.separator ? -1 :tabIndex" (click)="doCommand(i, $event)" (keyup.enter)="doCommand(i, $event)" (keydown.space)="doCommand(i, $event)" (focusout)="closeMenuOnFocusOut(i)" title="{{item.name}}">{{item.name}}</div>
        <button *ngIf="item.button" class="secondary" role="menuitem" id="{{getMenuId(item)}}" title="{{item.name}}" [tabindex]="tabIndex" (click)="doCommand(i, $event)" (keyup.enter)="doCommand(i, $event)" (keydown.space)="doCommand(i, $event)" (focusout)="closeMenuOnFocusOut(i)">{{item.name}}</button>
        <div *ngIf="item.heading" class="headingtext" title="{{item.name}}">{{item.name}}</div>
      </div>
    </li>
  </ul>
</ng-template>
<ng-template [ngIf]="ui>=2 && !forceMenuButton">
  <div *ngIf="menuID==3" class="menubutton" [ngClass]="{mobile:ui>=2, oai:officeAddin}" (click)="toggleMenuOpen($event)" (keyup.enter)="toggleMenuOpen($event)" (keydown.space)="toggleMenuOpen($event)" (keydown.shift.tab)="closeMenuOnFocusOut(-2)">
   <img *ngIf="buttonIcon" [id]="menuId" src="{{isOpen ? buttonIconOpen : buttonIcon}}" [tabindex]="tabIndex" title = "{{toolTip}}" alt="{{toolTip}}" role="menu" [ariaLabel]="menuId==='edx_overflow_menu'?altLabels['overFlowMenu']+' '+(isOpen?altLabels['expanded']:altLabels['collapsed']):''">
  </div>
  <div *ngIf="isOpen" class="menu-overlay mobile" [ngClass]="{opening:isOpening, closing:isClosing, topright:drawTopRight}" (click)="overlayClick($event)"></div>
  <ul #ul *ngIf="isOpen" class="mobile" [ngClass]="{opening:isOpening, closing:isClosing, topright:drawTopRight, cordova:ui>=4, ios:iOS, doaimac:officeAddinDesktopMac, oai:officeAddin}" (animationend)="transitionComplete()">
    <li class="heading">
      <div class="item-container">
        <img src="{{menuIcon}}" [ngClass]="{edx_transparent:!menuIcon}">
        <div class="headingtext">{{menuTitle}}</div>
      </div>
    </li>
    <li *ngFor="let item of items; let i=index" [ngClass]="{edx_hidden:!item.enabled, button:item.button, separator:item.separator}" (keyup)="onKeyUp($event,i)" (keydown.shift.tab)="onKeyUp($event,i)">
      <div *ngIf="!item.separator" class="item-container">
        <div *ngIf="item.checkable" class="checkmark" [ngClass]="{checked:item.checked, safari:safari}" (keyup.enter)="toggleCheck(i, $event)" (keydown.space)="toggleCheck(i, $event)" (click)="toggleCheck(i, $event)"></div>
        <img *ngIf="!item.button && !item.heading && !item.checkable" src="{{getItemIcon(item)}}" alt="item.name" [ngClass]="{edx_transparent:!item.icon}">
        <div *ngIf="!item.button && !item.heading" class="menu-text" [tabindex]="tabIndex" (keydown.space)="doCommand(i, $event)" (keyup.enter)="doCommand(i, $event)" (click)="doCommand(i, $event)" (focusout)="closeMenuOnFocusOut(i)" id="{{getMenuId(item)}}" title="{{item.name}}">{{item.name}}</div>
        <button *ngIf="item.button" class="secondary mobile" [ngClass]="{oai:officeAddin}" (click)="doCommand(i, $event)" id="{{getMenuId(item)}}" title="{{item.name}}">{{item.name}}</button>
        <div *ngIf="item.heading" class="headingtext" title="{{item.name}}">{{item.name}}</div>
      </div>
    </li>
  </ul>
</ng-template>
