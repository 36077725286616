<div *ngIf="ui<2" class="window-modal-admin window-modal" [ngClass]="{web:ui==0, desktop:ui==1}"
  (keyup.enter)="rightHdrBtnClick()" (keyup.escape)="cancelHdrBtnClick()">
  <edx-window-modal-header *ngIf="ui<2" [title]="getName()" [desc]="desc"
    [rightBtn]="showSave?saveStr:showReset?resetStr:null" [showCancel]="showSave"
    (rightHdrBtnClick)="rightHdrBtnClick()" (cancelHdrBtnClick)="cancelHdrBtnClick()"></edx-window-modal-header>
  <ng-template [ngIf]="!showSpinner&&ui<2">
    <div class="tabcontainer">
      <edx-tab-selector #tabSelector [receiver]="this" [tabdefs]="tabList" [disable]="showSave"></edx-tab-selector>
    </div>
    <div class="contentpanel admincontainer">
      <div class="adminlistcontainer">
        <div class="left">
          <div *ngIf="!showFilter">
            <ng-container *ngTemplateOutlet="librarySection"></ng-container>
          </div>
          <edx-filelist-sidebar *ngIf="!showFilter" [receiver]="this" [list]="leftNavList"
            [selectIndex]="leftNavSelectedIndex" [desc]="desc" [disabled]="disabledNav"
            [tabIndex]="0"></edx-filelist-sidebar>
          <edx-filter-sidebar [target]="this"></edx-filter-sidebar>
        </div>
        <div class="right" *ngIf="contentLoaded">
          <edx-action-bar *ngIf="tabId==='LOOKUPS'" #actionbar [menuID]="actionId" [target]="this"></edx-action-bar>
          <div *ngIf="tabId==='LOOKUPS'" class="listcontainer" id="edx_admin_list_container" tabindex="0">
            <edx-list-table #lookupTable [parent]="this" [desc]="lookupDesc" [lookupForm]="lookupForm"
              [lookupKey]="lookupKey" [formType]="lookupFormType" [leadingColums]="lookupLeadingColums"
              [inlineActionMenuId]="6" [inlineActionTarget]="this"></edx-list-table>
          </div>
          <div *ngIf="tabId!=='LOOKUPS'" class="settingwrapper">
            <div class="headerwrapper">
              <div class="settingdiv" [ngClass]="{general:tabId==='GENERAL',footer:tabId==='FOOTER'}">
                <div class="settingcolumn" [ngClass]="{general:tabId==='GENERAL',footer:tabId==='FOOTER'}" *ngFor="let column of contentColumns">
                  <span>{{column}}</span>
                </div>
              </div>
            </div>
            <edx-form-wrapper #formWrapper [kind]="contentFormKind" [desc]="desc" [formData]="formData"
              (okEnabled)="okEnabled($event)" (formDataChanged)="updateFormData($event)"></edx-form-wrapper>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <edx-spinner *ngIf="showSpinner"></edx-spinner>
</div>
<ng-template #librarySection>
  <div class="selwrapper">
    <div class="sellabel">{{forLibStr}}</div>
    <edx-select [id]="'edx_admin_libsel_item'" [ariaLabel]="forLibStr" [items]="librarySelectItems"
      [value]="!!desc?desc.lib:''" (change)="libraryChanged($event)"></edx-select>
  </div>
  <div *ngIf="tabId !== 'LOOKUPS'" class="search-bar" >
    <input #searchGroup class="search-text" placeholder="{{localizer.getTranslation('FORMS.LOOKUPS.SEARCH_BY_WATERMARK',[localizer.getTranslation('SEARCH_SELECTOR.GROUP')])}}" (keyup)="clearSearchWhenEmpty($event)" (keyup.enter)="searchClicked($event)" [(ngModel)]="searchValue" type="text" id="search" name="search"/>
    <div *ngIf="searchValue" role="button" id="edx_search_group_clr_btn" title="{{localizer.getTranslation('REFINE_SEARCH.CLEAR')}}" tabindex="0" class="icon clear" (keyup.enter)="searchClicked($event,true)" (keyup.space)="searchClicked($event,true)" (click)="searchClicked($event,true)"></div>
    <div  role="button" id="edx_search_group_btn" tabindex="0" title="{{localizer.getTranslation('REFINE_SEARCH.SEARCH')}}" class="icon search" (keyup.space)="searchClicked($event)"  (keyup.enter)="searchClicked($event)" (click)="searchClicked($event)"></div>  
  </div>
  <div *ngIf="showInfoMessage && tabId !== 'LOOKUPS'" role="status" class="info-message" tabindex="0" title="{{infoMessage}}" [attr.aria-label]="infoMessage" >{{infoMessage}}</div>
  <edx-spinner *ngIf="isFiltering" [mini]="true"></edx-spinner>
</ng-template>