import { AbstractControl } from '@angular/forms';
import * as microsoftTeams from '@microsoft/teams-js';

import { FormWrapperComponent } from './form-wrapper.component';
import { DynamicFormComponent } from './dynamic-form.component';
import { FormField, SelectItem } from '../models/form-field';
import { Tile } from '../models/tile';
import { ListData, BaseDesc } from '../models/base';
import { SecurityControl } from '../models/security-control';
import { Util } from '../utils/utils.module';
import { LocalizeService } from '../services/localize.service';
import { SettingsService } from '../services/settings.service';
import { ColumnDesc } from '../models/column';
import { ListItem } from '../models/list-item';
import { OOxmlService } from '../services/ooxml.service';
/* eslint-disable prefer-arrow/prefer-arrow-functions */

export const FormScripts: any = {
  _shared: {
    get_versions_or_attachments(isAttachments: boolean, formWrapper: FormWrapperComponent, formData: any): Promise<any[]> {
      let desc: any = formWrapper.desc ? formWrapper.desc : (formWrapper.selections && formWrapper.selections.length ? formWrapper.selections[0] : null);
      if (formData && (!desc || formData.readDesc)) {
        desc = formData.desc;
      }
      if (desc) {
        formWrapper.setLoading(true);
        return new Promise<any[]>((resolve, reject) => {
          Util.RestAPI.get(desc,isAttachments?'attachments':'versions', 'ascending=VERSION_LABEL').subscribe((listData: ListData) => {
            const list: any[] = !!listData && !!listData.list ? listData.list : [];
            formWrapper.setLoading(false);
            resolve(list);
          }, err => {
            formWrapper.setLoading(false);
            Util.RestAPI.handleError(err);
            reject(err);
          });
        });
      } else {
        return Promise.reject('no desc');
      }
    },
    populate_versions_or_attachments(isAttachments: boolean, formWrapper: FormWrapperComponent, removeAllOption: boolean, formData: any, localizer: LocalizeService) {
      const postGet = (list: any[]) => {
        const publishedLabel = localizer.getTranslation('DOC_STATUS.PUBLISHED');
        const readonlyLabel = localizer.getTranslation('DOC_STATUS.READONLY');
        const versionDropdownLabel = (item: any) => {
          const lastEditDate: string = Util.Transforms.formatDate(item.LASTEDITDATE, true);
          const statusId: string = item.STATUS;
          const status: string = (statusId === '20' ? publishedLabel : (statusId === '19' ? readonlyLabel : ''));
          return [item.VERSION_LABEL, lastEditDate, status].filter(n => n).join(' - ');
        };
        const verions: any = [];
        const dynamicForm: DynamicFormComponent = formWrapper.dynamicForm;
        const field: FormField = dynamicForm.getField(isAttachments ? '$edx_attachment' : '$edx_version');
        const nItems: number = list ? list.length : 0;
        let selectionMap: SelectItem[] = field.selectionMap;
        if (removeAllOption || nItems <= 1) {
          selectionMap = selectionMap.filter(i => i.value !== 'all' && i.value !== 'all_separate');
        }
        const publishedVersions: any = [];
        const readonlyVersions: any = [];
        if (nItems) {
          field.selectionMap = [];
          for (let i = nItems - 1; i >= 0; i--) {
            const item = list[i];
            const versionId = item.VERSION_ID;
            const versionLabel: string = item.VERSION_LABEL.toLowerCase();
            if (versionLabel !== 'pr1') {
              if (item.STATUS === '20') {
                publishedVersions.push(versionId);
              } else if (item.STATUS === '19') {
                readonlyVersions.push(versionId);
              }
              const dropdownItemLabel: string = versionDropdownLabel(item);
              selectionMap = selectionMap.filter(k => k.value !== versionId); //Make sure that we dont add same version twice
              selectionMap.push({ value: versionId, display: dropdownItemLabel });
              verions.push(item);
            }
          }
        }
        const theFormData = formData??formWrapper.formData;
        if (!!theFormData && !isAttachments) {
          theFormData['publishedVersions'] = publishedVersions;
          theFormData['readonlyVersions'] = readonlyVersions;
        }
        // force a rerender
        formWrapper.setLoading(true);
        setTimeout(() => {
          field.selectionMap = selectionMap;
          if (!isAttachments) {
            FormScripts._shared.extraVersionItem(formWrapper, localizer, 'C', false);
            FormScripts._shared.extraVersionItem(formWrapper, localizer, 'P', false);
            FormScripts._shared.setToCurrentVersion(formWrapper, true);
          }
          FormScripts.pick_version.toggleVersionChooserNote(formWrapper);
          if (verions.length) {
            formWrapper.scriptData[isAttachments ? 'ATTACHMENTS' : 'VERSIONS'] = verions;
          } else if (isAttachments && !nItems) {
            dynamicForm.setFieldVisibility('$edx_attachment', false);
            FormScripts._shared.remove_specfic_version_or_attachment(false, formWrapper, 'none');
          }
          formWrapper.setLoading(false);
        }, 1);
      };
      if (isAttachments) {
        FormScripts._shared.get_attachments(formWrapper, formData).then(postGet, err => { });
      } else {
        FormScripts._shared.get_versions(formWrapper, formData).then(postGet, err => { });
      }
    },
    remove_specfic_version_or_attachment(isAttachments: boolean, formWrapper: FormWrapperComponent, ver: string) {
      const dynamicForm: DynamicFormComponent = formWrapper.dynamicForm;
      const field: FormField = dynamicForm.getField(isAttachments?'$edx_attachment':'$edx_version');
      const selectionMap: SelectItem[] = field.selectionMap;
      field.selectionMap = selectionMap.filter(i => i.value!==ver);
    },
    get_versions(formWrapper: FormWrapperComponent, formData?: any): Promise<any[]> {
      return FormScripts._shared.get_versions_or_attachments(false, formWrapper, formData);
    },
    get_attachments(formWrapper: FormWrapperComponent, formData?: any): Promise<any[]> {
      return FormScripts._shared.get_versions_or_attachments(true, formWrapper, formData);
    },
    populate_versions(formWrapper: FormWrapperComponent, removeAllOption: boolean, localizer: LocalizeService, formData?: any) {
      return FormScripts._shared.populate_versions_or_attachments(false, formWrapper, removeAllOption, formData, localizer);
    },
    populate_attachments(formWrapper: FormWrapperComponent, removeAllOption: boolean, localizer: LocalizeService, formData?: any) {
      return FormScripts._shared.populate_versions_or_attachments(true, formWrapper, removeAllOption, formData, localizer);
    },
    add_specific_version(formWrapper: FormWrapperComponent, localizer: LocalizeService, ver: string, displayValue: string) {
      const field: FormField = formWrapper.dynamicForm.getField('$edx_version');
      const selectionMap: SelectItem[] = field.selectionMap;
      const selectedIndex = selectionMap.findIndex(item => item.value === ver);
      if (selectedIndex < 0) {
        const currentItem = { value: ver, display: localizer.getTranslation(displayValue) };
        selectionMap.splice(0, 0, currentItem);
      }
    },
    add_all_versions(formWrapper: FormWrapperComponent, localizer: LocalizeService) {
      FormScripts._shared.add_specific_version(formWrapper, localizer, 'all', 'FORMS.LOCAL.DOWNLOAD.ALL_VERSIONS');
    },
    remove_specifc_item_in_combobox(fieldName: string, formWrapper: FormWrapperComponent, localizer: LocalizeService, itemValue: string) {
      const field: FormField = formWrapper.dynamicForm.getField(fieldName);
      const selectionMap = field.selectionMap;
      if (selectionMap) {
        field.selectionMap = selectionMap.filter(i => i.value !== itemValue);
      }
    },
    show_or_hide_attachments_dropdown(formWrapper: FormWrapperComponent) {
      const attachmentField: FormField = formWrapper.dynamicForm.getField('$edx_attachment');
      const selectionMap = attachmentField.selectionMap;
      if (!!selectionMap && (selectionMap.length === 0) || (selectionMap.every(item => isNaN(+item.value)) )) {
        formWrapper.dynamicForm.setFieldVisibility('$edx_attachment', false);
      } else {
        formWrapper.dynamicForm.setFieldVisibility('$edx_attachment', true);
      }
    },
    remove_all_versions(formWrapper: FormWrapperComponent) {
      FormScripts._shared.remove_specfic_version_or_attachment(false, formWrapper, 'all');
    },
    remove_all_separate_versions(formWrapper: FormWrapperComponent) {
      FormScripts._shared.remove_specfic_version_or_attachment(false, formWrapper, 'all_separate');
    },
    remove_published_version(formWrapper: FormWrapperComponent) {
      FormScripts._shared.remove_specfic_version_or_attachment(false, formWrapper, 'P');
    },
    remove_current_version(formWrapper: FormWrapperComponent) {
      FormScripts._shared.remove_specfic_version_or_attachment(false, formWrapper, 'C');
    },
    remove_all_attachments(formWrapper: FormWrapperComponent) {
      FormScripts._shared.remove_specfic_version_or_attachment(true, formWrapper, 'all');
    },
    remove_all_separate_attachments(formWrapper: FormWrapperComponent) {
      FormScripts._shared.remove_specfic_version_or_attachment(true, formWrapper, 'all_separate');
    },
    check_remove_folder_pickers_for_export_checkout(formWrapper: FormWrapperComponent, formTemplate: any, bUseTempPath: boolean) {
      let pickerName: string;
      if (formTemplate && formTemplate.defs) {
        const nDefs: number = formTemplate.defs.length;
        for (let i=nDefs-1; i>=0; i--) {
          if (formTemplate.defs[i].lookup==='$edx_folder_picker') {
            if (Util.Device.bIsElectron || Util.RestAPI.hasPFTA()) {
              pickerName = formTemplate.defs[i].name;
            } else {
              formTemplate.defs.splice(i,1);
            }
          }
        }
      }
      if ((Util.Device.bIsElectron || Util.RestAPI.hasPFTA()) && formWrapper && pickerName) {
        setTimeout(() => {
          if (formWrapper.dynamicForm) {
            formWrapper.dynamicForm.updateControlValue(pickerName, bUseTempPath ? Util.RestAPI.getTempPath() : Util.RestAPI.getDownloadsPath(), true);
            formWrapper.dynamicForm.setFieldEditable(pickerName,true);
          }
          if (!Util.Device.bIsElectron && Util.RestAPI.pftaVersion()<0x00160600) {
            formWrapper.dynamicForm.setFieldEditable(pickerName, false);
          }
        }, 1);
      }
    },
    check_remove_email_pickers(formWrapper: FormWrapperComponent, formTemplate: any) {
      if (!Util.Device.bIsCordova && formTemplate && formTemplate.defs) {
        const nDefs: number = formTemplate.defs.length;
        for (let i = nDefs - 1; i >= 0; i--) {
          if (formTemplate.defs[i].lookup === '$edx_email_picker') {
            formTemplate.defs[i].lookup = null;
          }
        }
      }
    },
    extraVersionItem(formWrapper: FormWrapperComponent, localizer: LocalizeService, itemValue: string, shouldContainsItem: boolean) {
      if (shouldContainsItem !== null) {
        const field: FormField = formWrapper.dynamicForm.getField('$edx_version');
        const selectionMap = field.selectionMap;
        const itemIndex = selectionMap.findIndex(item => item.value === itemValue);
        if (!!shouldContainsItem) {
          if (itemIndex < 0) {
            const displayId = itemValue === 'C' ? 'FORMS.LOCAL.DOWNLOAD.CURRENT' : 'FORMS.LOCAL.DOWNLOAD.PUBLISHED';
            const currentItem = {
              display: localizer.getTranslation(displayId),
              value: itemValue
            };
            selectionMap.splice(0, 0, currentItem);
          }
        } else if ((itemIndex >= 0)) {
          selectionMap.splice(itemIndex, 1);
        }
      }
    },
    setToCurrentVersion(formWrapper: FormWrapperComponent, isForced: boolean) {
      if (!(!!formWrapper && !!formWrapper.selections && formWrapper.selections.length > 0 && !!formWrapper.selections[0].VERSION)) {
        const field: FormField = formWrapper.dynamicForm.getField('$edx_version');
        const selectionMap = field.selectionMap;
        const selectedValue = formWrapper.dynamicForm.getControl(field.name).value;
        const selectedIndex = selectionMap.findIndex(item => item.value === selectedValue);
        if (isForced || selectedIndex < 0) {
          const currentVersionId = selectionMap.filter(item => item.value > 0)[0]?.value;
          formWrapper.dynamicForm.getControl(field.name).setValue(currentVersionId);
        }
      }
    },
    adjustVersionOnTypeChange(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      const selectionMap = field.selectionMap;
      const option: any = selectionMap?.find(selections => selections.value === control.value);
      if (option) {
        if (formWrapper.formData['DOCUMENTS'].length === 1) {
          const shouldContainsItem = ['linkonly', 'all', 'linkonlyzip', 'allzip', 'url'].includes(option.value);
          const hasPublished = (formWrapper.formData['publishedVersions']?.length > 0) && shouldContainsItem;
          FormScripts._shared.extraVersionItem(formWrapper, localizer, 'P', hasPublished);
          FormScripts._shared.extraVersionItem(formWrapper, localizer, 'C', shouldContainsItem);
          FormScripts._shared.setToCurrentVersion(formWrapper, false);
        }
      }
    }
  },
  export: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      let versions = '';
      let versionIDs = '';
      let attachments = '';
      const docList: any[] = formData ? formData['DOCUMENTS'] : null;
      const nDocList: number = docList && docList.length && docList[0]['VERSION_LABEL'] ? docList.length : 0;
      const isExternalContainer: boolean = Util.isExternalLib(docList[0]['lib']);
      let isOnlyAttachments = false;
      let isOnlyVersions = false;
      for (let i=0; i<nDocList; i++) {
        if (docList[i]['VERSION']==='0') {
          attachments += docList[i]['VERSION_LABEL'];
          if (i<nDocList-1) {
            attachments += ';';
          }
          isOnlyAttachments = true;
        } else {
          versions += docList[i]['VERSION_LABEL'];
          if (i<nDocList-1) {
            versions += ';';
          }
          isOnlyVersions = true;
        }
        versionIDs += docList[i]['VERSION_ID'];
        if (i<nDocList-1) {
          versionIDs += ';';
        }
      }
      if (!!versionIDs) {
        formData['$edx_version_ID'] = versionIDs;
      }
      const nDefs: number = formTemplate.defs.length;
      for (let i=0; i<nDefs; i++) {
        if (formTemplate.defs[i].name==='$edx_version' && versions.length) {
          formTemplate.defs[i]['value'] = versions;
          formTemplate.defs[i].flags = formTemplate.defs[i].flags | 0x02000000; // disable versions selector
        } else if (formTemplate.defs[i].name==='$edx_download_type' && Util.Device.isMobileDevie()) {
          formTemplate.defs[i].flags = 0x01080000;
        } else if (formTemplate.defs[i].name==='$edx_attachment' && isOnlyAttachments) {
          formTemplate.defs[i]['value'] = attachments;
          formTemplate.defs[i].flags = formTemplate.defs[i].flags | 0x02000000; // disable attachment selector
        }
      }
      FormScripts._shared.check_remove_folder_pickers_for_export_checkout(formWrapper, formTemplate, false);
      setTimeout(() => {
        if (isExternalContainer) {
          // Remove all selections for external container files - simply download the selected items
          formWrapper.dynamicForm.setFieldVisibility('$edx_download_type', false);
          formWrapper.dynamicForm.setFieldVisibility('$edx_version', false);
          formWrapper.dynamicForm.setFieldVisibility('$edx_attachment', false);
          formWrapper.dynamicForm.setFieldVisibility('$edx_download_location', false);
        } else {
          if (!formWrapper.selections || formWrapper.selections.length===1) {
            if (!isOnlyAttachments) {
              FormScripts._shared.populate_versions(formWrapper, Util.Device.isMobileDevie(), localizer);
              FormScripts._shared.populate_attachments(formWrapper, Util.Device.isMobileDevie(), localizer);
            }
          } else {
            isOnlyVersions = true;
            if (Util.Device.isMobileDevie()) {
              FormScripts._shared.remove_all_versions(formWrapper, formTemplate);
              FormScripts._shared.remove_all_attachments(formWrapper, formTemplate);
            }
            FormScripts._shared.remove_all_separate_versions(formWrapper, formTemplate);
            FormScripts._shared.remove_all_separate_attachments(formWrapper, formTemplate);
          }
          if (isOnlyAttachments) {
            formWrapper.dynamicForm.setFieldVisibility('$edx_version', false);
            formWrapper.dynamicForm.updateControlValue('$edx_version','none');
          }
          if (isOnlyVersions) {
            formWrapper.dynamicForm.setFieldVisibility('$edx_attachment', false);
            FormScripts._shared.remove_specfic_version_or_attachment(false, formWrapper, 'none');
          }
        }
      }, 1);
    },
    choice_version(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      const attachValue = formWrapper.dynamicForm.getControlValue('$edx_attachment');
      formWrapper.dynamicForm.updateControlValue('$edx_force_invalid', (control.value !== 'none' || attachValue !== 'none') ? '1' : undefined, true);
      formWrapper.scriptData['$edx_version_ID'] = 'none';
      if (!!formWrapper.formDialog.doCommand) {
        formWrapper.formDialog.doCommand('disableok', control.value === 'none' && attachValue === 'none');
      }
    },
    choice_attachment(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      const versionValue = formWrapper.dynamicForm.getControlValue('$edx_version');
      formWrapper.dynamicForm.updateControlValue('$edx_force_invalid', (control.value !== 'none' || versionValue !== 'none') ? '1' : undefined, true);
      formWrapper.scriptData['$edx_version_ID'] = 'none';
      if (!!formWrapper.formDialog.doCommand) {
        formWrapper.formDialog.doCommand('disableok', control.value === 'none' && versionValue === 'none');
      }
    },
    download_type_changed(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      FormScripts._shared.adjustVersionOnTypeChange(formWrapper, formFields, field, control, localizer);
    },
  },
  exportresults: {
    formData: {},
    allColumns: [],
    defaultColumns: [],
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      formWrapper.setLoading(true);
      this.formData = formData;
      this.defaultColumns = formData.defaultCols.map(column => column.property);
      formTemplate.defs[1].prompt = localizer.getTranslation('PLACEHOLDER.SELECT_ALL');
      formTemplate.defs[2].prompt = localizer.getTranslation('CUSTOM_COLUMNS.SET_TO_DEFAULT');
      const columns: ColumnDesc[] = formData.columns;
      const fields_box: any = Util.FieldMappings.templateField(formTemplate.defs, 'export_fields');
      const fields: any[] = fields_box ? fields_box.fields : null;

      if (!!fields && !!columns) {
        const addField = (column: ColumnDesc): void => {
          const name: string = 'column__' + column.property;
          const checkboxName: string = 'chk__' + column.property;
          fields.push({
            fldtype: 'checkbox',
            label: column.label,
            datatype: '4',
            name: checkboxName,
            flags: 0x00000000,
            forceextension: true,
            uncheckedtrigger: '0',
            checkedtrigger: '1',
            value: this.defaultColumns.indexOf(column.property) !== -1 ? '1' : '0'
          });
          fields.push({
            fldtype: 'edit',
            name,
            flags: 0x00000002,
            extensionparent: checkboxName,
            isExtension: true,
            value: column.label
          });
        };
        for (const column of columns) {
          this.allColumns.push(column.property);
          addField(column);
        }
      }
      setTimeout(() => {
        formWrapper.setLoading(false);
      }, 1);
    },
    selectall(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl) {
      if (!!field) {
        this.allColumns.forEach(column => {
          formWrapper.dynamicForm.updateControlValue('chk__' + column, '1', true);
        });
      }
    },
    selectdefault(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl) {
      if (!!field) {
        this.allColumns.forEach(column => {
          formWrapper.dynamicForm.updateControlValue('chk__' + column, this.defaultColumns.indexOf(column) !== -1 ? '1' : '0', true);
        });
      }
    },
    getFormData(dynamicForm: DynamicFormComponent, formTemplate: any) {
      return this.formData;
    },
    destroy(formWrapper: FormWrapperComponent, confirmed: boolean, allData: any, dirtyData: any, localizer: LocalizeService) {
      this.formData = {};
      this.allColumns = [];
      this.defaultColumns = [];
    }
  },
  email: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      let versions = '';
      let versionIDs = '';
      let attachments = '';
      const docList: any[] = formWrapper ? formWrapper.selections : null;
      const nDocList: number = docList && docList.length && docList[0]['VERSION_LABEL'] ? docList.length : 0;
      const isVersionEmail: boolean = !!formData && !!formData.isVersionEmail;
      const curItem: any = Util.RestAPI.getCurItem();
      const fromAddress: string = Util.RestAPI.getEmail();
      const selections: any[] = isVersionEmail && !!curItem ? [curItem] : formWrapper.selections;
      const nSelections: number = selections ? selections.length : 0;
      let isOnlyAttachments = false;
      let isOnlyVersions = false;
      //Focus on first editable empty field
      formWrapper.disableFirstFocus = false;
      let bHaveNonDoc = false;
      for (let i=0; i<nSelections; i++) {
        if (selections[i].type !== 'documents') {
          bHaveNonDoc = true;
          break;
        }
      }
      let nDefs: number = formTemplate.defs.length;
      for (let i=nDefs-1; i>=0; i--) {
        if (formTemplate.defs[i].name==='$edx_email_from') {
          formTemplate.defs[i].value = fromAddress;
        }
        if (bHaveNonDoc && (formTemplate.defs[i].name==='$edx_version' || formTemplate.defs[i].name==='$edx_attachment' || formTemplate.defs[i].name==='$edx_share_type')) {
          formTemplate.defs[i].flags = 0x01080000;
        } else if (isVersionEmail && ((!formData.attachments && formTemplate.defs[i].name === '$edx_version') || (formData.attachments && formTemplate.defs[i].name === '$edx_attachment'))) {
          for (let n=0; n<nDocList; n++) {
            if (docList[n]['VERSION']==='0') {
              attachments += docList[n]['VERSION_LABEL'];
              if (n<nDocList-1) {
                attachments += ';';
              }
              isOnlyAttachments = true;
            } else {
              versions += docList[n]['VERSION_LABEL'];
              if (n<nDocList-1) {
                versions += ';';
              }
              isOnlyVersions = true;
            }
            versionIDs += docList[n]['VERSION_ID'];
            if (n<nDocList-1) {
              versionIDs += ';';
            }
          }
          if (!!versionIDs) {
            formData['$edx_version_ID'] = versionIDs;
          }
          if (versions.length) {
            formTemplate.defs[i]['value'] = versions;
            if (versions.indexOf(';') >= 0) {
              formTemplate.defs[i].flags = formTemplate.defs[i].flags | 0x02000000; // disable versions selector
            }
          }
        } else if (formData && formData['$edx_share_attach'] && formTemplate.defs[i].name==='$edx_form_list') {
          formTemplate.defs[i].prompt = localizer.getTranslation('FORMS.LOCAL.SHARE.ATTACH');
        } else if (formData && formData['$edx_share_nomail']) {
          switch (formTemplate.defs[i].name) {
            case '$edx_email_from':
            case '$edx_email_to':
            case '$edx_email_cc':
            case '$edx_email_bcc':
            case '$edx_share_subject':
            case '$edx_share_message':
              formTemplate.defs.splice(i,1);
              break;
          }
        }
      }
      formData['isOnlyAttachments'] = isOnlyAttachments;
      formData['isOnlyVersions'] = isOnlyVersions;
      // Size of form template may have changed. Refresh it.
      nDefs = formTemplate.defs.length;
      isOnlyVersions = isOnlyVersions || (docList?.length > 1);
      for (let i=0; i<nDefs; i++) {
        if (formTemplate.defs[i].name==='$edx_version' && versions.length) {
          formTemplate.defs[i]['value'] = versions;
          formTemplate.defs[i].flags = formTemplate.defs[i].flags | 0x02000000; // disable versions selector
        } else if (formTemplate.defs[i].name==='$edx_attachment' && isOnlyAttachments) {
          formTemplate.defs[i]['value'] = attachments;
          formTemplate.defs[i].flags = formTemplate.defs[i].flags | 0x02000000; // disable attachment selector
        }
      }
      FormScripts._shared.check_remove_email_pickers(formWrapper, formTemplate);
      setTimeout(() => {
        const dynamicForm: DynamicFormComponent = formWrapper.dynamicForm;
        let subject: string;
        let setCC = false;
        let setBCC = false;

        if (bHaveNonDoc) {
          subject = localizer.getTranslation('FORMS.LOCAL.SHARE.SUBJECT_FORMAT', [localizer.getTranslation('FORMS.LOCAL.SHARE.REFERENCES'), localizer.getTranslation('APP_TITLE')]);
          dynamicForm.updateControlValue('$edx_share_type', 'linkonly', true);
        } else {
          subject = localizer.getTranslation('FORMS.LOCAL.SHARE.SUBJECT_DEFAULT', [localizer.getTranslation('APP_TITLE')]);
          formWrapper.dynamicForm.updateControlValue('$edx_share_type', 'documents', true);
          if (nSelections <= 1) {
            FormScripts._shared.populate_versions(formWrapper, false, localizer);
            FormScripts._shared.populate_attachments(formWrapper, false, localizer);
          }
        }
        dynamicForm.updateControlValue('$edx_email_from', fromAddress, true);
        dynamicForm.updateControlValue('$edx_share_subject', subject, true);
        FormScripts.email.populate_address('$edx_email_to', dynamicForm, localizer);
        setCC = FormScripts.email.populate_address('$edx_email_cc', dynamicForm, localizer);
        setBCC = FormScripts.email.populate_address('$edx_email_bcc', dynamicForm, localizer);
        if (isOnlyAttachments) {
          formWrapper.dynamicForm.setFieldVisibility('$edx_version', false);
          formWrapper.dynamicForm.updateControlValue('$edx_version','none');
        }
        if (isOnlyVersions) {
          formWrapper.dynamicForm.setFieldVisibility('$edx_attachment', false);
          FormScripts._shared.remove_specfic_version_or_attachment(false, formWrapper, 'none');
        }
        formWrapper.dynamicForm.setFieldVisibility('$edx_link_action', false);
        formWrapper.dynamicForm.updateControlValue('$edx_link_action', 'download', true);
      }, 1);
    },
    share_type(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      const option: any = field.selectionMap && field.selectionMap.find((selections) => selections.value === control.value);
      if (option) {
        let displayName: string = option.display;
        let format: string;
        const dynamicForm = formWrapper.dynamicForm;
        const isOnlyAttachments: boolean = formWrapper.formData && formWrapper.formData['isOnlyAttachments'];
        const isOnlyVersions: boolean = formWrapper.formData && formWrapper.formData['isOnlyVersions'];
        if (option.value === 'url') {
          format = 'FORMS.LOCAL.SHARE.SUBJECT_FORMAT_FOR_URL';
          displayName = localizer.getTranslation('FORMS.LOCAL.SHARE.DOCUMENT_URL_WITH_LINK', [localizer.getTranslation('FORMS.LOCAL.LINK.ACTION_DOWNLOAD')]);
          dynamicForm.setFieldVisibility('$edx_link_action', true);
          formWrapper.dynamicForm.updateControlValue('$edx_link_action', 'download', true);
          if (isOnlyAttachments || isOnlyVersions) {
            FormScripts._shared.remove_specifc_item_in_combobox('$edx_link_action', formWrapper, localizer, 'profile');
            FormScripts._shared.remove_specifc_item_in_combobox('$edx_link_action', formWrapper, localizer, 'profile_preview');
            FormScripts._shared.remove_specifc_item_in_combobox('$edx_link_action', formWrapper, localizer, 'profile_viewer');
          } else if (!!formWrapper.selections && formWrapper.selections.length > 1) {
            FormScripts._shared.remove_specifc_item_in_combobox('$edx_link_action', formWrapper, localizer, 'open');
          }
        } else {
          format = 'FORMS.LOCAL.SHARE.SUBJECT_FORMAT';
          formWrapper.dynamicForm.setFieldVisibility('$edx_link_action', false);
          if (isOnlyAttachments || isOnlyVersions) {
            dynamicForm.setFieldVisibility('$edx_version', isOnlyVersions);
            dynamicForm.setFieldVisibility('$edx_attachment', isOnlyAttachments);
          } else {
            dynamicForm.setFieldVisibility('$edx_version', true);
            FormScripts._shared.show_or_hide_attachments_dropdown(formWrapper);
          }
          if (!!formWrapper.selections && formWrapper.selections.length > 1) {
            dynamicForm.setFieldVisibility('$edx_attachment', false);
          }
        }
        const subject: string = localizer.getTranslation(format, [displayName, localizer.getTranslation('APP_TITLE')]);
        formWrapper.dynamicForm.updateControlValue('$edx_share_subject', subject);
      }
      FormScripts._shared.adjustVersionOnTypeChange(formWrapper, formFields, field, control, localizer);
    },
    save_address(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      const extParent: FormField = formWrapper.dynamicForm.getField(field.extensionParent);
      const extParentControl: AbstractControl = formWrapper.dynamicForm.getControl(field.extensionParent);
      if (!!extParent && !!extParentControl) {
        const prefKey = 'share_'+extParent.name;
        const addresses: string = Util.RestAPI.getPreference(prefKey);
        if (addresses === extParentControl.value) {
          extParentControl.setValue('');
          Util.RestAPI.setPreference(prefKey, '');
          FormScripts.email.set_button_state(true, field, localizer);
        } else {
          Util.RestAPI.setPreference(prefKey, extParentControl.value.replace(/\s/g, ''));
          FormScripts.email.set_button_state(false, field, localizer);
        }
      }
    },
    address_changed(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      const prefKey = 'share_' + field.name;
      const addresses: string = Util.RestAPI.getPreference(prefKey);
      if (addresses !== control.value) {
        FormScripts.email.set_button_state(true, field.fields[0], localizer);
      }
      formWrapper.dynamicForm.setFieldVisibility(field.name + '_save', false);
      setTimeout(() => {
        if (!!control.status && control.status === 'VALID') {
          formWrapper.dynamicForm.setFieldVisibility(field.name + '_save', true);
        }
      }, 1);
    },
    populate_address(fieldName: string, dynamicForm: DynamicFormComponent, localizer: LocalizeService): boolean {
      const prefKey = 'share_'+fieldName;
      const extName = fieldName + '_save';
      const extension: FormField = dynamicForm.getField(extName);
      const addresses: string = Util.RestAPI.getPreference(prefKey);
      let set = false;
      if (!!addresses) {
        FormScripts.email.set_button_state(false, extension, localizer);
        dynamicForm.setFieldVisibility(extName, true);
        dynamicForm.updateControlValue(fieldName, addresses, true);
        set = true;
      } else {
        FormScripts.email.set_button_state(true, extension, localizer);
        dynamicForm.setFieldVisibility(extName, false);
      }
      return set;
    },
    set_button_state(asSave: boolean, extension: FormField, localizer: LocalizeService): void {
      if (extension) {
        if (asSave) {
          extension.buttonImg = 'toolbar_save24.svg';
          extension.label = localizer.getTranslation('FORMS.BUTTONS.SAVE');
        } else {
           extension.buttonImg = 'formfield_clear.svg';
          extension.label = localizer.getTranslation('FORMS.BUTTONS.CLEAR_ALL');
        }
      }
    },
    choice_version(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      FormScripts.email.get_versions_attachments_for_multiple_docs(formWrapper, false);
      const attachValue = formWrapper.dynamicForm.getControlValue('$edx_attachment');
      formWrapper.dynamicForm.updateControlValue('$edx_force_invalid', (control.value !== 'none' || attachValue !== 'none') ? '1' : undefined, true);
      const isFormInvalid = formWrapper.isFormInvalid();
      if (!!formWrapper.formDialog.doCommand) {
        formWrapper.formDialog.doCommand('disableok', (control.value === 'none' && attachValue === 'none') || isFormInvalid);
      }
    },
    choice_attachment(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      FormScripts.email.get_versions_attachments_for_multiple_docs(formWrapper, true);
      const versionValue = formWrapper.dynamicForm.getControlValue('$edx_version');
      formWrapper.dynamicForm.updateControlValue('$edx_force_invalid', (control.value !== 'none' || versionValue !== 'none') ? '1' : undefined, true);
      const isFormInvalid = formWrapper.isFormInvalid();
      if (!!formWrapper.formDialog.doCommand) {
        formWrapper.formDialog.doCommand('disableok', (control.value === 'none' && versionValue === 'none') || isFormInvalid);
      }
    },
    get_versions_attachments_for_multiple_docs(formWrapper: FormWrapperComponent, isAttachments: boolean) {
      if (formWrapper.isOutlookMB()) {
        const controlValue = formWrapper.dynamicForm.getControlValue(isAttachments ? '$edx_attachment' : '$edx_version');
        const selections: any[] = formWrapper.selections;
        const nSelections: number = !!selections ? selections.length : 0;
        const listName: string = isAttachments ? 'ATTACHMENTS' : 'VERSIONS';
        if (nSelections > 1 && controlValue === 'all' && !formWrapper.scriptData[listName]) {
          formWrapper.scriptData[listName] = [];
          setTimeout(() => {
            const getCB = isAttachments ? FormScripts._shared.get_attachments : FormScripts._shared.get_versions;
            for (const selection of selections) {
              getCB(formWrapper, { desc: selection, readDesc: true }).then((list: any[]) => {
                formWrapper.scriptData[listName].splice(0, 0, ...list);
              });
            }
          }, 1);
        }
      }
    },
    link_action(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      const option: any = field.selectionMap && field.selectionMap.find((selections) => selections.value === control.value);
      const dynamicForm = formWrapper.dynamicForm;
      if (option) {
        const displayName = localizer.getTranslation('FORMS.LOCAL.SHARE.DOCUMENT_URL_WITH_LINK', [option.display]);
        const key = formWrapper.dynamicForm.getControlValue('$edx_share_type') === 'url' ? 'FORMS.LOCAL.SHARE.SUBJECT_FORMAT_FOR_URL' : 'FORMS.LOCAL.SHARE.SUBJECT_FORMAT';
        const subject = localizer.getTranslation(key, [displayName, localizer.getTranslation('APP_TITLE')]);
        formWrapper.dynamicForm.updateControlValue('$edx_share_subject', subject);
        if (option.value === 'profile' || option.value === 'profile_preview' || option.value === 'profile_viewer') {
          dynamicForm.setFieldVisibility('$edx_version', false);
          dynamicForm.setFieldVisibility('$edx_attachment', false);
        } else {
          const isOnlyAttachments: boolean = formWrapper.formData && formWrapper.formData['isOnlyAttachments'];
          const isOnlyVersions: boolean = formWrapper.formData && formWrapper.formData['isOnlyVersions'];
          dynamicForm.setFieldVisibility('$edx_version', true);
          if (option.value === 'open') {
            dynamicForm.setFieldVisibility('$edx_attachment', false);
            FormScripts._shared.remove_all_versions(formWrapper);
            FormScripts._shared.remove_published_version(formWrapper);
            FormScripts._shared.remove_current_version(formWrapper);
            FormScripts._shared.setToCurrentVersion(formWrapper, false);
          } else if (option.value === 'download') {
            FormScripts._shared.show_or_hide_attachments_dropdown(formWrapper);
            const versionsField: FormField = formWrapper.dynamicForm.getField('$edx_attachment');
            if (!!versionsField.selectionMap && versionsField.selectionMap.length > 1) {
              FormScripts._shared.add_all_versions(formWrapper, localizer);
            }
            const shareAsTypeField: FormField = dynamicForm.getField('$edx_share_type');
            const shareAsTypeControl: AbstractControl = dynamicForm.getControl(shareAsTypeField.name);
            FormScripts._shared.adjustVersionOnTypeChange(formWrapper, formFields, shareAsTypeField, shareAsTypeControl, localizer);
          }
          if (isOnlyAttachments || isOnlyVersions) {
            dynamicForm.setFieldVisibility('$edx_version', isOnlyVersions);
            dynamicForm.setFieldVisibility('$edx_attachment', isOnlyAttachments);
          }
          if (!!formWrapper.selections && formWrapper.selections.length > 1) {
            dynamicForm.setFieldVisibility('$edx_attachment', false);
          }
        }
      }
    }
  },
  outlook_email: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      let versions = '';
      let versionIDs = '';
      let attachments = '';
      const docList: any[] = formWrapper ? formWrapper.selections : null;
      const nDocList: number = docList && docList.length && docList[0]['VERSION_LABEL'] ? docList.length : 0;
      const isVersionEmail: boolean = !!formData && !!formData.isVersionEmail;

      const curItem: any = Util.RestAPI.getCurItem();
      const selections: any[] = isVersionEmail && !!curItem ? [curItem] : formWrapper.selections;
      const nSelections: number = selections ? selections.length : 0;
      const fromAddress = Util.RestAPI.getOutlookPreferences('whoamI');
      let isOnlyAttachments = false;
      let isOnlyVersions = false;
      //Focus on first editable empty field
      formWrapper.disableFirstFocus = false;
      let bHaveNonDoc = false;
      for (let i=0; i<nSelections; i++) {
        if (selections[i].type !== 'documents') {
          bHaveNonDoc = true;
          break;
        }
      }
    let nDefs: number = formTemplate.defs.length;
    for (let i=nDefs-1; i>=0; i--) {
      if (formTemplate.defs[i].name==='$edx_outlook_email_from') {
        formTemplate.defs[i].value = fromAddress;
      }
      if (bHaveNonDoc && (formTemplate.defs[i].name==='$edx_version' || formTemplate.defs[i].name==='$edx_attachment' || formTemplate.defs[i].name==='$edx_share_type')) {
        formTemplate.defs[i].flags = 0x01080000;
      } else if (isVersionEmail && ((!formData.attachments && formTemplate.defs[i].name === '$edx_version') || (formData.attachments && formTemplate.defs[i].name === '$edx_attachment'))) {
        for (let n=0; n<nDocList; n++) {
          if (docList[n]['VERSION']==='0') {
            attachments += docList[n]['VERSION_LABEL'];
            if (n<nDocList-1) {
              attachments += ';';
            }
            isOnlyAttachments = true;
          } else {
            versions += docList[n]['VERSION_LABEL'];
            if (n<nDocList-1) {
              versions += ';';
            }
            isOnlyVersions = true;
          }
          versionIDs += docList[n]['VERSION_ID'];
          if (n<nDocList-1) {
            versionIDs += ';';
          }
        }
        if (!!versionIDs) {
          formData['$edx_version_ID'] = versionIDs;
        }
        if (versions.length) {
          formTemplate.defs[i]['value'] = versions;
          if (versions.indexOf(';') >= 0) {
            formTemplate.defs[i].flags = formTemplate.defs[i].flags | 0x02000000; // disable versions selector
          }
        }
      } else if (formData && formData['$edx_share_attach'] && formTemplate.defs[i].name==='$edx_form_list') {
        formTemplate.defs[i].prompt = localizer.getTranslation('FORMS.LOCAL.SHARE.ATTACH');
      } else if (formData && formData['$edx_share_nomail']) {
        switch (formTemplate.defs[i].name) {
          case '$edx_outlook_email_from':
          case '$edx_outlook_email_to':
          case '$edx_outlook_email_cc':
          case '$edx_outlook_email_bcc':
          case '$edx_share_subject':
          case '$edx_share_message':
            formTemplate.defs.splice(i,1);
            break;
        }
      }
    }
    formData['isOnlyAttachments'] = isOnlyAttachments;
    formData['isOnlyVersions'] = isOnlyVersions;
    // Size of form template may have changed. Refresh it.
    nDefs = formTemplate.defs.length;
    isOnlyVersions = isOnlyVersions || (docList?.length > 1);
    for (let i=0; i<nDefs; i++) {
      if (formTemplate.defs[i].name==='$edx_version' && versions.length) {
        formTemplate.defs[i]['value'] = versions;
        formTemplate.defs[i].flags = formTemplate.defs[i].flags | 0x02000000; // disable versions selector
      } else if (formTemplate.defs[i].name==='$edx_attachment' && isOnlyAttachments) {
        formTemplate.defs[i]['value'] = attachments;
        formTemplate.defs[i].flags = formTemplate.defs[i].flags | 0x02000000; // disable attachment selector
      }
    }
      setTimeout(() => {
        const dynamicForm: DynamicFormComponent = formWrapper.dynamicForm;
        let subject: string;
        let setCC = false;
        let setBCC = false;

        if (bHaveNonDoc) {
          subject = localizer.getTranslation('FORMS.LOCAL.SHARE.SUBJECT_FORMAT', [localizer.getTranslation('FORMS.LOCAL.SHARE.REFERENCES'), localizer.getTranslation('APP_TITLE')]);
          dynamicForm.updateControlValue('$edx_share_type', 'linkonly', true);
        } else {
          subject = localizer.getTranslation('FORMS.LOCAL.SHARE.SUBJECT_DEFAULT', [localizer.getTranslation('APP_TITLE')]);
          formWrapper.dynamicForm.updateControlValue('$edx_share_type', 'documents', true);
          if (nSelections <= 1) {
            FormScripts._shared.populate_versions(formWrapper, false, localizer);
            FormScripts._shared.populate_attachments(formWrapper, false, localizer);
          }
        }
        dynamicForm.updateControlValue('$edx_outlook_email_from', fromAddress, true);
        dynamicForm.updateControlValue('$edx_share_subject', subject, true);
        FormScripts.email.populate_address('$edx_outlook_email_to', dynamicForm, localizer);
        setCC = FormScripts.email.populate_address('$edx_outlook_email_cc', dynamicForm, localizer);
        setBCC = FormScripts.email.populate_address('$edx_outlook_email_bcc', dynamicForm, localizer);
        if (setCC || setBCC) {
          setTimeout(() => {
            formWrapper.showExtras(true);
          }, 300);
        }
        if (isOnlyAttachments) {
          formWrapper.dynamicForm.setFieldVisibility('$edx_version', false);
          formWrapper.dynamicForm.updateControlValue('$edx_version','none');
        }
        if (isOnlyVersions) {
          formWrapper.dynamicForm.setFieldVisibility('$edx_attachment', false);
          FormScripts._shared.remove_specfic_version_or_attachment(false, formWrapper, 'none');
        }
        formWrapper.dynamicForm.setFieldVisibility('$edx_link_action', false);
        formWrapper.dynamicForm.updateControlValue('$edx_link_action', 'download', true);
      }, 1);
    },
    share_type(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      FormScripts.email.share_type(formWrapper, formFields, field, control, localizer);
    },
    choice_version(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      FormScripts.email.choice_version(formWrapper, formFields, field, control, localizer);
    },
    choice_attachment(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      FormScripts.email.choice_attachment(formWrapper, formFields, field, control, localizer);
    },
    get_versions_attachments_for_multiple_docs(formWrapper: FormWrapperComponent, isAttachments: boolean) {
      FormScripts.email.get_versions_attachments_for_multiple_docs(formWrapper, isAttachments);
    },
    link_action(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      FormScripts.email.link_action(formWrapper, formFields, field, control, localizer);
    }
  },
  link: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      const selections: any[] = formWrapper.selections;
      formWrapper.disableFirstFocus = false;
      const linkTypeSelect: any = Util.FieldMappings.templateField(formTemplate.defs, '$edx_link_type');
      const linkActionSelect: any = Util.FieldMappings.templateField(formTemplate.defs, '$edx_link_action');
      if (!Util.RestAPI.canShowPreview()) {
        const option = linkActionSelect.selections.find(s=>s.value === 'profile_preview');
        const index = option ? linkActionSelect.selections.indexOf(option) : -1;
        if (index!==-1) {
          linkActionSelect.selections.splice(index,1);
        }
      }
      if (!Util.Device.bIsTeamsAddIn) {
        linkTypeSelect.flags = 0x01180000;
      }
      if (selections[0].type !== 'documents') {
        const actions = selections[0].type === 'flexfolders' ? ['open'] : ['open', 'profile'];
        linkActionSelect.selections = linkActionSelect.selections.filter(t => actions.indexOf(t.value) !== -1);
        linkActionSelect.value = 'open';
      }
      setTimeout(() => {
        this.make_link(formWrapper, localizer);
      }, 1);
    },
    make_link(formWrapper: FormWrapperComponent, localizer: LocalizeService) {
      const desc: any = formWrapper.selections[0];
      const selType = formWrapper.dynamicForm.getControl('$edx_link_type');
      const selAction = formWrapper.dynamicForm.getControl('$edx_link_action');
      let linkStr: string;
      switch (selType.value) {
        case 'web':
          linkStr = Util.RestAPI.makeDeepLink(desc, selAction.value, false);
          break;
        case 'pcdocs':
          linkStr = 'pcdocs://'+desc.lib+'/'+desc.DOCNUM+'/'+(desc.vers || 'r');
          break;
        case 'teams_auto':
          linkStr = Util.RestAPI.makeDeepLink(desc, selAction.value, true);
          break;
        case 'teams_dialog':
          const subEntity = {
            id: desc.id,
            type: desc.type,
            lib: desc.lib,
            DOCNAME: encodeURIComponent(desc.DOCNAME),
            action: selAction.value || '',
            version: desc.vers || 'C'
          };
          microsoftTeams.shareDeepLink({ subEntityId: JSON.stringify(subEntity), subEntityLabel: desc.DOCNAME, subEntityWebUrl: null });
          setTimeout(() => {
            if (!!formWrapper.formDialog) {
              formWrapper.formDialog.doCommand('back');
            }
          }, 1);
          break;
      }
      if (!!linkStr) {
        formWrapper.dynamicForm.updateControlValue('$edx_link', linkStr, false);
        if (Util.Device.teamsContext) {
          formWrapper.dynamicForm.setFieldVisibility('$edx_link_copymessage', true);
          (document.getElementById('$edx_link') as HTMLInputElement).select();
        }
      }
    },
    destroy(formWrapper: FormWrapperComponent, confirmed: boolean, allData: any, dirtyData: any) {
      if (confirmed) {
        const link = document.getElementById('$edx_link') as HTMLInputElement;
        const linkPlain = link.value;
        const linkHtml = '<a href="' + linkPlain + '">' + Util.Transforms.getDMDocName(allData.lib, allData.id, allData.DOCNAME, 'R') + '</a>';
        // Firefox is not supporting clipboard.write()
        // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard
        if (Util.Device.bIsFirefox) {
          const listener = (event: ClipboardEvent) => {
            if (!!link && !!allData && !!event && !!event.clipboardData && !!event.clipboardData.setData && !!event.preventDefault) {
              event.clipboardData.setData('text/plain', linkPlain);
              event.clipboardData.setData('text/html', linkHtml);
              event.preventDefault();
            }
          };
          document.addEventListener('copy', listener);
          document.execCommand('copy');
          document.removeEventListener('copy', listener);
        } else {
          const anyNavigator: any = window.navigator;
          const blobPlain = new Blob([linkPlain], { type: 'text/plain' });
          const blobHtml = new Blob([linkHtml], { type: 'text/html' });
          const clipboardItem = new ClipboardItem(
            {
              'text/plain': blobPlain,
              'text/html': blobHtml,
            });
          anyNavigator.clipboard.write([clipboardItem]);
        }
      }
    }
  },
  downloadzip: {
    desc: null,
    zipQuery: null,
    dlQuery: null,
    path: null,
    extraVers: null,
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      this.desc = formData ? formData.desc : null;
      this.zipQuery = formData ? formData.$edx_zip_type : null;
      this.dlQuery = formData ? formData.$edx_download_type : null;
      this.path = formData ? formData.$edx_download_location : null;
      this.extraVers = formData ? formData.$edx_extraVers : null;
      if (this.desc) {
        setTimeout(() => {
          formWrapper.showExtras(true);
          this.start(formWrapper);
        }, 1);
      }
    },
    destroy(formWrapper: FormWrapperComponent, confirmed: boolean, allData: any, dirtyData: any) {
      this.desc = null;
      this.zipQuery = null;
      this.dlQuery = null;
      this.path = null;
      this.extraVers = null;
    },
    start(formWrapper: FormWrapperComponent) {
      if (this.desc) {
        Util.RestAPI.post(this.desc,'','versions/zip',this.zipQuery,{observe:'response'}).subscribe((response) => {
          if (response.status === 200) {
            this.checkStatus(formWrapper);
          } else {
            this.end(formWrapper);
          }
        }, error => {
          this.end(formWrapper);
        });
      }
    },
    checkStatus(formWrapper: FormWrapperComponent) {
      if (this.desc) {
        Util.RestAPI.get(this.desc,'versions/zipstatus', null).subscribe(data => {
          //{"data":{"status":"<status>","error":"<error message for status "error">"}}
          //status can be: started, downloading, compressing, completed, error
          const status = data.status;
          try {
            if (!!status) {
              switch (status) {
              case 'error':
                this.end(formWrapper);
                break;
              case 'completed':
                Util.RestAPI.downloadFile(this.desc,'zip',this.dlQuery,this.path);
                setTimeout(() => {
                  this.end(formWrapper);
                }, 2000);
                break;
              default:
                setTimeout(() => {
                  this.checkStatus(formWrapper);
                }, 2000);
              }
            } else {
              this.end(formWrapper);
            }
          } catch (e) {
            this.end(formWrapper);
          }
        }, error => {
          this.end(formWrapper);
        });
      }
    },
    end(formWrapper: FormWrapperComponent) {
      const savedDesc = this.desc;
      const savedPath = this.path;
      const savedVers = this.extraVers;
      const savedDLQuery = this.dlQuery;
      this.dlQuery = null;
      this.zipQuery = null;
      if (this.desc) {
        if (!!savedVers) {
          setTimeout(() => {
            if (Util.Device.bIsElectron || Util.Device.bIsCordova) {
              Util.RestAPI.downloadFilesWithAppWorks([savedDesc], savedPath, undefined, savedVers, savedDLQuery, false, false);
            } else {
              const versions: string[] = savedVers.split(';');
              const path = Util.RestAPI.hasPFTA() && !savedPath ? 'downloads' : savedPath;
              for (const version of versions) {
                Util.RestAPI.downloadFile(savedDesc, version, savedDLQuery, path);
              }
            }
          }, 300);
        }
        this.desc = null;
        formWrapper.popupCancel();
      }
    }
  },
  copy: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      const desc: any = formWrapper.desc ? formWrapper.desc : (formWrapper.selections && formWrapper.selections.length ? formWrapper.selections[0] : null);
      if (!!desc) {
        formWrapper.selections = [desc];
        formWrapper.setRelateToDesc(Util.deepCopy(desc));
      }
      const nDefs: number = formTemplate.defs.length;
      for (let i=nDefs-1; i>=0; i--) {
        if (formTemplate.defs[i].name==='$edx_version') {
          if (!!desc && desc.type==='folders') {
            formTemplate.defs.splice(i,1);
          }
        }
      }
      setTimeout(() => {
        const parent: BaseDesc = Util.RestAPI.getLastCopyContainer();
        if (!!parent) {
          formWrapper.dynamicForm.updateControlValue('$edx_chooser', !!parent ? JSON.stringify(parent) : null);
          formWrapper.scriptData['CREATEDESC'] = parent;
        }
        if (!formWrapper.selections || formWrapper.selections.length === 1) {
          if (!desc || desc.type !== 'folders') {
            FormScripts._shared.populate_versions(formWrapper, Util.Device.isMobileDevie(), localizer);
          }
        }
        if (formWrapper.rightWrapper && formData) {
          formWrapper.rightWrapper.copyFileName = formData['DOCNAME'];
          formWrapper.rightWrapper.copyAppID = formData['APP_ID'];
          Util.RestAPI.get(formWrapper.desc, 'security').subscribe(data => {
            if (!!data && !!data.list) {
              formWrapper.rightWrapper.dynamicForm.setSecurityList(data.list);
            }
          }, () => { });
        }
        const waitForRightWrapper = () => {
          if (!!formWrapper.rightWrapper && !!formWrapper.headerWrapper) {
            formWrapper.rightWrapper.setEditable(true);
            formWrapper.headerWrapper.setEditable(true);
            if (!!formData['DOCS_LIBRARY_NAME'] && formData['DOCS_LIBRARY_NAME'] !== Util.RestAPI.getPrimaryLibrary()) {
              formWrapper.rightWrapper.setLibrary(Util.RestAPI.getPrimaryLibrary());
            }
          } else {
            setTimeout(waitForRightWrapper,100);
          }
        };
        setTimeout(waitForRightWrapper,100);
      }, 1);
    },
    location_change(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      const pickedItem = !!control.value ? JSON.parse(control.value) : null;
      if (!!pickedItem) {
        const curDesc = !!formWrapper.selections && formWrapper.selections.length ? formWrapper.selections[0] : null;
        formWrapper.scriptData['CREATEDESC'] = pickedItem;
        if (formWrapper.rightWrapper) {
          if (!formWrapper.rightWrapper.createType) {
            formWrapper.rightWrapper.createType = formWrapper.rightWrapper.desc.type;
          }
          Util.RestAPI.copyDescChanged({id:'', type:formWrapper.rightWrapper.desc.type,lib:pickedItem.lib});
          const isDVNode = (!!pickedItem.id && pickedItem.id.substring(0, 3) === 'DV-');
          if (!!curDesc && curDesc.lib !== pickedItem.lib || isDVNode || !!pickedItem.pickerpath) {
            if (!isDVNode) {
              formWrapper.rightWrapper.formData = {};
            } // changing libs we must knock out existing formData
            formWrapper.rightWrapper.locationChanged(pickedItem);
          }
        }
      }
    }
  },
  copytree: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      const desc: any = formWrapper.desc ? formWrapper.desc : (formWrapper.selections && formWrapper.selections.length ? formWrapper.selections[0] : null);
      if (!!desc) {
        formWrapper.selections = [desc];
        formWrapper.setIncludeTemplates(desc);
      }
      setTimeout(() => {
        const parent: BaseDesc = Util.RestAPI.getLastCopyContainer();
        if (!!parent) {
          formWrapper.dynamicForm.updateControlValue('$edx_chooser', !!parent ? JSON.stringify(parent) : null);
          formWrapper.scriptData['CREATEDESC'] = parent;
        }
        if (formWrapper.rightWrapper && formData) {
          formWrapper.rightWrapper.copyFileName = formData['DOCNAME'];
          formWrapper.rightWrapper.copyAppID = formData['APP_ID'];
        }
        const waitForRightWrapper = () => {
          if (!!formWrapper.rightWrapper && !!formWrapper.headerWrapper) {
            formWrapper.rightWrapper.setEditable(true);
            formWrapper.headerWrapper.setEditable(true);
          } else {
            setTimeout(waitForRightWrapper,100);
          }
        };
        setTimeout(waitForRightWrapper,100);
      }, 1);
    },
    location_change(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      const pickedItem = !!control.value ? JSON.parse(control.value) : null;
      if (!!pickedItem) {
        formWrapper.scriptData['CREATEDESC'] = pickedItem;
      }
    }
  },
  delete: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      const loginReply: any = Util.RestAPI.getLoginReply();
      const isExternalContainer: boolean = Util.isExternalLib(formData.selections[0]['lib']);
      let allowFileDel: boolean = !isExternalContainer ? true : false;
      let allowProfileAndFileDel: boolean =!isExternalContainer ? true : false;
      let allowQueueDel: boolean = !isExternalContainer ? true : false;
      let nItems = 1;
      if (loginReply && loginReply['HEADERS'] && !isExternalContainer) {
        if (loginReply['HEADERS']['X-DM-GROUPID']!=='DOCS_SUPERVISORS') {
          if (loginReply['EFFECTIVE_RIGHTS']) {
            if (loginReply['EFFECTIVE_RIGHTS']['ALLOW_CONTENT_DEL'] === 'N') {
              allowFileDel = false;
            }
            if (loginReply['EFFECTIVE_RIGHTS']['ALLOW_DOC_DELETE'] === 'N') {
              allowProfileAndFileDel = false;
            }
            if (loginReply['EFFECTIVE_RIGHTS']['ALLOW_QUEUE_DEL'] === 'N') {
              allowQueueDel = false;
            }
          }
        }
      }
      let onlyDocsSelected = true;
      if (formData.selections && formData.selections.length) {
        nItems = formData.selections.length;
        onlyDocsSelected = formData.selections.every(item => item.type === 'documents' && item.STATUS !== '18' && item.APP_ID !== '' && item.STORAGE !== 'P'  && !item.VERSION_ID);
        if (allowFileDel) {
          if (!onlyDocsSelected) {
            allowFileDel = false;
          }
        }
        if (allowQueueDel) {
          allowQueueDel = formData.selections.every(item => item.STORAGE !== 'D' && !item.VERSION_ID && item.type !== 'searches' && item.type !=='urls' && item.STORAGE !== 'P');
        }
      }
      if (formTemplate && formTemplate.defs) {
        const nDefs: number = formTemplate.defs.length;
        for (let i=nDefs-1; i>=0; i--) {
          if (formTemplate.defs[i].fldtype==='radiogroup') {
            let goodValue: string = Util.RestAPI.getPreference('edx_delete_type');
            if (!allowFileDel || !allowProfileAndFileDel || !allowQueueDel) {
              const delOptions: string[] = formTemplate.defs[i]['buttons'].split('|');
              if ((!allowFileDel && !allowProfileAndFileDel && !allowQueueDel) || delOptions.length!==3) {
                formTemplate.defs.splice(i,1);
              } else {
                const goodOptions: string[] = [];
                const addIt = (index) => {
                  let options: string = delOptions[index];
                  const values: string[] = options.split(';');
                  if (values && values.length) {
                    goodValue = values[0];
                    if (!onlyDocsSelected && goodValue==='DP') {
                      values[1] = localizer.getTranslation('FOLDER_ACTIONS.DELETE');
                      options = values.join(';');
                    }
                  }
                  goodOptions.push(options);
                };
                if (allowQueueDel) {
                  addIt(2);
                }
                if (allowProfileAndFileDel) {
                  addIt(1);
                }
                if (allowFileDel) {
                  addIt(0);
                }
                formTemplate.defs[i]['buttons'] = goodOptions.join('|');
                if (goodOptions.length<2) {
                  formTemplate.defs[i].flags = 0x01000000;
                }
              }
            }
            if (!!goodValue && formTemplate.defs[i].fldtype === 'radiogroup') {
              formTemplate.defs[i]['value'] = goodValue;
            }
          }
          if (formTemplate.defs[i]['name'] === 'question' && !allowProfileAndFileDel) {
            if (allowQueueDel && !allowFileDel) {
              formTemplate.defs[i]['value'] = nItems === 1 ? localizer.getTranslation('FORMS.LOCAL.DELETE.ASK_QUE_FOR_DELETE_SINGLE', [formData.docName]) : localizer.getTranslation('FORMS.LOCAL.DELETE.ASK_QUE_FOR_DELETE_MANY', [nItems.toString()]);
            }
            if (allowFileDel && !allowQueueDel) {
              formTemplate.defs[i]['value'] = nItems === 1 ? localizer.getTranslation('FORMS.LOCAL.DELETE.ASK_DELETE_CONTENT_SINGLE', [formData.docName]) : localizer.getTranslation('FORMS.LOCAL.DELETE.ASK_DELETE_CONTENT_MANY', [nItems.toString()]);
            }
          }
        }
      }
    }
  },
  confirm_delete: {
    confirmdelete(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      if (!!formWrapper.formDialog.doCommand) {
        formWrapper.formDialog.doCommand('disableok', control.value.length === 0);
      }
    }
  },
  conflictbehavior: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      if (formTemplate && formTemplate.defs) {
        const nDefs: number = formTemplate.defs.length;
        for (let i=nDefs-1; i>=0; i--) {
          if (formTemplate.defs[i].fldtype==='radiogroup') {
            const options: string[] = formTemplate.defs[i]['buttons'].split('|');
            let goodValue: string = Util.RestAPI.getPreference('edx_delete_type');
            if (!goodValue) {
              goodValue = options[0].split(';')[0];
            }
            formTemplate.defs[i]['value'] = goodValue;
          }
          if (formTemplate.defs[i]['name'] === 'question') {
            formTemplate.defs[i]['value'] = localizer.getTranslation('FORMS.LOCAL.CONFLICT_BEHAVIOR.ASK_SELECT',[formData.docName]);
          }
          if (formTemplate.defs[i]['name'] === 'file') {
            formTemplate.defs[i]['value'] = formData.docName;
          }
        }
      }
    }
  },
  permissions_selector: {
    originalValue: undefined,
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      const loginReply: any = Util.RestAPI.getLoginReply();
      let restrictedOnly = false;
      let rm_inherited = false;
      let security: string;
      const inDialog: boolean = formWrapper.inDialog;
      const isWorkspace: boolean = formWrapper.desc && formWrapper.desc.type === 'workspaces' &&
                                   (!formWrapper.createType || formWrapper.createType==='workspaces');
      const isSearch: boolean = formWrapper.desc && formWrapper.desc.type === 'searches';
      const isFlexFolder: boolean = formWrapper.desc && formWrapper.desc.type === 'flexfolders';
      const mainWrapperSercurity = !!formWrapper.mainWrapper && !!formWrapper.mainWrapper.desc ? formWrapper.mainWrapper.desc['SECUTRITY'] : null;
      const mainWrapperHasTrustees = !!formWrapper.mainWrapper && !!formWrapper.mainWrapper.trustees && formWrapper.mainWrapper.trustees.length;
      const mainWrapperTrustees = mainWrapperHasTrustees ? formWrapper.mainWrapper.trustees : null;
      const calcSecurity = () => {
        security = formData['DEFAULT_RIGHTS'];
        if (loginReply && loginReply['EFFECTIVE_RIGHTS'] && loginReply['EFFECTIVE_RIGHTS']['VIEW_UNSECURED'] === 'N' ) {
          restrictedOnly = true;
          security = security || formData['SEC_REASON_LINK'];
          security = security || '1';
        } else {
          const mainWrapperSelectedSercurityChoice: string = !!formWrapper.mainWrapper && !!formWrapper.mainWrapper.desc &&
                                                             formWrapper.mainWrapper.desc['edx_selected_security_choice'] || null;
          security = security || formData['edx_selected_security_choice'];
          security = security || formData['SECURITY'] || mainWrapperSelectedSercurityChoice; // formData security should have precedence over mainWrapper
          security = security || formData['SEC_REASON_LINK'];
          security = security || (!!formData['ACCESSRIGHTS'] ? '1' : undefined);
          security = security || ((!!formWrapper.createType && formWrapper.createType === 'workspaces') ? '1' : undefined);
          security = formWrapper.mainWrapper?.copyTree ? '2' : security; // setting seurity to no change for copy tree during initialization
          security = security || '2';
        }
        // security 2 means initial security for a new profile - user could be on home page, RED or in an unrestricted flex folder
        if (security === '3') {
          restrictedOnly = true;
          rm_inherited = true;
        }
      };
      /* do not change this code without checking with formservice.ts permissions_selector template */
      if (Util.Device.ui>=2 && !Util.Device.bIsOfficeAddin) {
        formTemplate.defs[0].combosegment = true;
        formTemplate.defs[0].prompt = null;
      }
      if (isWorkspace || (isSearch && !formWrapper.createType)) {
        formTemplate.defs.splice(0,1);
        delete formTemplate.defs[0]['extensionparent'];
        formTemplate.defs[1]['extensionparent'] = '$edx_permissions_edit';
      }
      const waitForForm = () => {
        if (!!formWrapper.dynamicForm.form && (!inDialog || (!!formWrapper.mainWrapper && !!formWrapper.mainWrapper.dynamicForm.form))) {
          setTimeout(() => {
            if (!!formWrapper.mainWrapper && !!formWrapper.mainWrapper.formData) {
              formData = formWrapper.mainWrapper.formData;
            }
            calcSecurity();
            if (isWorkspace || (isSearch && !formWrapper.createType)) {
              if (!isWorkspace || !formWrapper.bMassProfileUpdate) {
                formWrapper.dynamicForm.setFieldLabel('$edx_permissions_edit', 'FORMS.LOCAL.PERMISSIONS_SELECTOR.SHARE', true);
                formWrapper.dynamicForm.setFieldVisibility('$edx_permissions_edit', inDialog);
              }
            } else {
              if (inDialog) {
                formWrapper.dynamicForm.setFieldVisibility('$edx_permissions_edit', security === '1');
              } else {
                formWrapper.dynamicForm.setFieldLabel('$edx_permissions_edit', 'FORMS.LOCAL.PERMISSIONS_SELECTOR.SAVE', true);
              }
              formWrapper.dynamicForm.setFieldVisibility('SECURITY', true);
              if (mainWrapperHasTrustees) {
                formWrapper.dynamicForm.setFieldEditable('SECURITY', formWrapper.dynamicForm?.canUserEditSecurity(mainWrapperTrustees));
              } else {
                formWrapper.dynamicForm.setFieldEditable('SECURITY', true);
              }
              let securityField: FormField = formWrapper.dynamicForm.getField('SECURITY');
              let selectionMap: SelectItem[] = securityField.selectionMap;
              if (formWrapper.disableInherited) {
                securityField = formWrapper.dynamicForm.getField('SECURITY');
                selectionMap = securityField.selectionMap;
                selectionMap = selectionMap.filter(i => i.value!=='2');
                securityField.selectionMap = selectionMap;
                if (security === '2') {
                  security = '1';
                } // Convert INHERITED into RESTRICTED
              }
              if (restrictedOnly) {
                if (rm_inherited) {
                  selectionMap = selectionMap.filter(i => i.value === '3' || i.value === '0');
                  securityField.selectionMap = selectionMap;
                  security = '3';
                } else {
                  selectionMap = selectionMap.filter(i => i.value === '1');
                  securityField.selectionMap = selectionMap;
                  security = '1';
                }
              } else {
                selectionMap = selectionMap.filter(i => i.value !== '3');
                securityField.selectionMap = selectionMap;
              }
              if (!formWrapper.inDialog || (formWrapper.mainWrapper &&
                                            (!formWrapper.mainWrapper.createType &&
                                            !formWrapper.bMassProfileUpdate &&
                                            !formWrapper.mainWrapper.copyTree &&
                                            formWrapper.desc?.['id'] !== 'preferences' &&
                                            ['profile_savetoedocs'].indexOf(formWrapper.mainWrapper.kind) === -1))) {
                // We do not use INHERITED value for existing docs anymore. It is always restricted except when creating a doc
                if (security === '2') {
                  security = '1';
                } // Convert INHERITED into RESTRICTED
                selectionMap = selectionMap.filter(i => i.value!=='2');
                securityField.selectionMap = selectionMap;
                if (formWrapper.inDialog) {
                  formWrapper.dynamicForm.setFieldVisibility('$edx_permissions_edit', security === '1' || (rm_inherited && security === '3'));
                }
              }
              // Convert INHERITED into UNRESTRICTED when creating a doc and security is 2
              if ((!formWrapper.bMassProfileUpdate && !formWrapper.mainWrapper?.copyTree && formWrapper.desc?.['id'] !== 'preferences' || isFlexFolder) && security === '2') {
                security = '0';
              }
              // Filter out "No change" option when it's not a mass profile update or copy tree
              if (!formWrapper.bMassProfileUpdate && !formWrapper.mainWrapper?.copyTree && formWrapper.desc?.['id'] !== 'preferences') {
                selectionMap = selectionMap.filter(i => i.value !== '2');
                securityField.selectionMap = selectionMap;
              }
            }
            this.originalValue = security;
            setTimeout(() => {
              formWrapper.dynamicForm.updateControlValue('SECURITY', security);
            }, 1);
            if (!!formWrapper.mainWrapper) {
              const dynamicForm = formWrapper.mainWrapper.dynamicForm;
              /* User changes profile forms and if there is a mix of restricted and unrestricted
                 profile defaults, security list gets passed on to the next unrestricted one!
                 In Office addins, security gets recalculated if user chooses to hide/show security, save to, profile section
                 User chooses a restricted profile form and makes it unrestricted, due to trustees of profile defaults call,
                 form security is '1' BUT user chose unrestricted, we need to respect user selection.
                 User chooses an unrestricted profile and makes it restricted, form security shows '0'
                 due to profile default call, if user selects an unrestricted client/matter, respect user selection
                 and stay restricted. If client/matter has security, inherit from secured client/matter */
              if (security === '0') {
                if (!dynamicForm.userSecurityList?.length && !dynamicForm.inheritedFlexTrusteeList?.length) {
                  dynamicForm.setSecurityList([]);
                } else if ((dynamicForm.userSecuritySelection === '1' && dynamicForm.userSecurityList?.length) || dynamicForm.inheritedFlexTrusteeList?.length) {
                  security = '1';
                  dynamicForm.updateControlValue('SECURITY', '1');
                  formWrapper.dynamicForm.setFieldVisibility('$edx_permissions_edit', true);
                }
              } else if (security === '1' && !dynamicForm.inheritedFlexTrusteeList?.length && dynamicForm.userSecuritySelection === '0') {
                  security = '0';
                  dynamicForm.updateControlValue('SECURITY', '0');
                  formWrapper.dynamicForm.setFieldVisibility('$edx_permissions_edit', false);
              } else {
                dynamicForm.updateControlValue('SECURITY', security);
              }
            }
            formWrapper.permissionChanged(security);
          }, 1);
        } else {
          setTimeout(waitForForm, 100);
        }
      };
      const waitForMainWrapper = () => {
        if (!!formWrapper.mainWrapper) {
          formData = formWrapper.mainWrapper.formData;
          calcSecurity();
          if (formWrapper.readOnly !== formWrapper.mainWrapper.readOnly) {
            formWrapper.setEditable(!formWrapper.mainWrapper.readOnly);
          }
          if ((formWrapper.mainWrapper.createType || formWrapper.mainWrapper.kind === 'profile_copy')) {
            const dynamicForm = formWrapper.mainWrapper.dynamicForm;
            if (mainWrapperSercurity === '1' && !mainWrapperHasTrustees) {
              if (formWrapper.desc['APP_ID'] === 'FOLDER' || formWrapper.desc['type'] === 'flexfolders' || formWrapper.mainWrapper.kind === 'profile_copy') {
                // We are creating document inside a restricted folder or workspace, set the trustee information Only do it for new doc and not edit profile
                formWrapper.mainWrapper.inheritSecurity(formWrapper.desc);
              } else {
                // When creating a new document, set profile default trustees if any.
                formWrapper.mainWrapper.useDefaultTrustees();
              }
            } else if (security === '1' && mainWrapperHasTrustees) {
              if (!dynamicForm?.inheritedFlexTrusteeList?.length) {
                dynamicForm.setSecurityList(formWrapper.mainWrapper.trustees);
                dynamicForm.userSecurityList = formWrapper.mainWrapper.trustees;
              }
            }
          }
          waitForForm();
        } else {
          if (inDialog) {
            setTimeout(waitForMainWrapper, 100);
          } else {
            waitForForm();
          }
        }
      };
      waitForMainWrapper();
    },
    edit(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      if (formWrapper.mainWrapper) {
        if (formWrapper.inDialog) {
          formWrapper.mainWrapper.editSecurity();
        }
      } else {
        // It is coming from security tab so it is save
        formWrapper.saveSecurity();
        if (!!control) {
          this.originalValue = control.value;
        }
      }
    },
    cancel(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      formWrapper.resetSecurity();
      formWrapper.dynamicForm.updateControlValue('SECURITY', this.originalValue, true);
      if (formWrapper.mainWrapper) {
        formWrapper.mainWrapper.dynamicForm.updateControlValue('SECURITY', this.originalValue, true);
      }
      formWrapper.notifyPermissionChanged.emit(this.originalValue);
    },
    choice(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      const value: any = control.value;
      const title: string = localizer.getTranslation('METADATA.TABS.SECURITY');
      const body: string = localizer.getTranslation('FORMS.LOCAL.PERMISSIONS_SELECTOR.REMOVE_SECURITY', [formWrapper.desc['DOCNAME']]);
      const okBtn: string = localizer.getTranslation('FORMS.BUTTONS.OK');
      let previousSecurity: any = formWrapper.desc['SEC_REASON_LINK'];
      const dialogDone = (controlValue) => {
        if (previousSecurity !== value) {
          formWrapper.dynamicForm.updateControlValue('SECURITY', controlValue, true);
        }
        if (formWrapper.mainWrapper) {
          const dynamicForm = formWrapper.mainWrapper.dynamicForm;
          dynamicForm.updateControlValue('SECURITY', controlValue, true);
          // user changes an unrestricted document to restricted, so set user security list
          if (controlValue === '1') {
            dynamicForm.userSecurityList = formWrapper.mainWrapper.trustees;
          } else {
            dynamicForm.userSecurityList = [];
          }
          dynamicForm.userSecuritySelection = controlValue;
        }
        formWrapper.securityDirty(previousSecurity !== controlValue);
      };
      formWrapper.desc['edx_selected_security_choice'] = value;
      if (formWrapper.desc['SEC_REASON_LINK'] !== value && !formWrapper.createType) {
        if (formWrapper.desc['SEC_REASON_LINK'] && (formWrapper.desc['SEC_REASON_LINK'] === 1 || formWrapper.desc['SEC_REASON_LINK'] === 2) && value === '0') {
            previousSecurity = formWrapper.desc['SEC_REASON_LINK'];
            Util.Notify.warning(title, body, okBtn, null, true, true, true).then(confirmed => {
              if (confirmed && confirmed.confirm) {
                dialogDone(control.value);
                formWrapper.permissionChanged(control.value);
              } else {
                // Restore previously set security if not confirmed
                dialogDone(previousSecurity);
                formWrapper.permissionChanged(previousSecurity);
              }
            });
        } else {
          dialogDone(value);
          formWrapper.permissionChanged(control.value);
        }
      } else {
        dialogDone(value);
        formWrapper.permissionChanged(control.value);
      }
    }
  },
  checkout: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      for (const def of formTemplate['defs']) {
        if (def.name === '$edx_folder_loc') {
          const loginReply: any = Util.RestAPI.getLoginReply();
          if (!!loginReply.APPLICATIONS) {
            loginReply.APPLICATIONS.forEach(app => {
              if (app.access.indexOf('checkout-to') !== -1 || app.access.indexOf('checkout') !== -1) {
                const newSelection: any = Util.deepCopy(app);
                newSelection['display'] = app.name;
                newSelection['value'] = app.id;
                def.selections.push(newSelection);
              }
            });
          }
          break;
        }
      }
      FormScripts._shared.check_remove_folder_pickers_for_export_checkout(formWrapper, formTemplate, false);
      setTimeout(() => {
        formWrapper.dynamicForm.getField('%CHECKIN_DATE').minDate = Util.Transforms.formatDateForDM(new Date().toDateString(),true);
      }, 1);
    },
    external_checkout_location(formWrapper: FormWrapperComponent, formTemplate: any, field: FormField, control: AbstractControl, localizer: LocalizeService) {
      if (control.value !== 'LOCAL' && control.value !== 'NONE') {
        let newDesc: any = {};
        for (const sel of field.selectionMap) {
          if (sel['id'] === control.value) {
            const containers: any = localizer.getTranslation('FOLDER_ACTIONS.CONTAINERS');
            newDesc = {id:sel['id'],type:sel['type'],lib:sel['lib'],DOCNAME:containers};
            break;
          }
        }
        Util.RestAPI.pickFolders(newDesc, false, false, true, (list: any[], success: boolean) => {
          if (success && list && list.length) {
            formWrapper.dynamicForm.updateControlValue('$edx_sever_loc_display',list[0].DOCNAME);
            const pathParts: string[] = !!list[0].pickerpath ? list[0].pickerpath.split('/') : [''];
            pathParts.splice(pathParts.length-1);
            const externalLoc = pathParts.length ? pathParts.join('\\') + '\\' + list[0].DOCNAME : list[0].DOCNAME;
            formWrapper.dynamicForm.setFieldEditable('%CHECKIN_LOCATION', false);
            formWrapper.dynamicForm.updateControlValue('%CHECKIN_LOCATION',externalLoc);
            formWrapper.scriptData['CREATEDESC'] = list[0];
            if (formWrapper.rightWrapper) {
              formWrapper.rightWrapper.createType = formWrapper.rightWrapper.desc.type;
              Util.RestAPI.copyDescChanged({id:'', type:formWrapper.rightWrapper.desc.type,lib:list[0].lib});
              if (control.value!=='$edx_select_folder') {
                formWrapper.rightWrapper.formData = {}; // changing libs we must knock out existing formData
              }
            }
          } else {
            formWrapper.dynamicForm.updateControlValue('$edx_folder_loc','LOCAL');
          }
        });
      } else {
        FormScripts._shared.check_remove_folder_pickers_for_export_checkout(formWrapper, formWrapper.formTemplate, true);
      }
    },
    checkout_location(formWrapper: FormWrapperComponent, formTemplate: any, field: FormField, control: AbstractControl, localizer: LocalizeService) {
      for (const def of formTemplate['defs']) {
        if (def.name === '$edx_folder_loc') {
          const loginReply: any = Util.RestAPI.getLoginReply();
          if (!!loginReply.APPLICATIONS) {
            loginReply.APPLICATIONS.forEach(app => {
              if (app.access.indexOf('checkout-to') !== -1 || app.access.indexOf('checkout') !== -1) {
                const newSelection: any = Util.deepCopy(app);
                newSelection['display'] = app.name;
                newSelection['value'] = app.id;
                def.selections.push(newSelection);
              }
            });
          }
          break;
        }
      }
      FormScripts._shared.check_remove_folder_pickers_for_export_checkout(formWrapper, formTemplate, true);
    }
  },
  checkin: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      let index: number;
      let option: any;
      let newVersValue: string = null;
      let canAddNewVersion = true;
      let canAddNewSubVersion = true;
      const newVersSelect: any = Util.FieldMappings.templateField(formTemplate.defs, '$edx_new_version');
      const docs: any[] = !!formData['DOCUMENTS'] && formData['DOCUMENTS'].length ? formData['DOCUMENTS'] : [];
      let doc: any = docs.length ? docs[0] : null;
      let version = doc['VERSION_LABEL'];
      let subVersion;
      const docTypesList = docs.map(d => d['TYPE_ID']).filter((x, i, a) => a.indexOf(x) === i);
      const maxVersions = Util.RestAPI.maxVersionsForDocTypeArray(docTypesList);
      const maxSubversions = Util.RestAPI.maxVersionsForDocTypeArray(docTypesList, 'MAX_SUBVERSIONS');
      for (doc of docs) {
        [version, subVersion] = doc['VERSION_LABEL'].match(/\D+|\d+/g);
        if (version >= maxVersions) {
          canAddNewVersion = false;
        }
        if (!!subVersion && (subVersion.charCodeAt(0) - 64 >= maxSubversions)) {
          canAddNewSubVersion = false;
        }
      }
      const docsWithFirstLib = !!doc ? docs.filter(d => d.lib === doc.lib) : [];
      if (formData['STATUS']==='20' || formData['STATUS']==='19') {
        // the file is the pubished version or read-only do not include replace version in the picker
        if (!!newVersSelect && !!newVersSelect.selections && newVersSelect.selections.length) {
          option = newVersSelect.selections.find(s => s.value==='REPLACE_VERSION');
          index = option ? newVersSelect.selections.indexOf(option) : -1;
          if (index!==-1) {
            newVersSelect.selections.splice(index,1);
          }
        }
      }
      if (docsWithFirstLib.length !== docs.length && !!newVersSelect && !!newVersSelect.selections && newVersSelect.selections.length) {
        // different libs in list remove checkin as new doc
        option = newVersSelect.selections.find(s => s.value==='NEW_DOCUMENT');
        index = option ? newVersSelect.selections.indexOf(option) : -1;
        if (index!==-1) {
          newVersSelect.selections.splice(index,1);
        }
      }
      const postLoad = () => {
        setTimeout(() => {
          const versionField = formWrapper.dynamicForm.getField('$edx_new_version');
          const currentUser: string = Util.RestAPI.getUserID();
          if (!!versionField && !!newVersSelect.selections) {
            versionField.selectionMap = newVersSelect.selections;
          }
          if (!!newVersValue) {
            formWrapper.dynamicForm.updateControlValue('$edx_new_version', newVersValue, true);
          }
          formWrapper.dynamicForm.updateControlValue('%CHECKIN_LOCATION', '', true);
          formWrapper.dynamicForm.updateControlValue('TYPIST_ID', currentUser, true);
          formWrapper.dynamicForm.updateControlValue('AUTHOR_ID', currentUser, true);
          if ((Util.Device.bIsElectron || Util.Device.bIsCordova || Util.RestAPI.hasPFTA()) && formWrapper.selections && formWrapper.selections.length && formData['%CHECKIN_LOCATION']) {
            const checkinLoc: string = formData['%CHECKIN_LOCATION'];
            const filePaths: string[] = [];
            const nSelections: number = formWrapper.selections.length;
            let nResponses = 0;
            formWrapper.dynamicForm.setLoading(true);
            // Synchronous retrieval of filePaths so that they remain in the same order
            // as they are in formWrapper.selections. Multiple file check-in can then
            // correlate file names, app ids, and document numbers positionally in profile form
            // whn they are lsisted as semicolon separated values
            const getFilePaths = () => new Promise(resolve => {
              let i = 0;
              const checkDone = () => {
                ++i;
                if (i >= nSelections) {
                  resolve(filePaths);
                } else {
                  getNext();
                }
              };
              const getNext = () => {
                const filePath = Util.RestAPI.makeFilePath(formWrapper.selections[i], checkinLoc);
                  ++nResponses;
                  if (filePath) {
                    filePaths.push(filePath);
                  }
                  if (nResponses===nSelections) {
                    formWrapper.dynamicForm.updateControlValue('$edx_file_picker', filePaths[0], true);
                    formWrapper.dynamicForm.updateControlValue('%CHECKIN_LOCATION', checkinLoc, true);
                    formWrapper.uploadFiles(null, filePaths);
                    formWrapper.dynamicForm.setLoading(false);
                  }
                  checkDone();
              };
              getNext();
            });
            getFilePaths();
          } else if (formData['%CHECKIN_LOCATION'].startsWith('http') || formData['%CHECKIN_LOCATION'].startsWith('HTTP')) {
            formWrapper.dynamicForm.updateControlValue('%CHECKIN_LOCATION', formData['%CHECKIN_LOCATION'], true);
            formWrapper.dynamicForm.setFieldEditable('%CHECKIN_LOCATION', false);
          } else {
            formWrapper.dynamicForm.updateControlValue('$edx_file_picker', '', true);
          }
        }, 1);
      };
      FormScripts._shared.get_versions(formWrapper, formData).then((list: any[]) => {
        if (list && list.length) {
          let changed = false;
          const versions: any = {};
          let lastVersNumber: string;
          list = list.sort((a: any, b: any) => {
            const aVersLabel: string = a.VERSION_LABEL;
            const bVersLabel: string = b.VERSION_LABEL;
            const aNum: number = parseInt(a.VERSION);
            const bNum: number = parseInt(b.VERSION);
            if (aNum>bNum) {
              return 1;
            } else if (aNum<bNum) {
              return -1;
            }
            return aVersLabel.charCodeAt(aVersLabel.length-1) - bVersLabel.charCodeAt(bVersLabel.length-1);
          });
          for (const item of list) {
            if (lastVersNumber !== item.VERSION) {
              lastVersNumber = item.VERSION;
              versions[lastVersNumber] = 0;
            } else {
              ++versions[lastVersNumber];
            }
          }
          if (!canAddNewVersion || !Util.RestAPI.canAddNewVersion(list)) {
            changed = true;
            option = newVersSelect.selections.find(s => s.value==='NEW_VERSION');
            index = option ? newVersSelect.selections.indexOf(option) : -1;
            if (index!==-1) {
              newVersSelect.selections.splice(index,1);
            }
            newVersValue = 'NEW_SUB_VERSION';
          }
          if (!canAddNewSubVersion || (doc && doc.VERSION_LABEL)) {
            const docVersion = Util.versionFromLabel(doc.VERSION_LABEL);
            if (!canAddNewSubVersion || (!!versions[docVersion] && !Util.RestAPI.canAddNewSubversion(list, docVersion, doc.TYPE_ID))) {
              changed = true;
              option = newVersSelect.selections.find(s => s.value==='NEW_SUB_VERSION');
              index = option ? newVersSelect.selections.indexOf(option) : -1;
              if (index!==-1) {
                newVersSelect.selections.splice(index,1);
              }
              if (newVersValue === 'NEW_SUB_VERSION') {
                newVersValue = 'NEW_DOCUMENT';
              }
            }
          }
          if (changed) {
            postLoad();
          }
        }
      }, err => { });
      postLoad();
    },
    version_choice(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl) {
      const value: any = control.value;
      const show = value !== 'NEW_DOCUMENT';
      const nonNewDocFields = ['$edx_version_guide', 'AUTHOR_ID', 'TYPIST_ID', 'COMMENT'];
      nonNewDocFields.forEach(f => formWrapper.dynamicForm.setFieldVisibility(f, show));
    }
  },
  newversion: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      const doc: any = !!formData['DOCUMENTS'] && formData['DOCUMENTS'].length ? formData['DOCUMENTS'][0] : null;
      if (Util.Device.bIsCordova && formWrapper.selections && formWrapper.selections.length && formWrapper.selections[0].fullPath) {
        const parts: string[] = formWrapper.selections[0].fullPath.split('/');
        const filename: string = parts[parts.length-1];
        formData['%CHECKIN_LOCATION'] = filename;
        const docObj: any = Util.decodeFileName(filename);
        if (!!docObj) {
          formData['DOCNUMBER'] = docObj.docNum;
        }
        setTimeout(() => {
          formWrapper.dynamicForm.updateControlValue('$edx_file_picker', filename, true);
          formWrapper.uploadFiles(null, [filename]);
        }, 1);
      }
      FormScripts._shared.get_versions(formWrapper, formData).then((list: any[]) => {
        let changed = false;
        let index: number;
        let option: any;
        let newVersValue: string = null;
        const newVersSelect: any = Util.FieldMappings.templateField(formTemplate.defs, '$edx_new_version');
        if (list && list.length) {
          if (!Util.RestAPI.canAddNewVersion(list, formData?.TYPE_ID)) {
            changed = true;
            option = newVersSelect.selections.find(s => s.value==='NEW_VERSION');
            index = option ? newVersSelect.selections.indexOf(option) : -1;
            if (index!==-1) {
              newVersSelect.selections.splice(index,1);
            }
            newVersValue = 'NEW_SUB_VERSION';
          } else if (!Util.RestAPI.canAddNewSubversion(list, formData?.VERSION, formData?.TYPE_ID)) {
            changed = true;
            option = newVersSelect.selections.find(s => s.value==='NEW_SUB_VERSION');
            index = option ? newVersSelect.selections.indexOf(option) : -1;
            if (index!==-1) {
              newVersSelect.selections.splice(index,1);
            }
            newVersValue = 'NEW_VERSION';
          }
          if (changed) {
            setTimeout(() => {
              formWrapper.dynamicForm.updateControlValue('$edx_new_version', newVersValue, true);
              formWrapper.dynamicForm.updateControlValue('COMMENT', '', true);
            }, 1);
          }
        }
      }, err => { });
    }
  },
  pick_version: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      setTimeout(() => {
        FormScripts._shared.populate_versions(formWrapper, Util.Device.isMobileDevie(), localizer, formData);
      }, 1);
    },
    validate_published(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      FormScripts.pick_version.toggleVersionChooserNote(formWrapper);
    },

    toggleVersionChooserNote(formWrapper: FormWrapperComponent) {
      const formData = formWrapper.formData;
      if (!!formData) {
        const dynamicForm = formWrapper.dynamicForm;
        dynamicForm.setFieldVisibility('publishnote', false);
        dynamicForm.setFieldVisibility('readonlynote', false);
        const selectedVersion: string = dynamicForm.getControlValue('$edx_version')?.toLowerCase();
        localStorage.removeItem('openAsReadOnly');
        if (!!formData.publishedVersions && (formData.publishedVersions.indexOf(selectedVersion) > -1)) {
          dynamicForm.setFieldVisibility('publishnote', true);
          localStorage.setItem('openAsReadOnly', 'true');
        } else if (!!formData.readonlyVersions && (formData.readonlyVersions.indexOf(selectedVersion) > -1)) {
          dynamicForm.setFieldVisibility('readonlynote', true);
          localStorage.setItem('openAsReadOnly', 'true');
        }
      }
    }
  },
  template_options: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      const nDefs: number = formTemplate.defs.length;
      for (let i = 0; i < nDefs; i++) {
        if (formTemplate.defs[i].name === 'OPTIONS_NOTE' && !!formData['desc'] && !!formData['desc']['DOCNAME']) {
          formTemplate.defs[i]['value'] = localizer.getTranslation(formTemplate.defs[i]['value'], [formData['desc']['DOCNAME']]);
        }
      }
    }
  },
  preferences_general: {
    formData: {},
    adminFormData: {},
    selectedGroupData: null,
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      const hasOneDrive = !!Util.RestAPI.findExternalAppType('onedrive');
      const hasTeams = !!Util.RestAPI.findExternalAppType('teams');
      const generalPreferencesData = JSON.parse(localStorage.getItem('$edx_preferences_data') !== 'undefined' ? localStorage.getItem('$edx_preferences_data') : null);
      const overridableDoesNotExist = generalPreferencesData?.filter(t => t['UserOverridable'] === false).length > 0 ? true : false;
      const hideField = (name: string): void => {
        if (!Util.RestAPI.isAdminPage()) {
          const field = Util.FieldMappings.templateField(formTemplate.defs, name);
          if (!!field) {
            field.flags = 0x01000000;
          }
        }
      };

      if (Util.RestAPI.isAdminPage()) {
        this.selectedGroupData = { groupName: formData['GROUP_NAME'], lib: formData['SELECTED_LIB'] };
        this.adminFormData = {};
        this.formData = {};
        if (formData?.['GENERAL']) {
          for (let item of formData?.['GENERAL']) {
            this.formData[item['name']] = item['setting'];
            this.formData[item['name'] + '_o'] = item['UserOverridable'] ? '1' : '0';
          }
        }
      } else {
        this.formData = Util.Transforms.parseJsonString(localStorage.getItem('$edx_preferences'));
        for (let i = 0; i < formTemplate.defs.length; i++) {
          if (!!formTemplate.defs[i].fields) {
            formTemplate.defs[i].fields = formTemplate.defs[i].fields.filter(def => !def.extensionparent);
          }
        }
      }

      if (!Util.Device.bIsCordova) {
        hideField('uploading');
      }
      if ((!Util.Device.bIsElectron && !Util.RestAPI.hasPFTA() || !Util.RestAPI.canUserCheckoutDocuments())) {
        hideField('def_checkout_open');
      }
      if (Util.Device.bIsOfficeAddin && !Util.RestAPI.hasPFTA()) {
        hideField('def_open');
      }
      if (!hasOneDrive && !Util.RestAPI.hasPFTA()) {
        hideField('saving');
      } else {
        if (!hasOneDrive) {
          hideField('delete_original');
        }
        if (!hasTeams) {
          hideField('delete_original_teams');
        }
        if (!Util.RestAPI.hasPFTA()) {
          hideField('instant_save');
        }
      }
      const initData: any = {};
      Util.FieldMappings.forEachTemplateField(formTemplate.defs, false, ((field: any): boolean => {
        if (!!field && !!field.name) {
          initData[field.name] = field.value;
          if (!Util.RestAPI.isAdminPage() && overridableDoesNotExist && generalPreferencesData?.filter(t => (t['name'].toLowerCase() === 'g_' + field.name.toLowerCase() && t['UserOverridable'] === false)).length > 0) {
            if (field.name === "def_open") {
              field["isDisabled"] = true;
            } else {
              if (field.flags === 0) {
                field.flags = 0x02080000;
              }
            }
          } 
        }
        if (Util.RestAPI.isAdminPage()) {
          initData[field.name] = this.formData[field.name];
          if (!!field.extensionparent) {
            field.flags = 0x00000000;
            field.arialabel = localizer.getTranslation(field.arialabel) + ' ' + localizer.getTranslation('ADMIN.TILES.USER_OVERRIDE');
          }
        } else if (field.forceextension) {
          field.forceextension = false;
        }
        return true;
      }));
      formWrapper.scriptData['PREF_GENERAL'] = initData;
      formWrapper.setLoading(false);
      setTimeout(() => {
        const formField = new FormField(Util.FieldMappings.templateField(formTemplate?.defs, 'def_open'), '__local_preferences_general');
        FormScripts.preferences_general.version_option_change(formWrapper, formTemplate?.defs, formField, formWrapper?.dynamicForm?.getControl('def_open'), localizer);
      }, 100);
    },
    getFormData(dynamicForm: DynamicFormComponent, formTemplate: any) {
      return this.formData;
    },
    version_option_change(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      if (Util.RestAPI.isAdminPage()) {
        const fieldFound = formWrapper.dynamicForm?.getField(field.name + '_o');
        if (!!fieldFound && !!control.value) {
          const overridableCheckbox: HTMLElement = document.getElementById(field.name + '_o');
          if (!!overridableCheckbox) {
            const radioOption = (field?.buttonMap?.find(b => b.value === control?.value)?.display ?? '') + ' ' + localizer.getTranslation('ADMIN.TILES.USER_OVERRIDE');
            fieldFound.ariaLabel = radioOption;
            overridableCheckbox.title = radioOption;
            overridableCheckbox.setAttribute('aria-label', radioOption);
          }
        }
        formWrapper.setLoading(false);
      }
    },
    destroy(formWrapper: FormWrapperComponent, confirmed: boolean, allData: any, dirtyData: any, localizer: LocalizeService) {
      if (confirmed) {
        this.formData = Util.Transforms.parseJsonString(localStorage.getItem('$edx_preferences'));
        let adminFormData = [];
        Util.FieldMappings.forEachTemplateField(formWrapper.formTemplate.defs, false, ((field: any): boolean => {
          if (field && field.name && !field.extensionparent) {
            if (Util.RestAPI.isAdminPage()) {
              adminFormData.push({ name: field.name, setting: allData[field.name], UserOverridable: allData[field.name + '_o'] === '1' });
            } else {
              this.formData[field.name] = allData[field.name];
              delete allData[field.name + '_o'];
              delete this.formData[field.name + '_o'];
            }
          }
          return true;
        }));
        const saveDone = () => {
          this.formData = {};
          Util.RestAPI.prefsChanged();
        };

        if (Util.RestAPI.isAdminPage()) {
          const queryArgs = '?library=' + this.selectedGroupData['lib'];
          Util.RestAPI.saveGroupSettings(this.selectedGroupData['groupName'], 'general', adminFormData, null, queryArgs).then(() => {
          }, err => {
            Util.RestAPI.handleError(err);
          });
        } else {
          const settingsService = new SettingsService();
          settingsService.write('General', this.formData).then(response => {
            saveDone();
          }, err => {
            saveDone();
          });
        }
      }
    }
  },
  preferences_tiles: {
    formData: null,
    curTiles: null,
    allTiles: null,
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      formWrapper.setLoading(true);

      let curTile: Tile;
      const home_defaultBox: any = Util.FieldMappings.templateField(formTemplate.defs, 'home_default');
      const home_additionalBox: any = Util.FieldMappings.templateField(formTemplate.defs, 'home_additional');
      const home_customBox: any = Util.FieldMappings.templateField(formTemplate.defs, 'home_custom');
      const home_defaultBoxFields: any[] = home_defaultBox ? home_defaultBox.fields : null;
      const home_additionalBoxFields: any[] = home_additionalBox ? home_additionalBox.fields : null;
      const home_customBoxBoxFields: any[] = home_customBox ? home_customBox.fields : null;
      const defaultTiles = Util.RestAPI.getDefaultTiles();
      this.curTiles = Util.RestAPI.getTiles();
      this.allTiles = [].concat(this.curTiles);
      if (!!defaultTiles && defaultTiles.length) {
        defaultTiles.forEach(t1 => {
          if (this.allTiles.findIndex(t2 => t1.type===t2.type && t1.id===t2.id && (t1.lib===t2.lib || !t2.lib)) === -1) {
            this.allTiles.push(t1);
          }
        });
      }
      this.changeToLib = null;
      this.resetTiles = false;
      this.formData = {};
      const downloadsTile = this.curTiles.find(t => t.id==='downloads');
      const importsTile = this.curTiles.find(t => t.id==='imports');
      if (!!home_defaultBoxFields && !!home_additionalBoxFields && !!home_customBoxBoxFields && !!this.curTiles && !!defaultTiles) {
        const addTile = (tile: Tile, fields: any[]): void => {
          if (!(Util.Device.isPhoneLook() && ((!Util.Device.bIsOfficeAddin && tile.id==='checkedout') || tile.name==='hero'))) {
            const name: string = 'tile__' + tile.id + '__' + tile.type + '__' + tile.lib;
            const tileName: string = tile.name !== 'hero' ? localizer.getTranslation(tile.name) : localizer.getTranslation('TILE_NAMES.HERO_WELCOME');
            fields.push( {
              fldtype: 'checkbox',
              prompt: ' ',
              label: tileName,
              datatype: '4',
              name,
              flags: (tile['UserOverridable'] ?? true) ? 0x00000000 : 0x02080000,
              uncheckedtrigger: '0',
              checkedtrigger: '1',
              value: '1'
            });
            this.formData[name] = tile.index===-1 ? '0' : '1';
          }
        };
        for (curTile of defaultTiles) {
          if (curTile.index >= 0) {
            addTile(this.curTiles.find(t => t.id===curTile.id && t.type===curTile.type && t.lib===curTile.lib) || curTile, home_defaultBoxFields);
          } else {
            addTile(this.curTiles.find(t => t.id===curTile.id && t.type===curTile.type && t.lib===curTile.lib) || curTile, home_additionalBoxFields);
          }
        }
        for (curTile of this.curTiles) {
          if (!defaultTiles.find(t => t.id===curTile.id && t.type===curTile.type && t.lib===curTile.lib)) {
            if (!!downloadsTile && curTile.id==='downloads') {
              curTile.index = localStorage.getItem('tile__downloads__folders__' + Util.RestAPI.getPrimaryLibrary()) === '0' ? -1 : curTile.index;
              addTile(curTile, home_defaultBoxFields);
            } else if (!!importsTile && curTile.id==='imports') {
              curTile.index = localStorage.getItem('tile__imports__folders__' + Util.RestAPI.getPrimaryLibrary()) === '0' ? -1 : curTile.index;
              addTile(curTile, home_defaultBoxFields);
            } else {
              addTile(curTile, home_customBoxBoxFields);
            }
          }
        }
      }
      const keyAddTileAfterRed = 'add_tile_after_red';
      const addToHomeGroupBox = Util.FieldMappings.templateField(formTemplate.defs, 'add_to_home');
      if (Util.RestAPI.canUserAddTileAfterRed()) {
        addToHomeGroupBox.prompt = localizer.getTranslation('FOLDER_ACTIONS.ADD_TO_HOME');
        const tileStr = Util.Device.bIsOfficeAddin ? '' : ' ' + localizer.getTranslation('TILE_NAMES.TILE');
        addToHomeGroupBox.fields.push( {
          fldtype: 'checkbox',
          label: localizer.getTranslation('FORMS.LOCAL.PREFERENCES.ADD_TILE_AFTER_RED', [tileStr.toLowerCase()]),
          datatype: '4',
          name: 'add_tile_after_red',
          flags: 0x00000000,
          uncheckedtrigger: '0',
          checkedtrigger: '1',
          value: '0'
        });
        this.formData[keyAddTileAfterRed] = Util.RestAPI.getPreference(keyAddTileAfterRed, '$edx_preferences_tiles') || '0';
      } else if (!!addToHomeGroupBox) {
        addToHomeGroupBox.flags = 0x01000000;
      }
      setTimeout(() => {
        formWrapper.setLoading(false);
      }, 1);
    },
    getFormData(dynamicForm: DynamicFormComponent, formTemplate: any) {
      return this.formData;
    },
    destroy(formWrapper: FormWrapperComponent, confirmed: boolean, allData: any, dirtyData: any, localizer: LocalizeService) {
      if (confirmed) {
        const updateTiles = () => {
          let nTilesChanged = 0;
          const keys: string[] = Object.keys(dirtyData);
          let newTileIndex: number;
          for (const key of keys) {
            //"tile__" + tile.id
            if (key.startsWith('tile__')) {
              const value: string = dirtyData[key];
              const parts: string[] = key.split('__');
              const tile: Tile = this.allTiles.find(t => t.id === parts[1] && t.type === parts[2] && t.lib === parts[3]);
              if (value === '1') {
                if (tile.index === -1) {
                  if (tile.name === 'hero') {
                    tile.index = 0;
                  } else {
                    newTileIndex = Util.RestAPI.getNewTileIndex(this.allTiles.find((t) => t.name === 'hero'), this.allTiles.find((t) => t.id === 'recentedits'));
                    tile.index = newTileIndex !== -1 ? newTileIndex : this.curTiles.length + nTilesChanged;
                    tile.name = localizer.getTranslation(tile.name);
                    if (['downloads', 'imports'].indexOf(tile.id) !== -1) {
                      localStorage.setItem(key, '1');
                    }
                  }
                  ++nTilesChanged;
                  const curTile = this.curTiles.find(t => t.id === parts[1] && t.type === parts[2] && t.lib === parts[3]);
                  if (!!curTile) {
                    curTile.index = tile.index;
                  } else {
                    this.curTiles.push(tile);
                  }
                }
              } else if (value === '0') {
                if (tile.index !== -1) {
                  tile.index = -1;
                  if (!Util.RestAPI.isPermaTile(tile)) {
                    const indx = this.curTiles.indexOf(tile);
                    if (indx !== -1) {
                      this.curTiles.splice(indx, 1);
                      localStorage.setItem(key, '0');
                    }
                  }
                  ++nTilesChanged;
                }
              }
            }
          }
          if (nTilesChanged) {
            Util.RestAPI.setTiles(this.curTiles);
          }
          this.formData = null;
          this.curTiles = null;
          this.allTiles = null;
          this.defaultTiles = null;
        };
        if (dirtyData?.['add_tile_after_red'] !== undefined) {
          new SettingsService().write('Tiles', { add_tile_after_red: dirtyData['add_tile_after_red'] }).then((data) => {
            updateTiles();
          });
        } else {
          updateTiles();
        }
      }
    }
  },
  group_footer_defaults: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      this.selectedGroupData = { groupName: formData['GROUP_NAME'], lib: formData['SELECTED_LIB']};
      const showFooterOptions = Util.FieldMappings.templateField(formTemplate.defs, 'show_footer_options');
      const fields = [];
      formWrapper.setLoading(true);
      fields.push({
        fldtype: 'checkbox',
        label: '',
        prompt: '',
        datatype: '4',
        name: 'edx_show_footer_options',
        flags: 0x00000000,
        uncheckedtrigger: '0',
        checkedtrigger: '1',
        value: !formData['FOOTER']  || formData?.['FOOTER']?.['showfooteroptions'] === '1' ? '1' : '0',
        scripttrigger: { script: 'check_click' },
        arialabel: localizer.getTranslation('ADMIN.FOOTER.SHOW_FOOTER_OPTIONS') + ' ' + localizer.getTranslation('ADMIN.FOOTER.ENABLE')
      });
      showFooterOptions.fields = fields;
      this.formData = formData;
      setTimeout(() => {
        formWrapper.setLoading(false);
      }, 1);
    },
    getFormData(dynamicForm: DynamicFormComponent, formTemplate: any) {
      return this.formData;
    },
    destroy(formWrapper: FormWrapperComponent, confirmed: boolean, allData: any, dirtyData: any, localizer: LocalizeService) {
      const queryArgs =  '?library=' + this.selectedGroupData['lib'];
      const footerDefaults = {};
      footerDefaults['showfooteroptions'] = allData['edx_show_footer_options'] === '1' ? '1' : '0';
      const payloadData: any = {
        footerdefaults: {
          data: footerDefaults
        }
      };
      Util.RestAPI.put('settings/multiple/group/' + this.selectedGroupData['groupName'], payloadData, queryArgs).subscribe(response => {
        this.showFooterOptions = !!parseInt(response['showfooteroptions']);
      }, error => {
        console.log(error);
      });
    }
  },
  group_tile_settings: {
    formData: {},
    curTiles: null,
    selectedGroupData: null,
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      formWrapper.setLoading(true);

      const home_defaultBox: any = Util.FieldMappings.templateField(formTemplate.defs, 'home_default');
      const home_additionalBox: any = Util.FieldMappings.templateField(formTemplate.defs, 'home_additional');
      const home_defaultBoxFields: any[] = home_defaultBox ? home_defaultBox.fields : null;
      const home_additionalBoxFields: any[] = home_additionalBox ? home_additionalBox.fields : null;

      this.formData = formData ?? {};
      let curTile: Tile;
      let markAllTilesAsChecked = false;
      const defaultTiles = Util.RestAPI.getDefaultTiles();

      if (!!formData) {
        this.selectedGroupData = { groupName: formData['GROUP_NAME'], lib: formData['SELECTED_LIB']};
        if (!!formData['TILES'] && formData['TILES'].length) {
          this.curTiles = formData['TILES'];
        } else {
          markAllTilesAsChecked = true;
          this.curTiles = Util.deepCopy(defaultTiles || {});
          formData['TILES'] = this.curTiles;
        }

        const addTile = (tile: Tile, fields: any[]): void => {
          const name: string = 'tile__' + tile.id + '__' + tile.type + '__' + tile.lib;
          const tileName: string = FormScripts.group_tile_settings.getTranslatedTileName(tile.name, localizer);
          const checkboxes = [];
          checkboxes.push( localizer.getTranslation('DOC_STATUS.AVAILABLE'));
          checkboxes.push( localizer.getTranslation('ADMIN.TILES.USER_OVERRIDE'));
          const subFields = [];
          for (const checkbox of checkboxes) {
            const elementName = tileName + '___' + checkbox;
            let elementValue;
            switch (checkbox) {
              case localizer.getTranslation('DOC_STATUS.AVAILABLE'):
                elementValue = tile.index !== -1 ? '1' : '0';
                if (markAllTilesAsChecked) {
                  tile.name = tileName;
                }
                break;
              case localizer.getTranslation('ADMIN.TILES.USER_OVERRIDE'):
                if (markAllTilesAsChecked) {
                  elementValue = '1';
                  tile.name = tileName;
                  tile.UserOverridable = true;
                } else {
                  elementValue = tile.UserOverridable ? '1' : '0';
                }
                break;
            }
            this.formData[elementName] = elementValue;

            subFields.push({
              fldtype: 'checkbox',
              prompt: ' ',
              label: ' ',
              datatype: '4',
              name: elementName,
              flags: 0x00000000,
              uncheckedtrigger: '0',
              checkedtrigger: '1',
              value: this.formData[elementName],
              arialabel: tileName + ' ' + checkbox
            });
          }
          fields.push( {
            fldtype: 'checkboxgroup',
            prompt: ' ',
            label: tileName,
            name,
            flags: 0x00000000,
            fields: subFields
          });
        };
        let boxTile;
        for (curTile of defaultTiles) {
          boxTile = this.curTiles.find(t => t.id === curTile.id && t.type === curTile.type && t.lib === curTile.lib);
          if (!!boxTile) {
            addTile(boxTile, curTile.index >= 0 ? home_defaultBoxFields : home_additionalBoxFields);
          }
        }
      }
      
      setTimeout(() => {
        formWrapper.setLoading(false);
      }, 1);
    },
    getFormData(dynamicForm: DynamicFormComponent, formTemplate: any) {
      return this.formData;
    },
    getTranslatedTileName(tileName: string, localizer: LocalizeService) {
      return tileName !== 'hero' ? localizer.getTranslation(tileName) : localizer.getTranslation('TILE_NAMES.HERO_WELCOME');
    },
    destroy(formWrapper: FormWrapperComponent, confirmed: boolean, allData: any, dirtyData: any, localizer: LocalizeService) {
      if (confirmed) {
        let atleastOneTileIsModified = false;
        const keys: string[] = Object.keys(dirtyData);
        for (const key of keys) {
          const value: string = dirtyData[key];
          const parts: string[] = key.split('___');
          const tileIndex = this.curTiles.findIndex(t => FormScripts.group_tile_settings.getTranslatedTileName(t.name, localizer) === parts[0]);
          if (tileIndex >= 0) {
            const tile: Tile = this.curTiles[tileIndex];
            let tileValue;
            if (parts[1] === localizer.getTranslation('ADMIN.TILES.USER_OVERRIDE')) {
              tileValue = value === '1' ? true : false;
              if (tileValue !== tile.UserOverridable) {
                tile.UserOverridable = tileValue;
                atleastOneTileIsModified = true;
              }
            } else {
              tileValue = (value === '1') ? tileIndex : -1;
              if (tileValue !== tile.index) {
                tile.index = tileValue;
                atleastOneTileIsModified = true;
              }
            }
          }
        }

        const defaultTiles = Util.RestAPI.getDefaultTiles();
        let defaultTile = null;
        for (const tile of this.curTiles) {
          defaultTile = defaultTiles.find(t => (t.id === tile?.id && t.type === tile?.type));
          if (!!defaultTile) {
            tile.name = defaultTile.name;
          }
        }

        if (atleastOneTileIsModified) {
          const queryArgs =  '?library=' + this.selectedGroupData['lib'];
          Util.RestAPI.saveGroupTileSettings(this.selectedGroupData['groupName'], this.curTiles, null, queryArgs).then(() => {
          }, err => {
            Util.RestAPI.handleError(err);
          });
        }
      }
    }
  },
  savesearch: {
    parent: null,
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      formData = formData || {};
      formWrapper.layout = 'column';
      if (formData.isSavedSearch) {
        formTemplate.defs[1].selections[0]['display'] = formData.savedSearchName;
        formTemplate.defs[3].flags = 0x01000000;
      } else {
        formTemplate.defs.splice(1, 2);
      }
      FormScripts.savesearch.isSavedSearch = formData.isSavedSearch;
    },
    search_change(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl) {
      if (FormScripts.savesearch.isSavedSearch) {
        const dynamicForm: DynamicFormComponent = formWrapper.dynamicForm;
        const newSearch: any = dynamicForm.getField('name') || {};
        const updateText: any = dynamicForm.getField('update_saved_search') || {};

        newSearch.isVisible = control.value !== '0';
        updateText.isVisible = control.value === '0';
      }
    },
  },
  advancedsearch: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      if (formWrapper) {
        formWrapper.layout = 'column';
      }
    }
  },
  locations_chooser: {
    click(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl) {
      Util.RestAPI.clickPickFiles();
    }
  },
  oai_addmsg: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      const nDefs: number = formTemplate.defs.length;
      const attachments: any[] = formData['$edx_attachments'];
      const nAttachments: number = attachments ? attachments.length : 0;
      for (let i=0; i<nDefs; i++) {
        if (formTemplate.defs[i].fldtype==='radiogroup') {
          let buttons: string = formTemplate.defs[i]['buttons'];
          if (nAttachments) {
            buttons += '|E;'+localizer.getTranslation('FORMS.LOCAL.OAI_ADD.EXCLUDE_ATTACHMENTS');
            if (nAttachments>1) {
              buttons += '|-1;'+localizer.getTranslation('FORMS.LOCAL.OAI_ADD.ALL');
            }
            for (let j=0; j<nAttachments; j++) {
              const name: string = attachments[j].Name || attachments[j].name;
              buttons += '|'+j+';'+name;
            }
          }
          formTemplate.defs[i]['buttons'] = buttons;
          break;
        }
      }
    }
  },
  save_as: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      const list: any[] = formData['list'];
      const item: any = !!list && list.length > 0 ? list[0] : null;
      const isCheckedOutByMe: boolean = !!item && item['STATUS'] === '3' && !!item['checkout'] && item['checkout']['TYPIST_ID'].toUpperCase() === Util.RestAPI.getUserID();
      const saveAsRadio: any = Util.FieldMappings.templateField(formTemplate.defs, '$edx_save_as_radio');
      let buttons: string[] = saveAsRadio.buttons.split('|');
      const updateRadioOptions = (): void => {
        const radioField = formWrapper.dynamicForm.getField('$edx_save_as_radio');
        if (!!radioField) {
          radioField.buttonMap = buttons.map(button => {
            const parts = button.split(';');
            return { value: parts[0], display: parts[1] };
          });
        }
        formWrapper.dynamicForm.updateControlValue('$edx_save_as_radio', buttons[0].split(';')[0], true);
        formWrapper.dynamicForm.updateControlValue('COMMENT', '', true);
      };
      const rights: SecurityControl = Util.RestAPI.getRightsForItem(item);
      if (Util.Device.bIsOfficeAddin && (item['STATUS'] === '18' || (item['STATUS'] === '3' && !isCheckedOutByMe) || item['READONLY'] === 'Y' || !rights.canEditContent)) {
        const ndIndex = buttons.findIndex(b => b.split(';')[0] === 'ND');
        if (ndIndex !== -1) {
          buttons = buttons.slice(ndIndex, ndIndex + 1);
        }
        setTimeout(() => updateRadioOptions(), 1);
      } else {
        FormScripts._shared.get_versions(formWrapper, { desc: item }).then((versions: any[]) => {
          let versionIndex: number;
          if (versions && versions.length) {
            if (!Util.RestAPI.canAddNewVersion(versions, item.TYPE_ID)) {
              versionIndex = buttons.findIndex(b => b.split(';')[0] === 'NV');
              if (versionIndex !== -1) {
                buttons.splice(versionIndex, 1);
              }
            }
            let curDocVersion: any;
            if (!!formData['versionLabel']) {
              curDocVersion = versions.find((version) => version.VERSION_LABEL === formData['versionLabel']);
            }
            if (!Util.RestAPI.canAddNewSubversion(versions, curDocVersion?.VERSION, item.TYPE_ID)) {
              versionIndex = buttons.findIndex(b => b.split(';')[0] === 'NSV');
              if (versionIndex !== -1) {
                buttons.splice(versionIndex, 1);
              }
            }
            // Per DM Extensions, remove replace version radio from save-as menu, when the last version is read-only
            const lastVersionItem = Util.RestAPI.lastVersionItem(versions);
            const canUserEditPreviousVersions = Util.RestAPI.canUserEditVersion(versions, curDocVersion['VERSION']);
            if ((!!lastVersionItem && lastVersionItem['STATUS'] === '19') || (!!curDocVersion && (curDocVersion['STATUS'] === '19' || curDocVersion['STATUS'] === '20' || curDocVersion['READONLY'] === 'Y')) || !canUserEditPreviousVersions) {
              versionIndex = buttons.findIndex(b => b.split(';')[0] === 'RV');
              if (versionIndex !== -1) {
                buttons.splice(versionIndex, 1);
              }
            }
          }
          updateRadioOptions();
        }, err => {
          updateRadioOptions();
        });
        if (Util.Device.bIsOfficeAddinOutlook || (!!item && (item['STATUS'] === '18' || (item['STATUS'] === '3' && !isCheckedOutByMe) || item['READONLY'] === 'Y'))) {
          const rvIndex = buttons.findIndex(b => b.split(';')[0] === 'RV');
          if (rvIndex !== -1) {
            buttons.splice(rvIndex, 1);
          }
        }
      }
    }
  },
  activitysearch: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      setTimeout(() => {
        const dynamicForm: DynamicFormComponent = formWrapper.dynamicForm;
        const activityField: FormField = dynamicForm.getField('ACTIVITY');
        if (activityField) {
          const selectionMap: SelectItem[] = [];
          let i = 0;
          let foundAction = true;
          activityField.selectionMap = [];
          formWrapper.setLoading(false);
          setTimeout(() => {
            while (foundAction) {
              const display: string = localizer.getTranslation('HISTORY_ACTIONS.' + i);
              if (!!display && !display.startsWith('HISTORY_ACTIONS.')) {
                selectionMap.push({value:i.toString(),display});
                ++i;
              } else {
                foundAction = false;
              }
            }
            activityField.selectionMap = selectionMap;
          }, 300);
        }
      }, 1);
    }
  },
  date_range: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      setTimeout(() => {
        const date = new Date();
        const monthNum: number = date.getMonth()+1;
        const dayNum: number = date.getDate();
        const month: string = monthNum < 10 ? ('0' + monthNum) : monthNum.toString();
        const day: string = dayNum < 10 ? ('0' + dayNum) : dayNum.toString();
        const dateStr = date.getFullYear()+'-'+month+'-'+day;
        formWrapper.dynamicForm.getField('$edx_start_date').maxDate = dateStr;
      }, 1);
    },
    date_change(formWrapper: FormWrapperComponent, formTemplate: any, field: FormField, control: AbstractControl, localizer: LocalizeService) {
      const dynamicForm: DynamicFormComponent = formWrapper.dynamicForm;
      const value = control.value;
      let otherField: AbstractControl;
      if (field.name==='$edx_start_date') {
        otherField = dynamicForm.getControl('$edx_end_date');
        dynamicForm.getField('$edx_end_date').minDate = value;
      } else if (field.name==='$edx_end_date') {
        otherField = dynamicForm.getControl('$edx_start_date');
        dynamicForm.getField('$edx_start_date').maxDate = value;
      }
      if (!!otherField && !!dynamicForm.inlineParent) {
        const otherFieldValue = otherField.value;
        let dateRange = '';
        if (!!otherFieldValue && !!value) {
          if (field.name==='$edx_start_date') {
            dateRange = value + ' TO ' + otherFieldValue;
          } else {
            dateRange = otherFieldValue + ' TO ' + value;
          }
        }
        dynamicForm.markForCheck();
        setTimeout(() => {
          dynamicForm.inlineParent.inlineDateRangeChanged(dateRange, !!dateRange);
        }, 1);
      }
    }
  },
  rm_request: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      setTimeout(() => {
        const date = new Date();
        const monthNum: number = date.getMonth()+1;
        const dayNum: number = date.getDate();
        const month: string = monthNum < 10 ? ('0' + monthNum) : monthNum.toString();
        const day: string = dayNum < 10 ? ('0' + dayNum) : dayNum.toString();
        const dateStr = date.getFullYear()+'-'+month+'-'+day;
        formWrapper.dynamicForm.getField('%REQUEST_NOTREQUIREDAFTER_DATE').minDate = dateStr;
        formWrapper.dynamicForm.getField('%REQUEST_REQUIRED_DATE').minDate = dateStr;
      }, 1);
    },
    set_not_required_after(formWrapper: FormWrapperComponent, formTemplate: any, field: FormField, control: AbstractControl, localizer: LocalizeService) {
      const dateStr: string =  formWrapper.dynamicForm.getFieldValue('%REQUEST_REQUIRED_DATE');
      formWrapper.dynamicForm.getField('%REQUEST_NOTREQUIREDAFTER_DATE').minDate = dateStr;
      formWrapper.dynamicForm.updateControlValue('%REQUEST_NOTREQUIREDAFTER_DATE',dateStr,true);
    }
  },
  teams_appid: {
    click(formWrapper: FormWrapperComponent, formTemplate: any, field: FormField, control: AbstractControl, localizer: LocalizeService) {
      microsoftTeams.shareDeepLink({ subEntityId: '_', subEntityLabel: '_', subEntityWebUrl: null });
    },
    got_link(formWrapper: FormWrapperComponent, formTemplate: any, field: FormField, control: AbstractControl, localizer: LocalizeService) {
      if (!!control.value) {
        Util.Notify.confirmOK();
      }
    }
  },
  shareonedrive: {
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      const fromAddress: string = Util.RestAPI.getEmail();
      //Focus on first editable empty field
      formWrapper.disableFirstFocus = false;
      const nDefs: number = formTemplate.defs.length;
      for (let i=nDefs-1; i>=0; i--) {
        if (formTemplate.defs[i].name==='$edx_email_from') {
          formTemplate.defs[i].value = fromAddress;
        }
        if (formTemplate.defs[i].name==='$edx_version' || formTemplate.defs[i].name==='$edx_attachment' || formTemplate.defs[i].name==='$edx_share_type') {
          formTemplate.defs[i].flags = 0x01080000;
        }
      }
      FormScripts._shared.check_remove_email_pickers(formWrapper, formTemplate);
      setTimeout(() => {
        const singleDocName: string = (!!formData && !!formData['DOCUMENTS'] && formData['DOCUMENTS'].length===1) ? formData['DOCUMENTS'][0]['DOCNAME'] : null;
        const dynamicForm: DynamicFormComponent = formWrapper.dynamicForm;
        let setCC = false;
        let setBCC = false;
        let subject: string;

        if (!!singleDocName) {
          subject = localizer.getTranslation('FORMS.LOCAL.SHARE.SHARE_ONEDRIVE_SINGLE', [Util.RestAPI.getUserFullName(), singleDocName]);
        } else {
          subject = localizer.getTranslation('FORMS.LOCAL.SHARE.SHARE_ONEDRIVE_MANY', [Util.RestAPI.getUserFullName()]);
        }
        dynamicForm.updateControlValue('$edx_share_type', 'simplemail', true);
        dynamicForm.updateControlValue('$edx_email_from', fromAddress, true);
        dynamicForm.updateControlValue('$edx_link_scope', '', true);
        dynamicForm.updateControlValue('$edx_share_subject', subject, true);
        dynamicForm.updateControlValue('$edx_share_message', '', true);
        if (!!formWrapper.selections && formWrapper.selections.length) {
          const item = formWrapper.selections[0];
          dynamicForm.updateControlValue('$edx_link_scope', 'type=view&scope=organization', true);
          this.get_link_type(formWrapper,item,'type=view&scope=organization',localizer);
        }
        FormScripts.email.populate_address('$edx_email_to', dynamicForm, localizer);
        setCC = FormScripts.email.populate_address('$edx_email_cc', dynamicForm, localizer);
        setBCC = FormScripts.email.populate_address('$edx_email_bcc', dynamicForm, localizer);
        if (setCC || setBCC) {
          setTimeout(() => {
            formWrapper.showExtras(true);
          }, 300);
        }
      }, 1);
    },
    get_link_type(formWrapper: FormWrapperComponent, item: any, link_type: any, localizer: LocalizeService) {
      const handleError = (error) => {
        dynamicForm.updateControlValue('$edx_link_scope', '', true);
        formWrapper.setLoading(false);
        Util.Notify.error(localizer.getTranslation('FORMS.LOCAL.SHARE.LINK_ERROR'), error);
      };
      const dynamicForm: DynamicFormComponent = formWrapper.dynamicForm;
      const desc: BaseDesc = {id:item.id,lib:item.lib,type:item.type};
      Util.RestAPI.get(desc,'weblink',link_type).subscribe((data: any) => {
        try {
          if (!!data) {
            const link = data['webLink'];
            dynamicForm.updateControlValue('$edx_share_html_link', `<a target="_blank" href="${link}">${item.DOCNAME}</a>`);
          }
          formWrapper.setLoading(false);
        } catch (e) {
          handleError(e);
        }
      }, handleError);
    },
    choice_link_type(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      const dynamicForm: DynamicFormComponent = formWrapper.dynamicForm;
      const selections: any[] = formWrapper.selections;
      const scope: string = control.value.split('&')[1].split('=')[1];
      if (scope === 'invite') {
        dynamicForm.updateControlValue('$edx_share_type', 'invite', true);
        return;
      } else {
        dynamicForm.updateControlValue('$edx_share_type', 'simplemail', true);
      }
      if (!!selections && selections.length) {
        const item = selections[0];
        this.get_link_type(formWrapper,item,control.value,localizer);
      }
    },
    save_address(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      FormScripts.email.save_address(formWrapper, formFields, field, control, localizer);
    }
  },
  footer_options: {
    options: ['DOCNUMBER','DOCVERSION','DOCNAME','DOCAUTHOR','DOCTYPE','DOCAPPLICATION','DOCABSTRACT','DATE','DOCLIB','PAGENUMBERS','FONT','TEXTAREA','DOCCLIENTID','DOCCLIENTNAME','DOCMATTERID','DOCMATTERNAME'],
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      const optionsBox = Util.FieldMappings.templateField(formTemplate.defs, 'options');
      const autoCB = Util.FieldMappings.templateField(formTemplate.defs, 'auto');
      const dontShowCB = Util.FieldMappings.templateField(formTemplate.defs, 'dont_show');
      const xmlText = Util.FieldMappings.templateField(formTemplate.defs, 'xml');
      const optionsBoxFields: any[] = optionsBox.fields;
      const footerOptionsStr = Util.RestAPI.getPreference('edx_footer_options');
      const footerOptions = !!footerOptionsStr ? JSON.parse(footerOptionsStr) : {};
      let selectedCount = 0;
      for (const id of this.options) {
        if (!!footerOptions['xml'] && footerOptions['xml'].indexOf('</' + id + '>') !== -1) {
          footerOptions[id] = 1;
          selectedCount++;
        } else {
          footerOptions[id] = 0;
        }
        optionsBoxFields.push({
          fldtype: 'checkbox',
          label: localizer.getTranslation('FORMS.LOCAL.FOOTER_OPTIONS.' + id),
          datatype: '4',
          name: id,
          flags: 0x00000000,
          uncheckedtrigger: '0',
          checkedtrigger: '1',
          value: !!footerOptions[id] ? footerOptions[id] + '' : '0',
          scripttrigger: { script: 'check_click' }
        });
      }
      footerOptions['edx_select_all'] = selectedCount === optionsBoxFields.length ? '1' : '0';
      optionsBoxFields.push({
        fldtype: 'checkbox',
        label: localizer.getTranslation('FORMS.LOCAL.FOOTER_OPTIONS.SELALL'),
        datatype: '4',
        name: 'edx_select_all',
        flags: 0x00000000,
        uncheckedtrigger: '0',
        checkedtrigger: '1',
        value: footerOptions['edx_select_all'] || '0',
        scripttrigger: { script: 'check_click' }
      });
      autoCB.value = !!footerOptions['auto'] ? footerOptions['auto'] : '0';
      if (!footerOptions['dont_show']) {
        footerOptions['dont_show'] = '1';
        Util.RestAPI.setPreference('edx_footer_options', JSON.stringify(footerOptions));
      }
      dontShowCB.value = footerOptions['dont_show'];
      if (!!footerOptions['xml']) {
        xmlText.value = footerOptions['xml'];
      }
      setTimeout(() => {
        formWrapper.dynamicForm.updateControlValue('DOCNAME', ''+footerOptions['DOCNAME'], true);
      }, 10);
    },
    check_click(formWrapper: FormWrapperComponent, formFields: FormField[], field: FormField, control: AbstractControl, localizer: LocalizeService) {
      const selectAllField = 'edx_select_all';
      const selectAllChecked = formWrapper.dynamicForm.getFieldValue(selectAllField) === '1';
      const formValues = this.getFormValues(formWrapper);
      const allItemsChecked = Object.values(formValues).filter(i => i === '0').length === 0 ? true : false;
      formWrapper.setLoading(true);
      if (field.name === selectAllField) {
         for (const id of this.options) {
           formWrapper.dynamicForm.updateControlValue(id, control.value, true);
         }
      }
      if (field.name !== selectAllField) {
          if (selectAllChecked) {
            formWrapper.dynamicForm.updateControlValue(selectAllField, '0', true);
          } else if (allItemsChecked) {
            formWrapper.dynamicForm.updateControlValue(selectAllField, '1', true);
          }
      }
      let oldXmlStr = !!formWrapper.dynamicForm.getFieldValue('xml') ? formWrapper.dynamicForm.getFieldValue('xml') : '' ;
      let fontStyleValue = '\'Calibri\'';
      let fontSizeValue = '\'11\'';
      let pageNumberFontStyleValue = '\'Calibri\'';
      let pageNumberFontSizeValue = '\'11\'';
      const regexStyle = /STYLE\s*?=\s*?'(.*?)'/g;
      const regexSize = /SIZE\s*?=\s*?'(.*?)'/g;
      const regexPageNumberFont = /FONT\s*?=\s*?'(.*?)'/g;
      const regex = /'(.*?)'/g;
      let startTag  = '';
      let endTag = '';
      let cntrlVal = '';
      for (const id of this.options) {
        cntrlVal = formWrapper.dynamicForm.getFieldValue(id);
        startTag = '<'  +  id;
        endTag = `</${id}>`;
        if (cntrlVal === '1') {
          if (oldXmlStr) {
            if (oldXmlStr.includes('<FONT')) {
              const newFontStr = oldXmlStr.slice(oldXmlStr.indexOf('<FONT'), oldXmlStr.indexOf('</FONT>'));
              const fontStyle = newFontStr.match(regexStyle)[0];
              const fontSize = newFontStr.match(regexSize)[0];
              fontStyleValue = fontStyle.match(regex)[0];
              fontSizeValue = fontSize.match(regex)[0];
            }
            if (oldXmlStr.includes('<PAGENUMBERS')) {
              const newPageNumberFontStr = oldXmlStr.slice(oldXmlStr.indexOf('<PAGENUMBERS'), oldXmlStr.indexOf('</PAGENUMBERS>'));
              const pageNumberFontStyle = newPageNumberFontStr.match(regexPageNumberFont)[0];
              const pageNumberFontSize = newPageNumberFontStr.match(regexSize)[0];
              pageNumberFontStyleValue = pageNumberFontStyle.match(regex)[0];
              pageNumberFontSizeValue = pageNumberFontSize.match(regex)[0];
            }
          }
          if (!oldXmlStr || (!!oldXmlStr && oldXmlStr.indexOf(startTag) === -1)) {
            if (id === 'FONT') {
              oldXmlStr += `<FONT STYLE=${fontStyleValue} SIZE=${fontSizeValue}></FONT>`;
            } else if (id === 'PAGENUMBERS') {
              oldXmlStr += `<PAGENUMBERS ALIGN=\'RIGHT\' FONT=${pageNumberFontStyleValue} SIZE=${pageNumberFontSizeValue} TEXT1=\'${localizer.getTranslation('FORMS.LOCAL.FOOTER_OPTIONS.PAGE')} \' TEXT2=\'${localizer.getTranslation('FORMS.LOCAL.FOOTER_OPTIONS.SPACEOF')} \'></PAGENUMBERS>`;
            } else if (id === 'DOCVERSION') {
              oldXmlStr += '<DOCVERSION ALIGN=\'CENTER\'>' + localizer.getTranslation('FORMS.LOCAL.FOOTER_OPTIONS.' + id) + ': </DOCVERSION>';
            } else if (id === 'TEXTAREA') {
              oldXmlStr += '<TEXTAREA ALIGN=\'LEFT\'></TEXTAREA>';
            } else if (id === 'DATE') {
              oldXmlStr += '<DATE ALIGN=\'LEFT\'></DATE>';
            } else {
              oldXmlStr += startTag + ' ALIGN=\'LEFT\'>' + localizer.getTranslation('FORMS.LOCAL.FOOTER_OPTIONS.' + id) + ':' + endTag;
            }
          }
        } else if (cntrlVal === '0' && oldXmlStr.indexOf(startTag) !== -1) {
          oldXmlStr = oldXmlStr.replace(oldXmlStr.slice(oldXmlStr.indexOf(startTag), oldXmlStr.indexOf(endTag) + id.length + 3),'');
        }
      }
      formWrapper.dynamicForm.updateControlValue('xml', oldXmlStr, true);
      setTimeout(() => {
        formWrapper.setLoading(false);
      }, 300);
    },
    getFormValues(formWrapper: FormWrapperComponent) {
      const values = formWrapper.dynamicForm.form.value;
      Object.keys(values).forEach(value => {
        if (!this.options.includes(value)) {
          delete(values[value]);
        }
      });
      return values;
    },
    destroy(formWrapper: FormWrapperComponent, confirmed: boolean, allData: any, dirtyData: any, localizer?: LocalizeService, ooxmlService?: OOxmlService) {
      if (confirmed) {
        const footerOptionsStr = Util.RestAPI.getPreference('edx_footer_options');
        const footerOptions = !!footerOptionsStr ? JSON.parse(footerOptionsStr) : {};
        const keys = Object.keys(allData);
        for (const key of keys) {
          footerOptions[key] = allData[key];
        }
        const addInApplication = Util.Device.bIsOfficeAddinWord ? 'MS WORD' : (Util.Device.bIsOfficeAddinPowerPoint ? 'MS POWERPOINT' : 'MS EXCEL');
        const footerData: any = {};
        footerData['footeroptions'] = allData['xml'];
        if (!!dirtyData['auto']) {
          footerData['footerautoupdate'] = dirtyData['auto'] === '1' ? 'Y' : 'N';
        }
        if (!!dirtyData['dont_show']) {
          footerData['footershowoptionsdialog'] = dirtyData['dont_show']  === '1' ? 'N' : 'Y';
        }
        Util.RestAPI.setFooterOptions(JSON.stringify(footerData), addInApplication).subscribe(() => {
          Util.RestAPI.dismissPopup();
          Util.RestAPI.setPreference('edx_footer_options', JSON.stringify(footerOptions));
          const officeItem = Util.RestAPI.getOfficeItem();
          if (!!officeItem) {
              ooxmlService.addFooter(officeItem, true).then(() => {
            }, footerError => {
              console.log(footerError);
            });
          }
        }, error => {
          const appNotifyDialog = Util.Notify.getAppNotifyDialog();
          if (!!appNotifyDialog) {
            appNotifyDialog.doClose();
            appNotifyDialog.notifyAnimationComplete();
          }
          Util.Notify.showHide(true);
          Util.RestAPI.handleError(error);
        });
      }
    }
  },
  massprofile_results: {
    formData: null,
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      const nProfiles = formData.length;
      let nUpdated = 0;
      let nErorrs = 0;
      formData.forEach((i: any) => {
        if (!!i.error && i.error.code === 0) {
          ++nUpdated;
        } else if (!!i.error) {
          ++nErorrs;
        }
        if (!!i.error && i.error.message === '') {
          i.error.message = localizer.getTranslation('FORMS.BUTTONS.OK');
        }
      });
      this.formData = { nProfiles, nUpdated, nErorrs, DOCUMENTS: formData };
    },
    getFormData(dynamicForm: DynamicFormComponent, formTemplate: any) {
      return this.formData;
    },
    destroy(formWrapper: FormWrapperComponent, confirmed: boolean, allData: any, dirtyData: any) {
      this.formData = null;
    }
  },
  copytree_results: {
    formData: null,
    init(formWrapper: FormWrapperComponent, formTemplate: any, formData: any, localizer: LocalizeService) {
      const nFolders = formData['creates'];
      const nReference = formData['references'];
      const nErorrs = formData['errors'];
      this.formData = { nFolders, nReference, nErorrs};
    },
    getFormData(dynamicForm: DynamicFormComponent, formTemplate: any) {
      return this.formData;
    },
    destroy(formWrapper: FormWrapperComponent, confirmed: boolean, allData: any, dirtyData: any) {
      this.formData = null;
    }
  }
};
