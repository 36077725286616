import { Directive, HostListener, ElementRef, Input, Renderer2 } from '@angular/core';

import { Util } from '../utils/utils.module';

@Directive({
  selector: '[edx-draggable-file]'
})
export class DraggableFileDirective {
  @Input('edx-draggable-file') dragSource: any;
  private bIsGoodDrag = false;
  private svgData: string = null;
  constructor(private _elem: ElementRef, private _renderer: Renderer2) {
    setTimeout(() => {
      if (!!this.dragSource) {
        const dragAppID: string = this.dragSource.getDragAppID ? this.dragSource.getDragAppID(this._elem) : null;
        if (!!dragAppID) {
          Util.Transforms.svgDataFromAppID(dragAppID).then(data=> {
            this.svgData = data;
          }, err => {});
        }
      }
    }, 1);
  }

  @HostListener('dragstart', ['$event'])
  onDragStart(event) {
    const dt: DataTransfer = event.dataTransfer;
    const dragURL: string = this.dragSource.getDragURL ? this.dragSource.getDragURL(this._elem) : null;
    const dragName: string = this.dragSource.getDragName ? this.dragSource.getDragName(this._elem) : '';
    const dragJson: string = this.dragSource.getDragJson ? this.dragSource.getDragJson(this._elem) : null;
    const dragDesc: any = this.dragSource.getDragDesc ? this.dragSource.getDragDesc(this._elem) : null;
    const dragPromisedFile: string = this.dragSource.getDragPromisedFile ? this.dragSource.getDragPromisedFile(this._elem) : null;
    Util.RestAPI.setDragDesc(dragDesc);
    if (dragURL) {
      const fullUrl = Util.RestAPI.getServerURLOrigin()+dragURL;
      const downloadURL = '*/*:'+dragName+':'+fullUrl;
      dt.effectAllowed = this.dragSource.getEffectAllowed ? this.dragSource.getEffectAllowed() : 'copyMove';
      dt.setData('text/uri-list', fullUrl);
      dt.setData('DownloadURL', downloadURL);
      if (dragJson) {
        dt.setData('text/json', dragJson);
      }
      if (dragPromisedFile) {
        dt.setData('text/promised-file', dragPromisedFile);
      }
      if (!!this.svgData && !!dragDesc) {
        const imgUrl = this.svgData;
        const link = Util.RestAPI.makeDeepLink(dragDesc, ((!event.metaKey || Util.isContainer(dragDesc.type)) ? 'open' : 'download'), Util.Device.bIsTeamsAddIn);
        const html = '<div><img src="'+imgUrl+'" width="32" height="32"><a target="_blank" title="'+dragName+'" href="'+link+'" download><div style="display:inline-block;">'+dragName+'</div></a></div>';
        dt.setData('text/html', html);
      }
      this.bIsGoodDrag = true;
    }
    return this.bIsGoodDrag;
  }

  @HostListener('dragend', ['$event'])
  onDragEnd(event) {
    Util.RestAPI.showDropBanner(null);
    return this.bIsGoodDrag;
  }
}
