import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';

import { Util, UserInterface } from '../utils/utils.module';
import { BaseDesc } from '../models/base';
import { AppComponent } from '../app.component';
import { CommandHandler } from '../models/command-handler';

@Component({
  selector: 'edx-window-modal',
  styleUrls: ['window-modal.component.scss' ],
  template: `
    <div class="window-modal" [ngClass]="{ web: ui==0, desktop: ui==1, mobile: ui!=0&&ui!=1, oai: isOfficeAddin }">
      <edx-window-modal-header [parent]="this" [title]="getName()"></edx-window-modal-header>
      {{jsonResponseHdrs}}
      <br>
      {{jsonResponseData}}
    </div>
  `
})
export class WindowModalComponent implements OnInit, OnDestroy, CommandHandler {
  public ui: UserInterface;
  public isOfficeAddin: boolean;
  public jsonResponseHdrs = '';
  public jsonResponseData = '';
  protected name = '';
  protected desc: BaseDesc = {type:'',id:'',lib:''};
  protected rawUrl = '';
  private routeParamsListener: any = null;
  public lastCmd : string;

  constructor(protected location: Location, protected router: Router, protected route: ActivatedRoute) {
    this.ui = Util.Device.ui;
    this.isOfficeAddin = Util.Device.bIsOfficeAddin;
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  protected loadURL(url: string): void {
    // can override in subclasses to block the defualt action
    Util.RestAPI.get(url).toPromise().then((response: HttpResponse<any>) => {
      this.handleResponse(response);
    }).catch(this.handleError);
  }

  protected handleResponse(response: HttpResponse<any>): void {
    // can override in subclasses
    this.jsonResponseHdrs = response.headers.toString();
    this.jsonResponseData = JSON.stringify(response.body.data);
  }

  public settingUnrestrictedSecurity(): void {}

  public setIsOpening(): void {}

  public clearIsOpening(): void {}

  public refresh(cmd?: string, value?: any): void {}

  public getName(): string {
    return this.name;
  }

  public getDesc(): BaseDesc {
    return this.desc;
  }

  public getTooltip(): string {
    return this.name;
  }
  public changedName(newName: string): void {}

  public gotFocus(): void {}

  public doCommand(cmd: string): boolean {
    return false;
  }

  public getLastCommand(): string {
    return this.lastCmd;
  }

  public useURL(url: string): void {
    const app: AppComponent = Util.RestAPI.getAppComponent();
    this.rawUrl = url;
    url = Util.RestAPI.decodeChildRouteURL(url);
    const urlDesc = Util.RestAPI.getDescFromURL(url);
    this.name = Util.RestAPI.getNameFromURL(url);
    if (urlDesc.id.startsWith('evaluation')) {
      this.desc = urlDesc;
    } else {
      const openedDesc: any = Util.RestAPI.getCurDesc();
      if (openedDesc && openedDesc.id === urlDesc.id && openedDesc.lib === urlDesc.lib && openedDesc.type === urlDesc.type) {
        this.desc = openedDesc;
      } else {
        this.desc = urlDesc;
      }
    }
    if (this.desc && this.desc.lib) {
      this.desc.lib = this.desc.lib.split(',')[0];
    }
    app.desc = this.desc;
    this.loadURL(url);
    if (this.ui >= 2) {
      setTimeout(() => {
        app.curUrlChanged(this.rawUrl);
      }, 1);
    }
  }

  ngOnInit() {
    this.routeParamsListener = this.route.params.subscribe((params) => {
      const newUrl: string = this.router.url;
      // ignore hash urls
      if (newUrl && newUrl.indexOf('#') === -1) {
        this.useURL(newUrl);
      }
    });

    this.location.subscribe((data) => {
      if (data.type === 'popstate') {
        Util.RestAPI.setCurDescForFlexFolders(data.url);
      }
    });

    Util.RestAPI.setCurWindow(this);
  }

  ngOnDestroy() {
    if (this.routeParamsListener) {
      this.routeParamsListener.unsubscribe();
    }
    if (Util.RestAPI.getCurWindow() === this) {
      Util.RestAPI.setCurWindow(null);
    }
  }
}
