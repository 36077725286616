import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { WindowModalComponent } from '../windows/window-modal.component';
import { Util } from '../utils/utils.module';
import { _Transforms } from '../utils/transforms';
import { LocalizeService } from '../services/localize.service';
import { BaseDesc } from '../models/base';
import { DynamicFormComponent } from '../forms/dynamic-form.component';
import { ShortcutsService } from './shortcuts.service';

enum FormSendKind { kPost=0, kPut=1 }

@Injectable()
export class FormService {
  static localForms: any[] = null;
  private customForms: any = {};
  private transforms: _Transforms = Util.Transforms;
  public formUpdatedSuccessfully: boolean;
  private profileFieldsOnly: boolean;
  private mvFields: string[] = [];

  constructor(private localizer: LocalizeService, private shortcutService: ShortcutsService) {
    this.initLocalForms();
  }

  private handleError(error: any): Promise<any> {
    let error_code = null;
    if (!!error && !!error.error && !!error.error.ERROR && !!error.error.ERROR.rapi_code) {
      error_code = error.error.ERROR.rapi_code;
    }
    if (error_code === '0X8004013A') {
      const title = this.localizer.getTranslation('GENERIC_ERRORS.RESTRICTED');
      const body = this.localizer.getTranslation('GENERIC_ERRORS.NOT_AUTHORIZED');
      Util.RestAPI.navToURL(Util.RestAPI.getHomeURL());
      Util.Notify.warning(title, body, null, null, false, false, true);
    } else if (error_code === 18) {
      return Promise.reject(error_code);
    } else {
      console.log(Util.Transforms.formatError(error));
    }
    return Promise.resolve({});
  }

  public addCustomForms(vendor: string, forms: any[]): void {
    this.customForms[vendor] = forms;
  }

  // this is called by the receiver after data comes in to extract the Angular form model from the form data
  public toFormGroup(form: any): FormGroup {
    const group: any = {};
    if (form && form.defs) {
      form.defs.forEach(field => {
        if (field.fields && field.fields.length) {
          group[field.name] = this.toFormGroup({ defs: field.fields });
         } else {
          group[field.name] = field.IsRequired ? new FormControl(field.value || '', Validators.required) : new FormControl(field.value || '');
        }
      });
    }
    return new FormGroup(group);
  }

  public getFormData(desc: BaseDesc, kind: string, formName?: string): Promise<any> {
    return Util.RestAPI.getFormData(desc, kind, formName).toPromise().then(data => data, error => this.handleError(error));
  }

  public getFormTemplate(formName: string, library: string, addPaperStorageType: boolean): Promise<any>  {
    let json: any;
    if (!!formName && formName === 'shortcuts' ) {
      json = this.shortcutService.helpFormData;
      return Promise.resolve(json && json.data ? json.data : {});
    }
    if (!!formName && formName.startsWith(Util.kLocalFormPrefix)) {
      formName = Util.removeLocalFormPrefix(formName);
      json = this.getLocalFormTemplate(formName);
      return Promise.resolve(json && json.data ? json.data : {});
    } if (!!formName && formName.startsWith(Util.kCustomFormPrefix)) {
      formName = Util.removeCustomFormPrefix(formName);
      json = this.getCustomFormTemplate(formName);
      return Promise.resolve(json && json.data ? json.data : {});
    } else {
      return Util.RestAPI.getFormTemplate(formName, library).toPromise().then((template: any) => {
        let rc: any;
        try {
          formName = formName || template.name;
          if (addPaperStorageType) {
            const storage: any = Util.FieldMappings.templateField(template.defs, 'STORAGE');
            if (!!storage && storage.format && storage.selections && storage.format.indexOf('[=P]')===-1) {
              storage.format = '[=P]'+this.localizer.getTranslation('FORMS.BUTTONS.PAPER')+';'+storage.format;
              storage.selections.push({display:this.localizer.getTranslation('FORMS.BUTTONS.PAPER'),value:'P'});
              if (!Util.RestAPI.canUserManageTemplates()) {
                storage.selections = storage.selections.filter(selection => selection.value !== 'T');
              }
            }
          }
          Util.RestAPI.cacheForm(formName, library, JSON.stringify(template));
          rc = template;
        } catch (e) {
          rc = {};
        }
        return rc;
      }, error => this.handleError(error));
    }
  }

  public getLocalFormTemplate(formName: string): any {
    let form: any = null;

    for (const curForm of FormService.localForms) {
      if (curForm.data.name===formName) {
        form = Util.deepCopy(curForm);
        break;
      }
    }
    return Util.Transforms.translateForm(form);
  }

  private getCustomFormTemplate(formName: string): any {
    let form: any = null;
    const firstUS: number = formName.indexOf('_');
    const vendor: string = formName.substring(0, firstUS);
    formName = formName.substr(firstUS+1);
    if (!!this.customForms[vendor]) {
      for (const curForm of this.customForms[vendor]) {
        if (curForm.data.name===formName) {
          form = Util.deepCopy(curForm);
          Util.FieldMappings.forEachTemplateField(form.data.defs, false, (field): boolean => {
            if (!!field.schemaID) {
              field.schemaID = Util.kCustomFormPrefix + vendor + '_' + field.schemaID;
            }
            return true;
          });
          break;
        }
      }
    }
    return form;
  }

  private doSend(kind: FormSendKind, descOrCreateType: BaseDesc | string, data: any, params: string, queryargs: string, sendRaw: boolean , notifyTitle: string, notifyBody: string, notifyErrorTitle: string, notifyForm: string): void {
    let rc: Observable<Response>;
    if (kind===FormSendKind.kPut) {
      this.formUpdatedSuccessfully = true;
      rc = Util.RestAPI.put(descOrCreateType, data, params, queryargs, { sendRaw });
    } else if (kind===FormSendKind.kPost) {
      rc = Util.RestAPI.post(descOrCreateType, data, params, queryargs, { sendRaw });
    }
    if (rc) {
      rc.toPromise().then(response => {
        Util.RestAPI.refreshLists(typeof descOrCreateType !== 'string' ? descOrCreateType : null);
        if (!!response) {
          if (!!data['FILE_EXTENSION']) {
            response['FILE_EXTENSION'] = data['FILE_EXTENSION'];
          }
          Util.RestAPI.updateRecentLocinLocalStorage(data);
        }
        const curWindow: WindowModalComponent = Util.RestAPI.getCurWindow();
        if (curWindow) {
          curWindow.refresh(undefined,response);
        }
        if (!!notifyForm) {
          Util.Notify.info(notifyTitle, Util.kLocalFormPrefix + notifyForm, null, response);
        } else if (!!notifyTitle) {
          Util.Notify.success(notifyTitle, notifyBody);
        }
      }).catch(error => {
        this.formUpdatedSuccessfully = false;
        Util.Notify.warning(notifyErrorTitle || notifyTitle, error);
      });
    }
  }

  public putToServer(descOrCreateType: BaseDesc | string, data: any, params?: string ,queryargs?: string, sendRaw?: boolean, notifyTitle?: string, notifyBody?: string, notifyErrorTitle?: string, notifyForm?: string): void {
    this.doSend(FormSendKind.kPut, descOrCreateType, data, params, queryargs, sendRaw, notifyTitle, notifyBody, notifyErrorTitle, notifyForm);
  }

  public postToServer(descOrCreateType: BaseDesc | string, data: any, params?: string, queryargs?: string, sendRaw?: boolean, notifyTitle?: string, notifyBody?: string, notifyErrorTitle?: string, notifyForm?: string): void {
    this.doSend(FormSendKind.kPost, descOrCreateType, data, params, queryargs, sendRaw, notifyTitle, notifyBody, notifyErrorTitle, notifyForm);
  }

  public makeFormData(formName: string, list: any[]): any {
    let formData: any = null;

    if (formName.startsWith(Util.kLocalFormPrefix)) {
      const template: any = this.getLocalFormTemplate(Util.removeLocalFormPrefix(formName));
      if (template && template.data && template.data.defs) {
        const defs: any = template.data.defs;
        for (const def of defs) {
          if (def.fldtype==='list') {
            formData = {};
            formData[def.listKind] = list;
            break;
          }
        }
      }
    }
    if (!formData) {
      formData = list[0];
    }
    return formData;
  }

  public getSearchDescription(serverData: any, dynamicForm: DynamicFormComponent): string {
    //set the description for search criteria
    const searchDescription = [];
    Object.keys(serverData).forEach((key) => {
      //get the field info
      const field: any = dynamicForm.getField(key) || {};
      if (field.fldtype === 'edit' && (field.datatype === '1' || field.datatype === '8') && serverData[key]) {
        //date fields need to undo the local time to UTC offset
        let localDate: string = Util.Transforms.utcTimeRangeToLocalDate(serverData[key]);
        localDate = Util.Transforms.localizeDateRange(localDate);
        localDate = Util.Transforms.getBrowserFormatFromDmDate(localDate);
        searchDescription.push(localDate);
      } else if (field.fldtype === 'checkbox') {
        //for checkbox, push the prompt when checked, negate the prompt when unchecked
        const negation: string = serverData[key] === field.checkedValue ? '' : '~';
        searchDescription.push(negation + field.label);
      } else if (field.formatMap && (field.fldtype === 'radiogroup' || (field.controltype === 'combo' && key !== 'SEARCH_IN'))) {
        //for radio buttons or comboboxes, push the prompt based on the selections
        const selections: string[] = serverData[key].split(',').map((ic) => ic.trim());
        let buttons = [];
        if (field.fldtype === 'radiogroup') {
          buttons = field.buttonMap;
        } else if (field.controltype === 'combo') {
          buttons = field.formatMap;
        }
        searchDescription.push(buttons.filter((btn) => selections.indexOf(btn.value) >= 0).map((btn) => btn.display));
      } else if (key !== 'SEARCH_IN' && key !== '%SEARCH_ID') {
        if (serverData[key] !== '') {
          if (field.mvinfo) {
            this.mvFields.push(key);
          }
          searchDescription.push(serverData[key]);
        }
      }
    });

    return Util.Transforms.restoreQueryValue(searchDescription.join(','));
  }

  public isSearchOnlyProfileFields() {
    return this.profileFieldsOnly;
  }

  public getMultiValueFields() {
    return this.mvFields;
  }

  public updateSearchCriteria(serverData: any, formTemplate: any): void {
    if (serverData) {
      //security checkbox value should not be sent in the criteria, when it is in third state
      if (serverData['SECURITY'] === null) {
        delete serverData['SECURITY'];
      }
      this.profileFieldsOnly = serverData['SEARCH_IN'] === '1' || !serverData['FULLTEXT_EDIT'];
      for (const key of ['DOCNUM', 'DOCNAME']) {
        if (serverData[key]) {
          const value: string = serverData[key];
          serverData[key] = value.replace(/,/g, ';');
        }
      }
      if (serverData['FULLTEXT_EDIT']) {
        let str: string = serverData['FULLTEXT_EDIT'];
        str = str.replace(/</g, '[');
        str = str.replace(/>/g, ']');
        serverData['FULLTEXT_EDIT'] = str;
      }

      //modify the full text criteria as per the extensions.
      if (!!serverData['SEARCH_IN']) {
        // Adding criteria even when it's empty so that searched criteria is maintained for saving empty search.
        if (!!serverData['FULLTEXT_EDIT']) {
          serverData[Util.RestAPI.kFullTextTypes[serverData['SEARCH_IN']]] = serverData['FULLTEXT_EDIT'];
        }
        delete serverData['FULLTEXT_EDIT'];
        delete serverData['SEARCH_IN'];
      }
      for (const key in serverData) {
        const kNonTransformKeys = ['ITEM_TYPE', '%FOLDER_ID', 'FULLTEXT_CONTENT_PROFILE', 'STORAGE', 'STATUS'];
        if (kNonTransformKeys.indexOf(key) === -1) {
          serverData[key] = this.transforms.parseMultiValueSearchText(serverData[key]);
        }
      }
      if (formTemplate) {
        // change all dates into time range offset by local time to UTC
        const utcOffset: number = new Date().getTimezoneOffset() * 60 * 1000; // minutes to ms
        Util.FieldMappings.forEachTemplateField(formTemplate.defs, false, (field): boolean => {
          if (field.fldtype === 'edit' && (field.datatype === '1' || field.datatype === '8') && serverData[field.name]) {
            const localRange: string = Util.Transforms.offsetLocalDateToUTCTimeRange(serverData[field.name]);
            serverData[field.name] = localRange;
          }
          return true;
        });
      }
    }
  }

  private initLocalForms(): void {
    if (!FormService.localForms) {
      FormService.localForms = [
        {
          data : {
            fldtype: 'form',
            name: 'checkout',
            prompt: 'FORMS.LOCAL.CHECK_OUT.CHECK_OUT',
            height: 400,
            defs: [
              {
                fldtype: 'checkbox',
                datatype: '4',
                name: '%STATUS',
                flags: 0x03000000,
                uncheckedtrigger: '%LOCK',
                checkedtrigger: '%LOCK_FOR_CHECKOUT',
                value: '%LOCK_FOR_CHECKOUT'
              },
              {
                fldtype: 'list',
                name: '$edx_form_list',
                listKind: 'DOCUMENTS',
                prompt: 'FORMS.LOCAL.CHECK_OUT.CHECK_OUT',
                schemaID: 'FORM_DOCUMENT_LIST'
              },
              {
                fldtype: 'edit',
                name: '$edx_folder_loc',
                prompt: 'FORMS.LOCAL.CHECK_OUT.TO',
                flags: 0x00100000,
                scripttrigger: { script: 'external_checkout_location' },
                value: 'LOCAL',
                vistriggers: [{
                  hidden: true,
                  fields: ['%CHECKIN_LOCATION'],
                  values: ['NONE']
                }],
                selections: [
                  {
                    display: 'FORMS.LOCAL.CHECK_OUT.LOCAL_FOLDER',
                    value: 'LOCAL'
                  },
                  {
                    display: 'FORMS.LOCAL.CHECK_OUT.NO_FOLDER',
                    value: 'NONE'
                  }
                ]
              },
              {
                fldtype: 'edit',
                name: '%CHECKIN_LOCATION',
                lookup: '$edx_folder_picker',
                prompt: 'FORMS.LOCAL.CHECK_OUT.LOCATION',
                flags: 0x00000000
              },
              {
                fldtype: 'edit',
                datatype: '8',
                name: '%CHECKIN_DATE',
                prompt: 'FORMS.LOCAL.CHECK_OUT.CHECK_IN_DATE',
                flags: 0x00000000,
              },
              {
                fldtype: 'edit',
                name: '%CHECKOUT_COMMENT',
                prompt: 'FORMS.LOCAL.CHECK_OUT.COMMENT',
                flags: 0x00000080,
                h: 60,
              },
              {
                fldtype: 'guidetext',
                name: 'guidetext', // Need a way to set the value programmatically
                prompt: '',
                value: 'FORMS.LOCAL.CHECK_OUT.NOTE',
                flags: 0x00080000,
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'checkin',
            prompt: 'FORMS.LOCAL.CHECK_IN.CHECK_IN',
            defs: [
              {
                fldtype: 'list',
                name: '$edx_form_list',
                listKind: 'DOCUMENTS',
                prompt: 'FORMS.LOCAL.CHECK_IN.CHECK_IN',
                schemaID: 'FORM_DOCUMENT_LIST'
              },
              {
                fldtype: 'edit',
                name: '%CHECKIN_LOCATION',
                lookup: '$edx_file_picker',
                prompt: 'FORMS.LOCAL.CHECK_IN.FROM',
                flags: 0x00000042
              },
              {
                fldtype: 'edit',
                name: '$edx_new_version',
                prompt: 'FORMS.LOCAL.CHECK_IN.VERSIONING',
                flags: 0x00100000,
                value: 'NEW_VERSION',
                scripttrigger: { script: 'version_choice' },
                selections: [
                  {
                    display: 'FORMS.LOCAL.CHECK_IN.NEW_VERSION',
                    value: 'NEW_VERSION'
                  },
                  {
                    display: 'FORMS.LOCAL.CHECK_IN.NEW_SUB_VERSION',
                    value: 'NEW_SUB_VERSION'
                  },
                  {
                    display: 'FORMS.LOCAL.CHECK_IN.REPLACE_VERSION',
                    value: 'REPLACE_VERSION'
                  },
                  {
                    display: 'FORMS.LOCAL.CHECK_IN.NEW_DOCUMENT',
                    value: 'NEW_DOCUMENT'
                  }
                ]
              },
              {
                fldtype: 'text',
                name: '$edx_version_guide',
                prompt: 'Static Text',
                value: 'FORMS.LOCAL.CHECK_IN.NEW_VERSIONS',
                flags: 0x00000000,
              },
              {
                fldtype: 'edit',
                name: 'AUTHOR_ID',
                lookup: 'PEOPLE',
                sqlinfo: 'AUTHOR.AUTHOR_ID',
                prompt: 'FORMS.LOCAL.CHECK_IN.AUTHOR',
                flags: 0x00000002,
              },
              {
                fldtype: 'edit',
                name: 'TYPIST_ID',
                lookup: 'PEOPLE',
                sqlinfo: 'AUTHOR.TYPIST_ID',
                prompt: 'FORMS.LOCAL.CHECK_IN.ENTERED_BY',
                flags: 0x00000002,
              },
              {
                fldtype: 'edit',
                name: 'COMMENT',
                prompt: 'FORMS.LOCAL.CHECK_IN.COMMENT',
                flags: 0x00000080,
                h: 60,
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'checkout_open',
            prompt: 'FOLDER_ACTIONS.OPEN',
            flags: 0x00000000,
            defs: [
              {
                fldtype: 'guidetext',
                name: 'DOCNAME_NOTE',
                prompt: '',
                value: '',
                flags: 0x00080000
              },
              {
                fldtype: 'edit',
                name: 'CHECKOUT_DATE',
                prompt: 'FORMS.LOCAL.CHECK_OUT.ON',
                value: '',
                flags: 0x02000000
              },
              {
                fldtype: 'edit',
                name: 'CHECKED_OUT_BY',
                prompt: 'FORMS.LOCAL.CHECK_OUT.BY',
                value: '',
                flags: 0x02000000
              },
              {
                fldtype: 'edit',
                name: 'CHECKIN_DATE',
                prompt: 'FORMS.LOCAL.CHECK_OUT.RETURN_DATE',
                value: '',
                flags: 0x02000000
              },
              {
                fldtype: 'edit',
                name: 'COMMENTS',
                prompt: 'FORMS.LOCAL.CHECK_OUT.COMMENT',
                value: '',
                flags: 0x02000000
              },
              {
                fldtype: 'guidetext',
                name: 'OPEN_NOTE',
                prompt: '',
                value: 'FORMS.LOCAL.CHECK_OUT.OPEN_NOTE',
                flags: 0x00080000
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'newversion',
            prompt: 'FORMS.LOCAL.CHECK_IN.CHECK_IN',
            defs: [
              {
                fldtype: 'edit',
                name: '$edx_new_version',
                prompt: 'FORMS.LOCAL.CHECK_IN.ADD',
                flags: 0x00100000,
                value: 'NEW_VERSION',
                selections: [
                  {
                    display: 'FORMS.LOCAL.CHECK_IN.NEW_VERSION',
                    value: 'NEW_VERSION'
                  },
                  {
                    display: 'FORMS.LOCAL.CHECK_IN.NEW_SUB_VERSION',
                    value: 'NEW_SUB_VERSION'
                  }
                ]
              },
              {
                fldtype: 'edit',
                name: '%CHECKIN_LOCATION',
                lookup: '$edx_file_picker',
                prompt: 'FORMS.LOCAL.CHECK_IN.FROM',
                flags: 0x00000002
              },
              {
                fldtype: 'guidetext',
                name: 'guidetext', // Need a way to set the value programmatically
                prompt: '',
                value: 'FORMS.LOCAL.UPLOAD.OPTIONAL',
                flags: 0x00080000
              },
              {
                fldtype: 'edit',
                name: 'AUTHOR_ID',
                lookup: 'PEOPLE',
                sqlinfo: 'AUTHOR.AUTHOR_ID',
                prompt: 'FORMS.LOCAL.CHECK_IN.AUTHOR',
                flags: 0x00000002
              },
              {
                fldtype: 'edit',
                name: 'TYPIST_ID',
                lookup: 'PEOPLE',
                sqlinfo: 'AUTHOR.TYPIST_ID',
                prompt: 'FORMS.LOCAL.CHECK_IN.ENTERED_BY',
                flags: 0x00000002
              },
              { // Add comment field for new version
                fldtype: 'edit',
                name: 'COMMENT',
                prompt: 'FORMS.LOCAL.CHECK_IN.COMMENT',
                flags: 0x00000080,
                h: 60
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'delete',
            prompt: '',
            defs: [
              {
                fldtype: 'text',
                name: 'question',
                prompt: '',
                value: '',
                flags: 0x00000000
              },
              {
                fldtype: 'radiogroup',
                buttons: 'FORMS.LOCAL.DELETE.RADIO_BUTTONS',
                flags: 0,
                name: 'radio',
                prompt: '',
                value: 'DO'
              },
              {
                fldtype: 'guidetext',
                name: 'guidetext', // Need a way to set the value programmatically
                prompt: '',
                value: '',
                flags: 0x00080000
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'check_temp_file',
            prompt: '',
            defs: [
              {
                fldtype: 'text',
                name: 'question',
                prompt: '',
                value: '',
                flags: 0x00000000
              },
              {
                fldtype: 'guidetext',
                name: 'description',
                prompt: '',
                value: '',
                flags: 0x00000000
              },
              {
                fldtype: 'radiogroup',
                buttons: 'FORMS.LOCAL.CHECK_TEMP_FILE.RADIO_BUTTONS',
                flags: 0,
                name: 'radio',
                prompt: '',
                value: 'UTF'
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'cannot_checkout',
            prompt: '',
            defs: [
              {
                fldtype: 'text',
                name: 'question',
                prompt: '',
                value: '',
                flags: 0x00000000
              },
              {
                fldtype: 'guidetext',
                name: 'description',
                prompt: '',
                value: '',
                flags: 0x00000000
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'conflictbehavior',
            prompt: '',
            defs: [
              {
                fldtype: 'text',
                name: 'question',
                prompt: '',
                value: '',
                flags: 0x00000000
              },
              {
                fldtype: 'radiogroup',
                buttons: 'FORMS.LOCAL.CONFLICT_BEHAVIOR.RADIO_BUTTONS',
                flags: 0x00000000,
                name: 'radio',
                prompt: '',
                value: 'rename'
              },
              {
                fldtype: 'guidetext',
                name: 'guidetext', // Need a way to set the value programmatically
                prompt: '',
                value: '',
                flags: 0x00080000
              },
              {
                fldtype: 'text',
                name: 'file',
                prompt: '',
                value: '',
                flags: 0x01000000
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'publish',
            prompt: '',
            defs: [
              {
                fldtype: 'text',
                name: 'question',
                prompt: '',
                value: '',
                flags: 0x00000000
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'confirm_delete',
            extrasshown: true,
            defs: [
              {
                fldtype: 'list',
                name: '$edx_form_list',
                listKind: 'DOCUMENTS',
                prompt: this.localizer.getTranslation('FORMS.LOCAL.DELETE.NOTE'),
                schemaID: 'DELETE_LIST',
                scripttrigger: { script: 'confirmdelete' }
              },
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'export',
            prompt: 'FORMS.LOCAL.DOWNLOAD.DOWNLOAD',
            defs: [
              {
                fldtype: 'list',
                name: '$edx_form_list',
                listKind: 'DOCUMENTS',
                prompt: 'FORMS.LOCAL.DOWNLOAD.DOWNLOAD',
                schemaID: 'FORM_DOCUMENT_LIST'
              },
              {
                fldtype: 'edit',
                name: '$edx_download_type',
                prompt: 'FORMS.LOCAL.DOWNLOAD.DOWNLOAD_AS',
                flags: 0x00100000,
                value: 'DOWNLOAD',
                scripttrigger: { script: 'download_type_changed' },
                selections: [
                  {
                    display: 'FORMS.LOCAL.SHARE.DOCUMENTS',
                    value: 'DOWNLOAD'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.REFERENCES',
                    value: 'linkonly'
                  }
                ]
              },
              {
                fldtype: 'edit',
                name: '$edx_version',
                prompt: 'FORMS.LOCAL.DOWNLOAD.VERSION',
                flags: 0x00100000,
                value: 'C',
                scripttrigger: { script: 'choice_version' },
                selections: [
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.CURRENT',
                    value: 'C'
                  },
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.PUBLISHED',
                    value: 'P'
                  },
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.ALL_VERSIONS',
                    value: 'all_separate'
                  },
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.ALL_VERSIONS_ZIP',
                    value: 'all'
                  },
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.NONE',
                    value: 'none'
                  }
                ]
              },
              {
                fldtype: 'edit',
                name: '$edx_attachment',
                prompt: 'FORMS.LOCAL.DOWNLOAD.ATTACHMENT',
                flags: 0x00100000,
                value: 'none',
                scripttrigger: { script: 'choice_attachment' },
                selections: [
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.NONE',
                    value: 'none'
                  },
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.ALL_ATTACHMENTS',
                    value: 'all_separate'
                  },
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.ALL_ATTACHMENTS_ZIP',
                    value: 'all'
                  }
                ]
              },
              {
                fldtype: 'edit',
                name: '$edx_download_location',
                lookup: '$edx_folder_picker',
                prompt: 'FORMS.LOCAL.DOWNLOAD.TO',
                flags: 0x00000002
              },
              {
                fldtype: 'edit',
                name: '$edx_force_invalid',
                prompt: '',
                flags: 0x01000040,
                value: '1'
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'exportresults',
            prompt: 'FORMS.LOCAL.DOWNLOAD.DOWNLOAD',
            defs: [
              {
                fldtype: 'edit',
                name: '$edx_file_name',
                prompt: 'FORMS.LOCAL.DOWNLOAD.FILE_NAME',
                flags: 0x00000002,
                forceextension: true,
                value: 'download'
              },
              {
                fldtype: 'push',
                name: '$edx_export_select_all',
                flags: 0x00000000,
                forceextension: true,
                scripttrigger: { script: 'selectall' },
              },
              {
                fldtype: 'push',
                name: '$edx_export_select_default',
                flags: 0x00000000,
                extensionparent: '$edx_export_select_all',
                scripttrigger: { script: 'selectdefault' },
              },
              {
                prompt: 'CUSTOM_COLUMNS.EXPORT_COLUMNS',
                name: 'export_fields',
                fldtype: 'box',
                flags: 0x00000000,
                fields: []
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'email',
            prompt: 'FORMS.LOCAL.SHARE.SHARE',
            fullwidth: true,
            defs: [
              {
                fldtype: 'list',
                name: '$edx_form_list',
                listKind: 'DOCUMENTS',
                prompt: 'FORMS.LOCAL.SHARE.SHARE',
                schemaID: 'FORM_DOCUMENT_LIST'
              },
              {
                fldtype: 'edit',
                name: '$edx_share_type',
                prompt: 'FORMS.LOCAL.SHARE.SHARE_AS',
                flags: 0x00100000,
                scripttrigger: { script: 'share_type' },
                value: 'documents',
                selections: [
                  {
                    display: 'FORMS.LOCAL.SHARE.DOCUMENTS',
                    value: 'documents'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.REFERENCES',
                    value: 'linkonly'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.ALL',
                    value: 'all'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.DOCUMENTS_ZIP',
                    value: 'documentszip'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.REFERENCES_ZIP',
                    value: 'linkonlyzip'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.ALL_ZIP',
                    value: 'allzip'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.DOCUMENT_URL',
                    value: 'url'
                  }
                ]
              },
              {
                fldtype: 'edit',
                name: '$edx_link_action',
                prompt: 'FORMS.LOCAL.LINK.LINK',
                flags: 0x00100000,
                scripttrigger: { script: 'link_action' },
                value: 'download',
                selections: [
                  {
                    display: 'FORMS.LOCAL.LINK.ACTION_DOWNLOAD',
                    value: 'download'
                  },
                  {
                    display: 'FORMS.LOCAL.LINK.ACTION_OPEN',
                    value: 'open'
                  },
                  {
                    display: 'FORMS.LOCAL.LINK.ACTION_PROFILE',
                    value: 'profile'
                  },
                  {
                    display: 'FORMS.LOCAL.LINK.ACTION_PREVIEW',
                    value: 'profile_preview'
                  },
                  {
                    display: 'FORMS.LOCAL.LINK.ACTION_VIEW',
                    value: 'profile_viewer'
                  }
                ]
              },
              {
                fldtype: 'edit',
                name: '$edx_version',
                prompt: 'FORMS.LOCAL.DOWNLOAD.VERSION',
                flags: 0x00100000,
                value: 'C',
                scripttrigger: { script: 'choice_version' },
                selections: [
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.CURRENT',
                    value: 'C'
                  },
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.PUBLISHED',
                    value: 'P'
                  },
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.ALL_VERSIONS',
                    value: 'all'
                  },
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.NONE',
                    value: 'none'
                  }
                ]
              },
              {
                fldtype: 'edit',
                name: '$edx_attachment',
                prompt: 'FORMS.LOCAL.DOWNLOAD.ATTACHMENT',
                flags: 0x00100000,
                value: 'none',
                scripttrigger: { script: 'choice_attachment' },
                selections: [
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.NONE',
                    value: 'none'
                  },
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.ALL_ATTACHMENTS',
                    value: 'all'
                  }
                ]
              },
              {
                fldtype: 'edit',
                name: '$edx_email_from',
                prompt: 'FORMS.LOCAL.SHARE.FROM',
                flags: 0x00080000
              },
              {
                fldtype: 'edit',
                name: '$edx_email_to',
                prompt: 'FORMS.LOCAL.SHARE.TO',
                lookup: '$edx_email_picker',
                flags: 0x00000042,
                forceextension: true,
                vistriggers: [{
                  hidden: true,
                  fields: ['$edx_email_to_save'],
                  values: ['']
                }],
                scripttrigger: { script: 'address_changed' }
              },
              {
                fldtype: 'push',
                name: '$edx_email_to_save',
                prompt: 'FORMS.BUTTONS.SAVE',
                flags: 0x01000000,
                buttonimg: 'toolbar_save24.svg',
                extensionparent: '$edx_email_to',
                scripttrigger: { script: 'save_address' }
              },
              {
                fldtype: 'edit',
                name: '$edx_email_cc',
                prompt: 'FORMS.LOCAL.SHARE.CC',
                lookup: '$edx_email_picker',
                flags: 0x00000002,
                forceextension: true,
                vistriggers: [{
                  hidden: true,
                  fields: ['$edx_email_cc_save'],
                  values: ['']
                }]
              },
              {
                fldtype: 'push',
                name: '$edx_email_cc_save',
                prompt: 'FORMS.BUTTONS.SAVE',
                flags: 0x01000000,
                buttonimg: 'toolbar_save24.svg',
                extensionparent: '$edx_email_cc',
                scripttrigger: { script: 'save_address' }
              },
              {
                fldtype: 'edit',
                name: '$edx_email_bcc',
                prompt: 'FORMS.LOCAL.SHARE.BCC',
                lookup: '$edx_email_picker',
                flags: 0x00000002,
                forceextension: true,
                vistriggers: [{
                  hidden: true,
                  fields: ['$edx_email_bcc_save'],
                  values: ['']
                }]
              },
              {
                fldtype: 'push',
                name: '$edx_email_bcc_save',
                prompt: 'FORMS.BUTTONS.SAVE',
                flags: 0x01000000,
                buttonimg: 'toolbar_save24.svg',
                extensionparent: '$edx_email_bcc',
                scripttrigger: { script: 'save_address' }
              },
              {
                fldtype: 'edit',
                name: '$edx_share_subject',
                prompt: 'FORMS.LOCAL.SHARE.SUBJECT',
                flags: 0x00000000
              },
              {
                fldtype: 'edit',
                name: '$edx_share_message',
                prompt: 'FORMS.LOCAL.SHARE.MESSAGE',
                flags: 0x00000080,
                h: 100
              },
              {
                fldtype: 'edit',
                name: '$edx_force_invalid',
                prompt: '',
                flags: 0x01000040,
                value: '1'
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'outlook_email',
            prompt: 'FORMS.LOCAL.SHARE.SHARE',
            fullwidth: true,
            defs: [
              {
                fldtype: 'list',
                name: '$edx_form_list',
                listKind: 'DOCUMENTS',
                prompt: 'FORMS.LOCAL.SHARE.SHARE',
                schemaID: 'FORM_DOCUMENT_LIST'
              },
              {
                fldtype: 'edit',
                name: '$edx_share_type',
                prompt: 'FORMS.LOCAL.SHARE.SHARE_AS',
                flags: 0x00100000,
                scripttrigger: { script: 'share_type' },
                value: 'documents',
                selections: [
                  {
                    display: 'FORMS.LOCAL.SHARE.DOCUMENTS',
                    value: 'documents'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.REFERENCES',
                    value: 'linkonly'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.ALL',
                    value: 'all'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.DOCUMENTS_ZIP',
                    value: 'documentszip'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.REFERENCES_ZIP',
                    value: 'linkonlyzip'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.ALL_ZIP',
                    value: 'allzip'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.DOCUMENT_URL',
                    value: 'url'
                  }
                ]
              },
              {
                fldtype: 'edit',
                name: '$edx_link_action',
                prompt: 'FORMS.LOCAL.LINK.LINK',
                flags: 0x00100000,
                scripttrigger: { script: 'link_action' },
                value: 'download',
                selections: [
                  {
                    display: 'FORMS.LOCAL.LINK.ACTION_DOWNLOAD',
                    value: 'download'
                  },
                  {
                    display: 'FORMS.LOCAL.LINK.ACTION_OPEN',
                    value: 'open'
                  },
                  {
                    display: 'FORMS.LOCAL.LINK.ACTION_PROFILE',
                    value: 'profile'
                  },
                  {
                    display: 'FORMS.LOCAL.LINK.ACTION_PREVIEW',
                    value: 'profile_preview'
                  },
                  {
                    display: 'FORMS.LOCAL.LINK.ACTION_VIEW',
                    value: 'profile_viewer'
                  }
                ]
              },
              {
                fldtype: 'edit',
                name: '$edx_version',
                prompt: 'FORMS.LOCAL.DOWNLOAD.VERSION',
                flags: 0x00100000,
                value: 'C',
                scripttrigger: { script: 'choice_version' },
                selections: [
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.CURRENT',
                    value: 'C'
                  },
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.PUBLISHED',
                    value: 'P'
                  },
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.ALL_VERSIONS',
                    value: 'all'
                  },
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.NONE',
                    value: 'none'
                  }
                ]
              },
              {
                fldtype: 'edit',
                name: '$edx_attachment',
                prompt: 'FORMS.LOCAL.DOWNLOAD.ATTACHMENT',
                flags: 0x00100000,
                value: 'none',
                scripttrigger: { script: 'choice_attachment' },
                selections: [
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.NONE',
                    value: 'none'
                  },
                  {
                    display: 'FORMS.LOCAL.DOWNLOAD.ALL_ATTACHMENTS',
                    value: 'all'
                  }
                ]
              },
              {
                fldtype: 'edit',
                name: '$edx_outlook_email_from',
                prompt: 'FORMS.LOCAL.SHARE.FROM',
                sqlinfo: '$edx_outlook_email_from',
                flags: 0x00080000
              },
              {
                fldtype: 'edit',
                name: '$edx_outlook_email_to',
                prompt: 'FORMS.LOCAL.SHARE.TO',
                sqlinfo: '$edx_outlook_email_to',
                lookup: '$edx_contacts',
                _description: 'DISPLAYNAME',
                flags: 0x00000042,
                multiselect: true,
                multiselectsep: ';',
                selections: [],
              },
              {
                fldtype: 'edit',
                name: '$edx_outlook_email_cc',
                prompt: 'FORMS.LOCAL.SHARE.CC',
                sqlinfo: '$edx_outlook_email_cc',
                lookup: '$edx_contacts',
                _description: 'DISPLAYNAME',
                flags: 0x00000002,
                multiselect: true,
                multiselectsep: ';',
                selections: [],
                forceextension: true
              },
              {
                fldtype: 'edit',
                name: '$edx_outlook_email_bcc',
                prompt: 'FORMS.LOCAL.SHARE.BCC',
                sqlinfo: '$edx_outlook_email_bcc',
                lookup: '$edx_contacts',
                _description: 'DISPLAYNAME',
                flags: 0x00000002,
                multiselect: true,
                multiselectsep: ';',
                selections: [],
                forceextension: true
              },
              {
                fldtype: 'edit',
                name: '$edx_share_subject',
                prompt: 'FORMS.LOCAL.SHARE.SUBJECT',
                flags: 0x00000000
              },
              {
                fldtype: 'edit',
                name: '$edx_share_message',
                prompt: 'FORMS.LOCAL.SHARE.MESSAGE',
                flags: 0x00000080,
                h: 100
              },
              {
                fldtype: 'edit',
                name: '$edx_force_invalid',
                prompt: '',
                flags: 0x01000040,
                value: '1'
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'shareonedrive',
            prompt: 'FORMS.LOCAL.SHARE.ONEDRIVE',
            fullwidth: true,
            defs: [
              {
                fldtype: 'list',
                name: '$edx_form_list',
                listKind: 'DOCUMENTS',
                prompt: 'FORMS.LOCAL.SHARE.ONEDRIVE',
                schemaID: 'FORM_DOCUMENT_LIST'
              },
              {
                fldtype: 'edit',
                name: '$edx_email_from',
                prompt: 'FORMS.LOCAL.SHARE.FROM',
                flags: 0x00080000
              },
              {
                fldtype: 'edit',
                name: '$edx_share_type',
                prompt: 'FORMS.LOCAL.SHARE.SHARE_AS',
                flags: 0x00100000,
              },
              {
                fldtype: 'edit',
                name: '$edx_email_to',
                prompt: 'FORMS.LOCAL.SHARE.TO',
                lookup: '$edx_email_picker',
                flags: 0x00000042,
                forceextension: true,
                vistriggers: [{
                  hidden: true,
                  fields: ['$edx_email_to_save'],
                  values: ['']
                }]
              },
              {
                fldtype: 'edit',
                name: '$edx_link_scope',
                prompt: 'FORMS.LOCAL.SHARE.LINK_TYPE',
                flags: 0x00100042,
                value: 'C',
                scripttrigger: { script: 'choice_link_type' },
                selections: [
                  {
                    display: 'FORMS.LOCAL.SHARE.LINK_VIEW_ORGANIZATION',
                    value: 'type=view&scope=organization'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.LINK_EDIT_ORGANIZATION',
                    value: 'type=edit&scope=organization'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.LINK_VIEW_ANONYMOUS',
                    value: 'type=view&scope=anonymous'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.LINK_EDIT_ANONYMOUS',
                    value: 'type=edit&scope=anonymous'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.LINK_VIEW_INVITE',
                    value: 'role=read&scope=invite'
                  },
                  {
                    display: 'FORMS.LOCAL.SHARE.LINK_EDIT_INVITE',
                    value: 'role=write&scope=invite'
                  }
                ]
              },
              {
                fldtype: 'push',
                name: '$edx_email_to_save',
                prompt: 'FORMS.BUTTONS.SAVE',
                flags: 0x01000000,
                buttonimg: 'toolbar_save24.svg',
                extensionparent: '$edx_email_to',
                scripttrigger: { script: 'save_address' }
              },
              {
                fldtype: 'edit',
                name: '$edx_email_cc',
                prompt: 'FORMS.LOCAL.SHARE.CC',
                lookup: '$edx_email_picker',
                flags: 0x00000002,
                forceextension: true,
                isoption: true,
                vistriggers: [{
                  hidden: true,
                  fields: ['$edx_email_cc_save'],
                  values: ['']
                }]
              },
              {
                fldtype: 'push',
                name: '$edx_email_cc_save',
                prompt: 'FORMS.BUTTONS.SAVE',
                flags: 0x01000000,
                buttonimg: 'toolbar_save24.svg',
                extensionparent: '$edx_email_cc',
                scripttrigger: { script: 'save_address' }
              },
              {
                fldtype: 'edit',
                name: '$edx_email_bcc',
                prompt: 'FORMS.LOCAL.SHARE.BCC',
                lookup: '$edx_email_picker',
                flags: 0x00000002,
                forceextension: true,
                isoption: true,
                vistriggers: [{
                  hidden: true,
                  fields: ['$edx_email_bcc_save'],
                  values: ['']
                }]
              },
              {
                fldtype: 'push',
                name: '$edx_email_bcc_save',
                prompt: 'FORMS.BUTTONS.SAVE',
                flags: 0x01000000,
                buttonimg: 'toolbar_save24.svg',
                extensionparent: '$edx_email_bcc',
                scripttrigger: { script: 'save_address' }
              },
              {
                fldtype: 'edit',
                name: '$edx_share_subject',
                prompt: 'FORMS.LOCAL.SHARE.SUBJECT',
                flags: 0x00000000
              },
              {
                fldtype: 'edit',
                name: '$edx_share_message',
                prompt: 'FORMS.LOCAL.SHARE.MESSAGE',
                flags: 0x00000080,
                h: 100
              },
              {
                fldtype: 'edit',
                name: '$edx_force_invalid',
                prompt: '',
                flags: 0x01000040,
                value: '1'
              },
              {
                fldtype: 'edit',
                name: '$edx_share_html_link',
                prompt: '',
                flags: 0x01000000
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'downloadzip',
            prompt: '',
            defs: [
              {
                fldtype: 'edit',
                name: 'DOCNAME',
                maxchars: '240',
                prompt: 'FORMS.LOCAL.DOWNLOAD.DOWNLOAD',
                flags: 0x00080000
              },
              {
                fldtype: 'list',
                name: '$edx_form_list',
                listKind: 'DOCUMENTS',
                prompt: 'FORMS.LOCAL.DOWNLOAD.DOWNLOAD',
                schemaID: 'DOWNLOAD'
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'copy',
            prompt: '',
            defs: [
              {
                fldtype: 'edit',
                name: 'DOCNAME',
                maxchars: '240',
                prompt: 'FORMS.LOCAL.COPY.NEW_NAME',
                flags: 0x00000040
              },
              {
                fldtype: 'chooser',
                name: '$edx_chooser',
                prompt: 'FORMS.LOCAL.COPY.COPY_TO',
                flags: 0x00000000,
                scripttrigger: { script: 'location_change' },
                value: ''
              },
              {
                fldtype: 'edit',
                name: '$edx_version',
                prompt: 'FORMS.LOCAL.COPY.VERSIONS_TO_COPY',
                flags: 0x00100000,
                selections: []
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'copytree',
            prompt: '',
            defs: [
              {
                fldtype: 'edit',
                name: 'DOCNAME',
                maxchars: '240',
                prompt: 'FORMS.LOCAL.COPY_TREE.NEW_NAME',
                flags: 0x00000040
              },
              {
                fldtype: 'chooser',
                name: '$edx_chooser',
                prompt: 'FORMS.LOCAL.COPY_TREE.COPY_TO',
                flags: 0x00000000,
                scripttrigger: { script: 'location_change' },
                value: ''
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'copytree_results',
            prompt: '',
            extrasshown: true,
            defs: [
              {
                fldtype: 'edit',
                name: 'nFolders',
                prompt: 'FORMS.LOCAL.COPY_TREE.CREATED',
                flags: 0x00080000
              },
              {
                fldtype: 'edit',
                name: 'nReference',
                prompt: 'FORMS.LOCAL.COPY_TREE.REFERENCED',
                flags: 0x00080000
              },
              {
                fldtype: 'edit',
                name: 'nErorrs',
                prompt: 'FORMS.LOCAL.COPY_TREE.ERRORS',
                flags: 0x00080000
              }
            ]
          }
        },
        {
          /* do not change this form template without checking with form-scripts.ts permissions_selector */
          data : {
            fldtype: 'form',
            name: 'permissions_selector',
            prompt: '',
            localonly: true,
            defs: [
              {
                fldtype: 'edit',
                name: 'SECURITY',
                prompt: 'FORMS.LOCAL.PERMISSIONS_SELECTOR.PERMISSIONS',
                flags: 0x01100000,
                forceextension: true,
                scripttrigger: { script: 'choice' },
                value: '0',
                selections: [
                  {
                    display: 'FORMS.LOCAL.PERMISSIONS_SELECTOR.PUBLIC',
                    value: '0'
                  },
                  {
                    display: 'FORMS.LOCAL.PERMISSIONS_SELECTOR.PRIVATE',
                    value: '1'
                  },
                  {
                    display: 'FORMS.LOCAL.PERMISSIONS_SELECTOR.TRUSTEES_NOCHANGE',
                    value: '2'
                  },
                  {
                    display: 'FORMS.LOCAL.PERMISSIONS_SELECTOR.PRIVATE',
                    value: '3'
                  }
                ]
              },
              {
                fldtype: 'push',
                name: '$edx_permissions_edit',
                prompt: 'FORMS.LOCAL.PERMISSIONS_SELECTOR.EDIT',
                flags: 0x01000000,
                extensionparent: 'SECURITY',
                scripttrigger: { script: 'edit' },
                value: 'FORMS.LOCAL.PERMISSIONS_SELECTOR.EDIT'
              },
              {
                fldtype: 'push',
                name: '$edx_permissions_cancel',
                prompt: 'FORMS.BUTTONS.CANCEL',
                flags: 0x01000000,
                extensionparent: 'SECURITY',
                scripttrigger: { script: 'cancel' },
                value: 'FORMS.BUTTONS.CANCEL'
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'filetransfer',
            prompt: '',
            extrasshown: true,
            defs: [
              {
                fldtype: 'list',
                listKind: 'DOCUMENTS',
                prompt: 'FORMS.LOCAL.DOWNLOAD.DOWNLOAD',
                schemaID: 'FILE_TRANSFER'
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'rename',
            prompt: '',
            localonly: true,
            defs: [
              {
                fldtype: 'edit',
                name: 'DOCNAME',
                maxchars: '240',
                prompt: 'FORMS.LOCAL.RENAME.NAME',
                flags: 0x00000040
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'pick_version',
            prompt: 'FORMS.LOCAL.PICK_VERSION.CHOOSE_VERSION',
            defs: [
              {
                fldtype: 'edit',
                name: '$edx_version',
                prompt: 'FORMS.LOCAL.DOWNLOAD.VERSION',
                flags: 0x00100000,
                scripttrigger: { script: 'validate_published' },
                selections: []
              },
              {
                fldtype: 'guidetext',
                name: 'publishnote',
                prompt: '',
                value: 'FORMS.LOCAL.DOWNLOAD.OPEN_PUBLISHED',
                flags: 0x01080000
              },
              {
                fldtype: 'guidetext',
                name: 'readonlynote',
                prompt: '',
                value: 'FORMS.LOCAL.DOWNLOAD.OPEN_READONLY',
                flags: 0x01080000
              },
              {
                fldtype: 'edit',
                name: '$edx_currentversion_id',
                prompt: '',
                value: '',
                flags: 0x01000000
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'template_options',
            prompt: 'FORMS.LOCAL.TEMPLATE_OPTIONS.PROMPT',
            defs: [
              {
                fldtype: 'guidetext',
                name: 'OPTIONS_NOTE',
                prompt: '',
                value: 'FORMS.LOCAL.TEMPLATE_OPTIONS.GUIDE',
                flags: 0x00080000
              },
              {
                fldtype: 'radiogroup',
                buttons: 'FORMS.LOCAL.TEMPLATE_OPTIONS.TEMPLATE_RADIO_BUTTONS',
                flags: 0x00000000,
                name: 'use_edit',
                prompt: 'FORMS.LOCAL.TEMPLATE_OPTIONS.TEMPLATE_RADIO_BUTTONS_LABEL',
                value: 'U'
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'preferences_general',
            prompt: '',
            extrasshown: true,
            localonly: true,
            defs: [
              {
                prompt: 'FORMS.LOCAL.PREFERENCES.OPEN_RG',
                name: 'opening',
                fldtype: 'box',
                flags: 0x00000000,
                fields: [
                  {
                    fldtype: 'radiogroup',
                    buttons: 'FORMS.LOCAL.PREFERENCES.OPEN_RADIO_BUTTONS',
                    flags: 0x00000000,
                    name: 'def_open',
                    forceextension: true,
                    scripttrigger: { script: 'version_option_change' },
                    value: 'L'
                  },
                  {
                    fldtype: 'checkbox',
                    label: '',
                    datatype: '4',
                    name: 'def_open_o',
                    flags: 0x01000000,
                    uncheckedtrigger: '0',
                    checkedtrigger: '1',
                    extensionparent: 'def_open',
                    arialabel: 'ADMIN.TILES.USER_OVERRIDE',
                    value: '1'
                  },
                  {
                    fldtype: 'checkbox',
                    label: 'FORMS.LOCAL.PREFERENCES.OPEN_CHECKOUT',
                    datatype: '4',
                    name: 'def_checkout_open',
                    flags: 0x00000000,
                    uncheckedtrigger: '0',
                    checkedtrigger: '1',
                    forceextension: true,
                    value: '1'
                  },
                  {
                    fldtype: 'checkbox',
                    label: '',
                    datatype: '4',
                    name: 'def_checkout_open_o',
                    flags: 0x01000000,
                    uncheckedtrigger: '0',
                    checkedtrigger: '1',
                    extensionparent: 'def_checkout_open',
                    arialabel: 'FORMS.LOCAL.PREFERENCES.OPEN_CHECKOUT',
                    value: '1'
                  }
                ]
              },
              {
                prompt: 'FORMS.LOCAL.PREFERENCES.SHARE_CB',
                name: 'emailing',
                fldtype: 'box',
                flags: 0x00000000,
                fields: [
                  {
                    fldtype: 'checkbox',
                    label: 'FORMS.LOCAL.PREFERENCES.SHARE_WARN',
                    datatype: '4',
                    name: 'warn_share_secured',
                    flags: 0x00000000,
                    uncheckedtrigger: '0',
                    checkedtrigger: '1',
                    forceextension: true,
                    value: '0'
                  },
                  {
                    fldtype: 'checkbox',
                    label: '',
                    datatype: '4',
                    name: 'warn_share_secured_o',
                    flags: 0x01000000,
                    uncheckedtrigger: '0',
                    checkedtrigger: '1',
                    extensionparent: 'warn_share_secured',
                    arialabel: 'FORMS.LOCAL.PREFERENCES.SHARE_WARN',
                    value: '1'
                  }
                ]
              },
              {
                prompt: 'FORMS.LOCAL.PREFERENCES.UPLOADING',
                name: 'uploading',
                fldtype: 'box',
                flags: 0x00000000,
                fields: [
                  {
                    fldtype: 'checkbox',
                    label: 'FORMS.LOCAL.PREFERENCES.DELETE_UPLOAD',
                    datatype: '4',
                    name: 'delete_upload',
                    flags: 0x00000000,
                    uncheckedtrigger: '0',
                    checkedtrigger: '1',
                    forceextension: true,
                    value: '0'
                  },
                  {
                    fldtype: 'checkbox',
                    label: '',
                    datatype: '4',
                    name: 'delete_upload_o',
                    flags: 0x01000000,
                    uncheckedtrigger: '0',
                    checkedtrigger: '1',
                    extensionparent: 'delete_upload',
                    arialabel: 'FORMS.LOCAL.PREFERENCES.DELETE_UPLOAD',
                    value: '1'
                  }
                ]
              },
              {
                prompt: 'FORMS.LOCAL.PREFERENCES.SAVING',
                name: 'saving',
                fldtype: 'box',
                flags: 0x00000000,
                fields: [
                  {
                    fldtype: 'checkbox',
                    label: 'FORMS.LOCAL.PREFERENCES.DELETE_ORIGINAL',
                    datatype: '4',
                    name: 'delete_original',
                    flags: 0x00000000,
                    uncheckedtrigger: '0',
                    checkedtrigger: '1',
                    forceextension: true,
                    value: '0'
                  },
                  {
                    fldtype: 'checkbox',
                    label: '',
                    datatype: '4',
                    name: 'delete_original_o',
                    flags: 0x01000000,
                    uncheckedtrigger: '0',
                    checkedtrigger: '1',
                    extensionparent: 'delete_original',
                    arialabel: 'FORMS.LOCAL.PREFERENCES.DELETE_ORIGINAL',
                    value: '1'
                  },
                  {
                    fldtype: 'checkbox',
                    label: 'FORMS.LOCAL.PREFERENCES.DELETE_ORIGINAL_TEAMS',
                    datatype: '4',
                    name: 'delete_original_teams',
                    flags: 0x00000000,
                    uncheckedtrigger: '0',
                    checkedtrigger: '1',
                    forceextension: true,
                    value: '0'
                  },
                  {
                    fldtype: 'checkbox',
                    label: '',
                    datatype: '4',
                    name: 'delete_original_teams_o',
                    flags: 0x01000000,
                    uncheckedtrigger: '0',
                    checkedtrigger: '1',
                    extensionparent: 'delete_original_teams',
                    arialabel: 'FORMS.LOCAL.PREFERENCES.DELETE_ORIGINAL_TEAMS',
                    value: '1'
                  },
                  {
                    fldtype: 'checkbox',
                    label: 'FORMS.LOCAL.PREFERENCES.INSTANT_SAVE',
                    datatype: '4',
                    name: 'instant_save',
                    flags: 0x00000000,
                    uncheckedtrigger: '0',
                    checkedtrigger: '1',
                    forceextension: true,
                    value: '1'
                  },
                  {
                    fldtype: 'checkbox',
                    label: '',
                    datatype: '4',
                    name: 'instant_save_o',
                    flags: 0x01000000,
                    uncheckedtrigger: '0',
                    checkedtrigger: '1',
                    extensionparent: 'instant_save',
                    arialabel: 'FORMS.LOCAL.PREFERENCES.INSTANT_SAVE',
                    value: '1'
                  }
                ]
              },
              {
                prompt: 'FORMS.LOCAL.PREFERENCES.SHORTCUTS',
                name: 'shortcuts',
                fldtype: 'box',
                flags: 0x00000000,
                fields: [
                  {
                    fldtype: 'checkbox',
                    label: 'FORMS.LOCAL.PREFERENCES.ENABLE_SHORTCUTS',
                    datatype: '4',
                    name: 'enable_shortcuts',
                    flags: 0x00000000,
                    uncheckedtrigger: '0',
                    checkedtrigger: '1',
                    forceextension: true,
                    value: '1'
                  },
                  {
                    fldtype: 'checkbox',
                    label: '',
                    datatype: '4',
                    name: 'enable_shortcuts_o',
                    flags: 0x01000000,
                    uncheckedtrigger: '0',
                    checkedtrigger: '1',
                    extensionparent: 'enable_shortcuts',
                    arialabel: 'FORMS.LOCAL.PREFERENCES.ENABLE_SHORTCUTS',
                    value: '1'
                  }
                ]
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'preferences_tiles',
            prompt: '',
            extrasshown: true,
            localonly: true,
            defs: [
              {
                prompt: 'FOLDER_ACTIONS.ADD_TO_HOME',
                name: 'add_to_home',
                fldtype: 'box',
                flags: 0x00000000,
                fields: []
              },
              {
                prompt: 'FORMS.LOCAL.PREFERENCES.DEFAULT',
                name: 'home_default',
                fldtype: 'box',
                flags: 0x00000000,
                fields: []
              },
              {
                prompt: 'FORMS.LOCAL.PREFERENCES.ADDITIONAL',
                name: 'home_additional',
                fldtype: 'box',
                flags: 0x00000000,
                fields: []
              },
              {
                prompt: 'FORMS.LOCAL.PREFERENCES.CUSTOM',
                name: 'home_custom',
                fldtype: 'box',
                flags: 0x00000000,
                fields: []
              }
            ]
          }
        },
        {
          data: {
            fldtype: 'form',
            name: 'group_footer_defaults',
            prompt: '',
            extrasshown: false,
            localonly: true,
            defs: [
              {
                name: 'show_footer_options',
                fldtype: 'box',
                prompt:'',
                flags: 0x00000000,
                fields: []
              }
            ]

          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'group_tile_settings',
            prompt: '',
            extrasshown: true,
            localonly: true,
            defs: [
              {
                prompt: 'FORMS.LOCAL.PREFERENCES.DEFAULT',
                name: 'home_default',
                fldtype: 'box',
                flags: 0x00000000,
                fields: []
              },
              {
                prompt: 'FORMS.LOCAL.PREFERENCES.ADDITIONAL',
                name: 'home_additional',
                fldtype: 'box',
                flags: 0x00000000,
                fields: []
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'remove',
            prompt: '',
            defs: [
              {
                fldtype: 'text',
                name: 'question',
                prompt: '',
                value: '',
                flags: 0x00000000
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'url',
            prompt: '',
            defs: [
              {
                fldtype: 'edit',
                name: 'DISPLAYNAME',
                prompt: 'FORMS.LOCAL.URL.NAME',
                flags: 0x00000040
              },
              {
                fldtype: 'edit',
                name: 'URL_ADDRESS',
                prompt: 'FORMS.LOCAL.URL.URL',
                flags: 0x00000040
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'savesearch',
            prompt: '',
            localonly: true,
            defs: [
              {
                fldtype: 'edit',
                name: 'save_search_as',
                prompt: '',
                value: 'FORMS.LOCAL.SAVE_SEARCH.SAVE_SEARCH_AS',
                flags: 0x00080000
              },
              {
                fldtype: 'edit',
                name: '$edx_savesearch_combo',
                prompt: '',
                flags: 0x00100000,
                scripttrigger: { script: 'search_change' },
                value: '0',
                selections: [
                  {
                    display: 'FORMS.LOCAL.SAVE_SEARCH.SAVE_SEARCH',
                    value: '0'
                  },
                  {
                    display: 'FORMS.LOCAL.SAVE_SEARCH.NEW_SAVE_SEARCH',
                    value: '1'
                  }
                ]
              },
              {
                fldtype: 'edit',
                name: 'update_saved_search',
                prompt: '',
                value: 'FORMS.LOCAL.SAVE_SEARCH.UPDATE_SAVE_SEARCH',
                flags: 0x00080000
              },
              {
                fldtype: 'edit',
                name: 'name',
                maxchars: '60',
                prompt: '',
                flags: 0x00000040
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'advancedsearch',
            prompt: '',
            defs: [
              {
                fldtype: 'text',
                name: '',
                prompt: 'Static Text',
                value: 'FORMS.LOCAL.ADVANCED_SEARCH.SEARCH_WITHIN',
                flags: 0x00080000,
              },
              {
                fldtype: 'edit',
                name: '$edx_advsearch_exact_phrase',
                prompt: 'FORMS.LOCAL.ADVANCED_SEARCH.EXACT_WORD',
                value: '',
                flags: 0x00000000
              },
              {
                fldtype: 'edit',
                name: '$edx_advsearch_all_these_words',
                prompt: 'FORMS.LOCAL.ADVANCED_SEARCH.ALL_THESE_WORDS',
                value: '',
                flags: 0x00000000
              },
              {
                fldtype: 'edit',
                name: '$edx_advsearch_any_of_these_words',
                prompt: 'FORMS.LOCAL.ADVANCED_SEARCH.ANY_OF_THESE_WORDS',
                value: '',
                flags: 0x00000000
              },
              {
                fldtype: 'edit',
                name: '$edx_advsearch_nearby_words',
                prompt: 'FORMS.LOCAL.ADVANCED_SEARCH.NEARBY_WORDS',
                value: '',
                flags: 0x00000000
              },
              {
                fldtype: 'edit',
                name: '$edx_advsearch_word_prox',
                prompt: 'FORMS.LOCAL.ADVANCED_SEARCH.WORD_PROXIMITY',
                datatype: '4',
                value: '5',
                flags: 0x00000000
              },
              {
                fldtype: 'edit',
                name: '$edx_advsearch_not_these_words',
                prompt: 'FORMS.LOCAL.ADVANCED_SEARCH.NOT_THESE_WORDS',
                value: '',
                flags: 0x00000000
              }
            ]
          }
        },
        {
          data: {
            fldtype: 'form',
            name: 'activitysearch',
            prompt: '',
            defs: [
              {
                fldtype: 'edit',
                name: 'ACTIVITY',
                prompt: 'FORMS.LOCAL.ACTIVITY_SEARCH.ACTIVITY',
                flags: 0x00100000,
                value: '',
                selections: [{display:'TEMP', value: '-1'}]
              },
              {
                fldtype: 'edit',
                datatype: '4',
                name: 'DOCNUM',
                prompt: 'FORMS.LOCAL.FILTERS_MOBILE.DOCNUM',
                sqlinfo: 'DOCNUMBER',
                flags: 0x00000004
              },
              {
                fldtype: 'edit',
                name: 'AUTHOR_ID',
                prompt: 'FORMS.LOCAL.FILTERS_MOBILE.AUTHOR',
                sqlinfo: 'AUTHOR.AUTHOR_ID;DOCSADM.PEOPLE.SYSTEM_ID',
                maxchars: '20',
                flags: 0x0000000E,
                lookup: 'PEOPLE'
              },
              {
                fldtype: 'edit',
                name: 'TYPIST_ID',
                prompt: 'FORMS.LOCAL.FILTERS_MOBILE.TYPIST',
                sqlinfo: 'AUTHOR.TYPIST_ID;DOCSADM.PEOPLE.SYSTEM_ID',
                maxchars: '20',
                flags: 0x0000000E,
                lookup: 'PEOPLE'
              },
              {
                fldtype: 'edit',
                name: 'APP_ID',
                prompt: 'FORMS.LOCAL.FILTERS_MOBILE.APPLICATION',
                sqlinfo: 'APPLICATION.APPLICATION;DOCSADM.APPS.SYSTEM_ID',
                flags: 0x0000000E,
                lookup: '_APPS'
              },
              {
                fldtype: 'edit',
                name: 'START_DATE',
                prompt: 'FORMS.LOCAL.ACTIVITY_SEARCH.START_DATE',
                datatype: '8',
                sqlinfo: 'LAST_EDIT_DATE',
                lookup: '$edx_date_range',
                flags: 0x00000006
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'filters_mobile',
            prompt: '',
            defs: [
              {
                fldtype: 'edit',
                name: 'FULLTEXT_EDIT',
                prompt: 'FORMS.LOCAL.FILTERS_MOBILE.NAME_CONTAINS',
                flags: 0x00000000
              },
              {
                fldtype: 'radiogroup',
                name: 'ITEM_TYPE',
                prompt: 'FORMS.LOCAL.FILTERS_MOBILE.TYPES',
                value: '',
                buttons: 'FORMS.LOCAL.FILTERS_MOBILE.TYPES_BUTTONS',
                flags: 0x00000000
              },
              {
                fldtype: 'edit',
                name: 'AUTHOR_ID',
                prompt: 'FORMS.LOCAL.FILTERS_MOBILE.AUTHOR',
                sqlinfo: 'AUTHOR.USER_ID;DOCSADM.PEOPLE.SYSTEM_ID',
                maxchars: '20',
                flags: 0x0000004E,
                lookup: 'PEOPLE'
              },
              {
                fldtype: 'edit',
                name: 'LAST_EDIT_DATE',
                prompt: 'FORMS.LOCAL.FILTERS_MOBILE.EDIT_DATE',
                datatype: '8',
                sqlinfo: 'LAST_EDIT_DATE',
                flags: 0x00000004
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'locations_chooser',
            prompt: '',
            localonly: true,
            defs: [
              {
                fldtype: 'push',
                name: '$edx_locations_chooser',
                prompt: 'FORMS.LOCAL.LOCATIONS_CHOOSER.LOCATIONS',
                flags: 0x00000000,
                scripttrigger: { script: 'click' },
                value: 'FORMS.LOCAL.LOCATIONS_CHOOSER.LOCATIONS'
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'authenticate',
            prompt: '',
            defs: [
              {
                fldtype: 'edit',
                name: 'server',
                prompt: 'FORMS.LOCAL.AUTHENTICATE.SERVER',
                flags: 0x00080004
              },
              {
                fldtype: 'edit',
                name: 'userid',
                prompt: 'FORMS.LOCAL.AUTHENTICATE.USERID',
                flags: 0x00000044
              },
              {
                fldtype: 'edit',
                name: 'password',
                prompt: 'FORMS.LOCAL.AUTHENTICATE.PASSWORD',
                flags: 0x00000064
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'rm_request',
            prompt: '',
            defs: [
              {
                fldtype: 'list',
                name: '$edx_form_list',
                listKind: 'DOCUMENTS',
                prompt: ' ',
                schemaID: 'RM_DOCUMENT_LIST'
              },
              {
                fldtype: 'edit',
                name: 'USER_ID',   // Lookup key is USER_ID but REQUEST command uses mapped in "%REQUESTFOR_USERID",
                prompt: 'FORMS.LOCAL.RM_REQUEST.REQUEST_FOR',
                sqlinfo: 'AUTHOR.USER_ID',
                maxchars: '20',
                flags: 0x0000004E,
                lookup: 'PEOPLE'
              },
              {
                fldtype: 'edit',
                datatype: '8',
                name: '%REQUEST_REQUIRED_DATE',
                prompt: 'FORMS.LOCAL.RM_REQUEST.REQUIRED_DATE',
                flags: 0x00000040,
                scripttrigger: { script: 'set_not_required_after' }
              },
              {
                fldtype: 'edit',
                datatype: '8',
                name: '%REQUEST_NOTREQUIREDAFTER_DATE',
                prompt: 'FORMS.LOCAL.RM_REQUEST.NOTREQUIREDAFTER_DATE',
                flags: 0x00000000,
                isoption: true
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'rm_loanrequest',
            prompt: '',
            extrasshown: true,
            defs: [
              {
                fldtype: 'list',
                name: '$edx_form_list',
                listKind: 'DOCUMENTS',
                prompt: ' ',
                schemaID: 'RM_DOCUMENT_LIST'
              },
              {
                fldtype: 'guidetext',
                name: 'guidetext',
                prompt: ' ',
                value: ' ',
                flags: 0x00080000
              },
              {
                fldtype: 'edit',
                name: 'USER_ID', // Lookup key is USER_ID but REQUEST command uses mapped in "%BORROW_USERID",
                prompt: 'FORMS.LOCAL.RM_REQUEST.BORROWER',
                sqlinfo: 'AUTHOR.USER_ID',
                maxchars: '20',
                flags: 0x0000004E,
                lookup: 'PEOPLE'
              },
              {
                fldtype: 'edit',
                datatype: '8',
                name: '%BORROW_DATE',
                prompt: 'FORMS.LOCAL.RM_REQUEST.BORROW_DATE',
                flags: 0x00000040
              },
              {
                fldtype: 'edit',
                datatype: '8',
                name: '%BORROW_RETURNDATE',
                prompt: 'FORMS.LOCAL.RM_REQUEST.BORROW_RETURNDATE',
                flags: 0x00000040
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'rm_vitalreviewdate',
            prompt: '',
            defs: [
              {
                fldtype: 'list',
                name: '$edx_form_list',
                listKind: 'DOCUMENTS',
                prompt: 'FORMS.LOCAL.RM_REQUEST.CHANGE_REVIEW_DATE',
                schemaID: 'RM_DOCUMENT_LIST'
              },
              {
                fldtype: 'edit',
                datatype: '8',
                name: 'PD_VREVIEW_DATE',
                prompt: 'FORMS.LOCAL.RM_REQUEST.VITAL_REVIEW_DATE',
                flags: 0x00000040
              }
            ]
          }
        },
        {
          data: {
            fldtype: 'form',
            name: 'rm_putinbox',
            prompt: '',
            defs: [
              {
                fldtype: 'edit',
                name: 'PD_BOX',
                prompt: 'FORMS.LOCAL.RM_PUTINBOX.BOX',
                sqlinfo: 'FILEPART.PD_BOX',
                flags: 0x0000004E,
                lookup: 'CLIENT_BOX_LUP'
              }
            ]
          }
        },
        {
          data: {
            fldtype: 'form',
            name: 'rm_removefrombox',
            prompt: '',
            defs: [
              {
                fldtype: 'edit',
                name: 'PD_LOCATION_CODE',
                prompt: 'FORMS.LOCAL.RM_REMOVE_FROM_BOX.LOCATION',
                sqlinfo: 'PD_LOCATION.PD_LOCATION_CODE',
                flags: 0x0000004E,
                lookup: 'PD_LOCATION_LUP'
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'oai_addmsg',
            prompt: '',
            defs: [
              {
                fldtype: 'text',
                name: 'question',
                prompt: 'FORMS.LOCAL.OAI_ADD.ADD',
                value: '',
                flags: 0x00000000
              },
              {
                fldtype: 'radiogroup',
                buttons: 'FORMS.LOCAL.OAI_ADD.RADIO_BUTTONS',
                flags: 0,
                name: 'radio',
                prompt: '',
                value: 'M'
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'date_range',
            prompt: '',
            defs: [
              {
                fldtype: 'guidetext',
                name: 'guidetext',
                prompt: '',
                value: 'FORMS.LOCAL.DATE_RANGE.DATE_RANGE',
                flags: 0x00080000
              },
              {
                fldtype: 'radiogroup',
                rgcheckboxes: true,
                name: 'DATE',
                sqlinfo: 'DATE',
                prompt: '',
                value: '',
                buttons: 'FORMS.LOCAL.DATE_RANGE.DATE_BUTTONS',
                flags: 0x00000000,
                vistriggers: [{
                  hidden: true,
                  fields: ['$edx_start_date', '$edx_end_date'],
                  values: ['', 'D1', 'W1', 'W2', 'M1', 'M3', 'Y1', 'Y3']
                }]
              },
              {
                fldtype: 'edit',
                datatype: '8',
                name: '$edx_start_date',
                prompt: 'FORMS.LOCAL.ACTIVITY_SEARCH.START_DATE',
                flags: 0x01000004,
                scripttrigger: { script: 'date_change' }
              },
              {
                fldtype: 'edit',
                datatype: '8',
                name: '$edx_end_date',
                prompt: 'FORMS.LOCAL.ACTIVITY_SEARCH.END_DATE',
                flags: 0x01000004,
                scripttrigger: { script: 'date_change' }
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'save_as',
            prompt: '',
            defs: [
              {
                fldtype: 'radiogroup',
                buttons: 'FORMS.LOCAL.SAVE_AS.RADIO_BUTTONS',
                flags: 0,
                name: '$edx_save_as_radio',
                prompt: '',
                value: 'NV',
                vistriggers: [{
                  hidden: true,
                  fields: ['COMMENT'],
                  values: ['RV', 'ND']
                }]
              },
              {
                fldtype: 'edit',
                name: 'COMMENT',
                prompt: 'FORMS.LOCAL.CHECK_OUT.COMMENT',
                flags: 0x00000080,
                h: 60
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'save_as_dm_version',
            prompt: '',
            defs: [
              {
                fldtype: 'radiogroup',
                buttons: 'FORMS.LOCAL.SAVE_AS.RADIO_BUTTONS_NEW_VERSION',
                flags: 0,
                name: '$edx_save_as_radio',
                prompt: '',
                value: 'NV'
              },
              {
                fldtype: 'edit',
                name: 'COMMENT',
                prompt: 'FORMS.LOCAL.CHECK_OUT.COMMENT',
                flags: 0x00000080,
                h: 60
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'versions_editprofile',
            prompt: 'FOLDER_ACTIONS.NEW_VERSION',
            extrasshown: true,
            defs: [
              {
                fldtype: 'edit',
                name: 'AUTHOR_ID',
                lookup: 'PEOPLE',
                sqlinfo: 'AUTHOR.AUTHOR_ID',
                prompt: 'FORMS.LOCAL.CHECK_IN.AUTHOR',
                flags: 0x00000002
              },
              {
                fldtype: 'edit',
                name: 'TYPIST_ID',
                lookup: 'PEOPLE',
                sqlinfo: 'AUTHOR.TYPIST_ID',
                prompt: 'FORMS.LOCAL.CHECK_IN.ENTERED_BY',
                flags: 0x00000002
              },
              {
                fldtype: 'edit',
                name: 'COMMENTS',
                prompt: 'FORMS.LOCAL.CHECK_IN.COMMENT',
                flags: 0x00000080,
                h: 60
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'editsecurity',
            prompt: '',
            defs: [
              {
                fldtype: 'edit',
                name: 'DOCNAME',
                prompt: '',
                flags: 0x00082000
              },
              {
                fldtype: 'edit',
                name: 'DOCNUM',
                prompt: '',
                flags: 0x00082000
              },
              {
                fldtype: 'edit',
                name: 'lib',
                prompt: '',
                flags: 0x00082000
              },
              {
                fldtype: 'edit',
                name: 'SECURITY',
                prompt: '',
                value:'0',
                flags: 0x01000000
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'pfta_check',
            prompt: '',
            defs: [
              {
                fldtype: 'edit',
                name: 'version',
                prompt: '',
                value:'0',
                flags: 0x01000000,
                vistriggers: [
                  {
                    hidden: false,
                    fields: ['pfta_old'],
                    values: ['0'],
                  },
                  {
                    hidden: true,
                    fields: ['pfta_missing'],
                    values: ['0'],
                  }
                ]
              },
              {
                fldtype: 'guidetext',
                name: 'pfta_old',
                prompt: '',
                value: 'FORMS.LOCAL.PFTA.OLD_VERSION',
                flags: 0x00080000
              },
              {
                fldtype: 'guidetext',
                name: 'pfta_missing',
                prompt: '',
                value: 'FORMS.LOCAL.PFTA.NOT_RUNNING',
                flags: 0x00080000
              },
              {
                fldtype: 'checkbox',
                datatype: '4',
                name: 'dont_ask_pfta_download',
                prompt: ' ',
                label: 'FORMS.BUTTONS.DONT_ASK',
                flags: 0x00000004,
                uncheckedtrigger: '0',
                checkedtrigger: '1',
                value: '0'
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'link',
            prompt: '',
            localonly: true,
            extrasshown: true,
            defs: [
              {
                fldtype: 'edit',
                name: '$edx_link',
                prompt: 'FORMS.LOCAL.LINK.LINK',
                flags: 0x00000000
              },
              {
                fldtype: 'edit',
                name: '$edx_link_type',
                prompt: 'FORMS.LOCAL.LINK.TYPE',
                flags: 0x00100000,
                scripttrigger: { script: 'make_link' },
                value: 'web',
                selections: [
                  {
                    display: 'FORMS.LOCAL.LINK.WEB',
                    value: 'web'
                  },
                  {
                    display: 'FORMS.LOCAL.LINK.TEAMS_AUTO',
                    value: 'teams_auto'
                  }
                ],
                vistriggers: [
                  {
                    hidden: false,
                    fields: ['$edx_link_action'],
                    values: ['web', 'teams_auto']
                  }
                ]
              },
              {
                fldtype: 'edit',
                name: '$edx_link_action',
                prompt: 'FORMS.LOCAL.LINK.ACTION',
                flags: 0x00100000,
                scripttrigger: { script: 'make_link' },
                value: 'download',
                selections: [
                  {
                    display: 'FORMS.LOCAL.LINK.ACTION_DOWNLOAD',
                    value: 'download'
                  },
                  {
                    display: 'FORMS.LOCAL.LINK.ACTION_OPEN',
                    value: 'open'
                  },
                  {
                    display: 'FORMS.LOCAL.LINK.ACTION_PROFILE',
                    value: 'profile'
                  },
                  {
                    display: 'FORMS.LOCAL.LINK.ACTION_PREVIEW',
                    value: 'profile_preview'
                  },
                  {
                    display: 'FORMS.LOCAL.LINK.ACTION_VIEW',
                    value: 'profile_viewer'
                  }
                ]
              },
              {
                fldtype: 'guidetext',
                name: '$edx_link_copymessage',
                prompt: '',
                value: this.localizer.getTranslation('FOLDER_ACTIONS.LINK_COPY_MESSAGE'),
                flags: 0x01080000,
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'teams_appid',
            prompt: 'FOLDER_ACTIONS.NEW_VERSION',
            extrasshown: true,
            defs: [
              {
                fldtype: 'guidetext',
                name: 'guidetext',
                prompt: '',
                value: 'FORMS.LOCAL.LINK.APPID_GUIDE',
                flags: 0x00080000
              },
              {
                fldtype: 'edit',
                name: '$edx_url_appid',
                prompt: 'FORMS.LOCAL.LINK.LINK',
                flags: 0x00000040,
                scripttrigger: { script: 'got_link' }
              },
              {
                fldtype: 'push',
                name: '$edx_start_appid',
                prompt: 'FORMS.LOCAL.LINK.APPID_ACTION',
                flags: 0x00000000,
                scripttrigger: { script: 'click' }
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'createfolder',
            prompt: 'FOLDER_ACTIONS.NEW_FOLDER',
            extrasshown: true,
            defs: [
              {
                fldtype: 'edit',
                name: 'DOCNAME',
                maxchars: '240',
                prompt: 'FORMS.LOCAL.RENAME.NAME',
                flags: 0x00000040
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'createobject',
            prompt: 'FOLDER_ACTIONS.NEW_OBJECT',
            extrasshown: true,
            defs: [
              {
                fldtype: 'edit',
                name: 'DOCNAME',
                maxchars: '240',
                prompt: 'FORMS.LOCAL.RENAME.NAME',
                flags: 0x00000040
              },
              {
                fldtype: 'edit',
                name: '$edx_obj_desc',
                maxchars: '240',
                prompt: 'FORMS.LOCAL.OBJECT.DESCRIPTION',
                flags: 0x00000000
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'footer_options',
            prompt: '',
            localonly: true,
            defs: [
              {
                prompt: 'FORMS.LOCAL.FOOTER_OPTIONS.FOOTER_OPTIONS',
                name: 'options',
                fldtype: 'box',
                flags: 0x00000000,
                fields: []
              },
              {
                fldtype: 'edit',
                name: 'xml',
                prompt: '',
                flags: 0x00040080,
                h: 150
              },
              {
                fldtype: 'checkbox',
                label: 'FORMS.LOCAL.FOOTER_OPTIONS.DONTSHOW',
                datatype: '4',
                name: 'dont_show',
                flags: 0x00000000,
                uncheckedtrigger: '0',
                checkedtrigger: '1',
                value: '0'
              },
              {
                fldtype: 'checkbox',
                label: 'FORMS.LOCAL.FOOTER_OPTIONS.AUTO',
                datatype: '4',
                name: 'auto',
                flags: 0x00000000,
                uncheckedtrigger: '0',
                checkedtrigger: '1',
                value: '0'
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'massprofile_results',
            prompt: '',
            extrasshown: true,
            defs: [
              {
                fldtype: 'edit',
                name: 'nProfiles',
                prompt: 'FORMS.LOCAL.MASS_PROFILE.SELECTED',
                flags: 0x00080000
              },
              {
                fldtype: 'edit',
                name: 'nUpdated',
                prompt: 'FORMS.LOCAL.MASS_PROFILE.UPDATED',
                flags: 0x00080000
              },
              {
                fldtype: 'edit',
                name: 'nErorrs',
                prompt: 'FORMS.LOCAL.MASS_PROFILE.ERRORS',
                flags: 0x00080000
              },
              {
                fldtype: 'list',
                name: '$edx_form_list',
                listKind: 'DOCUMENTS',
                prompt: 'FORMS.LOCAL.MASS_PROFILE.RESULTS',
                schemaID: 'MASSPROFILE_RESULTS'
              }
            ]
          }
        },
        {
          data : {
            fldtype: 'form',
            name: 'filters_history',
            prompt: '',
            defs: [
              {
                fldtype: 'edit',
                name: 'START_DATE',
                prompt: 'FORMS.LOCAL.ACTIVITY_SEARCH.START_DATE',
                datatype: '8',
                sqlinfo: 'LAST_EDIT_DATE',
                lookup: '$edx_date_range',
                flags: 0x00000006
              },
              {
                fldtype: 'edit',
                name: 'ACTIVITY_TYPE',
                datatype: '4',
                prompt: 'FORMS.LOCAL.LINK.ACTION',
                multiselect: true,
                multiselectsep: ';',
                flags: 0x00100000,
                selections: []
              },
              {
                fldtype: 'edit',
                name: 'VERSION_LABEL',
                prompt: 'FORMS.LOCAL.CHECK_IN.VERSION',
                multiselect: true,
                multiselectsep: ';',
                flags: 0x00100000,
                selections: []
              },
              {
                fldtype: 'edit',
                name: 'TYPIST_ID',
                prompt: 'FORMS.LOCAL.FILTERS_MOBILE.USER',
                multiselect: true,
                multiselectsep: ';',
                flags: 0x00100000,
                selections: []
              }
            ]
          }
        }
      ];
    }
  }
}
