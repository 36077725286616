import { Injectable } from '@angular/core';
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import * as microsoftTeams from '@microsoft/teams-js';

import { Util } from '../utils/utils.module';
import { Session } from 'inspector';

declare let Office: any;
const kStoreKey = 'edx_oauth2_sso';
const kExpiryKey = 'edx_oauth2_expires_at';

const _getAuthData = (): any => {
  let auth = null;
  const authStorage = localStorage.getItem(kStoreKey);
  if (authStorage) {
    try {
      auth = JSON.parse(authStorage);
    } catch (e) {
      auth = null;
    }
  }
  return auth;
};

const _getExpiryMS = (token?: any): number => {
  let expiresInMilliSeconds = 0;
  if (!!token) {
    const payload = Util.Transforms.decodeJWT(token);
    if (!!payload) {
      const delta = payload.exp - payload.iat;
      expiresInMilliSeconds = delta * 800;  // 80% in MS
    }
  } else {
    const expiresAtStr = localStorage.getItem(kExpiryKey);
    if (!!expiresAtStr) {
      const expiresAt = parseInt(expiresAtStr);
      const now = new Date();
      expiresInMilliSeconds = expiresAt - now.getTime();
      if (expiresInMilliSeconds < 0) {
        expiresInMilliSeconds = 0;
      }
    }
  }
  return expiresInMilliSeconds;
};

const _getExpiresAt = (token?: any): number => {
  let expiresAt = 0;
  if (!!token) {
    const expiresInMilliSeconds = _getExpiryMS(token);
    const now = new Date();
    expiresAt = now.getTime() + expiresInMilliSeconds;
  } else {
    const expiresAtStr = localStorage.getItem(kExpiryKey);
    if (!!expiresAtStr) {
      expiresAt = parseInt(expiresAtStr);
    }
  }
  return expiresAt;
};

const _setExpiresAt = (expiresAt: number): void => {
  localStorage.setItem(kExpiryKey, expiresAt.toString());
};

export interface RefreshCallback {
  refreshed(token: string, error: any): void;
}

class SilentRefresher {
  private _boundMessageEvent: any;
  private _frame: HTMLIFrameElement;
  private _resolve: any;
  private _reject: any;

  constructor() {
    this._boundMessageEvent = this._message.bind(this);
    window.addEventListener('message', this._boundMessageEvent, false);
    this._frame = window.document.createElement('iframe');
    this._frame.style.visibility = 'hidden';
    this._frame.style.position = 'absolute';
    this._frame.style.display = 'none';
    this._frame.style.width = '0px';
    this._frame.style.height = '0px';
    window.document.body.appendChild(this._frame);
  }

  get origin(): string {
    return location.protocol + '//' + location.host;
  }

  private _success(data: any): void {
    this._cleanup();
    this._resolve(data);
  }

  private _error(message: string): void {
    this._cleanup();
    this._reject(new Error(message));
  }

  private _cleanup(): void {
    if (!!this._frame) {
      window.removeEventListener('message', this._boundMessageEvent, false);
      window.document.body.removeChild(this._frame);
      this._frame = null;
      this._boundMessageEvent = null;
    }
  }

  private _message(event: MessageEvent): void {
    if (event.origin === this.origin && event.source === this._frame.contentWindow) {
      const date = new Date();
      const dateStr = date.getHours() + ':'  + date.getMinutes() + ':'  + date.getSeconds() + ' : ';
      if (!!event.data) {
        try {
          const data = JSON.parse(event.data);
          if (!!data.token) {
            this._success(data);
          } else {
            this._error(`${dateStr} No token in data from frame: ${event.data}`);
          }
        } catch (e) {
          this._error(`${dateStr} Invalid json from frame: ${e.message || e}`);
        }
        const params = Util.RestAPI.getQueriesFromURL(event.data.replace('#', '?'));
        this._success(params);
      } else {
        this._error(`${dateStr} Invalid response from frame: ${event.origin + ' - ' + event.source}`);
      }
    }
  }

  public navigate(url: string): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
    if (!!url) {
      this._frame.src = url;
    } else {
      this._error('No url provided');
    }
    return promise;
  }

  public close(): void {
    this._cleanup();
  }
}

class TokenRefresher {
  private timer = null;

  constructor(refreshMS: number, private token: string, private callback: RefreshCallback) {
    this.start(refreshMS);
  }

  private start(refreshMS: number): void {
    let expiresAt;
    if (!refreshMS) {
      refreshMS = _getExpiryMS(this.token);
      expiresAt = _getExpiresAt(this.token);
      _setExpiresAt(expiresAt);
    }
    this.timer = setTimeout(() => {
      const loc = Util.getRootSiteUrl();
      const silentRefresher: SilentRefresher = new SilentRefresher();
      silentRefresher.navigate(`${loc}/assets/silent-office-callback.html`).then((data: any) => {
        this.token = data.token;
        this.callback.refreshed(this.token, null);
        expiresAt = _getExpiresAt(this.token);
        _setExpiresAt(expiresAt);
        this.start(_getExpiryMS(this.token));
      }, err => {
        this.callback.refreshed(null, err);
        console.error(err);
      });
    }, refreshMS);
  }

  public stop(): void {
    clearTimeout(this.timer);
    this.timer = null;
  }
}

@Injectable()
export class oAuth2Service implements RefreshCallback {
  private bInitDone = false;
  private userManager: UserManager;
  private currentToken: string;
  private bOfficeAuth: boolean;
  private bTeamsAuth: boolean;
  private boundHandleOfficeMessage = this.handleOfficeMessage.bind(this);
  private loginResolve = null;
  private loginReject = null;
  private officeDialog = null;
  private tokenRefresher: TokenRefresher = null;
  private config: any = null;
  private ssoData: any = null;

  constructor() {
    const waitInit = () => {
      if (Util.Device.initialized()) {
        this.bOfficeAuth = Util.Device.bIsOfficeAddin && !Util.Device.bIsOfficeAddinWeb && !!Office && !!Office.context;
        this.bTeamsAuth = Util.Device.bIsTeamsAddIn;
        this.bInitDone = true;
      } else {
        setTimeout(waitInit, 100);
      }
    };
    waitInit();
  }

  public refreshed(token: string, error: any): void {
    if (!!token) {
      this.currentToken = token;
      Util.RestAPI.setSSOAccessToken(this.currentToken);
      if (!!this.loginResolve) {
        this.loginResolve(this.currentToken);
        this.loginResolve = null;
        this.loginReject = null;
      }
    } else {
      const loc = Util.getRootSiteUrl();
      if (!!error) {
        console.log(error);
      }
      if (this.bOfficeAuth) {
        Office.context.ui.displayDialogAsync(`${loc}/assets/signin-office.html?state=${encodeURIComponent(JSON.stringify(this.ssoData))}`, {height: 50, width: 25}, asyncResult => {
          if (!!asyncResult && !!asyncResult.value) {
            this.officeDialog = asyncResult.value;
            this.officeDialog.addEventHandler(Office.EventType.DialogMessageReceived, this.boundHandleOfficeMessage);
          } else {
            if (!!this.loginReject) {
              if (!!asyncResult.error) {
                this.loginReject(asyncResult.error);
              } else {
                this.loginReject('office login unknown error');
              }
            }
            this.loginResolve = null;
            this.loginReject = null;
          }
        });
      } else if (this.bTeamsAuth) {
        microsoftTeams.authentication.authenticate({
          url: `${loc}/assets/signin-teams.html?state=${encodeURIComponent(JSON.stringify(this.ssoData))}`,
          width: 600,
          height: 535,
          successCallback: (result: any) => {
            if (!!result.token) {
              this.currentToken = result.token;
              Util.RestAPI.setSSOAccessToken(this.currentToken);
              if (!!this.loginResolve) {
                this.loginResolve(this.currentToken);
              }
            } else if (!!this.loginReject) {
              this.loginReject(result.error || 'teams login no result no error');
            }
            this.loginResolve = null;
            this.loginReject = null;
          },
          failureCallback: (err) => {
            if (!!this.loginReject) {
              this.loginReject(err);
            }
            this.loginResolve = null;
            this.loginReject = null;
          }
        });
      } else {
        if (!!this.loginReject) {
          this.loginReject('not office or teams refresh');
        }
        this.loginResolve = null;
        this.loginReject = null;
      }
    }
  }

  private handleOfficeMessage(arg: any): void {
    let err = 'unknown err';
    if (!!arg && !!arg.message) {
      try {
        const data = JSON.parse(arg.message);
        if (!!data.token) {
          this.currentToken = data.token;
          Util.RestAPI.setSSOAccessToken(this.currentToken);
          this.tokenRefresher = new TokenRefresher(0, this.currentToken, this);
          err = null;
        } else if (!!data.url) {
          const params = Util.RestAPI.getQueriesFromURL(data.url.replace('#', '?'));
          this.currentToken = params.access_token;
          Util.RestAPI.setSSOAccessToken(this.currentToken);
          this.tokenRefresher = new TokenRefresher(0, this.currentToken, this);
          err = null;
        } else {
          err = data.error;
        }
      } catch (e) {
        err = !!e.message || e;
      }
    }
    if (!!err) {
      console.error(err);
    }
    if (!!this.loginResolve && !err) {
      this.loginResolve(this.currentToken);
    } else if (!!this.loginReject) {
      this.loginReject(err);
    }
    if (!!this.officeDialog) {
      this.officeDialog.close();
    }
    this.officeDialog = null;
    this.loginResolve = null;
    this.loginReject = null;
  }

  public login(singleSignOnData: any): Promise<string> {
    const isRedirectSignin = Util.RestAPI.siteConfigurations.redirectSignin;

    const rc = new Promise<string>((resolve, reject) => {
      this.loginResolve = resolve;
      this.loginReject = reject;
    });

    const signinSuccess = (user: User) => {
      sessionStorage.removeItem('ssoSettings');
      this.currentToken = user.access_token;
      this.loginResolve(this.currentToken);
      this.loginResolve = null;
      this.loginReject = null;
    }

    const doSignIn = () => {
      const loc = Util.getRootSiteUrl();
      if (this.bOfficeAuth) {
        Office.context.ui.displayDialogAsync(`${loc}/assets/signin-office.html?state=${encodeURIComponent(JSON.stringify(this.ssoData))}`, { height: 50, width: 25 }, asyncResult => {
          if (!!asyncResult && !!asyncResult.value) {
            this.officeDialog = asyncResult.value;
            this.officeDialog.addEventHandler(Office.EventType.DialogMessageReceived, this.boundHandleOfficeMessage);
          } else {
            if (!!asyncResult.error) {
              this.loginReject(asyncResult.error);
            } else {
              this.loginReject('office login unknown error');
            }
            this.loginResolve = null;
            this.loginReject = null;
          }
        });
      } else if (this.bTeamsAuth) {
        microsoftTeams.authentication.authenticate({
          url: `${loc}/assets/signin-teams.html?state=${encodeURIComponent(JSON.stringify(this.ssoData))}`,
          width: 600,
          height: 535,
          successCallback: (result: any) => {
            if (!!result.token) {
              this.currentToken = result.token;
              this.tokenRefresher = new TokenRefresher(0, this.currentToken, this);
              this.loginResolve(this.currentToken);
            } else {
              this.loginReject(result.error || 'teams login no result no error');
            }
            this.loginResolve = null;
            this.loginReject = null;
          },
          failureCallback: (error) => {
            this.loginReject(error);
            this.loginResolve = null;
            this.loginReject = null;
          }
        });
      } else {
        const showSigninPopup = () => {
          this.userManager.signinPopup().then((user: User) => {
            signinSuccess(user)
          }, err2 => {
            this.loginReject(err2);
            this.loginResolve = null;
            this.loginReject = null;
            doInit();
            showSigninPopup();
          });
        }

        const redirectSignin = () => {
          this.userManager.signinRedirect().then(() => {
          }).catch(err => {
            console.log(err);
            this.loginReject(err);
            this.loginResolve = null;
            this.loginReject = null;
            doInit();
          });
        }
        if (isRedirectSignin) {
          redirectSignin();
        }
        else {
          showSigninPopup();
        }
      }
    };
    const doInit = () => {
      const loc = Util.getRootSiteUrl();
      const settings: UserManagerSettings = {
        authority: this.ssoData.authority,
        client_id: this.ssoData.client_id,
        redirect_uri: `${loc}/assets/signin-redirect-callback.html`,
        silent_redirect_uri: `${loc}/assets/silent-callback.html`,
        popup_redirect_uri: `${loc}/assets/signin-popup-callback.html`,
        post_logout_redirect_uri: `${loc}/assets/signout-end.html`,
        scope: this.ssoData.scope,
        staleStateAgeInSeconds: 60,
        automaticSilentRenew: false,
        response_type: 'code',
        loadUserInfo: false,
        userStore: new WebStorageStateStore({ store: window.localStorage })
      };
      if (isRedirectSignin) {
        delete settings.userStore;
        sessionStorage.setItem("ssoSettings", JSON.stringify(settings));
      }
      this.userManager = new UserManager(settings);
      this.userManager.events.addAccessTokenExpiring(() => {
        this.userManager.signinSilent().then((userValue: User) => {
          const accessToken = userValue.access_token;
          if (!!accessToken) {
            this.currentToken = accessToken;
            Util.RestAPI.setSSOAccessToken(this.currentToken);
          } else {
            this.userManager.signinPopup().then((user: User) => {
              this.currentToken = user.access_token;
              Util.RestAPI.setSSOAccessToken(this.currentToken);
            }, err => {
              Util.RestAPI.logOff(true);
            });
          }
        });
      });
      this.userManager.getUser().then((user: User) => {
        if (!!user && !!user.access_token) {
          signinSuccess(user);
        } else {
          doSignIn();
        }
      }, doSignIn);
    };
    const waitInit = () => {
      if (this.bInitDone) {
        this.ssoData = singleSignOnData;
        const auth = _getAuthData();
        if (!!auth && !!auth.tokens && auth.tokens.access_token && (this.bTeamsAuth || this.bOfficeAuth)) {
          const getConfig = (authority) => {
            Util.RestAPI.getExternalData(authority + '/.well-known/openid-configuration', null).then(data => {
              let expiryMS = 0;
              this.config = data;
              if (!!auth.config && auth.config.authorization_endpoint === this.config.authorization_endpoint && (expiryMS = _getExpiryMS()) !== 0) {
                this.tokenRefresher = new TokenRefresher(expiryMS, auth.tokens.access_token, this);
                this.refreshed(auth.tokens.access_token, null);
              } else {
                doSignIn();
              }
            }, err3 => {
              this.loginReject(err3);
              this.loginResolve = null;
              this.loginReject = null;
            });
          };
          getConfig(this.ssoData.authority);
        } else if (this.bTeamsAuth || this.bOfficeAuth) {
          doSignIn();
        } else {
          doInit();
        }
      } else {
        setTimeout(waitInit, 100);
      }
    };
    waitInit();
    return rc;
  }

  public logout(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!!this.tokenRefresher) {
        this.tokenRefresher.stop();
        this.tokenRefresher = null;
      }
      if (!!this.userManager) {
        this.userManager.signoutRedirect().then(() => {
          this.userManager.clearStaleState();
          this.userManager.removeUser();
          resolve();
        }, reject);
      } else if ((this.bTeamsAuth || this.bOfficeAuth) && !!this.currentToken) {
          const loc = Util.getRootSiteUrl();
          const done = (err?: any) => {
            localStorage.removeItem(kStoreKey);
            localStorage.removeItem(kExpiryKey);
            if (!!err) {
              reject(err);
            } else {
              resolve();
            }
          };
          if (this.bOfficeAuth) {
            Office.context.ui.displayDialogAsync(`${loc}/assets/signout-office-start.html`, {height: 30, width: 20}, asyncResult => {
              if (!!asyncResult && !!asyncResult.value) {
                this.officeDialog = asyncResult.value;
                this.officeDialog.addEventHandler(Office.EventType.DialogMessageReceived, arg => {
                  let err = 'unknown err';
                  if (!!arg && !!arg.message) {
                    try {
                      const data = JSON.parse(arg.message);
                      if (!!data.success) {
                        err = null;
                      } else {
                        err = data.error;
                      }
                    } catch (e) {
                      err = !!e.message || e;
                    }
                  }
                  done(err);
                });
              } else {
                done('office login unknown error');
              }
            });
          } else if (this.bTeamsAuth) {
            microsoftTeams.authentication.authenticate({
              url: `${loc}/assets/signout-office-start.html?`,
              width: 600,
              height: 535,
              successCallback: (result: any) => {
                if (!!result.success) {
                  done();
                } else {
                  done(result.error);
                }
              },
              failureCallback: done
            });
          } else {
            done('not teams or office');
          }
      } else {
        localStorage.removeItem(kStoreKey);
        localStorage.removeItem(kExpiryKey);
        resolve();
      }
    });
  }

  public reset(): void {
    this.serverChanged();
    if (!!this.userManager) {
      this.userManager.clearStaleState();
      this.userManager.removeUser();
    }
  }

  public serverChanged(): void {
    localStorage.removeItem(kStoreKey);
    localStorage.removeItem(kExpiryKey);
  }

  public get accessToken(): string {
    return this.currentToken;
  }
}
