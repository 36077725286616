/*

  See icclient-settings.ts in models for data structure.

*/
import { Injectable } from '@angular/core';
import { Util } from '../utils/utils.module';

const kRoot = 'settings/icclient';
const preferencesPath = 'settings/preferences';

const preferenceSections = {
  General: {
    serverPrefix: 'G_',
    formName: 'preferences_general',
    localStorageKey: '$edx_preferences'
  },
  Tiles: {
    serverPrefix: 'T_',
    formName: 'preferences_tiles',
    localStorageKey: '$edx_preferences_tiles'
  }
};

@Injectable()
export class SettingsService {

  public read(sectionName: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const section = preferenceSections[sectionName];
      if (!!section) {
        const queryArgs = `filter=name=${section.serverPrefix}*`;
        Util.RestAPI.get(preferencesPath, null, queryArgs).toPromise().then(serverSettings => {
          const defaultSettings = this.preferencesDefaults(section.formName);
          const localSettings = JSON.parse(localStorage.getItem(section.localStorageKey)) ?? {};
          const settings = ({ ...defaultSettings, ...localSettings });
          for (const key in settings) {
            const serverKey = (section.serverPrefix + key).toUpperCase();
            const serverValue = serverSettings?.filter(item => item.name.toUpperCase() === serverKey)?.[0]?.setting;
            if (!!serverValue) {
              settings[key] = serverValue;
            }
          }
          const stringSettings = JSON.stringify(settings);
          localStorage.setItem(section.localStorageKey, stringSettings);
          localStorage.setItem(section.localStorageKey + "_data", JSON.stringify(serverSettings));
          resolve(settings);
        }, reject);
      } else {
        reject();
      }
    });
  }

  public write(sectionName: string, settings: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const section = preferenceSections[sectionName];
      if (!!section) {
        const serverData = this.makeSaveServerData(section, settings, sectionName === 'General' && Util.RestAPI.isAdminPage());
        Util.RestAPI.put(preferencesPath, serverData, null, null, { observe: 'response' }).subscribe(response => {
          const stringSettings = JSON.stringify(settings);
          localStorage.setItem(section.localStorageKey, stringSettings);
          const data = response.status === 200 && !!response.body ? response.body.data : null;
          resolve(data);
        }, err => {
          const stringSettings = JSON.stringify(settings);
          localStorage.setItem(section.localStorageKey, stringSettings);
          reject();
        });
      } else {
        reject();
      }
    });
  }

  public reset(sectionName: string): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      const section = preferenceSections[sectionName];
      if (!!section) {
        const queryArgs = `filter=name=${section.serverPrefix}*`;
        Util.RestAPI.delete(preferencesPath, null, queryArgs).subscribe(res => {
          resolve(true);
        }, reject);
      } else {
        reject();
      }
    });
  }

  public get(path: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      Util.RestAPI.get(kRoot, path, null, { observe: 'response' }).subscribe(res => {
        if (res.status === 200) {
          const data = !!res.body ? res.body.data : null;
          resolve(data);
        } else {
          resolve(null);
        }
      }, reject);
    });
  }

  public put(path: string, data: any): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      Util.RestAPI.put(kRoot, data, path, null, { observe: 'response' }).subscribe(res => {
        if (res.status === 200) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, reject);
    });
  }

  public delete(path: string): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      if (!!path) {
        Util.RestAPI.delete(kRoot, path).subscribe(res => {
          resolve(true);
        }, reject);
      } else {
        reject('Cannot delete root');
      }
    });
  }

  private preferencesDefaults(formName: string): any {
    const defaults = [];
    const generalForm = Util.RestAPI.getLocalFormTemplate(formName);
    const sections = generalForm?.defs;
    for (const section of sections) {
      const fields = section.fields;
      for (const field of fields) {
        defaults[field.name] = field.value;
      }
    }
    return defaults;
  }

  private makeSaveServerData(section: any, settings: any, rawData = false): any {
    const defaultSettings = this.preferencesDefaults(section.formName);
    const data = [];
    for (const key in defaultSettings) {
      const value = settings[key];
      if (!!value) {
        const serverKey = (section.serverPrefix + key).toUpperCase();
        const item = {
          name: serverKey,
          setting: value
        };
        data.push(item);
      }
    }
    return rawData ? settings : data;
  }
}
