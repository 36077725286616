<ng-template #expandedSectionTemplate let-estShown="estShown" let-section="section">
  <ng-template [ngIf]="estShown">
    <div class="section expanded">
      <div *ngIf="section.canSearch" class="inputwrapper">
        <input type="TEXT" [id]="'input_'+section.id" [name]="section.id" [placeholder]="placeholderForSection(section)" tabindex="0" (keyup)="sectionSearchKeyup($event, section)">
        <div class="nameclear" tabindex="0" [attr.aria-label]="clearStr" role="button" (click)="toggleSectionSearch(section, true, true)" (keydown.space)="toggleSectionSearch(section, true, true)" (keyup.enter)="toggleSectionSearch(section, true, true)"></div>
      </div>
      <div class="sectionbody">
        <div *ngIf="section.loading" class="facet"><edx-spinner [mini]="true"></edx-spinner></div>
        <ng-template [ngIf]="!!section.items && !!section.items.length">
          <div *ngFor="let item of section.items" class="facet" [ngClass]="{readonly:item.readOnly}">
            <input type="checkbox" [id]="facetKey(item)" (click)="facetClicked($event, item)" class="checkbox facetcb" tabindex="-1" [ngClass]="{checked: item.checked}">
            <span for [id]="facetKey(item)" role="checkbox" [attr.aria-label]="getAriaLabel(facetKeyName(item, section)+' '+facetValue(item)+' '+altLabels['facetItem'],item)" title="{{facetKeyName(item, section)}}" tabindex="0" [attr.aria-checked]="item.checked" (click)="facetClicked($event, item)" (keydown.space)="facetClicked($event, item)">{{facetKeyName(item, section)}}</span>
            <div *ngIf="facetValue(item) !== -1" class="facetcount">{{facetValue(item)}}</div>
          </div>
        </ng-template>
        <div *ngIf="!section.loading && (!section.items || !section.items.length)" class="facet" tabindex="0" [attr.aria-label]="altLabels['noItems']">{{altLabels['noItems']}}</div>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #sectionsTemplate let-stShown="stShown">
  <ng-template [ngIf]="stShown">
    <button *ngIf="showReset() && ui>=2 && !!sections" class="mobile secondary right top" [ngClass]="{readonly:disableReset(), oai:officeAddin}" tabindex="0" (click)="reset()" (keydown.space)="reset()" (keyup.enter)="reset()" [disabled]="disableReset()">{{clearStr}}</button>
    <div *ngFor="let section of sections" class="section">
      <div class="sectionheader">
        <div class="section-title" [tabindex]="!expandedSection?0:-1" [attr.aria-label]="altLabels['facet']+' '+section.title">{{section.title}}</div>
      </div>
      <div class="sectiontoggle" [ngClass]="{open:section.isOpen, closed:!section.isOpen}" [attr.aria-label]="getAriaLabelForExpander(section)" [attr.aria-expanded]="section.isOpen" role="button" [tabindex]="!expandedSection?0:-1" (click)="toggleSection(sections.indexOf(section))" (keydown.space)="toggleSection(sections.indexOf(section))"></div>
      <ng-template [ngIf]="section.id!='datefacets' && section.canSearch">
        <div *ngIf="section.isOpen && !section.isSearchOpen" class="sectionsearch" [tabindex]="!expandedSection?0:-1" [attr.aria-label]="altLabels['searchFacet']" role="button" (click)="toggleSectionSearch(section, false)" (keydown.space)="toggleSectionSearch(section, false)"></div>
        <div *ngIf="section.isOpen && section.isSearchOpen" class="inputwrapper">
          <input type="TEXT" [id]="'input_'+section.id" [name]="section.id" [placeholder]="placeholderForSection(section)" [tabindex]="!expandedSection?0:-1" (keyup)="sectionSearchKeyup($event, section)">
          <div class="nameclear" [tabindex]="!expandedSection?0:-1" role="button" [attr.aria-label]="clearStr" (click)="toggleSectionSearch(section, true)" (keydown.space)="toggleSectionSearch(section, true)" (keyup.enter)="toggleSectionSearch(section, true)"></div>
        </div>
      </ng-template>
      <div *ngIf="section.isOpen || section.id=='datefacets'" class="sectionbody" [ngClass]="{edx_hidden:!section.isOpen, opening:section.isOpening, closing: section.isClosing}" (animationend)="animationComplete(section)">
        <ng-template [ngIf]="section.id!='datefacets'">
          <div *ngIf="section.loading" class="facet">
            <edx-spinner [mini]="true"></edx-spinner>
          </div>
          <ng-template [ngIf]="!!section.items && !!section.items.length">
            <ng-template ngFor let-item let-i="index" [ngForOf]="section.items">
              <div *ngIf="!section.moreLess || section.moreShown || i<section.lessLen || item.checked" class="facet" [ngClass]="{readonly:item.readOnly}">
                <input type="checkbox" [id]="facetKey(item)" (click)="facetClicked($event, item)" class="checkbox facetcb" tabindex="-1" [ngClass]="{checked: item.checked}">
                <span for [id]="facetKey(item)" role="checkbox" [attr.aria-label]="getAriaLabel(facetKeyName(item, section)+' '+facetValue(item)+' '+altLabels['facetItem'],item)" title="{{facetKeyName(item, section)}}" [tabindex]="!expandedSection?0:-1" [attr.aria-checked]="item.checked" (click)="facetClicked($event, item)" (keydown.space)="facetClicked($event, item)">{{facetKeyName(item, section)}}</span>
                <div *ngIf="facetValue(item) !== -1" class="facetcount">{{facetValue(item)}}</div>
              </div>
            </ng-template>
            <div *ngIf="!!section.moreLess" class="moreless" [attr.aria-label]="altLabels['showAllItems']" [tabindex]="!expandedSection?0:-1" (click)="toggleExpandedSection(section)" (keydown.space)="toggleExpandedSection(section)" (keyup.enter)="toggleExpandedSection(section)">{{section.moreLess}}</div>
          </ng-template>
          <div *ngIf="!section.loading && (!section.items || !section.items.length)" [tabindex]="!expandedSection?0:-1" [attr.aria-label]="altLabels['noItems']" class="facet">{{altLabels['noItems']}}</div>
        </ng-template>
        <edx-dynamic-form *ngIf="section.id=='datefacets'" #dynamicform [ngClass]="{edx_hidden:!section.isOpen}" [formKind]="'profile_query_datefacets'" [layout]="formLayout" [formTemplate]="facetFormTemplate" [inDialog]="true" [desc]="desc" [controller]="this"></edx-dynamic-form>
      </div>
    </div>
  </ng-template>
</ng-template>

<div *ngIf="ui<2 && isOpen" class="sidebar-container">
  <div *ngIf="!!expandedSection" class="headerbody expanded">
    <div class="sidebar-header">
      <div class="sidebar-header-backarrow" id="edx_sidebar_header_arrow" tabindex="0" [attr.aria-label]="altLabels['backToFacets']" role="button" (click)="toggleExpandedSection(expandedSection)" (keydown.space)="toggleExpandedSection(expandedSection)"></div>
      <div class="sidebar-title">{{expandedSection.title}}</div>
    </div>
    <ng-container *ngTemplateOutlet="expandedSectionTemplate;context:{estShown:true,section:expandedSection}"></ng-container>
  </div>
  <div class="headerbody" [ngClass]="{hidden:!!expandedSection, hiding:expandedSection&&expandedSection.isExpanding, showing:expandedSection&&expandedSection.isContracting}" (animationend)="animationComplete(expandedSection)">
    <div class="sidebar-header">
      <div class="sidebar-title" [tabindex]="!expandedSection?0:-1">{{title}}</div>
    </div>
    <div *ngIf="!loading" class="sidebar-content">
      <div *ngIf="showFilters">
        <edx-dynamic-form *ngIf="!canHaveFacets" #dynamicform [formKind]="kind" [layout]="formLayout" [formTemplate]="formTemplate" [inDialog]="true" [desc]="desc" [controller]="this"></edx-dynamic-form>
        <ng-container *ngTemplateOutlet="sectionsTemplate;context:{stShown:true}"></ng-container>
      </div>
      <div *ngIf="!showFilters || (canHaveFacets && !sections)">
        <div class="no-filters" tabindex="!expandedSection?0:-1">{{noFiltersStr}}</div>
      </div>
      <div *ngIf="showFilters">
        <div class="gradient-strip"></div>
      </div>
    </div>
  </div>
  <div class="sidebar-footer">
    <button [ngClass]="{readonly: okDisabled}" id="edx_filter_apply" tabindex="0" role="button" class="primary" [disabled]="okDisabled" (click)="search()">{{applyStr}}</button>
    <button class="secondary right" id="edx_filter_clear" tabindex="0" role="button" [ngClass]="{readonly:disableReset()}" (click)="reset()" [disabled]="disableReset()">{{clearStr}}</button>
  </div>
  <edx-spinner *ngIf="loading"></edx-spinner>
</div>
<ng-template [ngIf]="ui>=2 && isOpen">
  <edx-popup #formDialog [desc]="desc" [callback]="this" [kind]="kind" [title]="title" [ok]="applyStr" [okDisabled]="okDisabled">
    <div *ngIf="!loading" class="sidebar-container mobile">
      <div class="sidebar-content mobile">
        <ng-template [ngIf]="showFilters">
          <ng-template [ngIf]="!canHaveFacets">
            <button *ngIf="showReset()" class="mobile secondary right" tabindex="0" role="button" [ngClass]="{readonly:disableReset(), oai:officeAddin}" (click)="reset()" [disabled]="disableReset()">{{clearStr}}</button>
            <edx-dynamic-form #dynamicform [formKind]="formDialog.kind" [layout]="formLayout" [formTemplate]="formTemplate" [inDialog]="true" [data]="formDialog.formData" [desc]="formDialog.desc" [controller]="this"></edx-dynamic-form>
          </ng-template>
          <ng-container *ngTemplateOutlet="sectionsTemplate;context:{stShown:true}"></ng-container>
        </ng-template>
        <ng-template [ngIf]="!showFilters || (canHaveFacets && !sections)">
          <div class="no-filters" tabindex="0">{{noFiltersStr}}</div>
        </ng-template>
      </div>
    </div>
  </edx-popup>
  <edx-spinner *ngIf="loading"></edx-spinner>
</ng-template>