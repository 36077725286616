import { OnChanges, Input, Component, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { FormField, RadioGroupInterface } from '../models/form-field';
import { FormGroup } from '@angular/forms';
import { DynamicFormFieldComponent } from './dynamic-form-field.component';
import { RadioFieldComponent } from './radio-field.component';


@Component({
  selector: 'edx-radio-group',
  styleUrls: ['dynamic-form-field.component.scss'],
  template: `
    <div class="radio-group" id="field.name" [ngClass]="{indialog:inDialog||inNotify, columnview:columnView, haslabel:hasLabel, footer: parent.isShowFooterOptions(field)}">
      <div *ngFor="let button of field.buttonMap;let i=index" [ngClass]="{readonly:viewReadonly, footerradiobutton:parent.isShowFooterOptions(field)}">
        <edx-radio-field *ngIf="!field.isCheckboxGroup" [itemIndex]="i" [radioId]="getRadioId(i)" [value]="button.value" [label]="button.display" [field]="field" [form]="form" [parent]="this" [formReadonly]="formReadonly" [inDialog]="inDialog" [columnView]="columnView" [radioGroup]="this" [tabIndex]="tabIndex"></edx-radio-field>
      </div>
    </div>
  `
})
export class RadioGroupComponent implements RadioGroupInterface, OnChanges {
  @ViewChildren(RadioFieldComponent) buttons: QueryList<RadioFieldComponent>;
  @Input() field: FormField;
  @Input() form: FormGroup;
  @Input() parent?: DynamicFormFieldComponent;
  @Input() inDialog?: boolean = false;
  @Input() formReadonly?: boolean = false;
  @Input() columnView?: boolean = false;
  @Input() inNotify?: boolean = false;
  @Input() hasLabel = false;
  @Input() tabIndex = 0;
  private viewReadonly = false;

  ngOnChanges(): void {
    setTimeout(() => {
      if (this.buttons) {
        this.buttons.forEach((item: RadioFieldComponent, index: number, array: RadioFieldComponent[]) => {
          item.ngOnChanges();
        });
      }
    }, 1);
  }

  public radioButtonOn(btn: RadioFieldComponent, value: string): void {
    this.parent.updateControlValue(value, true);
    this.parent.fieldChanged();
    this.buttons.forEach((item: RadioFieldComponent, index: number, array: RadioFieldComponent[]) => {
      if (item!==btn) {
        item.ngOnChanges();
      }
    });
  }

  public shiftRadioFocus(event: KeyboardEvent, rbIndex: number): void {
    if (rbIndex >= 0 && rbIndex < this.field.buttonMap.length) {
      const radioId = this.getRadioId(rbIndex);
      if (!!radioId) {
        const rbElement = document.getElementById(radioId) as HTMLElement;
        if (!!rbElement) {
          rbElement.focus();
          rbElement.click();
        }
      }
    }
  }

  public getRadioId(index: number): string {
    return !!this.field.name ? ('edx_radio_' + this.field.name + '_' + index) : null;
  }
}
