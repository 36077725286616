import { Component, Input, Output, EventEmitter, SimpleChange, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges } from '@angular/core';
import { Location } from '@angular/common';
import { Util, UserInterface } from '../utils/utils.module';
import { BaseDesc } from '../models/base';
import { CommandHandler } from '../models/command-handler';
import { LocalizeService } from '../services/localize.service';

@Component({
  selector: 'edx-window-modal-header',
  styleUrls: [ 'window-modal.component.scss','window-modal-header.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="window-modal-header">
      <img *ngIf="ui<2" role="button" class="icon contractor" [ngClass]="{disabled:showCancel}" [src]="iconUrl" [title]="closeStr" [alt]="closeStr + ' ' + title" (mouseover)="showCancel?null:showContract($event)" (focus)="showCancel?null:showContract($event)" (focusout)="showCancel?null:hideContract($event)" (mouseleave)="showCancel?null:hideContract($event)" tabindex="11" (click)="showCancel?null:contract($event)" (keyup.enter)="showCancel?null:contract($event)" (keyup.space)="showCancel?null:contract($event)">
      <img *ngIf="ui>=2" class="icon" [src]="iconUrl" (click)="contract($event)" [alt]="closeStr + title">
      <h1 class="window-modal-header-title" [ngClass]="{mobile:ui>=2}" title="{{title}}">{{title}}</h1>
      <div *ngIf="filtered" class="filtericon" tabindex="13"></div>
      <div *ngIf="menuID!=0" class="window-modal-header-menu">
        <edx-menu [menuID]="menuID" [callback]="parent" [hideIfEmpty]="true" [rerender]="rerender" [tabIndex]="12"></edx-menu>
      </div>
      <button *ngIf="showCancel" tabindex="14" class="save-search-button secondary" [ngClass]="{mobile:ui>=2, oai:officeAddin}" [title]="cancelStr" (click)="cancelHdrBtnClick.emit()" (keyup.enter)="cancelHdrBtnClick.emit()" (keyup.space)="cancelHdrBtnClick.emit()">{{cancelStr}}</button>
      <button *ngIf="!!rightBtn" tabindex="15" class="save-search-button secondary" [ngClass]="{mobile:ui>=2, oai:officeAddin}" [title]="rightBtn" (click)="rightHdrBtnClick.emit()" (keyup.enter)="rightHdrBtnClick.emit()" (keyup.space)="rightHdrBtnClick.emit()">{{rightBtn}}</button>
    </div>
  `
})
export class WindowModalHeaderComponent implements OnChanges {
  public iconUrl: string;
  public ui: UserInterface;
  public officeAddin: boolean;
  private reloadStr: string = this.localizer.getTranslation('FOLDER_ACTIONS.RELOAD');
  private closeStr: string = this.localizer.getTranslation('FORMS.BUTTONS.CLOSE');
  private cancelStr: string = this.localizer.getTranslation('FORMS.BUTTONS.CANCEL');
  @Output() rightHdrBtnClick: EventEmitter<void> = new EventEmitter();
  @Output() cancelHdrBtnClick: EventEmitter<void> = new EventEmitter();
  @Input() parent: CommandHandler;
  @Input() title: string;
  @Input() desc: BaseDesc;
  @Input() menuID?: number = 0;
  @Input() filtered = false;
  @Input() rightBtn: string = null;
  @Input() showCancel = false;
  @Input() rerender = 0;

  constructor(protected location: Location, private cdr: ChangeDetectorRef, private localizer: LocalizeService) {
    this.ui = Util.Device.ui;
    this.officeAddin = Util.Device.bIsOfficeAddin;
  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}): void {
    const chng: any = changes['desc'];
    if (chng) {
      this.iconUrl = Util.Transforms.titleIconUrlFromDesc(this.desc);
      this.rerender++;
    }
  }

  private showContract(event: MouseEvent): void {
    this.iconUrl = 'assets/images/minimize.svg';
  }

  private hideContract(event: MouseEvent): void {
    this.iconUrl = Util.Transforms.tileIconUrlFromDesc(this.desc);
  }

  private contract(event: MouseEvent): void {
    if (this.ui<2) {
      Util.RestAPI.navBack();
    }
  }
}
