/**
 * Created by kevin on 2016-12-07.
 *
 * Checkbox field implementation with custom formatting and multiple value setting
 *
 */

import { OnInit, OnChanges, Input, ViewChild, Component } from '@angular/core';
import { FormField } from '../models/form-field';
import { FormGroup } from '@angular/forms';
import { DynamicFormFieldComponent } from './dynamic-form-field.component';
import { Util } from '../utils/utils.module';
import { LocalizeService } from '../services/localize.service';

@Component({
  selector: 'edx-checkbox-field',
  styleUrls: ['dynamic-form-field.component.scss'],
  template: `
  <div [formGroup]="form" class="controlcontainer" [ngClass]="{columnview: columnView, footer: parent.isShowFooterOptions(field)}">
    <div *ngIf="parent.isShowFooterOptions(field)" [title]="footerLabel" class="footer-label">
      <span>{{footerLabel}}</span>
    </div>
    <div class="checkboxlabel" [ngClass]="{preferences:parent.isTileSettingsCheckbox(), readonly:viewReadonly, indialog:inDialog, oai:officeAddin, footer: parent.isShowFooterOptions(field)}" [title]="label()">
      <input #cb [formControlName]="field.name" [id]="'CB_'+field.name" type="text" class="checkbox" [ngClass]="{checked:isChecked, indeterminate:field.isTriStateCheckbox && isIndeterminateState}" tabindex="-1">
      <span [title]="getAriaLabel()" class="checklabel" [ngClass]="{'add-blank-space': !field.checkLabel}" [attr.aria-label]="isChrome?(isChecked?getAriaLabel()+' ':getAriaLabel()):getAriaLabel()" [id]="!!checkBoxId?checkBoxId:field.name" [tabindex]="viewReadonly?-1:tabIndex" [attr.aria-disabled]="viewReadonly" role="checkbox" [attr.aria-checked]="field.isTriStateCheckbox && isIndeterminateState?'mixed':isChecked" (click)="onChange(cb,$event)" (keydown.space)="onChange(cb,$event)" (keyup.ArrowUp)="parent.shiftCheckBoxFocus($event,itemIndex-1)" (keyup.ArrowDown)="parent.shiftCheckBoxFocus($event,itemIndex+1)" (keyup.ArrowLeft)="onArrowLeftKeyUp()">{{label()}}</span>
    </div>
  </div>
`
})
export class CheckboxFieldComponent implements OnInit, OnChanges {
  @Input() field: FormField;
  @Input() form: FormGroup;
  @Input() parent?: DynamicFormFieldComponent;
  @Input() inDialog?: boolean = false;
  @Input() formReadonly?: boolean = false;
  @Input() columnView?: boolean = false;
  @Input() rerender = 0;
  @Input() checkBoxId?: string = null;
  @Input() itemIndex?: number = -1;
  @Input() tabIndex = 0;
  @Input() ariaLabel?: string;
  @ViewChild('checkbox') checkbox: any;
  public viewReadonly = false;
  public isChecked = false;
  public isIndeterminateState = false;
  public officeAddin = Util.Device.bIsOfficeAddin;
  public isChrome: boolean = Util.Device.bIsChrome;
  public footerLabel: string;

  constructor(private localizer: LocalizeService) {}

  ngOnInit() {
    this.form.controls[this.field.name].valueChanges.subscribe((newValue) => {
      this.doStateChangeUponValue(newValue);
      if (this.parent) {
        this.parent.fieldChanged();
      }
    });
    if (!!this.parent && this.parent.isShowFooterOptions(this.field)) {
      this.footerLabel = this.localizer.getTranslation('ADMIN.FOOTER.SHOW_FOOTER_OPTIONS');
      this.ariaLabel = !!this.ariaLabel ? this.ariaLabel : (!!this.field.ariaLabel ? this.field.ariaLabel : (this.footerLabel + ' ' + this.localizer.getTranslation('ADMIN.FOOTER.ENABLE')));
    }
  }

  ngOnChanges() {
    let value: string = this.form.controls[this.field.name].value;
    value = value ? value.toString() : null;
    if (this.field.isTriStateCheckbox) {
      this.doStateChangeUponValue(value);
    } else if (value === this.field.checkedValue) {
      this.isChecked = true;
    } else if (value === this.field.uncheckedValue) {
      this.isChecked = false;
    }
    this.viewReadonly = this.field.isReadonly || this.formReadonly;
  }

  public label(): string {
    if (this.columnView && this.viewReadonly && !this.parent.isTileSettingsCheckbox()) {
      return this.field.label;
    }
    return this.field.checkLabel;
  }

  public getAriaLabel(): string {
    return !!this.ariaLabel ? this.ariaLabel : (!!this.field.ariaLabel ? this.field.ariaLabel : this.label());
  }

  private doStateChangeUponValue(value: any): void {
    if (this.field.isTriStateCheckbox) {
      if (value === this.field.uncheckedValue) {
        this.isChecked = false;
        this.isIndeterminateState = false;
      } else if (value === this.field.checkedValue) {
        this.isChecked = true;
        this.isIndeterminateState = false;
      } else {
        this.isChecked = false;
        this.isIndeterminateState = true;
      }
    }
  }

  public onChange(element: Element, event: Event): boolean {
    if (!!event && (event.type === 'keydown' || event.type === 'click')) {
      const id = document.activeElement?.id;
      if (this.field?.isTriStateCheckbox || (Util.RestAPI.isAdminPage() && (id === 'edx_show_footer_options' || !!this.field?.extensionParent))) {
        setTimeout(() => {
          Util.Transforms.focusOnElementById(id);
        }, 100);
      } else {
        event.preventDefault();
      }
    }
    if (!this.viewReadonly) {
      // update the form control
      let newValue: string = this.field.defaultValue;
      if (!this.field.isTriStateCheckbox) {
        this.isChecked = !this.isChecked;
      } else {
        if (this.isChecked) {
          // checked to indeterminate
          this.isChecked = false;
          this.isIndeterminateState = true;
        } else if (this.isIndeterminateState) {
          // indeterminate to unchecked
          this.isChecked = false;
          this.isIndeterminateState = false;
        } else {
          // unchecked to checked
          this.isChecked = true;
          this.isIndeterminateState = false;
        }
      }
      if (this.isChecked && this.field.checkedValue) {
        newValue = this.field.checkedValue;
      } else if (!this.isChecked) {
        if (!this.isIndeterminateState && this.field.uncheckedValue) {
          newValue = this.field.uncheckedValue;
        } else {
          newValue = null;
        }
      }

      let subField: FormField;
      if (this.parent.field?.controltype === 'checkboxgroup') {
        subField = this.field;
      }

      // perform any required field control actions
      if (this.parent) {
        this.parent.updateControlValue(newValue, true, subField);
        this.parent.fieldChanged();
      }
      return false;
    }
    return true;
  }

  public onArrowLeftKeyUp(): void {
    if (!!this.parent && this.parent.isShowFooterOptions(this.field)) {
      Util.Transforms.focusOnElementByClassName('fileitem selected');
    }
  }
}
