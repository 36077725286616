export enum UserInterface { web=0, desktop=1, phone=2, tablet=3, phone_cordova=4, tablet_cordova=5 }
import * as microsoftTeams from '@microsoft/teams-js';

export interface OrientationListener {
  deviceDidRotate(isPortrait: boolean): void;
}

const kPhoneWidth = 639;
declare let Office: any;

export class _Device {
  private bInitStarted = false;
  private bInitDone = false;
  private bIsTabletSize = false;
  private orientationListeners: OrientationListener[] = [];
  public bIsWinSurface = false;
  public bIsWinMobile = false;
  public bIsAndroid = false;
  public bIsBB10Device = false;
  public bIsTouchDevice = false;
  public bIsIOSDevice = false;
  public bIsElectron = false;
  public bIsCordova = false;
  public bIsWindows = false;
  public bIsMacintosh = false;
  public bIsChrome = false;
  public bIsSafari = false;
  public bIsFirefox = false;
  public bIsEdge = false;
  public bIsEdgeChromium = false;
  public bIsIE = false;
  public bIsOfficeAddin = false;
  public bIsOfficeAddinWeb = false;
  public bIsOfficeAddinDesktop = false;
  public bIsOfficeAddinOutlook = false;
  public bIsOfficeAddinOutlookMessageEditable = false;
  public bIsOfficeAddinWord = false;
  public bIsOfficeAddinExcel = false;
  public bIsOfficeAddinPowerPoint = false;
  public bIsTeamsAddIn = false;
  public teamsContext: microsoftTeams.Context = null;
  public officeDMItem: any = null;
  public officeDocDelete: boolean = undefined;
  public bUseWebLogin = false;
  public width = 0;
  public height = 0;
  public ui: UserInterface = UserInterface.web;
  public version = '24.2.0';
  public year = '2024';

  constructor() {
    const desktopLook: string = localStorage.getItem('desktop_look');
    const buildJson: any = require('../../assets/build.json');
    const isTabletSize: boolean = window.innerWidth>kPhoneWidth && window.innerHeight>kPhoneWidth;
    const navAgentStr: string = navigator.userAgent.toLowerCase();
    const browserWindowResized = () => {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    };

    if (buildJson && buildJson.build) {
      this.version += '.' + buildJson.build;
    }
    this.bIsWinSurface = !!((navAgentStr.indexOf('webview') >= 0) && (navAgentStr.indexOf('trident') >= 0));
    this.bIsWinMobile = !!(navAgentStr.indexOf('iemobile') >= 0);
    this.bIsAndroid = !!(!this.bIsWinMobile && (navAgentStr.indexOf('android') >= 0));
    this.bIsBB10Device = !!(!this.bIsWinMobile && !this.bIsAndroid && (navAgentStr.match(/BB10|BB11/i) !== null));
    this.bIsIOSDevice = !!(!this.bIsWinMobile && !this.bIsAndroid && !this.bIsBB10Device && (navAgentStr.match(/iPhone|iPod|iPad/i) !== null));
    this.bIsTouchDevice = this.bIsIOSDevice || this.bIsAndroid || this.bIsWinMobile || this.bIsWinSurface || this.bIsBB10Device;
    this.bIsElectron = !this.bIsTouchDevice && (navAgentStr.indexOf('electron') >= 0) && ((navAgentStr.indexOf('edocs-infocenter') >= 0) || (navAgentStr.indexOf('standard') >= 0));
    this.bIsCordova = this.bIsTouchDevice && window.hasOwnProperty('cordova');
    this.bIsWindows = navAgentStr.indexOf('windows') >= 0;
    this.bIsMacintosh = navAgentStr.indexOf('macintosh') >= 0;
    this.bIsEdge = (navAgentStr.indexOf('edge') >= 0);
    this.bIsEdgeChromium = (navAgentStr.indexOf('edg') >= 0);
    this.bIsIE = (navAgentStr.indexOf('trident') >= 0);
    this.bIsChrome = !this.bIsEdge && !this.bIsEdgeChromium && navAgentStr.indexOf('chrome') >= 0;
    this.bIsSafari = !this.bIsEdge && !this.bIsEdgeChromium &&  !this.bIsChrome && (navAgentStr.indexOf('safari') >= 0);
    this.bIsFirefox = !this.bIsEdge && !this.bIsEdgeChromium && !this.bIsSafari && !this.bIsChrome && (navAgentStr.indexOf('firefox') >= 0);
    if (this.bIsElectron || desktopLook==='desktop') {
      this.ui = UserInterface.desktop;
    } else if ((this.bIsTouchDevice && isTabletSize && this.bIsCordova) || desktopLook==='tablet_cordova') {
      this.ui = UserInterface.tablet_cordova;
    } else if (this.bIsCordova || desktopLook==='phone_cordova') {
      this.ui = UserInterface.phone_cordova;
    } else if ((this.bIsTouchDevice && isTabletSize) || desktopLook==='tablet') {
      this.ui = UserInterface.tablet;
    } else if (this.bIsTouchDevice || desktopLook==='phone') {
      this.ui = UserInterface.phone;
    }
    if (this.bIsTouchDevice) {
      addEventListener('orientationchange', browserWindowResized);
    }
    addEventListener('resize', browserWindowResized);
    setTimeout(() => {
      browserWindowResized();
    }, 1);
    if (this.bIsEdge || this.bIsIE) {
      document.getElementsByTagName('BODY')[0].classList.add('ie_edge');
    }
  }

  public init(): void {
    if (!this.bInitStarted) {
      this.bIsOfficeAddin = window.hasOwnProperty('Office');
      this.bInitStarted = true;
      if (this.bIsOfficeAddin && Office && (Office.edx_test || Office.context && Office.context.platform)) {
        this.bIsOfficeAddin = true;
        this.ui = UserInterface.phone;
        if (Office.context && Office.context.platform) {
          this.bUseWebLogin = false;
          if (Office.context.platform.toLowerCase().indexOf('online')>=0) {
            this.bIsOfficeAddinWeb = true;
          } else {
            this.bIsOfficeAddinDesktop = true;
          }
          this.bIsOfficeAddinOutlook = !!Office.context.mailbox;
          this.bIsOfficeAddinOutlookMessageEditable = !!Office.context.mailbox && !!Office.context.mailbox.item && !!Office.context.mailbox.item.addFileAttachmentAsync;
          this.bIsOfficeAddinWord = window.hasOwnProperty('Word');
          this.bIsOfficeAddinExcel = window.hasOwnProperty('Excel');
          this.bIsOfficeAddinPowerPoint = window.hasOwnProperty('PowerPoint');
        } else {
          this.bIsOfficeAddinWeb = true;
        }
        this.bInitDone = true;
      } else {
        this.bIsOfficeAddin = false;
        if (this.isMobile()) {
          this.bIsTabletSize = !this.bIsOfficeAddin && screen.availWidth > kPhoneWidth && screen.availHeight > kPhoneWidth;
        }
      }
      if (!this.bIsOfficeAddin) {
        setTimeout(() => {
          // calling microsoftTeams.initialize(callback) outside of teams does NOT call callback so timeout
          this.bInitDone = true;
        }, 1000);
        microsoftTeams.initialize(() => {
          microsoftTeams.getContext((context: microsoftTeams.Context) => {
            if (!!context && !!context.entityId) {
              this.bIsTeamsAddIn = true;
              this.teamsContext = context;
              this.bInitDone = true;
            }
          });
        });
      }
    }
  }

  public initialized(): boolean {
    return this.bInitDone;
  }

  public isMobile(): boolean {
    return this.ui>=UserInterface.phone;
  }

  public isMobileDevie(): boolean {
    return this.isMobile() && !this.bIsOfficeAddin;
  }

  public isPhoneLook(): boolean {
    return this.isMobile() && !this.bIsTabletSize;
  }

  public isTabletLook(): boolean {
    return this.isMobile() && this.bIsTabletSize;
  }

  public isWebLook(): boolean {
    return !this.isMobile() && this.ui!==UserInterface.desktop;
  }

  public isDesktopLook(): boolean {
    return !this.isMobile() && this.ui===UserInterface.desktop;
  }

  public isWhiteIconLook(): boolean {
    return this.isMobile() && !this.bIsOfficeAddin;
  }

  public isPortrait(): boolean {
    if (typeof window === 'undefined' || typeof window.orientation === 'undefined') {
      return this.height> this.width;
    }
    return orientation===0 || orientation===180;
  }

  public isLandscape(): boolean {
    return !this.isPortrait();
  }

  public canDownloadOfficeDoc(item: any): boolean {
    return (this.bIsOfficeAddinWord && item['APP_ID']==='MS WORD') || (this.bIsOfficeAddinExcel && item['APP_ID']==='MS EXCEL') || (this.bIsOfficeAddinPowerPoint && item['APP_ID']==='MS POWERPOINT');
  }

  public getUserLanguage(withCountry: boolean): string {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return undefined;
    }
    const nav: any = window.navigator;
    let browserLang: any = (nav.languages && nav.languages.length>0) ? nav.languages[0] : (nav.language || nav.browserLanguage || nav.userLanguage);

    if (browserLang && !withCountry) {
      if (browserLang.indexOf('-') !== -1) {
        browserLang = browserLang.split('-')[0];
      }
      if (browserLang.indexOf('_') !== -1) {
        browserLang = browserLang.split('_')[0];
      }
    }
    return browserLang;
  }

  public deviceDidRotate(): void {
    // we are called by a programmatic button click in the app header
    // we can not just repsond to the orientation change event as cordova
    // delays it and angular refuses to render unless we are called by a user event
    // such as a click that we initiate in code. this is very crappy but it works
    // could be an AppWorks thing or a combination of AppWorks and cordova
    const portrait: boolean = this.isPortrait();
    for (const listener of this.orientationListeners) {
      listener.deviceDidRotate(portrait);
    }
  }

  public registerOrientationListener(listener: OrientationListener): void {
    const index: number = this.orientationListeners.indexOf(listener);
    if (index===-1) {
      this.orientationListeners.push(listener);
    }
  }

  public deregisterOrientationListener(listener: OrientationListener): void {
    const index: number = this.orientationListeners.indexOf(listener);
    if (index>=0) {
      this.orientationListeners.splice(index,1);
    }
  }
}
