<ng-template #searchTemplate [ngIf]="!!sections" let-searchTemplateShown="searchTemplateShown" let-disabled="disabled">
<ng-template [ngIf]="searchTemplateShown">
 <div *ngIf="showSearchBar || searchbarShowing || searchbarHiding" class="header-search-bar" [ngClass]="{showing:searchbarShowing,hiding:searchbarHiding,shown:showSearchBar,disabled:disabled, adjust:ui==0&&canUserAccessAdminMaintenance()}" (animationend)="searchbarAnimationComplete()">
   <input #searchTextInput placeholder="{{searchPlaceHolder}}" type="text" autocomplete="off" id="edxSearchText" tabindex="2" [disabled]="disabled" class="headersearchtext" [ngClass]="{container:showSearchWhere}" (keyup)="searchTextEntered($event)" (blur)="searchTextBlur($event)">
   <div *ngIf="showClearIcon" [ngClass]="{searchclear:true, container:showSearchWhere}" tabindex="4" (click)="clearSearch()" (keyup.enter)="clearSearch()" (keyup.space)="clearSearch()" title = "{{this.localizer.getTranslation('TOOLTIP.CLEAR_KEYWORDS')}}"></div>
   <ng-template [ngIf]="showSearchWhere">
     <div class="search-separator left"></div>
     <edx-menu #searchWhereMenu [menuID]="35" [callback]="this" class="search-where-menu" [tabIndex]="5"></edx-menu>
     <div class="header-search-where" [ngClass]="{global:searchWhere===0, onelevel:searchWhere===2}" title="{{'GLOBAL_SEARCHSCOPE.SEARCH_WHERE' | localize}}" [tabindex]="ui>=2?4:-1" (click)="searchWhereButtonClicked($event)" (keyup.enter)="searchWhereButtonClicked($event)" (keydown.space)="searchWhereButtonClicked($event)"  (keydown.shift.tab)="closeMenuOnFocusOut(searchWhereMenu)"></div>
   </ng-template>
   <div class="search-separator"></div>
   <ul *ngIf="searchSuggestionsShown" class="searchsuggestions" id="edx_search_suggestions" role="menu">
     <ng-template [ngIf]="!loadingSuggestionsCount">
       <li *ngFor="let suggestion of searchSuggestions" [ngClass]="{selected:suggestion===curSearchSuggestion}" role="menuitem" tabindex="3" (keyup.enter)="suggestionClicked(suggestion)" (keyup.space)="suggestionClicked(suggestion)" (click)="suggestionClicked(suggestion)">
         <div>{{suggestion}}</div>
       </li>
     </ng-template>
     <div *ngIf="loadingSuggestionsCount" class="loading">
       <edx-spinner [mini]="true"></edx-spinner>
     </div>
   </ul>
   <edx-search-menu *ngIf="!disabled" [callback]="this" #searchMenu [app]="app" class="search-menu" [adjust]="ui==0&&canUserAccessAdminMaintenance()"></edx-search-menu>
 </div>
</ng-template>
</ng-template>
<div *ngIf="(ui>=2) && maskHeaderCount" class="header-mask" [ngClass]="{cordova:ui>3,oai:isOfficeAddin}"></div>
<button *ngIf="ui==1 || ui>=4" #cordovaCmdButton class="cordovacmdbutton" (click)="doCordavaCommand()"></button>
<div class="header" [ngClass]="{web:ui==0,desktop:ui==1,mobile:ui>1,phone:ui==2||ui==4,cordova:ui>3,oai:isOfficeAddin,oaidbutton:hasOfficeInfoButton}">
 <ng-template [ngIf]="ui<4">
   <div *ngIf="showBack()" class="toucharea left" (click)="leftClicked($event)" (keyup.enter)="leftClicked($event)" (keyup.space)="leftClicked($event)" id="edx_hdr_btn_left" tabindex="1">
     <div class="toucharea-inner">
       <div [ngClass]="{iconic:!getLeftText(),headertext:getLeftText(),back:!getLeftText()}">{{getLeftText()}}</div>
     </div>
   </div>
   <div *ngIf="showMobileHamburger()" class="toucharea left" [ngClass]="{hasback:showBack()}" tabindex="1" aria-haspopup="true" (click)="hamburgerClicked($event)" (keyup.enter)="hamburgerClicked($event)" (keyup.space)="hamburgerClicked($event)" id="edx_hdr_hamburger_menu" (keydown.shift.tab)="closeHamburger()">
     <div class="toucharea-inner">
       <div class="iconic hamburger"></div>
     </div>
   </div>
   <div *ngIf="ui<2" class="header-hamburger" title="{{'HEADER.MAIN' | localize}}" tabindex="1" aria-haspopup="true" [ngClass]="{disabled:navDisabledCount}" (keyup.enter)="!navDisabledCount?hamburgerClicked($event):null" (keyup.space)="!navDisabledCount?hamburgerClicked($event):null" (click)="!navDisabledCount?hamburgerClicked($event):null" id="edx_app_nav" (keydown.escape)="closeHamburger()" (keydown.shift.tab)="closeHamburger()"></div>
   <div class="header-center" *ngIf="displayBranding()">
     <div class="header-branding" [ngClass]="{web:ui==0}" (click)="homeButtonClicked($event)">
        <div *ngIf="ui==0||ui==1" class="header-logo"
              title="{{this.localizer.getTranslation(getTitle())}}"></div>
        <div class="header-app-name">{{getTitle()}}</div>
     </div>
   </div>
   <div *ngIf="ui>=2" class="toucharea right" [ngClass]="{disabled:!rightEnabled()}" [tabindex]="rightEnabled()?1:-1" (keyup.enter)="rightClicked($event)" (keyup.space)="rightClicked($event)" (click)="rightClicked($event)" (keydown.shift.tab)="closeMenuOnFocusOut(actionMenu)" id="edx_hdr_btn_right">
     <div class="toucharea-inner">
       <div [ngClass]="{iconic:!getRightText(),headertext:getRightText(),action:showRightActionIcon()}" [style.backgroundImage]="getRightBGIcon()">{{getRightText()}}</div>
     </div>
   </div>
   <edx-menu *ngIf="ui>=2 && getActionMenuID()>=0" #actionMenu [menuID]="getActionMenuID()" [tabIndex]="rightEnabled()?1:-1" [callback]="getCurHdrCommandHandler()" [menuTitle]="getActionMenuTitle()" [menuIcon]="getActionMenuIcon()"></edx-menu>
 </ng-template>
 <ng-template [ngIf]="ui>=2 && showMobileSearchHeader()">
   <ng-container *ngTemplateOutlet="searchTemplate;context:{searchTemplateShown:true, disabled:navDisabledCount}"></ng-container>
 </ng-template>
 <ng-template [ngIf]="ui<2">
  <edx-menu *ngIf="ui==0" #userMenu [menuID]="7" [tabIndex]="10" [callback]="this" class="user-menu" [ariaLabel]="'HEADER.SETTINGS' | localize"></edx-menu>
  <div *ngIf="ui==0" class="header-user" title="{{'HEADER.SETTINGS' | localize}}" tabindex="-1" (click)="userButtonClicked($event)" (keyup.enter)="userButtonClicked($event)" (keyup.space)="userButtonClicked($event)"></div>  
  <edx-menu #helpMenu [menuID]="46" [tabIndex]="9" [callback]="this" [ngClass]="{'help-menu': ui==0, 'help-menu-electron': ui==1}" [ariaLabel]="'HEADER.HELP' | localize"></edx-menu>
  <div class="header-help" title="{{'HEADER.HELP' | localize}}" tabindex="-1" (click)="helpButtonClicked($event)" (keyup.enter)="helpButtonClicked($event)" (keyup.space)="helpButtonClicked($event)"></div>
  <ng-container *ngTemplateOutlet="searchTemplate;context:{searchTemplateShown:true, disabled:navDisabledCount}"></ng-container>
  <div class="refresh-wrapper header-ring" role="button" [attr.aria-label]="'HEADER.REFRESH' | localize" tabindex="8" (keyup.enter)="refresh()" (keyup.space)="refresh()"><edx-refresher class="refresher" id="edx-hdr-refresh" (refresh)="refresh()" [name]="reloadStr"></edx-refresher></div>
  <div *ngIf="canUserAccessAdminMaintenance()" class="header-admin" role="button" [title]="'ADMIN.ADMINISTRATION' | localize" [attr.aria-label]="'ADMIN.ADMINISTRATION' | localize" tabindex="7" (click)="launchAdminMaintenance()" (keyup.enter)="launchAdminMaintenance()" (keyup.space)="launchAdminMaintenance()"></div>
 </ng-template>
 <ng-template [ngIf]="ui>=4">
   <edx-menu *ngIf="getActionMenuID()>=0" #actionMenu [tabIndex]="rightEnabled()?1:-1" [menuID]="getActionMenuID()" [callback]="getCurHdrCommandHandler()" [menuTitle]="getActionMenuTitle()" [menuIcon]="getActionMenuIcon()"></edx-menu>
 </ng-template>
</div>
