import { Injectable } from '@angular/core';
import { Util } from '../utils/utils.module';
import { ListData } from '../models/base';

const kRLKey = 'edx_recent_locations';

@Injectable({
  providedIn: 'root'
})
export class RecentLocationService {
  constructor() { }

  private setRecentLocationsInLocalStorage(recentLocations: any[]): void {
    if (!!recentLocations && recentLocations.length) {
      localStorage.setItem(kRLKey, JSON.stringify(recentLocations));
    }
  }

  private transformRecentLocationEntries(recentLocations: any[]): any[] {
    const locs: any[] = [];
    if (!recentLocations || !recentLocations.length) {
      return locs;
    }
    const recentLocationsMaxCount = Util.RestAPI.getRecentLocationsMaxCount();
    const libraries = Util.RestAPI.getLibraryNamesListInLowerCase();
    let addedRecentLocsCount = 0;
    let recentLocData: any = null;
    let recentIds = '';
    let library;
    for (const recentLoc of recentLocations) {
      if (addedRecentLocsCount >= recentLocationsMaxCount) {
        break;
      }
      recentLocData = JSON.parse(recentLoc.DATA);
      library = recentLocData.lib.toLocaleLowerCase();
      if (!!recentLocData) {
        if (libraries?.indexOf(library) >= 0 && this.getRecentLocationIndexIfAlreadyExists(recentLocData, locs) < 0) {
          locs.push(recentLocData);
          addedRecentLocsCount++;
          if (!isNaN(parseInt(recentLocData.id))) {
            recentIds += (!!recentIds ? ',' : '') + recentLocData.id;
          }
        }
      }
    }
    const queryString = `?library=${library.toLocaleUpperCase()}&libs=${libraries.join(',').toLocaleUpperCase()}`;
    Util.RestAPI.post('searches', { criteria: { DOCNUM: recentIds }, DESCRIPTION: recentIds }, 'evaluation', queryString).subscribe((listData: ListData) => {
      if (!!listData?.list?.length) {
        const filteredLocs = locs.filter(function (item) {
          return isNaN(parseInt(item.id)) || listData.list.findIndex(ac => ac.id === item.id && ac.lib.toLocaleUpperCase() === item.lib.toLocaleUpperCase()) !== -1;
        })
        this.setRecentLocationsInLocalStorage(filteredLocs);
      }
    }, error => {
    });
    return locs;
  }

  private getRecentLocationIndexIfAlreadyExists(loc, existingRecentLocs?: any[]): number {
    if (!loc) {
      return -1;
    }
    if (!existingRecentLocs) {
      existingRecentLocs = this.getRecentLocationsFromLocalStorage();
    }
    return existingRecentLocs && existingRecentLocs.length ? existingRecentLocs.findIndex(l => loc.id && l.id === loc.id && l.type === loc.type && l.lib === loc.lib) : -1;
  }

  public getRecentlyUsedContainers(): void {
    Util.RestAPI.get('/settings', 'recentlocations', 'descending=LAST_USED_ON').subscribe((listData: ListData) => {
      if (!listData || !listData.list || !listData.list.length) {
        const recentLocStr = localStorage.getItem(kRLKey);
        if (recentLocStr && recentLocStr.length) {
          Util.RestAPI.put('settings/recentlocations/', recentLocStr).subscribe(() => {});
        }
      } else {
        this.setRecentLocationsInLocalStorage(this.transformRecentLocationEntries(listData.list));
      }
    }, err => {});
  }

  public createRecentLocationItem(item): any {
    if (!item) {
      return null;
    }
    const name = (item.DOCNAME || item.name || '') + (item.PD_FILE_NAME || '');
    return {
      id: item.id,
      DOCNUM: item.DOCNUM,
      type: item.type,
      lib: item.lib,
      DOCNAME: name,
      pickerpath: item.pickerpath
    };
  }

  public getRecentLocationItemInJSON(item): string {
    const recentLoc = this.createRecentLocationItem(item);
    return !!recentLoc ? JSON.stringify(recentLoc) : '';
  }

  public updateRecentLocationInLocalStorage(newRecentLoc: any, remove = false): void {
    if (!newRecentLoc) {
      return;
    }
    const recentLocs = this.getRecentLocationsFromLocalStorage();
    if (!!recentLocs) {
      const locObj = typeof newRecentLoc === 'object' ? newRecentLoc : JSON.parse(newRecentLoc);
      const index = this.getRecentLocationIndexIfAlreadyExists(locObj);
      if (index >= 0) {
        recentLocs.splice(index, 1);
      }
      if (!remove) {
        recentLocs.unshift(locObj);
      }
      if (recentLocs.length > Util.RestAPI.getRecentLocationsMaxCount()) {
        recentLocs.splice(-1);
      }
      this.setRecentLocationsInLocalStorage(recentLocs);
    }
  }

  public getRecentLocationsFromLocalStorage(): any[] {
    const recentStr: string = localStorage.getItem(kRLKey);
    return !!recentStr ? JSON.parse(recentStr) : [];
  }
}
