import { Injectable } from '@angular/core';

import { LocalizeService } from '../services/localize.service';
import { TileService } from './tile.service';
import { NavItem } from '../models/nav-item';
import { Tile } from '../models/tile';
import { Util } from '../utils/utils.module';

@Injectable()
export class NavService {
  private navItems: NavItem[] = [];

  constructor(private tileService: TileService, private localizer: LocalizeService) { }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  public reset(): void {
    this.navItems = [];
  }

  public changingPrimaryLibraries(): void {
    this.reset();
  }

  public async getItems(forceGet?: boolean): Promise<NavItem[]>  {
    if (!forceGet && this.navItems && this.navItems.length) {
      return Promise.resolve(this.navItems);
    }

    const hiddenRemotes: string[] = (Util.RestAPI.getPreference('hidden_remote_libs') as any) || [];
    this.navItems = [];
    return this.tileService.getTiles().then(async (tiles) => {
      let tile;
      this.navItems = [];
      tiles = tiles || [];
      const primaryLibrary: string = Util.RestAPI.getPrimaryLibrary();
      this.navItems.push({
        type:'heading',
        id:'',
        lib:'',
        name:primaryLibrary,
        imgPath: null
      });
      const hiddenTiles: Tile[] = [];
      const addToHidden = (aTile: Tile): void => {
        const existingIndex = hiddenTiles.findIndex(t => t.lib===aTile.lib && t.id===aTile.id && t.type===aTile.type);
        if (existingIndex === -1) {
          hiddenTiles.push(tile);
        }
      };
      const addTile = (aTile: Tile): void => {
        const existingIndex = this.navItems.findIndex(t => t.lib===aTile.lib && t.id===aTile.id && t.type===aTile.type);
        if (existingIndex === -1) {
          this.navItems.push({
            type:aTile.type,
            id:aTile.id,
            lib:aTile.lib,
            name:this.localizer.getTranslation(aTile.name),
            imgPath: null
          });
        }
      };
      for (tile of tiles) {
        if (tile.name !== 'hero') {
          if (tile.index === -1) {
            addToHidden(tile);
          } else {
            addTile(tile);
          }
        }
      }
      for (tile of hiddenTiles) {
        if (!!tile['UserOverridable']) {
          addTile(tile);
        }
      }
      const libraries: any[] = Util.RestAPI.getLibraries();
      const primaryNameUC: string = primaryLibrary.toUpperCase();
      if (libraries && libraries.length) {
        for (let lib of libraries) {
          const libraryName: string = lib.LIBRARY_NAME.toUpperCase();
          if (lib.DISABLED === 'N' && libraryName !== primaryNameUC && hiddenRemotes.indexOf(libraryName) === -1) {
            lib = await Util.RestAPI.checkLibrarySettingsByName(libraryName).toPromise();
            this.navItems.push({
              type: 'separator',
              id: '',
              lib: '',
              name: '',
              imgPath: null
            });
            this.navItems.push({
              type: 'heading',
              id: '',
              lib: lib.LIBRARY_NAME,
              name: lib.LIBRARY_NAME,
              imgPath: null
            });
            this.navItems.push({
              type: 'folders',
              id: 'public',
              lib: lib.LIBRARY_NAME,
              name: this.localizer.getTranslation('TILE_NAMES.PUBLIC_FOLDERS'),
              imgPath: null
            });
            this.navItems.push({
              type: 'searches',
              id: '',
              lib: lib.LIBRARY_NAME,
              name: this.localizer.getTranslation('TILE_NAMES.QUICK_SEARCH'),
              imgPath: null
            });
            if (!!lib.EFFECTIVE_RIGHTS && lib.EFFECTIVE_RIGHTS.ENABLE_WORKSPACE === 'Y') {
              this.navItems.push({
                type: 'workspaces',
                id: '',
                lib: lib.LIBRARY_NAME,
                name: this.localizer.getTranslation('TILE_NAMES.WORKSPACES'),
                imgPath: null
              });
            }
            if (!!lib.EFFECTIVE_RIGHTS && lib.EFFECTIVE_RIGHTS.ENABLE_FLEXFOLDERS === 'Y') {
              this.navItems.push({
                type: 'flexfolders',
                id: '',
                lib: lib.LIBRARY_NAME,
                name: this.localizer.getTranslation('TILE_NAMES.FLEXFOLDERS'),
                imgPath: null
              });
            }
            if (!!lib.EFFECTIVE_RIGHTS && lib.EFFECTIVE_RIGHTS.ENABLE_RECORDS_MANAGEMENT === 'Y') {
              this.navItems.push({
                type: 'fileplans',
                id: '',
                lib: lib.LIBRARY_NAME,
                name: this.localizer.getTranslation('TILE_NAMES.FILEPLAN'),
                imgPath: null
              });
            }
          }
        }
      }
      const apps: any[] = Util.RestAPI.getLoginReply().APPLICATIONS;
      if (apps && apps.length) {
        this.navItems.push({
          type:'heading',
          id:'',
          lib:'EX_',
          name:this.localizer.getTranslation('NAVBAR.APPLICATIONS'),
          imgPath: null
        });
        for (const app of apps) {
          if (Util.RestAPI.isExtAppEnabledForSaveToeDOCS(app.apptype)) {
this.navItems.push(app);
}
         }
      }
      return this.navItems;
    });
  }

  public openItem(navItem: NavItem, noAnimate?: boolean): void {
    let param: string;
    let pathName: string;
    let queries: string = 'name='+Util.RestAPI.encodeChildRouteName(navItem.name);
    if (navItem['imgPath']) {
      queries += '&imgPath='+Util.RestAPI.encodeChildRouteName(navItem['imgPath']);
    }
    if (Util.isContainer(navItem.type)) {
      param = null;
      pathName = navItem.type;
      queries += '&max='+Util.RestAPI.getDefualtMaxItems();
    } else {
      param = 'profile';
      pathName = 'profile';
    }
    if (noAnimate) {
      queries += '&edx_noanimate';
    }
    const url = Util.RestAPI.makeChildRouteURL('home', 'tcc_outlet', pathName, navItem, param, queries);
    Util.RestAPI.navHome().then(() => {
      setTimeout(() => {
        Util.RestAPI.navToURL(url);
      }, Util.kPopupDismissMS);
    });
  }
}
