import { Injectable } from '@angular/core';
import { subscribeOn } from 'rxjs/operators';
import { FormField } from '../models/form-field';
import { Util } from '../utils/utils.module';

@Injectable()
export class UploadService {
    private contentFileList: any = [];
    private filesToFormMap = null;
    private initialForm = true;
    private securityData = null;
    private requiredFields = [];
    private serverData = null;

    constructor() {

    }

    public mapFilesToForm(fileList: any[], desc: any): void {
        const filesToFormMap = {};
        let form = '';
        for (let i = 0; i < fileList?.length; i++) {
            form = Util.RestAPI.getFormFromFileName(fileList[i].name);
            if (!filesToFormMap[form]) {
                filesToFormMap[form] = [];
            }
            filesToFormMap[form].push(fileList[i]);
        }
        this.filesToFormMap = filesToFormMap;
    }

    public refreshFileToFormMapping(formChangedName: string, prevForm: string, applyAllChecked: boolean): any {
        const filesToFormMap = this.filesToFormMap;
        let formNames;
        for (let i = 0; i < this.contentFileList?.length; i++) {
            formNames = Util.RestAPI.getFormFromFileName(this.contentFileList[i].name, true);
            if (!!(formNames?.find(c => c['%FORM_NAME'] === formChangedName))) {
                if (!filesToFormMap[formChangedName]) {
                    filesToFormMap[formChangedName] = [];
                }
                if (filesToFormMap[formChangedName]?.indexOf(this.contentFileList[i]) === -1) {
                    filesToFormMap[formChangedName]?.push(this.contentFileList[i]);
                }
                if (filesToFormMap[prevForm]?.indexOf(this.contentFileList[i]) !== -1) {
                    filesToFormMap[prevForm]?.splice(filesToFormMap[prevForm].indexOf(this.contentFileList[i]), 1);
                }
            }
        }
        this.filesToFormMap = filesToFormMap;
        return applyAllChecked ? this.filesToFormMap?.[formChangedName] : this.contentFileList;
    }

    public getFilesToFormMap(): any {
        return this.filesToFormMap;
    }

    public setFilesToFormMap(filesToFormMap: any) {
        this.filesToFormMap = filesToFormMap;
    }

    public getFilesToUpload(isGroup: boolean = false): any {
        const files = this.filesToFormMap ? Object.values(this.filesToFormMap)[0] : [];
        return isGroup ? files : this.contentFileList;
    }

    public getContentFileList(): any[] {
        return this.contentFileList;
    }

    public isInitialForm(): boolean {
        return this.initialForm;
    }

    public getSecurityData(): any {
        return this.securityData;
    }

    public setData(contentFileList: any[], desc: any, securityData: any, initialForm: boolean) {
        if (contentFileList !== undefined) {
            this.contentFileList = contentFileList;
            this.mapFilesToForm(contentFileList, desc);
        }
        if (securityData !== undefined) {
            this.securityData = securityData;
        }
        if (initialForm !== undefined) {
            this.initialForm = initialForm;
        }
        if (initialForm) {
            this.getNewRequiredFields(Object.keys(this.filesToFormMap)[0], desc).then(fields => {
                this.requiredFields = fields;
            });
        }
    }

    public setServerData(serverData: any) {
        this.serverData = serverData;
    }

    public getServerData(): any {
        return this.serverData;
    }

    public resetData(): void {
        this.contentFileList = [];
        this.filesToFormMap = null;
        this.securityData = null;
        this.initialForm = true;
        this.requiredFields = [];
        this.serverData = null;
    }

    public deleteUploadedFiles(fileNames: any): void {
        const names = Util.deepCopy(fileNames);
        const filesLength = names?.length;
        let form = '';
        if (!!this.filesToFormMap && this.contentFileList?.length > 0) {
            for (let i = 0; i < filesLength; i++) {
                form = Util.RestAPI.getFormFromFileName(names[i]);
                if (!!this.filesToFormMap[form]) {
                    const index = this.filesToFormMap[form].findIndex(file => file.name === names[i]);
                    this.filesToFormMap[form].splice(index, 1);
                    if (this.filesToFormMap[form].length === 0) {
                        delete this.filesToFormMap[form];
                    }
                }
                this.contentFileList.splice(this.contentFileList.findIndex(file => file.name === names[i]), 1);
            }
        }
    }

    public getRequiredFields(): any[] {
        return this.requiredFields;
    }

    public setRequiredFields(fields: any[]) {
        this.requiredFields = fields;
    }

    public getNewRequiredFields(formName: any, desc: any): Promise<any> {
        const requiredFields = [];
        let fields = [];
        let newField = null;
        let newSubField = null;
        let subFields = [];
        const lib = !!desc && !!desc.lib ? desc.lib : Util.RestAPI.getPrimaryLibrary();
        return new Promise(resolve => {
            Util.RestAPI.getFormTemplate(formName, lib).toPromise().then(template => {
                if (!!template) {
                    fields = template.defs;
                    for (const field of fields) {
                        newField = new FormField(field, 'profile');
                        if (newField.isRequired) {
                            requiredFields.push(newField.name);
                        }
                        if (!!field.fields) {
                            subFields = field.fields;
                            for (const subField of subFields) {
                                newSubField = new FormField(subField, 'profile');
                                if (newSubField.isRequired) {
                                    requiredFields.push(newSubField.name);
                                }
                            }
                        }
                    }
                    resolve(requiredFields);
                }
            });
        });
    }

    public fileNameForUpload(applyAllChecked: boolean, kind: string, fileList: File[], filePaths: any[], dataFiles: any[], copyFileName: string, decodeFileName: boolean = true): string {
        let names: string = null;
        let nFiles: number = fileList && fileList.length > 0 ? fileList.length : filePaths && filePaths.length > 0 ? filePaths.length : dataFiles ? dataFiles.length : 0;
        if (nFiles) {
            nFiles = applyAllChecked ? nFiles : 1;
            for (let i = 0; i < nFiles; i++) {
                let name: string;
                if (fileList && fileList.length > 0) {
                    const file: File = fileList[i];
                    name = file.name;
                } else if (filePaths && filePaths.length > 0) {
                    name = Util.RestAPI.getFileNameFromPath(filePaths[i]);
                    if (decodeFileName && kind === 'profile_checkin') {
                        const docObj = Util.RestAPI.decodeFileName(name);
                        if (!!docObj) {
                            name = docObj.name + (!!docObj.ext ? '.' + docObj.ext : '');
                        }
                    }
                } else if (dataFiles && dataFiles.length>0) {
                    name = dataFiles[i].name;
                }
                if (name) {
                    const parts: string[] = name.split('.');
                    if (parts.length > 1) {
                        parts.splice(parts.length - 1, 1);
                        name = parts.join('.');
                    }
                    name = name.replace(/,; /g, '_');//replace(', ', '__');
                    if (names) {
                        names += Util.RestAPI.kMultiFileSeparator + name;
                    } else {
                        names = name;
                    }
                }
            }
        } else if (copyFileName) {
            names = copyFileName;
        }
        return names;
    }

    public updateAttachNum(serverData: any, dataFiles: any[], emailHasAttachments: boolean): any {
        const nDataFiles = dataFiles.length;
        serverData['MSG_ITEM'] = '1';
        const firstAttachNum: number = dataFiles[0].attachNum;
        if (dataFiles.findIndex(item => item['url'] === 'promise_msg') !== -1 && nDataFiles > 1) {
            serverData['ATTACH_NUM'] = '';
            for (let i = 0; i < nDataFiles; i++) {
                if (dataFiles[i]['url'] === 'promise_msg') {
                    serverData['ATTACH_NUM'] += !emailHasAttachments ? '0' : '-1';
                } else {
                    serverData['ATTACH_NUM'] += (dataFiles[i].attachNum + 1).toString();
                }
                serverData['MAIL_ID'] = !!serverData['MAIL_ID'] ? serverData['MAIL_ID'] : dataFiles[i].mailID;
                if (i < nDataFiles - 1) {
                    serverData['ATTACH_NUM'] += Util.RestAPI.kMultiFileSeparator;
                }
            }
        } else if (firstAttachNum !== undefined && firstAttachNum !== null) {
            if (firstAttachNum === -1) {
                serverData['ATTACH_NUM'] = !emailHasAttachments ? '0' : '-1';
                serverData['MAIL_ID'] = !!serverData['MAIL_ID'] ? serverData['MAIL_ID'] : dataFiles[0].mailID;
            } else {
                serverData['ATTACH_NUM'] = '';
                for (let i = 0; i < nDataFiles; i++) {
                    const dataFile = dataFiles[i];
                    const curAttachNum: number = dataFile.attachNum + 1;
                    serverData['ATTACH_NUM'] += curAttachNum.toString();
                    serverData['MAIL_ID'] = dataFile.mailID;
                    if (i < nDataFiles - 1) {
                        serverData['ATTACH_NUM'] += Util.RestAPI.kMultiFileSeparator;
                    }
                }
            }
        } else {
            serverData['ATTACH_NUM'] = !emailHasAttachments ? '0' : '-1';
        }
        return serverData;
    }


    public requiredFieldsSatisfied(fields: any[]): boolean {
        if (fields.every(field => this.requiredFields.includes(field))) {
            return true;
        } else {
            const serverData = this.getServerData();
            const extraFields = fields.filter(field => !this.requiredFields.includes(field));
            this.requiredFields = this.requiredFields.concat(extraFields);
            for (const field of extraFields) {
                if (!serverData || !serverData[field]) {
                    return false;
                }
            }
            return true;
        }
    }

}
