<ng-template [ngIf]="!hideChoosers">
  <div *ngIf="showLocationChooser()"
        class="chooseform"
        [ngClass]="{mobile:ui>=2,phone:ui===2||ui===4,oai:officeAddin,columnview:pickersAreColumnview()}">
    <div *ngIf="kind === 'profile_uploadfolders'" class="uploadfoldercount">
      <img class="folderinfoicon" tabindex="-1" [src]="getInfoIconPath()" alt="{{uploadFolderInfoImgAltStr}}">
      <span>{{uploadFolderInfoStr}}</span>    
    </div>
    <label for="locationchooser">
      {{saveToStr}}
    </label>
    <edx-location-chooser [ariaLabel]="saveToStr"
                          (change)="locationChanged($event)"
                          [id]="'locationchooser'"
                          [desc]="desc"
                          [tabIndex]="tabIndex">
    </edx-location-chooser>
  </div>
  <div *ngIf="showProfilePicker()"
        class="chooseform"
        [ngClass]="{mobile:ui>=2,phone:ui===2||ui===4,oai:officeAddin,columnview:pickersAreColumnview(),copy:kind==='profile_copy'}">
    <label for="formselect">
      {{profileStr}}
    </label>
    <edx-select (change)="changeFormTemplate($event)"
                [ariaLabel]="profileStr"
                [id]="'formselect'"
                [value]="defForm"
                [items]="formSelectList()"
                [disabled]="isProfilePickerDisabled()"
                [inDialog]="inDialog"
                [tabIndex]="tabIndex">
    </edx-select>
  </div>
  <div *ngIf="showSaveAsPicker()"
        class="chooseform"
        [ngClass]="{mobile:ui>=2,phone:ui===2||ui===4,oai:officeAddin,columnview:pickersAreColumnview()}">
    <label for="saveasselect">
      {{this.localizer.getTranslation('FORMS.BUTTONS.SAVE_AS')}}
    </label>
    <edx-select (change)="changeSaveAs($event)"
                [id]="'savaselect'"
                [value]="'D'"
                [items]="saveAsSelectList()"
                [inDialog]="inDialog"
                [tabIndex]="tabIndex">
    </edx-select>
  </div>
</ng-template>
<edx-dynamic-form [formKind]="kind"
                  [createType]="createType"
                  [layout]="layout"
                  [formTemplate]="formTemplate"
                  [inDialog]="inDialog"
                  [inNotify]="inNotify"
                  [data]="formData"
                  [tabIndex]="tabIndex"
                  [desc]="desc"
                  [readOnly]="readOnly"
                  [controller]="this"
                  [inlineParent]="inlineParent"
                  [fileDropTarget]="fileDropTarget"
                  [choosersHidden]="hideChoosers">
</edx-dynamic-form>
<div *ngIf="applyAllShown()" class="applyall">
  <input type="checkbox"
          class="checkbox"
          [ngClass]="{checked:applyAllChecked}"
          tabindex="-1">
  <span [tabindex]="tabIndex"
        (click)="applyAllClicked($event)"
        role="checkbox"
        [attr.aria-checked]="applyAllChecked"
        (keyup.space)="applyAllClicked($event)">
    {{applyAllStr}}
  </span>
</div>
<div *ngIf="relateToStr&&showRelateTo"
      class="applyall"
      [ngClass]="{phone:ui===2||ui===4}">
  <input type="checkbox"
          class="checkbox"
          [ngClass]="{checked:relateToChecked}"
          tabindex="-1">
  <span [tabindex]="tabIndex"
        (click)="relateToClicked($event)"
        role="checkbox"
        [attr.aria-checked]="relateToChecked"
        (keydown.space)="relateToClicked($event)">
    {{relateToStr}}
  </span>
</div>
<div *ngIf="includeTemplatesStr&&showIncludeTemplates"
      class="applyall showIncludeTemplates">
  <input type="checkbox"
          class="checkbox"
          [ngClass]="{checked:includeTemplatesChecked}"
          tabindex="-1">
  <span [tabindex]="tabIndex"
        (click)="includeTemplatesClicked($event)"
        role="checkbox"
        [attr.aria-checked]="includeTemplatesChecked"
        (keydown.space)="includeTemplatesClicked($event)">
    {{includeTemplatesStr}}
  </span>
</div>