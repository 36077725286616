
export interface TabItem {
  title: string;
  id: string;
  selected?: boolean;
  enabled?: boolean;
  icon?: string;
}

export interface TabReceiver {
  tabSelected(id: string): void;
  tabEnabled?(id: string): boolean;
  focusOnContent?(): void;
  focusOnSelectedTab?(): void;
  allowEventPropagation?(): boolean;
}
