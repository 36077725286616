<otc-inline-actions
    *ngIf="isShown()"
    (animationend)="animationComplete()"
    class="inline-action-bar"
    [ngClass]="{mobile:ui >= 2 && !isOfficeAddin, oai:isOfficeAddin, showing:showing, hiding:hiding}">
  <ng-template ngFor let-item [ngForOf]="items" let-i="index">
    <otc-inline-action-item
        *ngIf="item.enabled"
        role="menuitem"
        id="{{item.name}}"
        href=""
        title="{{item.name}}"
        icon="{{item.icon}}"
        attr.aria-label="{{item.name}}"
        (keyup.enter)="itemClick(items.indexOf(item), $event)"
        (keydown.space)="itemClick(items.indexOf(item), $event)"
        (click)="itemClick(items.indexOf(item), $event)">
    </otc-inline-action-item>
  </ng-template>
    <otc-inline-action-item
        *ngIf="enabledCustomMenuItemCount > 0 && isShown()"
        id="more_menu"
        title="More"
        href=""
        icon="action:action_more32"
        (click)="showHideMoreMenu($event)">
    </otc-inline-action-item>
    <otc-menu
        #moreMenu
        id="moreMenu"
        buttonid="more_menu"
        role="menu">
      <ng-template ngFor let-menuItem [ngForOf]="customItems" let-i="index" let-length="count">
        <otc-menuitem
            role="menuitem"
            tabindex="0"
            id="{{menuItem.name}}"
            title="{{menuItem.name}}"
            (keyup.enter)="itemClick(customItems.indexOf(menuItem), $event, true)"
            (keydown.space)="itemClick(customItems.indexOf(menuItem), $event, true)"
            (click)="itemClick(customItems.indexOf(menuItem), $event, true)">
          {{menuItem.name}}
        </otc-menuitem>
      </ng-template>
    </otc-menu>
</otc-inline-actions>